const GlobalStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 12,
  },
  headerProfile: {
    display: 'flex',
    width: '100%',
    height: '64px',
    alignItems: 'center',
    marginBottom: '40px',
    backgroundColor: '#5255A4',
  },
  mainContent: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  buttonStyle1: {
    marginTop: 24,
  },
  buttonStyle2: {
    backgroundColor: '#250E39 !important',
  },
  buttonStyle3: {
    backgroundColor: '#F3F1F5',
  },
  whiteButtonStyle: {
    backgroundColor: '#ffffff',
  },
  label: {
    fontFamily: 'OpenSans',
    color: 'corePurple70.main',
  },
  profileName: {
    fontFamily: 'Museo Sans',
    fontSize: '16px',
    fontWeight: '400',
    color: '#FFFFFF',
  },
  profileImg: {
    color: '#FFFFFF',
    width: '22px',
    height: '22px',
    marginRight: '10px',
    marginLeft: '24px',
  },
  modalCloseBtn: {
    position: 'fixed',
    top: '25px',
    right: '35px',
  },
  link: {
    cursor: 'pointer',
    color: '#5255A4',
    textDecoration: 'underline',
  },
};

export default GlobalStyle;
