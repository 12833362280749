import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

import ModalStyle from '../../styles/ModalStyle';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const useStyles = makeStyles(ModalStyle);

export default function ConfirmModal({
  open = false,
  onConfirm,
  onClose,
  onExit,
  title,
  content,
  specificStyle = null,
  okText = 'OK',
  cancelText = 'Cancel',
  exitText = 'Cancel',
  showConfirm = true,
  showExit = false,
  showCancel = true,
  loading = false,
}) {
  const classes = useStyles();
  return (
    <div>
      {open && (
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: `${classes.modal} ${specificStyle}`,
          }}
          maxWidth="xs"
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={(e, reason) => {
            if (reason === 'backdropClick') {
              return false;
            }
            return onClose(e);
          }}
          aria-labelledby="large-modal-slide-title"
          aria-describedby="large-modal-slide-description">
          <DialogTitle
            id="large-modal-slide-title"
            className={classes.modalHeader}>
            {/* <Button
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={onClose}>
              <CloseIcon className={classes.modalClose} />
            </Button> */}
            <Typography pr={2} pl={2} pt={1} variant="h5" align="center">
              {title}
            </Typography>
          </DialogTitle>
          <DialogContent
            id="large-modal-slide-description"
            className={classes.modalBody}>
            <Grid pr={2} pl={2} pb={2} container>
              <Grid item sm={12} xs={12} mb={2}>
                {content}
              </Grid>
              <Grid item width="100%">
                {showConfirm && (
                  <>
                    <Button
                      disabled={loading}
                      className={classes.confirmButton}
                      variant="contained"
                      color="primary"
                      onClick={onConfirm}>
                      {okText}
                    </Button>
                    &nbsp;
                  </>
                )}
                {showCancel && (
                  <Button
                    sx={{ marginTop: -1 }}
                    className={classes.cancelButton}
                    onClick={onClose}
                    disabled={loading}>
                    {cancelText}
                  </Button>
                )}
                {showExit && (
                  <Button
                    sx={{ marginTop: 1 }}
                    className={classes.exitButton}
                    onClick={onExit}
                    disabled={loading}>
                    {exitText}
                  </Button>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

ConfirmModal.propTypes = {
  open: PropTypes.bool,
  showConfirm: PropTypes.bool,
  hideCancel: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  onExit: PropTypes.func,
  exitText: PropTypes.string,
  showExit: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.node,
  specificStyle: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
};
