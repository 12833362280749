import React, { createContext, useContext } from 'react';
import { API } from 'aws-amplify';

import {
  INIT_ROUND_UP,
  SET_LOADING,
  SET_ROUND_UP,
  SET_ROUND_AMOUNTS,
  SET_PLAID_LINK_TOKEN,
  SET_PLAID_PUBLIC_TOKEN,
  SET_TRANSACTION_FILTER,
  SET_LINKED_BANK,
  SET_PLAID_PAYROLL_LINK_TOKEN,
  SET_PLAID_PAYROLL_PUBLIC_TOKEN,
  SET_PLAID_PAYROLL_SUCCESS_DATA,
  SET_BILL_GO_DATA,
  SET_LINKED_PAYROLL,
  SET_TRANSACTION_DATA,
  UPDATE_TRANSACTION_DATA,
  SET_PLAID_BANK_PUBLIC_TOKEN,
  SET_PLAID_BANK_SUCCESS_DATA,
  SET_PLAID_BANK_LINK_TOKEN,
  SET_ALL_LINKED_BANKS,
  UPDATE_LINKED_BANK,
  REMOVE_LINKED_BANK,
} from './types';
import CreateDataContext from './CreateDataContext';

import {
  API_ROUNDUP,
  API_PLAID,
  SOMETHING_WRONG,
  API_BILL_GO,
  API_TRANSACTION,
} from 'capio-common/src/constants';
import { convertDateLabel, formatError } from 'capio-common/src/main';

export const PaymentContext = createContext();

const initialState = {
  roundUp: {
    id: null,
    frequency: '',
    amount: 0,
    depositMode: '',
    autoDeposit: false,
    depositDate: null,
    depositDateLabel: '',
    roundUpResult: false,
    roundUpEditing: false,
  },
  loading: false,
  error: null,
  debtTransactionFilter: 'matching',
  transactionType: '',
  dateRange: '',
  roundAmounts: null,
  plaid: {
    linkToken: null,
    payrollLinkToken: null,
    currentNotificationId: null,
    access_token: null,
    payrollAccessToken: null,
    plaidInfoId: null,
    account: null,
    exchangePlaidInfo: null,
    institution: null,
    payrollUploaded: false,
    bankLinkToken: null,
  },
  allLinkedBanks: [],
  billGo: {
    userId: null,
    token: null,
    bills: [],
    downloadUrl: null,
  },
  transactionData: {
    total: 0,
    transactions: [],
  },
};

const setLoading = (dispatch, loading) => {
  dispatch({
    type: SET_LOADING,
    payload: { loading },
  });
};

const setUpRoundUp = (dispatch) => {
  return async (jwt, roundUpData, checkAuthentication) => {
    const { frequency, amount, depositMode, manualDate, depositDate } =
      roundUpData;
    const path = '/roundup/settings/set';
    let body;
    if (depositMode === 'auto') {
      body = {
        depositMode,
        autoDeposit: true,
        frequency,
        depositDate,
        createdAt: new Date(),
      };
    } else {
      body = {
        depositMode,
        autoDeposit: false,
        amount,
        manualDate,
        createdAt: new Date(),
      };
    }

    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body,
    };

    try {
      setLoading(dispatch, true);
      const roundUpResult = await API.post(API_ROUNDUP, path, init);
      dispatch({
        type: SET_ROUND_UP,
        payload: { roundUpResult: true, id: roundUpResult.id },
      });
      return { status: true };
    } catch (error) {
      checkAuthentication(error);
      dispatch({
        type: SET_ROUND_UP,
        payload: { error, roundUpResult: false },
      });
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getRoundUp = (dispatch) => {
  return async (jwt, checkAuthentication) => {
    const path = `/roundup/settings/get`;

    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const result = await API.get(API_ROUNDUP, path, init);
      const { depositDate, frequency } = result;
      dispatch({
        type: SET_ROUND_UP,
        payload: {
          ...result,
          depositDateLabel: convertDateLabel(depositDate, frequency),
        },
      });
      return { status: true };
    } catch (error) {
      checkAuthentication(error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const updateRoundUp = (dispatch) => {
  return async (jwt, roundUpData, checkAuthentication) => {
    const { id } = roundUpData;
    const temp = { ...roundUpData };
    delete temp.id;
    delete temp.depositDateLabel;
    delete temp.roundUpEditing;
    delete temp.roundUpResult;
    delete temp.userId;
    delete temp.synapseUser;

    if (temp.autoDeposit) {
      delete temp.manualDate;
    }
    const path = `/roundup/settings/update?id=${id}`;
    const body = {
      ...temp,
      updatedAt: new Date(),
    };

    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body,
    };

    try {
      setLoading(dispatch, true);
      await API.put(API_ROUNDUP, path, init);
      return { status: true };
    } catch (error) {
      checkAuthentication(error);
      dispatch({
        type: SET_ROUND_UP,
        payload: { error },
      });
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const initRoundUp = (dispatch) => {
  return () => {
    dispatch({
      type: INIT_ROUND_UP,
    });
  };
};

const getRoundAmounts = (dispatch) => {
  return async (jwt, checkAuthentication) => {
    const path = '/roundup/get';

    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const { data } = await API.get(API_ROUNDUP, path, init);
      dispatch({
        type: SET_ROUND_AMOUNTS,
        payload: data,
      });
      return { status: true };
    } catch (error) {
      checkAuthentication(error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getPlaidLinkTokenUpdate = (dispatch) => {
  return async (
    jwt,
    item_id,
    notificationId,
    checkAuthentication,
    onSuccessCallback,
  ) => {
    const path = '/plaid/update-linktoken';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        item_id,
      },
    };

    try {
      setLoading(dispatch, true);
      const data = await API.post(API_PLAID, path, init);
      dispatch({
        type: SET_PLAID_LINK_TOKEN,
        payload: {
          ...data,
          currentNotificationId: notificationId,
        },
      });
      onSuccessCallback();
      return { status: true };
    } catch (error) {
      console.log('getPlaidLinkTokenUpdate error ===>', error);
      checkAuthentication(error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getPlaidLinkToken = (dispatch) => {
  return async (jwt, checkAuthentication) => {
    const path = '/plaid/get-link-token';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const data = await API.get(API_PLAID, path, init);
      dispatch({
        type: SET_PLAID_LINK_TOKEN,
        payload: data,
      });
      return { status: true };
    } catch (error) {
      console.log('getPlaidLinkToken error ===>', error);
      checkAuthentication(error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const exchangePlaidPublicToken = (dispatch) => {
  return async (payload) => {
    const { token, plaidInfoId, success, accountType } = payload;
    const path = '/plaid/public-token-exchange';
    try {
      const accountData = success.metadata.accounts.find(
        (item) => item.subtype === 'checking',
      );

      const init = {
        headers: {
          Authorization: token,
        },
        body: {
          publicToken: success.public_token,
          institution: {
            id: success.metadata?.institution?.institution_id,
            name: success.metadata?.institution?.name,
          },
        },
      };

      if (plaidInfoId) {
        init.queryStringParameters = {
          plaidInfoId,
        };
      }

      if (accountType) {
        init.body.accountType = accountType;
      }

      setLoading(dispatch, true);
      const data = await API.post(API_PLAID, path, init);
      dispatch({
        type: SET_PLAID_PUBLIC_TOKEN,
        payload: {
          ...data,
          plaidType: 'billpay',
          accountType,
        },
      });
      return { status: true };
    } catch (error) {
      return { status: false, message: formatError(error) };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getAllLinkedBanks = (dispatch) => {
  return async (jwt, userId, checkAuthentication) => {
    const path = `/plaid/linked-banks/${userId}`;
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const data = await API.get(API_PLAID, path, init);
      if (data && Array.isArray(data)) {
        dispatch({
          type: SET_ALL_LINKED_BANKS,
          payload: data,
        });
      } else {
        dispatch({
          type: SET_ALL_LINKED_BANKS,
          payload: [],
        });
      }
      return { status: true };
    } catch (error) {
      console.log('getAllLinkedBanks error ===>', error);
      checkAuthentication(error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const updateLinkedBank = (dispatch) => {
  return async (payload) => {
    const { jwt, id, success, plaidInfoId, accountType, plaidType } = payload;
    const path = `/plaid/update-linked-bank/${id}?accountType=${accountType}`;
    try {
      const accountData = success.metadata.accounts.find(
        (item) => item.subtype === 'checking',
      );

      const init = {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        body: {
          publicToken: success.public_token,
          institution: {
            id: success.metadata?.institution?.institution_id,
            name: success.metadata?.institution?.name,
          },
        },
      };

      if (plaidInfoId) {
        init.queryStringParameters = {
          plaidInfoId,
        };
      }

      if (accountType) {
        init.body.accountType = accountType;
      }

      setLoading(dispatch, true);
      const data = await API.patch(API_PLAID, path, init);
      dispatch({
        type: UPDATE_LINKED_BANK,
        payload: {
          data: {
            ...data,
            accountType,
            plaidType,
            id,
          },
          id,
        },
      });
      return { status: true };
    } catch (error) {
      return { status: false, message: formatError(error) };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const removeLinkedBank = (dispatch) => {
  return async ({ jwt, id }) => {
    const path = `/plaid/remove-linked-bank/${id}`;
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const data = await API.del(API_PLAID, path, init);
      dispatch({
        type: REMOVE_LINKED_BANK,
        payload: {
          id,
        },
      });
      return { status: true };
    } catch (error) {
      console.log('getAllLinkedBanks error ===>', error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getPlaidPayrollLinkToken = (dispatch) => {
  return async (jwt, checkAuthentication) => {
    const path = '/plaid/payroll/get-link-token';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const data = await API.post(API_PLAID, path, init);
      if (data) {
        dispatch({
          type: SET_PLAID_PAYROLL_LINK_TOKEN,
          payload: data,
        });
      }
      return { status: true };
    } catch (error) {
      checkAuthentication(error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const exchangePlaidPayrollPublicToken = (dispatch) => {
  return async (payload) => {
    const { token, success, type } = payload;
    dispatch({
      type: SET_PLAID_PAYROLL_SUCCESS_DATA,
      payload: success.metadata,
    });
    const path = '/plaid/payroll/public-token-exchange';
    try {
      const init = {
        headers: {
          Authorization: token,
        },
        body: {
          publicToken: success.public_token,
          institution: {
            id: success.metadata?.institution?.institution_id,
            name: success.metadata?.institution?.name,
          },
          type,
        },
      };

      setLoading(dispatch, true);
      const data = await API.post(API_PLAID, path, init);
      dispatch({
        type: SET_PLAID_PAYROLL_PUBLIC_TOKEN,
        payload: { ...data, payrollUploaded: type === 'upload_doc' },
      });
      return { status: true, data };
    } catch (error) {
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getPlaidBankLinkToken = (dispatch) => {
  return async (jwt, checkAuthentication) => {
    const path = '/plaid/bank/get-link-token';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const data = await API.post(API_PLAID, path, init);
      if (data) {
        dispatch({
          type: SET_PLAID_BANK_LINK_TOKEN,
          payload: data,
        });
      }
      return { status: true };
    } catch (error) {
      checkAuthentication(error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const exchangePlaidBankPublicToken = (dispatch) => {
  return async (payload) => {
    const { token, success, type } = payload;
    dispatch({
      type: SET_PLAID_BANK_SUCCESS_DATA,
      payload: success.metadata,
    });
    const path = '/plaid/bank/public-token-exchange';
    try {
      const init = {
        headers: {
          Authorization: token,
        },
        body: {
          publicToken: success.public_token,
          institution: {
            id: success.metadata?.institution?.institution_id,
            name: success.metadata?.institution?.name,
          },
          type,
        },
      };

      setLoading(dispatch, true);
      const data = await API.post(API_PLAID, path, init);
      dispatch({
        type: SET_PLAID_BANK_PUBLIC_TOKEN,
        payload: { ...data, payrollUploaded: type === 'upload_doc' },
      });
      return { status: true, data };
    } catch (error) {
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getTransactionHistory = (dispatch) => {
  return async (jwt, params, userId, loadMore = false) => {
    const path = `/transactions/${userId}`;
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      queryStringParameters: params,
    };

    try {
      setLoading(dispatch, true);
      const result = await API.get(API_TRANSACTION, path, init);
      if (loadMore) {
        dispatch({
          type: UPDATE_TRANSACTION_DATA,
          payload: result,
        });
      } else {
        dispatch({
          type: SET_TRANSACTION_DATA,
          payload: result,
        });
      }
    } catch (error) {
      console.log('get transaction error ===>', error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const registerBillGoUser = (dispatch) => {
  return async (jwt) => {
    const path = '/billgo/users';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const data = await API.post(API_BILL_GO, path, init);
      dispatch({
        type: SET_BILL_GO_DATA,
        payload: data,
      });
      return { status: true, data };
    } catch (error) {
      console.log('register billgo user error ===>', error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getBillGoUser = (dispatch) => {
  return async (jwt) => {
    const path = '/billgo/users';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const data = await API.get(API_BILL_GO, path, init);
      dispatch({
        type: SET_BILL_GO_DATA,
        payload: data,
      });
      return { status: true, data };
    } catch (error) {
      console.log('get billgo user error ===>', error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getBillGoToken = (dispatch) => {
  return async (jwt) => {
    const path = '/billgo/jwt';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const data = await API.get(API_BILL_GO, path, init);
      if (data) {
        dispatch({
          type: SET_BILL_GO_DATA,
          payload: { token: data },
        });
      }
      return { status: true, token: data };
    } catch (error) {
      console.log('get billgo token error ===>', error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getBills = (dispatch) => {
  return async (jwt) => {
    const path = '/billgo/users/bills';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const data = await API.get(API_BILL_GO, path, init);
      if (data) {
        dispatch({
          type: SET_BILL_GO_DATA,
          payload: { bills: data.bills },
        });
      }
      return { status: true, token: data };
    } catch (error) {
      console.log('get billgos error ===>', error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const cancelAllPayments = (dispatch) => {
  return async (jwt) => {
    const path = '/billgo/users/payments/cancel-payments';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const data = await API.post(API_BILL_GO, path, init);
      dispatch({
        type: SET_BILL_GO_DATA,
        payload: data,
      });
      return { status: true, data };
    } catch (error) {
      console.log('cancel billgo payments error ===>', error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getDownloadDocumentUrl = (dispatch) => {
  return async (jwt, documentId) => {
    const path = `/billgo/users/documents/${documentId}/download`;
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const data = await API.get(API_BILL_GO, path, init);
      if (data) {
        dispatch({
          type: SET_BILL_GO_DATA,
          payload: { downloadUrl: data },
        });
      }
      return { status: true, downloadUrl: data };
    } catch (error) {
      console.log('get download url error ===>', error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    case SET_ROUND_UP:
      return {
        ...state,
        roundUp: {
          ...state.roundUp,
          ...action.payload,
        },
      };
    case SET_ROUND_AMOUNTS:
      return {
        ...state,
        roundAmounts: action.payload,
      };
    case SET_PLAID_LINK_TOKEN:
      return {
        ...state,
        plaid: {
          ...state.plaid,
          linkToken: action.payload.link_token,
          currentNotificationId: action.payload.currentNotificationId,
        },
      };
    case SET_PLAID_PUBLIC_TOKEN:
      return {
        ...state,
        plaid: {
          ...state.plaid,
          ...action.payload,
        },
        allLinkedBanks: state.allLinkedBanks.concat(action.payload),
      };
    case SET_PLAID_PAYROLL_LINK_TOKEN:
      return {
        ...state,
        plaid: {
          ...state.plaid,
          payrollLinkToken: action.payload.link_token,
        },
      };
    case SET_PLAID_PAYROLL_PUBLIC_TOKEN:
      return {
        ...state,
        plaid: {
          ...state.plaid,
          payrollAccessToken: action.payload.access_token,
          payrollUploaded: !!action.payload.payrollUploaded,
        },
      };
    case SET_PLAID_PAYROLL_SUCCESS_DATA:
      return {
        ...state,
        plaid: {
          ...state.plaid,
          institution: action.payload.institution,
        },
      };

    case SET_PLAID_BANK_LINK_TOKEN:
      return {
        ...state,
        plaid: {
          ...state.plaid,
          bankLinkToken: action.payload.link_token,
        },
      };
    //TODO
    case SET_PLAID_BANK_PUBLIC_TOKEN:
      return {
        ...state,
        plaid: {
          ...state.plaid,
          payrollAccessToken: action.payload.access_token,
          payrollUploaded: !!action.payload.payrollUploaded,
        },
        //TODO
      };
    case SET_PLAID_BANK_SUCCESS_DATA:
      return {
        ...state,
        plaid: {
          ...state.plaid,
          institution: action.payload.institution,
        },
      };
    case SET_TRANSACTION_DATA:
      return {
        ...state,
        transactionData: action.payload,
      };
    case UPDATE_TRANSACTION_DATA:
      return {
        ...state,
        transactionData: {
          total: action.payload.total,
          transactions: [
            ...state.transactionData.transactions,
            ...action.payload.transactions,
          ],
        },
      };

    case SET_ALL_LINKED_BANKS:
      return {
        ...state,
        allLinkedBanks: action.payload,
      };
    case UPDATE_LINKED_BANK:
      return {
        ...state,
        allLinkedBanks: state.allLinkedBanks.map((v) => {
          if (v.id === action.payload.id) {
            return action.payload.data;
          }
          return v;
        }),
      };
    case REMOVE_LINKED_BANK:
      return {
        ...state,
        allLinkedBanks: state.allLinkedBanks.filter(
          (v) => v.id !== action.payload.id,
        ),
      };
    case SET_BILL_GO_DATA:
      return {
        ...state,
        billGo: {
          ...state.billGo,
          ...action.payload,
        },
      };
    case INIT_ROUND_UP:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const dispatch = (dispatch) => {
  return async (action) => {
    dispatch(action);
  };
};

export const { Provider, Context } = CreateDataContext(
  reducer,
  {
    initRoundUp,
    setUpRoundUp,
    getRoundUp,
    updateRoundUp,
    dispatch,
    getRoundAmounts,
    getPlaidLinkToken,
    getPlaidLinkTokenUpdate,
    exchangePlaidPublicToken,
    getPlaidPayrollLinkToken,
    exchangePlaidPayrollPublicToken,
    getAllLinkedBanks,
    getTransactionHistory,
    registerBillGoUser,
    getBillGoUser,
    getBillGoToken,
    getBills,
    cancelAllPayments,
    getDownloadDocumentUrl,
    getPlaidBankLinkToken,
    exchangePlaidBankPublicToken,
    updateLinkedBank,
    removeLinkedBank,
  },
  initialState,
);
