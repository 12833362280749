import React, { useContext } from 'react';
import { Button, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../../styles/GlobalStyle';
import TitleText from '../../../components/common/TitleText';
import { Context as UserContext } from '../../../context/UserContext';
import {
  LOW_INCOME_DESCRIPTION1,
  LOW_INCOME_DESCRIPTION2,
  LOW_INCOME_TITLE,
} from 'capio-common/src/constants';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 35,
    marginBottom: 35,
    position: 'relative',
  },
  description2: {
    marginTop: '10px',
    marginBottom: '40px',
  },
  button: {
    padding: '8px 10px',
    border: '2px solid  #B5B6DA',
    borderRadius: 4,
    color: '#494C93',
    display: 'block',
    marginTop: 20,
    fontWeight: '600',
  },
  plainButton: {
    padding: '8px 10px',
    border: 'none',
    borderRadius: 4,
    color: '#494C93',
    display: 'block',
    marginTop: 20,
    fontWeight: '600',
  },
}));

export default function LowIncome({ updateStep }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state: userState, addContact } = useContext(UserContext);

  const onContact = async (initiator, details) => {
    const params = {
      email: userState['email'],
      lastName: userState['lastName'],
      firstName: userState['firstName'],
      initiator,
      userId: userState['userId'],
      details,
      guidelineType: '',
    };

    const res = await addContact(userState.jwt, params);
    if (res && res['status']) {
      navigate('/compass');
    }
  };

  const onMaybeLater = () => {
    navigate('/compass');
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title={LOW_INCOME_TITLE}
        description={LOW_INCOME_DESCRIPTION1}
      />
      <Typography variant="p" className={classes.description2}>
        {LOW_INCOME_DESCRIPTION2}
      </Typography>
      <Button className={classes.button} onClick={() => onContact('admin', '')}>
        Contact me
      </Button>
      <Button className={classes.button} onClick={() => onContact('user', '')}>
        I’ll contact you myself
      </Button>
      <Button className={classes.plainButton} onClick={() => onMaybeLater()}>
        Maybe later
      </Button>
    </Container>
  );
}
