import React, { useState, useContext } from 'react';
import { Drawer, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import AccountItem from './Accounttem';
import EditAccountMenu from './EditAccountMenu';
import TitleText from '../../../../components/common/TitleText';
import PlaidButton from '../../../../components/PlaidButton';
import CLoading from '../../../../components/common/CLoading';
import CAlert from '../../../../components/common/CAlert';
import { Context as PaymentContext } from '../../../../context/PaymentContext';
import { Context as UserContext } from '../../../../context/UserContext';
import { sendAmplitudeData } from '../../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  accountListContainer: {
    width: '100%',
  },
  accountItemType: {
    marginBottom: '25px',
  },
  link: {
    cursor: 'pointer',
  },
}));

function AccountList({ updateStep, accounts }) {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [editAccount, setEditAccount] = useState();
  const [error, setError] = useState('');
  const [checked, setChecked] = useState(false);

  const { state: userState, checkAuthentication } = useContext(UserContext);
  const {
    state: paymentState,
    exchangePlaidPublicToken,
    updateLinkedBank,
    removeLinkedBank,
  } = useContext(PaymentContext);
  const { plaid, loading } = paymentState;

  const toggleDrawer = (isOpen, editAccount, fromFundingAccount) => {
    if (editAccount) {
      editAccount['fromFundingAccount'] = fromFundingAccount;
      setEditAccount(editAccount);
    }
    setMenuOpen(isOpen);
  };

  const onSuccess = async (success, type) => {
    setError('');
    const payload = {
      token: userState.jwt,
      plaidInfoId: plaid.plaidInfoId,
      success,
      accountType: type,
    };
    try {
      const { status, message } = await exchangePlaidPublicToken(payload);
      if (status) {
        sendAmplitudeData('SavingsSetup_3_PlaidBankConnect');
      } else {
        setError(message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setChecked(false);
    }
  };

  const handleUpdateLinkedBank = async (success) => {
    setError('');
    toggleDrawer(false);
    const payload = {
      jwt: userState.jwt,
      success,
      id: editAccount.id,
      plaidType: 'billpay',
      plaidInfoId: plaid.plaidInfoId,
      accountType: editAccount['fromFundingAccount']
        ? 'funding_account'
        : 'spending_account',
    };
    try {
      const { status, message } = await updateLinkedBank(payload);
      if (status) {
        sendAmplitudeData('SavingsSetup_3_PlaidBankUpdate');
      } else {
        setError(message);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleRemoveLinkedBank = async () => {
    setError('');
    toggleDrawer(false);
    const payload = {
      jwt: userState.jwt,
      id: editAccount.id,
    };

    try {
      const { status, message } = await removeLinkedBank(payload);
      if (status) {
        sendAmplitudeData('SavingsSetup_3_PlaidBankRemove');
      } else {
        setError(message);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const renderBank = (type) => {
    const item = accounts.find((v) => v.accountType === type);
    return item ? (
      <AccountItem
        item={item}
        type={type}
        onEdit={() => toggleDrawer(true, item, type === 'funding_account')}
      />
    ) : (
      plaid.linkToken && (
        <PlaidButton
          label="Link an account"
          linkToken={plaid.linkToken}
          onSuccess={(val) => onSuccess(val, type)}
          loading={loading}
          onPress={() => setChecked(true)}
          checked={checked}
          setChecked={setChecked}
        />
      )
    );
  };

  return (
    <div className={classes.accountListContainer}>
      <TitleText title="Linked Bank" description="" />
      <CAlert severity="error" message={error} />

      {loading && <CLoading />}
      <div className={classes.accountItemType}>
        <Typography variant="body4">Funding Account</Typography>
        {renderBank('funding_account')}
      </div>
      <div className={classes.accountItemType}>
        <Typography variant="body4">Spending Account</Typography>
        {renderBank('spending_account')}
      </div>
      <Typography
        color="purple70.main"
        variant="body2"
        className={classes.link}
        onClick={() => updateStep(1)}>
        Learn about the different account types
      </Typography>
      <Drawer
        anchor="right"
        open={menuOpen}
        onClose={() => toggleDrawer(false)}>
        <EditAccountMenu
          toggleDrawer={toggleDrawer}
          editAccount={editAccount}
          handleUpdateLinkedBank={handleUpdateLinkedBank}
          handleRemoveLinkedBank={handleRemoveLinkedBank}
          linkToken={plaid.linkToken}
        />
      </Drawer>
    </div>
  );
}

export default AccountList;
