import React, { useContext, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, Typography, IconButton, Box, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

import { Context as UserContext } from '../../context/UserContext';
import AuthGroup from './AuthGroup';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Screen1 from '../../assets/images/onboarding/screen1.png';
import Screen2 from '../../assets/images/onboarding/screen2.png';
import Screen3 from '../../assets/images/onboarding/screen3.png';
import Screen4 from '../../assets/images/onboarding/screen4.png';
import KYCBG from '../../assets/images/kyc-bg.png';
import LogoImage from '../../components/LogoImage';
import { isIOS} from 'react-device-detect';
import Logo64 from '../../assets/images/logo64.svg'
import RatingStars from '../../assets/images/4-of-5-stars.svg'
import CloseIcon from '../../assets/images/close-x.svg'

const Items = [
  {
    name: 'Welcome to BuoyFi',
    description:
      'Rise above medical bills and chart a course to a healthier financial destination',
    image: (
      <img
        src={Screen1}
        width="100%"
        height="100%"
        style={{ position: 'relative', zIndex: -1 }}
      />
    ),
  },
  {
    name: 'Calculate a Plan',
    description:
      'Build custom guidelines for solutions you can afford based on your household income',
    image: <img src={Screen2} width="100%" height="100%" />,
  },
  {
    name: 'Simplify Savings',
    description:
      'Round up your credit or debit card purchases and start saving for future medical expenses',
    image: <img src={Screen3} width="100%" height="100%" />,
  },
  {
    name: 'Pay Your Bills',
    description: 'Organize your expenses and make payments all in one place',
    image: (
      <img
        src={Screen4}
        width="100%"
        height="100%"
        style={{ position: 'relative', zIndex: -1 }}
      />
    ),
  },
];

const bannerButtonStyling =  {
  backgroundColor: '#4A26D6',
  color: '#FFFFFF',
  padding: '10px 20px',
  textTransform: 'none',
  borderRadius: '54px',
  margin: '20px 0px',
  display: 'flex',
  textAlign: 'center',
  marginTop: 2,
  height: '32px'
};

const useStyles = makeStyles(() => ({
  arrowButton: {
    position: 'absolute',
    zIndex: 2,
    bottom: 5,
    width: 30,
    height: 30,
    cursor: 'pointer',
    color: 'white',
  },
  authContainer: {
    height: '100%',
  },
}));



function Onboarding() {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(UserContext);
  const size = useWindowDimensions();
  const [isShowmWebBanner, setShowmWebBanner] = useState(true);

  const CarouselItem = (props) => {
    const { item } = props;
    const { name, description, image } = item;
    return (
      <Grid display="flex" justifyContent="center">
        <Grid
          sx={{
            width: { md: '100%', sm: '100%' }, 
            height: { md: '100%', sm: '100%' },
            background:
              '-webkit-gradient(linear, left bottom, left top, color-stop(0%, #5255A4), color-stop(100%, rgba(0,0,0,0)))',
          }}>
          {image}
        </Grid>
        <Grid sx={{ position: 'absolute', bottom: 200 }}>
          <Typography variant="h5" color="white.main" mt={2} mb={2}>
            {name}
          </Typography>
          <Typography color="white.main">{description}</Typography>
        </Grid>
      </Grid>
    );
  };


  const mwebAppReferralComponent = () => {
    let appStoreUrl = isIOS ? 'https://apps.apple.com/us/app/buoyfi/id1632295016' : 'https://play.google.com/store/apps/details?id=com.buoyfi&pcampaignid=web_share';
   
    return (
       <Grid sx={{
        background: theme.palette.purple10.background,
        justifyContent: 'flex-start', 
        alignItems: 'center' 
       }} 
       container
       >
        <Grid item>
          <IconButton onClick={() => {setShowmWebBanner(false)}} > <img src={CloseIcon} /></IconButton>
        </Grid>
        <Grid item><img src={Logo64} /> </Grid>
        <Grid item  direction="column">
          <Grid item sx={{margin: '15px 0px -4px 2px' 
           }}>
            <Typography fontSize={12} variant="h6" color="black" lineHeight={1} >
              A better way to tackle <br /> your medical bills.
            </Typography>
          </Grid>

          <Grid item>
            <img src={RatingStars} />
          </Grid>
        </Grid>

       <Grid item sx={{marginLeft: '15%'}}>
            <LoadingButton
              sx={{...bannerButtonStyling}}
              variant="contained"
              onClick={() => {
                window.open(appStoreUrl, "_blank");
              }}>
              Open App
            </LoadingButton>
        </Grid>
       </Grid>
    )
  }

  const getRightLargeSectionWidth = () => {
    return size.width - size.height * 0.65;
  };

  const isSmallView = () => {
    return getRightLargeSectionWidth() < 370;
  };

  const getLeftSectionWidth = () => {
    let width = size.height * 0.613;
    if (isSmallView()) {
      width = '100%';
    }
    return width;
  };

  const getRightSectionWidth = () => {
    let width = getRightLargeSectionWidth();
    if (isSmallView()) {
      width = '100%';
    }
    return width;
  };


  return (
    <div>
      {(isSmallView() && isShowmWebBanner) ? mwebAppReferralComponent() : <></>}
    
    <Grid
      container
      sx={{
        flexDirection: 'row',
        height: '100%',
        flexDirection:
          (state.showOnboarding && isSmallView()) ? 'column-reverse' : 'row',
      }}>
      {state.showOnboarding && (
        <Grid
          sx={{ marginTop: isSmallView() ? '20px' : '0' }}
          item
          width={getLeftSectionWidth()}>
          <Carousel
            autoPlay={false}
            infiniteLoop
            showStatus={false}
            showThumbs={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <IconButton
                  onClick={onClickHandler}
                  className={classes.arrowButton}
                  sx={{ left: 100 }}>
                  <ArrowCircleLeftOutlinedIcon />
                </IconButton>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <IconButton
                  onClick={onClickHandler}
                  className={classes.arrowButton}
                  sx={{ right: 100 }}>
                  <ArrowCircleRightOutlinedIcon />
                </IconButton>
              )
            }
          >
            {Items.map((item, i) => (
              <CarouselItem key={i} item={item} />
            ))}
          </Carousel>
        </Grid>
      )}
      <Grid
        item
        width={state.showOnboarding ? getRightSectionWidth() : '100%'}
        display="flex"
        alignItems="center"
        flexDirection="column">
        {!state.showOnboarding && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: -1,
            }}>
            <img src={KYCBG} width="100%" height="100%" />
          </Box>
        )}
        <Container
          className={classes.authContainer}
          style={{
            display: state.showOnboarding ? 'block' : 'flex',
            alignItems: 'center',
            height: '100%',
            overflowY: 'auto',
            position: 'relative',
          }}>
          {state.showOnboarding ? <LogoImage /> : null}
          <AuthGroup />
        </Container>
        <Typography sx={{ fontSize: 10, textAlign: 'center' }} mb={1}>
          Ver 1.2.2
        </Typography>
      </Grid>
    </Grid>
    </div>
  );
}

export default Onboarding;
