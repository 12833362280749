import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Box,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import GlobalStyle from '../../../styles/GlobalStyle';
import {
  ADD_MEDICAL_DEBT_DESCRIPTION,
  ADD_MEDICAL_DEBT_DESCRIPTIONP1,
  ADD_MEDICAL_DEBT_TITLE,
  PROVIDE_MEDICAL_DEBT_TITLE,
  INVALID_AMOUNT,
  INVALID_NAME,
  MAX_LENGTH_50,
  REQUIRED_AMOUNT,
  REQUIRED_NAME,
  DEBT_INFO_DESCRIPTIONP2,
  DEBT_HOLDER_MAX_ERROR,
  PROVIDE_MEDICAL_DEBT_DESCRIPTION,
} from 'capio-common/src/constants';
import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import CPrefixInput from '../../../components/common/CPrefixInput';
import { ReactComponent as Clipboard } from '../../../assets/images/clipboard.svg';
import { ReactComponent as Currency } from '../../../assets/images/currency-solid.svg';
import { AMOUNT_PATTERN, DEBT_NAME_PATTERN } from 'capio-common/src/patterns';
import CTextField from '../../../components/common/CTextField';
import CloseButton from '../../../components/CloseButton';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  closeButton: {
    color: '#3C1D57',
  },
  title: {
    marginBottom: '0px',
    fontFamily: 'RobotoSlab',
  },
  description: {
    fontFamily: 'OpenSans',
    marginBottom: '10px',
  },
  inputWrapper: {
    marginBottom: '20px',
    marginTop: '15px',
  },
  inputWrapperTitle: {
    marginBottom: '0px',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addInputWrapper: {
    marginTop: '15px',
  },
  addLink: {
    cursor: 'pointer',
  },
  description2: {
    marginTop: '60px',
  },
  accountItem: {
    borderTop: '1px solid #C0B9C7',
    marginTop: '25px',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
  },
  smallIconContainer: {
    paddingTop: 5,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const buttonSx = { 
  fontSize: '16px',
  width: '100%',
  backgroundColor: '#4A26D6',
  color: '#FFFFFF',
  padding: '10px 20px',
  textTransform: 'none',
  borderRadius: '54px',
  margin: '20px 0px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: 2
 };

export default function AddMedicalDebt({
  capioAccount,
  fromEdit,
  updateStep,
  option,
  setOption,
  setMedicalDebt,
  medicalDebt,
}) {
  const classes = useStyles();
  const [debtHolderError, setDebtHolderError] = useState();
  const [disabled, setDisabled] = useState(false);
  const handleRadioChange = ({ target }) => {
    // TODO set option when going back
    setOption(target.value);
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: medicalDebt,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'debtHolders',
  });

  useEffect(() => {
    if (option === 'TotalAmount') {
      setDisabled(
        medicalDebt.amount && medicalDebt.amount !== '0' ? false : true,
      );
    } else {
      if (medicalDebt.debtHolders.length === 0) {
        setDisabled(true);
        return;
      }
      const item = medicalDebt.debtHolders.filter(
        (v) => !v.name || !v.amount || v.amount === '0',
      );
      if (item.length > 0) {
        setDisabled(true);
        return;
      }
      setDisabled(false);
    }
  }, [medicalDebt, option]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (option === 'TotalAmount') {
        setDisabled(value.amount && value.amount !== '0' ? false : true);
        return;
      } else {
        if (value.debtHolders.length === 0) {
          setDisabled(true);
          return;
        }
        const item = value.debtHolders.filter(
          (v) => !v.name || !v.amount || v.amount === '0',
        );
        if (item.length > 0) {
          setDisabled(true);
          return;
        }
        setDisabled(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, option]);

  const onSubmit = (data) => {
    setMedicalDebt(data);
    if (!fromEdit) {
      updateStep(1);
    } else {
      updateStep('EditDebtInfo');
    }
  };

  const getActiveInput = () => {
    if (option === 'TotalAmount') {
      return (
        <Grid className={classes.inputWrapper} item>
          <CPrefixInput
            placeholder="Total Other Bill Amount"
            inputId="amount"
            name="amount"
            label="Amount"
            prefix="$"
            control={control}
            rules={{
              required: {
                value: option === 'TotalAmount' ? true : false,
                message: REQUIRED_AMOUNT,
              },
              pattern: { value: AMOUNT_PATTERN, message: INVALID_AMOUNT },
            }}
            error={!!errors.amount}
            helperText={errors.amount && errors.amount.message}
          />
        </Grid>
      );
    } else if (option === 'AccountSeparately') {
      return (
        <>
          {fields.map((field, index) => (
            <Grid className={classes.accountItem} key={index}>
              <Grid className={classes.inputWrapperTitle} item>
                <Typography
                  variant="body3"
                  color="corePurple60.main"
                  className={classes.title}>
                  Bill Holder {index + 1}
                </Typography>
                {fields.length > 1 ? (
                  <CloseButton
                    className={classes.closeButton}
                    onClick={() => remove(index)}
                  />
                ) : null}
              </Grid>
              <Grid className={classes.inputWrapper} item>
                <CTextField
                  placeholder="Name of Bill Holder"
                  name={`debtHolders[${index}].name`}
                  control={control}
                  rules={{
                    required: {
                      value: option === 'AccountSeparately' ? true : false,
                      message: REQUIRED_NAME,
                    },
                     pattern: { value: DEBT_NAME_PATTERN, message: INVALID_NAME },
                    maxLength: { value: 50, message: MAX_LENGTH_50 },
                  }}
                  fullWidth
                  error={
                    errors &&
                    errors['debtHolders'] &&
                    errors['debtHolders'][index] &&
                    errors['debtHolders'][index]['name']
                      ? true
                      : false
                  }
                  helperText={
                    errors &&
                    errors['debtHolders'] &&
                    errors['debtHolders'][index] &&
                    errors['debtHolders'][index]['name'] &&
                    errors['debtHolders'][index]['name']['message']
                  }
                />
              </Grid>
              <Grid className={classes.inputWrapper} item>
                <CPrefixInput
                  placeholder="Bill Amount"
                  inputId="amount"
                  name={`debtHolders[${index}].amount`}
                  label="Debt Amount"
                  prefix="$"
                  control={control}
                  rules={{
                    required: {
                      value: option === 'AccountSeparately' ? true : false,
                      message: REQUIRED_AMOUNT,
                    },
                    pattern: {
                      value: AMOUNT_PATTERN,
                      message: INVALID_AMOUNT,
                    },
                  }}
                  error={
                    errors &&
                    errors['debtHolders'] &&
                    errors['debtHolders'][index] &&
                    errors['debtHolders'][index]['amount']
                      ? true
                      : false
                  }
                  helperText={
                    errors &&
                    errors['debtHolders'] &&
                    errors['debtHolders'][index] &&
                    errors['debtHolders'][index]['amount'] &&
                    errors['debtHolders'][index]['amount']['message']
                  }
                />
              </Grid>
            </Grid>
          ))}
          <Grid className={classes.addInputWrapper} item>
            {debtHolderError ? (
              <Typography variant="span" color="error.main">
                {DEBT_HOLDER_MAX_ERROR}
              </Typography>
            ) : (
              <Typography
                className={classes.addLink}
                onClick={() => {
                  if (fields.length < 10) {
                    append({
                      name: '',
                      amount: '',
                    });
                  } else {
                    setDebtHolderError(true);
                  }
                }}
                variant="body3"
                color="purple70.main">
                + Add another bill account
              </Typography>
            )}
          </Grid>
        </>
      );
    }
    return null;
  };

  const RenderIcon = () => {
    return (
      <Box className={classes.iconContainer}>
        <Clipboard />
        <Box className={classes.smallIconContainer}>
          <Currency />
        </Box>
      </Box>
    );
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        icon={<RenderIcon />}
        title={
          capioAccount ? ADD_MEDICAL_DEBT_TITLE : PROVIDE_MEDICAL_DEBT_TITLE
        }
        style={{ marginBottom: 0 }}
      />
      <RadioGroup
        value={option}
        onChange={handleRadioChange}
        name="filterGroup">
        {capioAccount ? (
          <FormControlLabel
            value="NotOtherDebt"
            control={<Radio />}
            label="I do not have other medical bills"
          />
        ) : null}
        <FormControlLabel
          value="TotalAmount"
          control={<Radio />}
          label="I want to enter the total amount"
        />
        <FormControlLabel
          value="AccountSeparately"
          control={<Radio />}
          label="I want to add each account separately"
        />
      </RadioGroup>
      {option ? getActiveInput() : null}

      <Typography className={classes.description2}>
        {DEBT_INFO_DESCRIPTIONP2}
      </Typography>

      <CButton
        sx={{ ...buttonSx}}
        disabled={!option ? true : option === 'NotOtherDebt' ? false : disabled}
        variant="contained"
        fullWidth
        onClick={handleSubmit(onSubmit)}>
        Continue
      </CButton>
    </Container>
  );
}
