import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  AppBar,
  CssBaseline,
  IconButton,
  Collapse,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Context as UserContext } from '../context/UserContext';
import LogoImage from './LogoImage';
import BootstrapTooltip from '../pages/home/BootstrapTooltip';
import { BILLPAY_TOOLTIP, COMPASS_TOOLTIP } from 'capio-common/src/constants';

import { ReactComponent as CalculatorSVG } from '../assets/images/calculator.svg';
import { ReactComponent as CalculatorActiveSVG } from '../assets/images/calculator-active.svg';
import { ReactComponent as PaymentSVG } from '../assets/images/currency-dollar.svg';
import { ReactComponent as PaymentActiveSVG } from '../assets/images/currency-dollar-active.svg';
import { ReactComponent as HomeSVG } from '../assets/images/home.svg';
import { ReactComponent as HomeActiveSVG } from '../assets/images/home-active.svg';
import { ReactComponent as UserCircleSVG } from '../assets/images/user-circle.svg';
import { ReactComponent as UserCircleActiveSVG } from '../assets/images/user-circle-active.svg';
import { ReactComponent as LogoutSVG } from '../assets/images/logout.svg';
import CopyRight from './common/CopyRight';
import { sendAmplitudeData } from '../utilities/amplitude';

const drawerWidth = 240;
const topRoutes = [
  { name: 'Home', path: '/home', icon: HomeSVG, activeIcon: HomeActiveSVG },
  {
    name: 'Calculator',
    path: '/compass',
    icon: CalculatorSVG,
    activeIcon: CalculatorActiveSVG,
  },
  // { name: 'Learn', path: '/learn', icon: MenuBookOutlinedIcon },
  {
    name: 'Payments',
    path: '/payments',
    icon: PaymentSVG,
    activeIcon: PaymentActiveSVG,
  },
];

const bottomRoutes = [
  {
    name: 'Settings',
    path: '/profile/details',
    icon: UserCircleSVG,
    activeIcon: UserCircleActiveSVG,
    children: [
      { name: 'Personal Contact Information', path: '/profile/details' },
      { name: 'Linked Bank', path: '/profile/linked-bank' },
      // { name: 'Linked Billers', path: '/profile/linked-billers' },
      { name: 'BuoyFi Calculator Settings', path: '/profile/compass-settings' },
      // { name: 'Linked Payroll', path: '/profile/linked-payroll' },
      { name: 'Notifications', path: '/profile/notifications' },
      { name: 'Sign in Security', path: '/profile/security/edit-pass' },
      { name: 'Help & Support', path: '/profile/help-and-support' },
      { name: 'Privacy Policy', path: '/profile/privacy-policy' },
      { name: 'Terms & Conditions', path: '/profile/tos' },
    ],
  },
  {
    name: 'Sign out',
    path: '/',
    icon: LogoutSVG,
    activeIcon: LogoutSVG,
  },
];

const specialCases = {
  Calculator: '/choose-tool/compass',
  Payments: '/choose-tool/bill-pay',
  Settings: '/profile/details',
};

function Sidebar(props) {
  const { window, children } = props;
  const theme = useTheme();
  const { state, signOut } = useContext(UserContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [subMenuIndex, setSubMenuIndex] = useState(-1);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const width = useMemo(() => {
    return state.showSideMenu ? `calc(100% - ${drawerWidth}px)` : '100%';
  }, [state.showSideMenu]);

  const onItemClick = (index, isBottom) => {
    const routes = isBottom ? bottomRoutes : topRoutes;
    if (routes[index]['name'] === 'Payments') {
      sendAmplitudeData('Navigation_Payments');
    }
    if (routes[index]['name'] === 'Calculator') {
      sendAmplitudeData('Navigation_Calculator');
    }
    if (routes[index]['name'] === 'Settings') {
      sendAmplitudeData('Navigation_Settings');
    }

    if (routes[index]['name'] === 'Sign out') {
      localStorage.removeItem('calculatorMedicalDebt');
      localStorage.removeItem('calculatorIncome');
      localStorage.removeItem('calculatorHouseholdSize');
      localStorage.removeItem('calculatorCurrentStep');
      localStorage.removeItem('calculatorProgress');
      sessionStorage.removeItem('buoyfiToken');
      sessionStorage.removeItem('buoyfiUserId');
      signOut();
      sendAmplitudeData('Profile_LogOff');
    }
    if (routes[index]['children']) {
      setSubMenuIndex(subMenuIndex === index ? -1 : index);
    } else {
      setSubMenuIndex(-1);
    }
  };

  const getPath = (item) => {
    if (item.name === 'Payments') {
      if (state && state.paymentSetupFlags && state.paymentSetupFlags.billpay) {
        return item.path;
      } else {
        return '/choose-tool/bill-pay';
      }
    } else if (item.name === 'Calculator') {
      if (state && state.paymentSetupFlags && state.paymentSetupFlags.compass) {
        return item.path;
      } else {
        return '/choose-tool/compass';
      }
    }
    return item.path;
  };

  const getToolTipText = (item) => {
    if (
      item['name'] === 'Calculator' &&
      state &&
      state.paymentSetupFlags &&
      !state.paymentSetupFlags.compass
    ) {
      return COMPASS_TOOLTIP;
    }

    if (
      item['name'] === 'Payments' &&
      state &&
      state.paymentSetupFlags &&
      !state.paymentSetupFlags.billpay
    ) {
      return BILLPAY_TOOLTIP;
    }

    return '';
  };

  const checkPaymentSetup = () => {
    return (
      state &&
      state.paymentSetupFlags &&
      (state.paymentSetupFlags.billpay || state.paymentSetupFlags.compass)
    );
  };

  const isSubItemDisable = (subMenuItem) => {
    // if (
    //   subMenuItem.name === 'BuoyFi Calculator Settings' &&
    //   !state?.paymentSetupFlags?.compass
    // ) {
    //   return true;
    // }

    // if (
    //   subMenuItem.name === 'Linked Bank' &&
    //   !state?.paymentSetupFlags?.billpay
    // ) {
    //   return true;
    // }

    return false;
  };

  const isRouteActive = (routePath, itemName) => {
    if (specialCases[itemName]) {
      return location.pathname.startsWith(specialCases[itemName]);
    }
    return location.pathname.startsWith(routePath);
  };

  const drawer = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <LogoImage />
      <List>
        {topRoutes.map((item, index) => {
          const isActive = isRouteActive(item.path, item.name);
          return (
            <Box
              key={index}
              sx={{
                mx: 2,
              }}>
              <BootstrapTooltip title={getToolTipText(item)} placement="right">
                <ListItem
                  button
                  sx={{
                    borderRadius: isActive ? '20px' : '0px',
                    '&:hover': {
                      borderRadius: '20px',
                    },
                  }}
                  component={Link}
                  to={getPath(item)}
                  onClick={() => onItemClick(index, false)}
                  selected={isActive}>
                  <ListItemIcon>
                    {isActive ? <item.activeIcon /> : <item.icon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{ color: 'primary' }}
                  />
                </ListItem>
              </BootstrapTooltip>
            </Box>
          );
        })}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <List>
        {bottomRoutes.map((item, index) => {
          const isActive =
            isRouteActive(item.path, item.name) && item.name !== 'Sign out';
          return (
            <Box
              key={index}
              sx={{
                mx: 2,
              }}>
              <BootstrapTooltip title={getToolTipText(item)} placement="right">
                <ListItem
                  button
                  sx={{
                    borderRadius: isActive ? '20px' : '0px',
                    '&:hover': {
                      borderRadius: '20px',
                    },
                  }}
                  component={Link}
                  to={getPath(item)}
                  onClick={() => onItemClick(index, true)}
                  selected={isActive}>
                  <ListItemIcon>
                    {isActive ? <item.activeIcon /> : <item.icon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{ color: 'primary' }}
                  />
                </ListItem>
              </BootstrapTooltip>
              {item.children ? (
                <Collapse
                  in={subMenuIndex === index}
                  timeout="auto"
                  unmountOnExit>
                  <List component="div" disablePadding>
                    {item.children.map((subMenuItem, subIndex) => {
                      if (!isSubItemDisable(subMenuItem)) {
                        return (
                          <ListItem
                            button
                            component={Link}
                            to={subMenuItem.path}
                            key={subIndex}
                            sx={{ pl: 4 }}>
                            <ListItemText primary={subMenuItem['name']} />
                          </ListItem>
                        );
                      } else return null;
                    })}
                  </List>
                </Collapse>
              ) : null}
            </Box>
          );
        })}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.bw10.main,
        display: 'flex',
        minHeight: '100vh',
      }}>
      {state.showSideMenu && (
        <>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: { lg: `calc(100% - ${drawerWidth}px)` },
              ml: { lg: `${drawerWidth}px` },
              display: { xs: 'block', lg: 'none' },
            }}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { lg: 'none' } }}>
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{
              width: { lg: drawerWidth },
              flexShrink: { lg: 0 },
              visibility: state.showSideMenu ? 'visible' : 'hidden',
            }}
            aria-label="menu">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', lg: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}>
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', lg: 'block' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
              open>
              {drawer}
            </Drawer>
          </Box>
        </>
      )}
      <Box
        component="main"
        sx={{
          backgroundColor: theme.palette.bw10.main,
          flexGrow: 1,
          minHeight: 'calc(100vh - 30px)',
          width: { lg: width },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          // height: '100%',
        }}>
        {state.showSideMenu && (
          <Toolbar
            sx={{
              display: { xs: 'block', lg: 'none' },
            }}
          />
        )}
        <Box
          sx={{
            flexGrow: 1,
            height: {
              xs: state.showSideMenu ? 'calc(100% - 90px)' : '100%',
              lg: state.showSideMenu ? 'calc(100% - 50px)' : '100%',
            },
            width: '100%',
            position: 'relative',
            overflowY: 'auto',
          }}>
          {children}
        </Box>
        {/* {state.showSideMenu && (
          <Box mt={4}>
            <CopyRight />
          </Box>
        )} */}
      </Box>
    </Box>
  );
}

export default Sidebar;
