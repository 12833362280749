import React, { useEffect, useMemo } from 'react';
import { PlaidLink, usePlaidLink } from 'react-plaid-link';
import PropTypes from 'prop-types';

import CButton from './common/CButton';
import { sendAmplitudeData } from '../utilities/amplitude';
import { getAmplitudeEventName } from '../utilities/getAmplitudeEventName';

const PlaidButton = ({
  label,
  linkToken,
  onSuccess,
  loading,
  checked,
  onPress,
  setChecked,
  step = '', // for amplitude
  btnDisabled = false,
  onModalClose = () => {},
}) => {
  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: (public_token, metadata) => {
      onSuccess({ public_token, metadata });
    },
    onEvent: (eventName) => {
      const plaidStep = step + '_Plaid';
      sendAmplitudeData(plaidStep, { event_name: eventName });
      if (eventName === 'EXIT') {
        onModalClose();
        setChecked(false);
      }
    },
  });
  useEffect(() => {
    if (checked && linkToken && ready) {
      open();
    }
  }, [checked, linkToken, ready]);

  const plaidButtonStyling = {
    fontSize: '16px',
    width: '100%',
    backgroundColor: '#4A26D6',
    color: '#FFFFFF',
    padding: '10px 20px',
    textTransform: 'none',
    borderRadius: '54px',
    margin: '20px 0px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: 2
  };

  return (
    <CButton
      sx={{ ...plaidButtonStyling }}
      variant="contained"
      fullWidth
      onClick={() => {
        if (checked && linkToken) {
          open();
          return;
        } else {
          onPress && onPress();
          return;
        }
      }}
      loading={loading}
      disabled={btnDisabled || loading}>
      {label}
    </CButton>
  );
};

PlaidButton.propTypes = {
  label: PropTypes.string,
};

export default PlaidButton;
