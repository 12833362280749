import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles(() => ({
  checklistItem: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0 0 0',
  },
  normalContainer: {
    backgroundColor: '#F3F1F5',
  },
  successContainer: {
    backgroundColor: '#DCF1DA',
  },
  progressBar: {
    display: 'flex',
    width: '80%',
    alignItems: 'center',
  },
}));

const FileUpload = (props) => {
  const { title, error, setImage, removeImage } = props;
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [fileInfos, setFileInfos] = useState([]);
  const [isUploading, setUploading] = useState(true);
  const classes = useStyles();

  useEffect(() => {}, []);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      reader.onload = () => {
        const baseURL = reader.result;
        resolve(baseURL);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const selectFile = async (event) => {
    setSelectedFiles(event.target.files);

    const file = event.target.files[0];
    if (file) {
      const base64 = await getBase64(file);
      // If the file is larger than 8mb, display an error
      setImage(base64, file.size > 8000000);
    }
  };

  const upload = () => {
    let currentFile = selectedFiles[0];

    setProgress(0);
    setCurrentFile(currentFile);

    // UploadService.upload(currentFile, (event) => {
    //   setProgress(Math.round((100 * event.loaded) / event.total));
    // })
    //   .then((response) => {
    //     setMessage(response.data.message);
    //     return UploadService.getFiles();
    //   })
    //   .then((files) => {
    //     setFileInfos(files.data);
    //   })
    //   .catch(() => {
    //     setProgress(0);
    //     setMessage('Could not upload the file!');
    //     setCurrentFile(undefined);
    //   });

    setSelectedFiles(undefined);
  };

  return (
    <div>
      <Grid container className={classes.checklistItem}>
        <Typography>{title}</Typography>
        {selectedFiles && selectedFiles.length > 0 ? (
          <Button
            onClick={() => {
              setSelectedFiles(undefined);
              removeImage();
            }}>
            <DeleteIcon />
          </Button>
        ) : (
          <label>
            <input
              name="btn-upload"
              style={{ display: 'none' }}
              type="file"
              accept="image/*, image/heif, image/raw"
              onChange={selectFile}
            />
            <Button component="span" className={classes.normalContainer}>
              <AddCircleOutlineIcon />
            </Button>
          </label>
        )}
      </Grid>
      <Grid container alignItems="center">
        {isUploading && currentFile && (
          <Grid className={classes.progressBar}>
            <Box width="100%" mr={1}>
              <LinearProgress
                color="primary"
                variant="determinate"
                value={progress}
              />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2">{`${progress}%`}</Typography>
            </Box>
          </Grid>
        )}
        {error && (
          <>
            <CancelOutlinedIcon fontSize="small" color="error" />
            <Typography variant="body2" ml={1} color="error">
              {error}
            </Typography>
          </>
        )}
        {!error && selectedFiles && selectedFiles.length > 0 && (
          <CheckCircleOutlineIcon fontSize="small" color="success" />
        )}
        <Typography variant="body2" ml={1}>
          {!error && selectedFiles && selectedFiles.length > 0
            ? selectedFiles[0].name
            : null}
        </Typography>
      </Grid>
    </div>
  );
};

export default FileUpload;
