/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserInformationEncrypt = /* GraphQL */ `
  mutation CreateUserInformationEncrypt(
    $input: CreateUserInformationInput!
    $condition: ModelUserInformationConditionInput
  ) {
    createUserInformationEncrypt(input: $input, condition: $condition) {
      id
      userId
      email
      personalInfo {
        lastFourSSN
        legalFirstName
        legalLastName
        dateOfBirth
        mobileNumber
      }
      address {
        streetAddress
        city
        state
        postalCode
        country
      }
      appStatus {
        parent
        sub
      }
      failedAt
      failedCount
      policyStatus
      tosStatus
      capioToken
      capioAccounts {
        capioAccountID
        phoneNumber
        debtName
        amount
      }
      compassInfo {
        totalMonthlyIncome
        userIncome
        spousalIncome
        otherIncome
        totalDebt
        debtInfo {
          capioAccountID
          phoneNumber
          guidelines {
            payment {
              amount
              period
              forgiven
            }
            settlement {
              amount
              period
              forgiven
            }
          }
          debt {
            debtName
            debtKey
            amount
          }
        }
        unemployed
        consenttoshare
        guidelinePresented
        married
        contact
        incomeType
        roundedTotalPaymentPercent
        roundedTotalSettlementPercent
      }
      paymentSetupFlags {
        compass
        billpay
      }
      householdSize
      billGoUserId
      incomeInformation {
        payFrequency
        maritalStatus
        grossEarnings
        startDate
        endDate
        employerName
        userMonthlyIncome
        spouseIncome
        otherIncome
        incomeSource
        verified
      }
      monthlyIncome
      annualHouseholdIncome
      synapseUser {
        id
        achNodeId
        custodyNodeId
        docIds {
          docId
          physicalFrontDocId
          physicalBackDocId
          virtualDocId
          socialDocId
        }
        docType
        ip
        fp
        verified
        pass
        photoFront
        photoBack
        isActive
        isTrueVerified
      }
      isDisabled
      notification {
        pushToken
        deviceType
        deviceMeta
        subscriptionID
        browser
      }
      notifySetting {
        emailNotification
        pushNotification
      }
      securitySetting {
        faceID
      }
      emailSearch
      lastLogged
      searchContent {
        legalFirstName
        legalLastName
        mobileNumber
        email
        lastLogged
        stateName
      }
      changeLog {
        web
        mobile
      }
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserInformationEncrypt = /* GraphQL */ `
  mutation UpdateUserInformationEncrypt(
    $input: UpdateUserInformationInput!
    $condition: ModelUserInformationConditionInput
  ) {
    updateUserInformationEncrypt(input: $input, condition: $condition) {
      id
      userId
      email
      personalInfo {
        lastFourSSN
        legalFirstName
        legalLastName
        dateOfBirth
        mobileNumber
      }
      address {
        streetAddress
        city
        state
        postalCode
        country
      }
      appStatus {
        parent
        sub
      }
      failedAt
      failedCount
      policyStatus
      tosStatus
      capioToken
      capioAccounts {
        capioAccountID
        phoneNumber
        debtName
        amount
      }
      compassInfo {
        totalMonthlyIncome
        userIncome
        spousalIncome
        otherIncome
        totalDebt
        debtInfo {
          capioAccountID
          phoneNumber
          guidelines {
            payment {
              amount
              period
              forgiven
            }
            settlement {
              amount
              period
              forgiven
            }
          }
          debt {
            debtName
            debtKey
            amount
          }
        }
        unemployed
        consenttoshare
        guidelinePresented
        married
        contact
        incomeType
        roundedTotalPaymentPercent
        roundedTotalSettlementPercent
      }
      paymentSetupFlags {
        compass
        billpay
      }
      householdSize
      billGoUserId
      incomeInformation {
        payFrequency
        maritalStatus
        grossEarnings
        startDate
        endDate
        employerName
        userMonthlyIncome
        spouseIncome
        otherIncome
        incomeSource
        verified
      }
      monthlyIncome
      annualHouseholdIncome
      synapseUser {
        id
        achNodeId
        custodyNodeId
        docIds {
          docId
          physicalFrontDocId
          physicalBackDocId
          virtualDocId
          socialDocId
        }
        docType
        ip
        fp
        verified
        pass
        photoFront
        photoBack
        isActive
        isTrueVerified
      }
      isDisabled
      notification {
        pushToken
        deviceType
        deviceMeta
        subscriptionID
        browser
      }
      notifySetting {
        emailNotification
        pushNotification
      }
      securitySetting {
        faceID
      }
      emailSearch
      lastLogged
      searchContent {
        legalFirstName
        legalLastName
        mobileNumber
        email
        lastLogged
        stateName
      }
      changeLog {
        web
        mobile
      }
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserInformation = /* GraphQL */ `
  mutation CreateUserInformation(
    $input: CreateUserInformationInput!
    $condition: ModelUserInformationConditionInput
  ) {
    createUserInformation(input: $input, condition: $condition) {
      id
      userId
      email
      personalInfo {
        lastFourSSN
        legalFirstName
        legalLastName
        dateOfBirth
        mobileNumber
      }
      address {
        streetAddress
        city
        state
        postalCode
        country
      }
      appStatus {
        parent
        sub
      }
      failedAt
      failedCount
      policyStatus
      tosStatus
      capioToken
      capioAccounts {
        capioAccountID
        phoneNumber
        debtName
        amount
      }
      compassInfo {
        totalMonthlyIncome
        userIncome
        spousalIncome
        otherIncome
        totalDebt
        debtInfo {
          capioAccountID
          phoneNumber
          guidelines {
            payment {
              amount
              period
              forgiven
            }
            settlement {
              amount
              period
              forgiven
            }
          }
          debt {
            debtName
            debtKey
            amount
          }
        }
        unemployed
        consenttoshare
        guidelinePresented
        married
        contact
        incomeType
        roundedTotalPaymentPercent
        roundedTotalSettlementPercent
      }
      paymentSetupFlags {
        compass
        billpay
      }
      householdSize
      billGoUserId
      incomeInformation {
        payFrequency
        maritalStatus
        grossEarnings
        startDate
        endDate
        employerName
        userMonthlyIncome
        spouseIncome
        otherIncome
        incomeSource
        verified
      }
      monthlyIncome
      annualHouseholdIncome
      synapseUser {
        id
        achNodeId
        custodyNodeId
        docIds {
          docId
          physicalFrontDocId
          physicalBackDocId
          virtualDocId
          socialDocId
        }
        docType
        ip
        fp
        verified
        pass
        photoFront
        photoBack
        isActive
        isTrueVerified
      }
      isDisabled
      notification {
        pushToken
        deviceType
        deviceMeta
        subscriptionID
        browser
      }
      notifySetting {
        emailNotification
        pushNotification
      }
      securitySetting {
        faceID
      }
      emailSearch
      lastLogged
      searchContent {
        legalFirstName
        legalLastName
        mobileNumber
        email
        lastLogged
        stateName
      }
      changeLog {
        web
        mobile
      }
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserInformation = /* GraphQL */ `
  mutation UpdateUserInformation(
    $input: UpdateUserInformationInput!
    $condition: ModelUserInformationConditionInput
  ) {
    updateUserInformation(input: $input, condition: $condition) {
      id
      userId
      email
      personalInfo {
        lastFourSSN
        legalFirstName
        legalLastName
        dateOfBirth
        mobileNumber
      }
      address {
        streetAddress
        city
        state
        postalCode
        country
      }
      appStatus {
        parent
        sub
      }
      failedAt
      failedCount
      policyStatus
      tosStatus
      capioToken
      capioAccounts {
        capioAccountID
        phoneNumber
        debtName
        amount
      }
      compassInfo {
        totalMonthlyIncome
        userIncome
        spousalIncome
        otherIncome
        totalDebt
        debtInfo {
          capioAccountID
          phoneNumber
          guidelines {
            payment {
              amount
              period
              forgiven
            }
            settlement {
              amount
              period
              forgiven
            }
          }
          debt {
            debtName
            debtKey
            amount
          }
        }
        unemployed
        consenttoshare
        guidelinePresented
        married
        contact
        incomeType
        roundedTotalPaymentPercent
        roundedTotalSettlementPercent
      }
      paymentSetupFlags {
        compass
        billpay
      }
      householdSize
      billGoUserId
      incomeInformation {
        payFrequency
        maritalStatus
        grossEarnings
        startDate
        endDate
        employerName
        userMonthlyIncome
        spouseIncome
        otherIncome
        incomeSource
        verified
      }
      monthlyIncome
      annualHouseholdIncome
      synapseUser {
        id
        achNodeId
        custodyNodeId
        docIds {
          docId
          physicalFrontDocId
          physicalBackDocId
          virtualDocId
          socialDocId
        }
        docType
        ip
        fp
        verified
        pass
        photoFront
        photoBack
        isActive
        isTrueVerified
      }
      isDisabled
      notification {
        pushToken
        deviceType
        deviceMeta
        subscriptionID
        browser
      }
      notifySetting {
        emailNotification
        pushNotification
      }
      securitySetting {
        faceID
      }
      emailSearch
      lastLogged
      searchContent {
        legalFirstName
        legalLastName
        mobileNumber
        email
        lastLogged
        stateName
      }
      changeLog {
        web
        mobile
      }
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNotificationTemplates = /* GraphQL */ `
  mutation CreateNotificationTemplates(
    $input: CreateNotificationTemplatesInput!
    $condition: ModelNotificationTemplatesConditionInput
  ) {
    createNotificationTemplates(input: $input, condition: $condition) {
      id
      templateId
      title
      subTitle
      appMessage
      pushMessage
      imageURL
      notificationType
      resourceType
      appUrl
      webUrl
      status
      requiredFields
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificationTemplates = /* GraphQL */ `
  mutation UpdateNotificationTemplates(
    $input: UpdateNotificationTemplatesInput!
    $condition: ModelNotificationTemplatesConditionInput
  ) {
    updateNotificationTemplates(input: $input, condition: $condition) {
      id
      templateId
      title
      subTitle
      appMessage
      pushMessage
      imageURL
      notificationType
      resourceType
      appUrl
      webUrl
      status
      requiredFields
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotificationTemplates = /* GraphQL */ `
  mutation DeleteNotificationTemplates(
    $input: DeleteNotificationTemplatesInput!
    $condition: ModelNotificationTemplatesConditionInput
  ) {
    deleteNotificationTemplates(input: $input, condition: $condition) {
      id
      templateId
      title
      subTitle
      appMessage
      pushMessage
      imageURL
      notificationType
      resourceType
      appUrl
      webUrl
      status
      requiredFields
      createdAt
      updatedAt
    }
  }
`;
export const createNotificationSegments = /* GraphQL */ `
  mutation CreateNotificationSegments(
    $input: CreateNotificationSegmentsInput!
    $condition: ModelNotificationSegmentsConditionInput
  ) {
    createNotificationSegments(input: $input, condition: $condition) {
      id
      segmentId
      segmentName
      status
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificationSegments = /* GraphQL */ `
  mutation UpdateNotificationSegments(
    $input: UpdateNotificationSegmentsInput!
    $condition: ModelNotificationSegmentsConditionInput
  ) {
    updateNotificationSegments(input: $input, condition: $condition) {
      id
      segmentId
      segmentName
      status
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotificationSegments = /* GraphQL */ `
  mutation DeleteNotificationSegments(
    $input: DeleteNotificationSegmentsInput!
    $condition: ModelNotificationSegmentsConditionInput
  ) {
    deleteNotificationSegments(input: $input, condition: $condition) {
      id
      segmentId
      segmentName
      status
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserNotifications = /* GraphQL */ `
  mutation CreateUserNotifications(
    $input: CreateUserNotificationsInput!
    $condition: ModelUserNotificationsConditionInput
  ) {
    createUserNotifications(input: $input, condition: $condition) {
      id
      userId
      notificationId
      title
      appIconUrl
      appMessage
      pushMessage
      appUrl
      webUrl
      createdAt
      updatedAt
      showOnFrontend
      isDelayed
      isRead
      emailSubject
      emailBody
      status
      notificationType
      itemId
    }
  }
`;
export const updateUserNotifications = /* GraphQL */ `
  mutation UpdateUserNotifications(
    $input: UpdateUserNotificationsInput!
    $condition: ModelUserNotificationsConditionInput
  ) {
    updateUserNotifications(input: $input, condition: $condition) {
      id
      userId
      notificationId
      title
      appIconUrl
      appMessage
      pushMessage
      appUrl
      webUrl
      createdAt
      updatedAt
      showOnFrontend
      isDelayed
      isRead
      emailSubject
      emailBody
      status
      notificationType
      itemId
    }
  }
`;
export const deleteUserNotifications = /* GraphQL */ `
  mutation DeleteUserNotifications(
    $input: DeleteUserNotificationsInput!
    $condition: ModelUserNotificationsConditionInput
  ) {
    deleteUserNotifications(input: $input, condition: $condition) {
      id
      userId
      notificationId
      title
      appIconUrl
      appMessage
      pushMessage
      appUrl
      webUrl
      createdAt
      updatedAt
      showOnFrontend
      isDelayed
      isRead
      emailSubject
      emailBody
      status
      notificationType
      itemId
    }
  }
`;
