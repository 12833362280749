import React, { useEffect, useState, useContext } from 'react';
import { Container, Card, CardContent, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { Context as UserContext } from '../../../context/UserContext';
import { Context as PaymentContext } from '../../../context/PaymentContext';

// import MakePayment from './MakePayment';
// import CommonResult from '../../../components/CommonResult';
import GlobalStyle from '../../../styles/GlobalStyle';
import { sendAmplitudeData } from '../../../utilities/amplitude';

const steps = ['MakePayment', 'CommonResult'];

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  myPayees: {
    position: 'fixed',
    height: '100vh',
    width: '100%',
    top: 0,
    left: 0,
    overflowY: 'auto',
    zIndex: 9999,
    background: 'white',
  },
}));

function Payment() {
  // const [step, setStep] = useState(steps[0]);
  const navigate = useNavigate();
  const classes = useStyles();
  const { state: userState } = useContext(UserContext);
  const { getBillGoUser, getBillGoToken } = useContext(PaymentContext);
  const [showBillPay, setShowBillPay] = useState(false);

  // const updateStep = (move) => {
  //   const newStep = steps[steps.indexOf(step) + move];
  //   setStep(newStep);
  // };

  useEffect(() => {
    checkBillGoUser();
  }, []);

  const handleMyPayeeEvents = (evt) => {
    console.log(evt, ' :billgo event in mypayees layout');
    if (evt.role === 'PAYEE_DETAIL_CLICKED') {
      sendAmplitudeData('SavingsHome_BillPayAccountDetail');
    }
  };

  const loadWidget = (token, userId) => {
    const billgoWidget = window.loadMyPayeesWidgetWeb(token, userId);
    billgoWidget.watchEvent(
      billgoWidget.EventType.TAB_LAYOUT,
      handleMyPayeeEvents,
    );
  };

  const checkBillGoUser = async () => {
    const { status, data } = await getBillGoUser(userState.jwt);
    if (status) {
      const { status, token } = await getBillGoToken(userState.jwt);
      if (status) {
        setShowBillPay(true);
        loadWidget(token, data.userId);
      }
    }
  };

  // let activeComponent = '';
  // switch (step) {
  //   case 'MakePayment':
  //     activeComponent = <MakePayment updateStep={updateStep} />;
  //     return (
  //       <Container maxWidth="lg" className={classes.mainContent}>
  //         <Card sx={{ width: '100%', height: '90vh' }}>
  //           <CardContent sx={{ width: '100%', height: '100%' }}>
  //             {activeComponent}
  //           </CardContent>
  //         </Card>
  //       </Container>
  //     );
  //   case 'CommonResult':
  //     activeComponent = (
  //       <CommonResult
  //         successMsg="Your payment..."
  //         failureMsg="Your payment..."
  //         onClickDone={() => navigate(-1)}
  //         onClickRetry={() => navigate(-1)}
  //       />
  //     );
  //     break;
  // }

  return (
    <Container maxWidth="sm" className={classes.mainContent}>
      <Card sx={{ width: '100%' }}>
        <CardContent>
          {showBillPay ? (
            <Box className={classes.myPayees} id="my-payees" />
          ) : (
            'Loading...'
          )}
        </CardContent>
      </Card>
    </Container>
  );
}

export default Payment;
