import React, { useContext, useEffect, useState, useRef } from 'react';
import { Box, Card, CardContent, Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

import CloseButton from '../../../../components/CloseButton';
import { Context as UserContext } from '../../../../context/UserContext';
import { Context as PaymentContext } from '../../../../context/PaymentContext';
import GlobalStyle from '../../../../styles/GlobalStyle';
import CompassSettingList from './CompassSettingList';
import KYCBG from '../../../../assets/images/kyc-bg.png';
import EditIncometInfo from './EditIncometInfo';
import EditAccountInfo from './EditAccountInfo';
import EditPayrollPlaid from './EditPayrollPlaid';
import EditIncomeForm from './EditIncomeForm';
import PayrollDocVerify from './PayrollDocVerify';
import PayrollDocSuccess from './PayrollDocSuccess';

import AddMedicalDebt from '../../../choose-tool/compass/AddMedicalDebt';
import ConfirmDebtInfo from '../../../choose-tool/compass/ConfirmDebtInfo';
import { onUpdateUserInformationById } from 'capio-common/src/graphql/subscriptions';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  cardContent: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '25px',
    right: '25px',
  },
}));

function CompassSettings() {
  const width = window.innerWidth;
  const { state: userState, checkAuthentication } = useContext(UserContext);
  const {
    state: paymentState,
    dispatch,
    getAllLinkedBanks,
  } = useContext(PaymentContext);

  const classes = useStyles();
  const [step, setStep] = useState('CompassSettingInfo');
  const [userInfo, setUserInfo] = useState();
  const [accounts, setAccounts] = useState([]); // used for compass settings list
  const [option, setOption] = useState('AccountSeparately'); // or TotalAmount
  const [medicalDebt, setMedicalDebt] = useState({
    amount: '',
    debtHolders: [{ name: '', amount: '' }],
  });
  const subscription = useRef();

  useEffect(() => {
    if (userState) {
      initDebtInfo();
    }
  }, [userState.compassInfo]);

  useEffect(() => {
    if (!userInfo) setUserInfo({ ...userState });
  }, [userState]);

  useEffect(() => {
    if (userState.id) {
      dispatch(
        getAllLinkedBanks(userState.jwt, userState.id, checkAuthentication),
      );
    }
  }, [userState.id, userState.jwt, paymentState.plaid]);

  useEffect(() => {
    subscription.current = API.graphql({
      query: onUpdateUserInformationById,
      variables: {
        id: userState.userSub,
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    }).subscribe({
      next: async ({ provider, value }) => {
        console.log('setting subscription===>', value);
        if (
          value?.data?.onUpdateUserInformationById?.incomeInformation?.verified
        ) {
          setStep('PayrollDocVerify');
        }
      },
      error: (error) => {
        console.error('subscription error:', error);
      },
    });
    return () => {
      subscription.current.unsubscribe();
    };
  }, []);

  const initDebtInfo = () => {
    let accountData = [];
    if (userState['compassInfo'] && userState['compassInfo']['debtInfo']) {
      accountData = accountData.concat(userState['compassInfo']['debtInfo']);
    }
    setAccounts([...accountData]);

    const debtHolders = [];
    let amount = 0;
    let optionCase = 'AccountSeparately'; // or TotalAmount
    userState?.compassInfo?.debtInfo?.forEach((item) => {
      // do not add capio debt account
      if (!item.capioAccountID) {
        // for total debt case
        if (
          item?.debt?.debtName === 'Medical Bill' ||
          item?.debt?.debtName === 'Other'
        ) {
          optionCase = 'TotalAmount';
          amount = item?.debt?.amount;
        }

        // for separate debt case
        if (optionCase === 'AccountSeparately' && !item?.debt?.debtKey) {
          debtHolders.push({
            name: item?.debt?.debtName,
            amount: item?.debt?.amount,
          });
        }
      }
    });

    if (optionCase === 'TotalAmount') {
      setOption('TotalAmount');
    } else {
      setOption('AccountSeparately');
    }
    setMedicalDebt({ amount: amount, debtHolders });
  };

  const updateDebtInfo = (debtInfo) => {
    setMedicalDebt(debtInfo);
    const { amount, debtHolders } = debtInfo;

    const debtAccountData = debtHolders.map((item) => {
      return {
        capioAccountID: null,
        debt: {
          amount: amount ? amount : item.amount,
          debtName: item.name,
        },
      };
    });

    const capioDebtData = accounts.filter((item) => !!item.capioAccountID);
    setAccounts(capioDebtData.concat(debtAccountData));
  };

  const renderActiveCard = () => {
    if (step === 'EditDebtInfo') {
      return (
        <ConfirmDebtInfo
          capioAccount={userState['capioAccount']}
          medicalDebt={medicalDebt}
          setMedicalDebt={updateDebtInfo}
          updateStep={setStep}
          option={option}
          userInfo={userInfo}
          fromEdit
        />
      );
    } else if (step === 'EditIncomeInfo') {
      return (
        <EditIncometInfo
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          setStep={setStep}
        />
      );
    } else if (step === 'EditPayrollPlaid') {
      return <EditPayrollPlaid setStep={setStep} />;
    } else if (step === 'PayrollDocVerify') {
      return <PayrollDocVerify setStep={setStep} />;
    } else if (step === 'PayrollDocSuccess') {
      subscription.current && subscription.current.unsubscribe();
      return <PayrollDocSuccess setStep={setStep} />;
    } else if (step === 'EditAccountInfo') {
      return <EditAccountInfo setStep={setStep} />;
    } else if (step === 'EditSpouseIncome' || step === 'EditOtherIncome') {
      return (
        <EditIncomeForm
          step={step}
          type={step === 'EditSpouseIncome' ? 'spousalIncome' : 'otherIncome'}
          setStep={setStep}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
        />
      );
    } else if (step === 'EditMedicalDebt') {
      return (
        <AddMedicalDebt
          capioAccount={userState['capioAccount']}
          fromEdit
          medicalDebt={medicalDebt}
          setMedicalDebt={updateDebtInfo}
          updateStep={setStep}
          option={option}
          setOption={setOption}
        />
      );
    }
  };

  const goToCompassSetting = () => {
    if (
      step === 'EditIncomeInfo' ||
      step === 'EditSpouseIncome' ||
      step === 'EditOtherIncome'
    ) {
      setUserInfo({ ...userState });
    } else if (step === 'EditDebtInfo' || step === 'EditMedicalDebt') {
    }
    setStep('CompassSettingInfo');
  };

  const renderCardScreen = () => {
    return (
      <Grid container sx={{ flexDirection: 'row', height: '100%' }}>
        <Grid item width={'100%'} display="flex" alignItems="center">
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: -1,
            }}>
            <img src={KYCBG} width="100%" height="100%" />
          </Box>
          <Container
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Container maxWidth="sm" sx={{ marginTop: -1 }}>
              <Card>
                <CardContent className={classes.cardContent}>
                  <CloseButton
                    className={classes.closeButton}
                    onClick={() => goToCompassSetting()}
                  />
                  {renderActiveCard()}
                </CardContent>
              </Card>
            </Container>
          </Container>
        </Grid>
      </Grid>
    );
  };

  let activeComponent = '';
  switch (step) {
    case 'CompassSettingInfo':
      activeComponent = (
        <CompassSettingList
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          isLoading={paymentState['loading']}
          accounts={accounts}
          setStep={setStep}
        />
      );
      break;
    case 'EditDebtInfo':
    case 'EditIncomeInfo':
    case 'EditAccountInfo':
    case 'EditPayrollPlaid':
    case 'EditSpouseIncome':
    case 'EditOtherIncome':
    case 'EditMedicalDebt':
    case 'PayrollDocVerify':
    case 'PayrollDocSuccess':
      activeComponent = renderCardScreen();
      break;
  }

  return <>{activeComponent}</>;
}

export default CompassSettings;
