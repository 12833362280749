import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
const HouseholdSizes = [
  { title: '1', value: 1 },
  { title: '2', value: 2 },
  { title: '3', value: 3 },
  { title: '4', value: 4 },
  { title: '5', value: 5 },
  { title: '6', value: 6 },
  { title: '7', value: 7 },
  { title: '+8', value: 8 },
];
const useStyles = makeStyles(() => ({
  btnUnselected: {
    width: 111,
    height: 48,
    borderWidth: 1,
    borderColor: '#B5B6DA',
    borderStyle: 'solid',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fffff',
  },
  btnSelected: {
    width: 111,
    height: 48,
    borderWidth: 2,
    borderColor: '#5255A4',
    borderStyle: 'solid',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fffff',
  },
}));
export default function HouseholdGrid({ handleOnClick }) {
  const classes = useStyles();
  const [selected, setSetselected] = useState('');
  function HouseholdGridItem({ item }) {
    return (
      <Typography
        sx={{ fontFamily: 'Museo Sans', fontWeight: '600', fontSize: 16 }}>
        {item.title}
      </Typography>
    );
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ md: 0.5 }} columns={{ md: 12 }}>
        {HouseholdSizes.map((item, index) => {
          return (
            <Grid md={3} key={index}>
              <Button
                variant="outlined"
                fullWidth
                className={
                  item.title == selected
                    ? classes.btnSelected
                    : classes.btnUnselected
                }
                onClick={() => {
                  setSetselected(item.title);
                  handleOnClick(item.value);
                }}>
                <HouseholdGridItem item={item} />
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}