import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import GlobalStyle from './../../styles/GlobalStyle';
import ResultItem from '../../components/ResultItem';
import { MANUAL_MAIL_TITLE, SUPPORT_EMAIL } from 'capio-common/src/constants';
import { openEmail } from 'capio-common/src/main';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 35,
    marginBottom: 35,
    position: 'relative',
    paddingTop: 70,
  },
  button: {
    padding: '8px 10px',
    borderRadius: 4,
    display: 'block',
    marginTop: 100,
    fontWeight: '600',
  },
  contact: {
    textAlign: 'center',
  },
}));

export default function ManualEmail({ refNumber, setPage, fromSetUp }) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm" className={classes.container}>
      <ResultItem
        title={MANUAL_MAIL_TITLE}
        description=""
        variant="warning"
        icon={<EmailOutlinedIcon style={{ fontSize: 25, color: '#fff' }} />}
      />
      <Typography textAlign="center">
        Please email us at:{' '}
        <a
          target={'_blank'}
          className={classes.link}
          onClick={() => openEmail()}>
          {SUPPORT_EMAIL}
        </a>
      </Typography>
      <Typography mt={2} textAlign="center">
        and provide us your reference number:
      </Typography>
      {/* TODO random number */}
      <Typography variant="body4" className={classes.contact}>
        {refNumber}
      </Typography>
      <Button
        variant={'contained'}
        className={classes.button}
        onClick={() => {
          if (fromSetUp) {
            navigate('/choose-tool');
          } else {
            setPage('CompassInfo');
          }
        }}>
        Done
      </Button>
    </Container>
  );
}
