import React, { useContext, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Context as PaymentContext } from '../../context/PaymentContext';
import { Context as UserContext } from '../../context/UserContext';
import { SET_ROUND_UP } from '../../context/types';
import TitleText from '../../components/common/TitleText';
import CButton from '../../components/common/CButton';
import GlobalStyle from '../../styles/GlobalStyle';
import CLoading from '../../components/common/CLoading';
import { sendAmplitudeData } from '../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  item: {
    height: 50,
    marginTop: 16,
    border: '1px solid #D7D8EB',
  },
  selectedItem: {
    border: '2px solid #5255A4',
  },
}));

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

function RoundWeekly(props) {
  const { updateStep } = props;
  const { state, dispatch, updateRoundUp, setUpRoundUp } =
    useContext(PaymentContext);
  const {
    state: userState,
    checkAuthentication,
    updateUserInfo,
  } = useContext(UserContext);
  const [weekday, setWeekday] = useState('');
  const classes = useStyles();

  const onClickContinue = async () => {
    sendAmplitudeData('Roundup Weekly', {
      'Day of week': `${weekday}`,
    });

    dispatch({
      type: SET_ROUND_UP,
      payload: {
        autoDeposit: true,
        depositDate: WEEKDAYS.indexOf(weekday) + 1,
        depositDateLabel: weekday,
      },
    });

    if (state.roundUp.roundUpEditing) {
      if (state.roundUp.id && state.roundUp.id !== 'null') {
        const { status, message } = await updateRoundUp(
          userState.jwt,
          { ...state.roundUp, depositDate: WEEKDAYS.indexOf(weekday) + 1 },
          checkAuthentication,
        );

        if (status) {
          updateStep(-2);
        }
      } else {
        const { status, message } = await setUpRoundUp(
          userState.jwt,
          { ...state.roundUp, depositDate: WEEKDAYS.indexOf(weekday) + 1 },
          checkAuthentication,
        );
        if (status) {
          updateStep(-2);
        }
      }
    } else {
      updateUserInfo({
        id: userState['userSub'],
        appStatus: {
          parent: 'Home',
          sub: 'Weekly',
        },
      });
      updateStep(4);
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      {state.loading && <CLoading />}
      <TitleText
        title="Which day would you like to add funds?"
        description=""
      />
      <Grid item>
        {WEEKDAYS.map((item, index) => (
          <CButton
            key={index}
            variant="outlined"
            fullWidth
            className={clsx(classes.item, {
              [classes.selectedItem]: weekday === item,
            })}
            onClick={() => setWeekday(item)}>
            {item}
          </CButton>
        ))}
      </Grid>
      <Grid item mt={7}>
        <CButton
          variant="contained"
          disabled={!weekday}
          fullWidth
          loading={state.loading}
          onClick={onClickContinue}>
          {`Set Every ${weekday}`}
        </CButton>
      </Grid>
    </Container>
  );
}

export default RoundWeekly;
