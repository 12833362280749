import React, { useContext, useEffect, useState } from 'react';
import { Grid, Container, Link, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import CTextField from '../../components/common/CTextField';
import CButton from '../../components/common/CButton';
import CAlert from '../../components/common/CAlert';
import { Context as UserContext } from '../../context/UserContext';
import { USER_SETUP } from '../../context/types';
import {
  INCORRECT_INFO,
  INVALID_EMAIL_ADDRESS,
  REQUIRED_EMAIL,
  REQUIRED_PASSWORD,
  SIGN_IN_ERROR,
  ACCOUNT_LOCKED,
  REMEMBER_ME_TOOLTIP,
} from 'capio-common/src/constants';
import { EMAIL_PATTERN } from 'capio-common/src/patterns';
import GlobalStyle from '../../styles/GlobalStyle';
import { sendAmplitudeData } from '../../utilities/amplitude';
import { datadogLogs} from '@datadog/browser-logs';
import BootstrapTooltip from '../home/BootstrapTooltip';
import InformationCircle from '../../assets/images/information-circle.svg';


const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  terms: {
    alignSelf: 'center',
  },
  link: {
    cursor: 'pointer',
    marginLeft: 10,
    marginRight: 10,
  },
  title: {
    marginBottom: 8,
    marginTop: 8,
    fontFamily: 'RobotoSlab',
  },
}));

function LogInAccount(props) {
  const { updateStep } = props;
  const { state, dispatch, signIn, handleRefreshToken } =
    useContext(UserContext);
  const classes = useStyles();
  const [error, setError] = useState('');
  const [rememberMeChecked, setChecked] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setError('');
    const { email, password } = data;
    const res = await signIn(email, password, state.errorCount);

    if (res === ACCOUNT_LOCKED) {
      updateStep(9);
    } else if (res === SIGN_IN_ERROR) {
      datadogLogs.logger.info('Sign In Error');
      setError(res);
    } else {
      setInterval(() => {
        handleRefreshToken();
      }, [3602 * 1000]);
      datadogLogs.logger.info('Sign In Success');
      sessionStorage.setItem('loggedInBefore', true);
      if(rememberMeChecked) {
        window.localStorage.setItem('u_e', email)
      } else {
        window.localStorage.removeItem('u_e');
      }
      sendAmplitudeData('LOGIN');
    }
  };

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const saveUserData = () => {
    const email = getValues('email');
    dispatch({
      type: USER_SETUP,
      payload: { email },
    });
  };

  const onClickTerms = (terms) => {
    saveUserData();
    updateStep(8, terms);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };


  useEffect(() => {
    const  email  = window.localStorage.getItem('u_e');
    setValue('email', email);
  }, []);

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        Welcome Back!
      </Typography>
      <CAlert severity="error" message={error} />
      <Grid item>
        <CTextField
          fullWidth
          type="email"
          name="email"
          label="Email Address"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_EMAIL },
            pattern: { value: EMAIL_PATTERN, message: INVALID_EMAIL_ADDRESS },
          }}
          error={!!errors.email}
          helperText={errors.email && errors.email.message}
        />
      </Grid>
      <Grid item>
        <CTextField
          fullWidth
          type="password"
          name="password"
          label="Password"
          control={control}
          rules={{
            required: true,
          }}
          error={!!errors.password}
          helperText={errors.password && REQUIRED_PASSWORD}
        />
      </Grid>
      <Grid item container sx={{alignItems: 'baseline'}}>

        <FormControlLabel
          sx={{ marginTop: 0 }}
          label={'Remember me'}
          control={<Checkbox checked={rememberMeChecked} onChange={handleChange} />}
        />
        <BootstrapTooltip
          title={REMEMBER_ME_TOOLTIP}
          placement="bottom"
          sx={{}}
          >
            <img src={InformationCircle}  />
        </BootstrapTooltip>
      </Grid>

      <Grid item>
        <CButton
          variant="contained"
          disabled={!isValid}
          fullWidth
          data-testid="loginBtn"
          loading={state.loading}
          sx={{ marginTop: 3 }}
          name="loginBtn"
          onClick={handleSubmit(onSubmit)}>
          Sign In
        </CButton>
      </Grid>
      <Grid item>
        <CButton variant="text" fullWidth onClick={() => updateStep(1)}>
          Sign In Help
        </CButton>
      </Grid>
      <Grid item className={classes.terms}>
        <Link
          color="secondary"
          className={classes.link}
          onClick={() => onClickTerms(1)}>
          Terms of Service
        </Link>
        <Link
          color="secondary"
          className={classes.link}
          onClick={() => onClickTerms(2)}>
          Privacy Policy
        </Link>
      </Grid>
    </Container>
  );
}

export default LogInAccount;
