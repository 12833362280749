import { useContext, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import OneSignal from 'react-onesignal';
import { browserName } from 'react-device-detect';

import Sidebar from './components/Sidebar';
import Home from './pages/home';
import DebtAccount from './pages/home/debt/DebtAccount';
import Payment from './pages/home/debt/index';
import RoundAccount from './pages/home/round/RoundAccount';
import Deposit from './pages/home/round/deposit';
import RoundSetUp from './pages/home/round/settings';
import Onboarding from './pages/onboarding/Onboarding';
import WithdrawFunds from './pages/home/round/withdraw';
import ViewStatements from './pages/home/round/ViewStatements';
import TransactionDetail from './pages/home/round/TransactionDetail';
import TransactionConfirm from './pages/home/round/TransactionConfirm';
import TransasctionVerify from './pages/home/round/TransasctionVerify';

import ChangePassword from './pages/profile/security/ChangePass';
import ProfileDetails from './pages/profile/details';
import EditName from './pages/profile/details/editName/EditName';
import EditPhoneIndex from './pages/profile/details/editPhone';
import EditEmailAddressIndex from './pages/profile/details/editEmailAddress';
import EditAddressIndex from './pages/profile/details/editAddress';
import LinkedBank from './pages/profile/menu/linkedBank/LinkedBank';
import LinkedBillers from './pages/profile/menu/LinkedBillers';
import LinkedPayroll from './pages/profile/menu/LinkedPayroll';
import NotificationsSetting from './pages/profile/menu/Notifications';
import HelpAndSupport from './pages/profile/menu/HelpAndSupport';
import PrivacyPolicy from './pages/profile/menu/PrivacyPolicy';
import TermsService from './pages/profile/menu/TOS';
import { Context as UserContext } from './context/UserContext';
import { Context as PaymentContext } from './context/PaymentContext';
import InActivityModal from './components/InActivityModal';
import ChooseTool from './pages/choose-tool';
import Compass from './pages/choose-tool/compass';
import BillPay from './pages/choose-tool/bill-pay';
import CompassPage from './pages/compass';
import Notifications from './pages/notifications';
import CompassSettings from './pages/profile/menu/compassSettings';
import ConnectBank from './pages/choose-tool/bill-pay/ConnectBank';
import { NOTIFICATION_STATUS } from 'capio-common/src/constants';

import {
  AddPayee,
  BillsDue,
  MyPayees,
  ScheduledPayments,
  PaymentHistory,
} from './components/billgo';
import { USER_SETUP } from './context/types';

OneSignal.init({
  appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
});

function AppRouter() {
  const navigate = useNavigate();
  const {
    dispatch,
    state,
    updateUserInfo,
    getUserInfo,
    checkAuthentication,
    updateNotificationStatus,
  } = useContext(UserContext);
  const {
    dispatch: paymentDispatch,
    getPlaidLinkTokenUpdate,
    state: paymentState,
  } = useContext(PaymentContext);

  useEffect(() => {
    const handleOnesignal = async () => {
      console.log('userSub===>', state.id);

      const subscriptionID = await OneSignal.getUserId();
      if (!subscriptionID) return;

      let existingSubs = state.notification ?? [];
      existingSubs = existingSubs.filter(
        (sub) => sub.subscriptionID !== subscriptionID,
      );

      await updateUserInfo({
        id: state.id,
        notification: [
          ...existingSubs,
          {
            subscriptionID: subscriptionID,
            deviceType: 'browser',
            browser: browserName,
          },
        ],
        failedCount: 0,
        failedAt: new Date(),
        searchContent: {
          ...state.searchContent,
          lastLogged: new Date().toISOString(),
        },
        lastLogged: new Date().toISOString(),
      });

      await OneSignal.setExternalUserId(state.id);
    };
    if (state.id) {
      handleOnesignal();
    }
  }, [state.id]);

  useEffect(() => {
    if (!state?.jwt) return;
    OneSignal.on('notificationDisplay', async function (event) {
      console.warn('OneSignal notification displayed:', event);
      if (!event.data?.itemId) {
        console.warn("There's no valid item_id.");
        return;
      }
      paymentDispatch(
        getPlaidLinkTokenUpdate(
          state.jwt,
          event.data.itemId,
          event.data.notificationId,
          checkAuthentication,
          () => {
            navigate('/connect-bank/update');
          },
        ),
      );
    });

    return () => OneSignal.off('notificationDisplay');
  }, [state?.jwt, navigate]);

  const checkAuth = useMemo(() => {
    const buoyfiToken = sessionStorage.getItem('buoyfiToken');
    const buoyfiUserId = sessionStorage.getItem('buoyfiUserId');
    if (state['id'] && state['jwt']) {
      return true;
    } else if (buoyfiToken && buoyfiUserId) {
      dispatch({
        type: USER_SETUP,
        payload: {
          jwt: buoyfiToken,
          userSub: buoyfiUserId,
          showSideMenu: true,
        },
      });
      if (!state.id) {
        getUserInfo(buoyfiUserId);
      }
      return true;
    }
    return false;
  }, [sessionStorage, state['id'], state['jwt']]);

  const checkPaymentSetup = () => {
    return (
      state &&
      state.paymentSetupFlags &&
      (state.paymentSetupFlags.billpay || state.paymentSetupFlags.compass)
    );
  };


  const renderAuthorizedRoutes = () => {
    return (
      <>
        <Route path="/choose-tool" element={<ChooseTool />} />
        <Route path="/choose-tool/compass" element={<Compass />} />
        <Route path="/choose-tool/bill-pay" element={<BillPay />} />
        <Route path="/compass" element={<CompassPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/home/debt" element={<DebtAccount />} />
        <Route path="/home/debt/payment" element={<Payment />} />
        <Route path="/home/round" element={<RoundAccount />} />
        <Route path="/home/round/deposit" element={<Deposit />} />
        <Route path="/home/round/settings" element={<RoundSetUp />} />
        <Route path="/home/round/withdraw" element={<WithdrawFunds />} />
        <Route
          path="/home/round/view-statements"
          element={<ViewStatements />}
        />
        <Route
          path="/home/round/transaction-detail"
          element={<TransactionDetail />}
        />
        <Route
          path="/home/round/transaction-verify"
          element={<TransasctionVerify />}
        />
        <Route
          path="/home/round/transaction-confirm"
          element={<TransactionConfirm />}
        />
        <Route path="/profile/details" element={<ProfileDetails />} />
        <Route path="/profile/details/edit-name" element={<EditName />} />
        <Route
          path="/profile/details/edit-address"
          element={<EditAddressIndex />}
        />
        <Route
          path="/profile/details/edit-emailaddress"
          element={<EditEmailAddressIndex />}
        />
        <Route
          path="/profile/details/edit-phone"
          element={<EditPhoneIndex />}
        />
        <Route
          path="/profile/security/edit-pass"
          element={<ChangePassword />}
        />
        <Route path="/profile/linked-bank" element={<LinkedBank />} />
        <Route path="/profile/linked-billers" element={<LinkedBillers />} />
        <Route path="/profile/compass-settings" element={<CompassSettings />} />
        <Route path="/profile/linked-payroll" element={<LinkedPayroll />} />
        <Route
          path="/profile/notifications"
          element={<NotificationsSetting />}
        />
        <Route path="/profile/help-and-support" element={<HelpAndSupport />} />
        <Route path="/profile/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/profile/tos" element={<TermsService />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route
          path="/connect-bank/:connectType?"
          element={
            <ConnectBank
              updateStep={() => {}}
              setCompletedStep={async () => {
                console.log('redirect to the home page...');
                const notificationResponse = await updateNotificationStatus({
                  id: paymentState.plaid.currentNotificationId,
                  status: NOTIFICATION_STATUS.PROCESSED,
                });
                if (notificationResponse) {
                  navigate('/home');
                }
              }}
            />
          }
        />
        <Route path="/" element={<Navigate to="/home" replace />} />
      </>
    );
  };

  const renderUnAuthorizedRoutes = () => {
    return (
      <>
        <Route path="/" element={<Onboarding />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="billgo-add-payee" element={<AddPayee />} />
        <Route path="billgo-bills-due" element={<BillsDue />} />
        <Route path="billgo-my-billers" element={<MyPayees />} />
        <Route path="billgo-payment-history" element={<PaymentHistory />} />
        <Route
          path="billgo-scheduled-payments"
          element={<ScheduledPayments />}
        />
      </>
    );
  };

  return (
    <Sidebar>
      <Routes>
        {checkAuth ? renderAuthorizedRoutes() : renderUnAuthorizedRoutes()}
      </Routes>
      {checkAuth ? <InActivityModal /> : null}
    </Sidebar>
  );
}

export default AppRouter;
