import React, { useContext } from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CancelIcon from '@mui/icons-material/Cancel';

import GlobalStyle from '../../styles/GlobalStyle';
import CButton from '../../components/common/CButton';
import ResultItem from '../../components/ResultItem';
import { Context as UserContext } from '../../context/UserContext';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  failedContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 50,
    marginBottom: 24,
    marginLeft: 20,
    marginRight: 20,
  },
}));

export default function DocVerificationFail({ updateStep }) {
  const classes = useStyles();
  const { state: userState, updateUserInfo } = useContext(UserContext);

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Grid className={classes.failedContainer}>
        <ResultItem
          title="There is an issue with your account"
          description="Our banking partner is having trouble confirming your information. We are unable to continue with your account creation at this time. Contact us at customerservice@buoyfi.com or at 1(888) 308-6004. Our support hours are Monday - Friday, 8am - 4pm CST to learn more."
          variant="fail"
          icon={<CancelIcon style={{ fontSize: 25, color: '#fff' }} />}
        />
      </Grid>
      <CButton
        sx={{ marginTop: 15 }}
        variant="contained"
        fullWidth
        onClick={() => {
          // setStep('DocChecklist');
          updateUserInfo({
            id: userState['userSub'],
            appStatus: {
              parent: 'Home',
              sub: 'DocChecklist',
            },
          });
          updateStep(-3);
        }}>
        Continue
      </CButton>
    </Container>
  );
}
