import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

import GlobalStyle from '../../../styles/GlobalStyle';
import KYCBG from '../../../assets/images/kyc-bg.png';
import LogoImage from '../../../components/LogoImage';
import CloseButton from '../../../components/CloseButton';
import ConfirmModal from '../../../components/common/ConfirmModal';
import SPModalContent from '../../../components/common/SPModalContent';
import PopupModal from '../../../components/common/PopupModal';
import ConnectBank from './ConnectBank';
import SetUpBillPay from './SetUpBillPay';
import TOS from './../../kyc/TOS';
import SaveSimple from './SaveSimple';
import VerifySteps from './VerifySteps';
import W9Certification from './W9Certification';
import Agreement from './Agreement';
import DocChecklist from '../../kyc/DocChecklist';
import DocSuccess from '../../kyc/DocSuccess';
import DocVerificationSuccess from '../../kyc/DocVerificationSuccess';
import DocVerificationFail from '../../kyc/DocVerificationFail';
import RoundSetUp from '../../kyc/RoundSetUp';
import RoundWithdraw from '../../kyc/RoundWithdraw';
import RoundWeekly from '../../kyc/RoundWeekly';
import RoundMonthly from '../../kyc/RoundMonthly';
import RoundManual from '../../kyc/RoundManual';
import RoundResult from '../../kyc/RoundResult';
import RoundDateSelection from '../../kyc/RoundDateSelection';
import LinkBillPay from './LinkBillPay';
import { Context as UserContext } from '../../../context/UserContext';
import { USER_SETUP } from '../../../context/types';
import { sendAmplitudeData } from '../../../utilities/amplitude';


const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  cardContent: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '25px',
    right: '25px',
  },
  backButton: {
    position: 'absolute',
    top: '25px',
    left: '25px',
  },
  portalButton: {
    width: '100%',
    backgroundColor: '#4A26D6',
    color: '#FFFFFF',
    padding: '10px 20px',
    textTransform: 'none',
    borderRadius: '54px',
    margin: '20px 0px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  portalText: {
    fontSize: '16px',
    fontWeight: 600,
  },
}));

const capioSteps = [
  'SetUpBillPay',
  'SaveSimple',
  'VerifySteps1',
  'ConnectBank',
  'PrivacyPolicy',
  'VerifySteps2',
  'DocChecklist',
  'DocUploadSuccess',
  'DocVerificationSuccess',
  'DocVerificationFail',
  'Agreement',
  'VerifySteps3',
  'RoundSetUp',
  'Withdraw',
  'Weekly',
  'Monthly',
  'Manually',
  'RoundDateSelection',
  'VerifySteps4',
  'LinkBillAccount',
  'W9Certification',
  'RoundResult',
];

export default function BillPay() {
  const classes = useStyles();
  const width = window.innerWidth;
  const navigate = useNavigate();
  const [step, setStep] = useState(capioSteps[0]);

  const [completedStep, setCompletedStep] = useState(0);
  const [verifySteps] = useState([
    {
      name: 'Verify Information',
    },
    {
      name: 'Link Bank Account',
      description:
        'Connect your bank account to turn spare change to BuoyFi Savings.',
    },
    {
      name: 'Verify Document',
      description:
        'Required for security and compliance, we will capture images of your State ID.',
    },
    {
      name: 'Set Up BuoyFi Savings',
      description:
        'Configure how often you want your calculated round up funds to be transferred out of your connected bank account.',
    },
    {
      name: 'Link Bill Pay Accounts',
      description: 'Use BuoyFi Savings or another linked account to pay bills.',
    },
  ]);
  const [isVisible, setIsVisible] = useState(false);
  const [isSPModalVisible, setIsSPModalVisible] = useState(true);

  const {
    state: userState,
    dispatch,
    updateUserInfo,
    updateUserTags,
  } = useContext(UserContext);

  useEffect(() => {
    dispatch({
      type: USER_SETUP,
      payload: { showSideMenu: true },
    });
  }, []);

  useEffect(() => {
    if (
      userState.appStatus &&
      userState.appStatus.parent &&
      userState.appStatus.sub
    ) {
      setStep(userState.appStatus.sub);
      const index = capioSteps.indexOf(userState.appStatus.sub);
      if (index > 2) {
        setCompletedStep(1);
      }
      if (index > 5) {
        setCompletedStep(2);
      }
      if (index > 11) {
        setCompletedStep(3);
      }
      if (index > 18) {
        setCompletedStep(4);
      }
    }
  }, [userState.jwt]);

  const handleClose = async () => {
    setIsVisible(false);
  };

  const handleAmplitudeEventClose = () => {
    if (step === 'RoundSetUp') {
      sendAmplitudeData('SavingsSetup_7B_ClickSetupSavings', {
        click_name: 'Dismissed screen',
      });
    }
    if (step === 'LinkBillAccount') {
      sendAmplitudeData('PaymentSetup_2_SetUpBillPay', {
        click_name: 'Dismissed screen',
      });
    }
  };

  const handleConfirm = () => {
    handleAmplitudeEventClose();
    setIsVisible(false);
    navigate('/choose-tool');
  };

  const handleCancel = async () => {
    handleAmplitudeEventClose();
    setIsVisible(false);
    await updateUserInfo({
      id: userState['userSub'],
      appStatus: {
        parent: '',
        sub: '',
      },
    });

    await updateUserTags(userState.jwt, {
      tags: {
        Payment_Setup_Complete: null,
      },
      userId: userState.userSub,
    });

    navigate('/choose-tool');
  };

  const updateStep = (move) => {
    const newStep = capioSteps[capioSteps.indexOf(step) + move];
    setStep(newStep);
  };

  let activeComponent = '';
  switch (step) {
    case 'SetUpBillPay':
      activeComponent = <SetUpBillPay updateStep={updateStep} />;
      break;
    case 'SaveSimple':
      activeComponent = <SaveSimple updateStep={updateStep} />;
      break;
    case 'VerifySteps1':
    case 'VerifySteps2':
    case 'VerifySteps3':
    case 'VerifySteps4':
      activeComponent = (
        <VerifySteps
          activeStep={step}
          completedStep={completedStep}
          verifySteps={verifySteps}
          updateStep={updateStep}
        />
      );
      break;
    case 'ConnectBank':
      activeComponent = (
        <ConnectBank
          activeStep={step}
          updateStep={updateStep}
          setCompletedStep={(value) => {
            setCompletedStep(1);
            updateStep(value);
          }}
        />
      );
      break;
    case 'PrivacyPolicy':
      activeComponent = (
        <TOS updateStep={updateStep} backStepCount={-1} terms={0} />
      );
      break;
    case 'DocChecklist':
      activeComponent = <DocChecklist updateStep={updateStep} />;
      break;
    case 'DocUploadSuccess':
      activeComponent = (
        <DocSuccess
          updateStep={(value) => {
            updateStep(value);
          }}
          setStep={setStep}
        />
      );
      break;
    case 'DocVerificationSuccess': {
      activeComponent = (
        <DocVerificationSuccess
          activeStep={step}
          updateStep={(value) => {
            updateStep(value);
            setCompletedStep(2);
          }}
        />
      );
      break;
    }
    case 'DocVerificationFail': {
      activeComponent = (
        <DocVerificationFail
          updateStep={(value) => {
            updateStep(value);
            setCompletedStep(2);
          }}
        />
      );
      break;
    }
    case 'Agreement':
      activeComponent = <Agreement updateStep={updateStep} />;
      break;
    case 'RoundSetUp':
      activeComponent = (
        <RoundSetUp
          updateStep={(val) => {
            updateStep(val);
            setCompletedStep(3);
          }}
        />
      );
      break;
    case 'LinkBillAccount':
      activeComponent = (
        <LinkBillPay
          updateStep={(value) => {
            updateStep(value);
          }}
        />
      );
      break;
    case 'Withdraw':
      activeComponent = <RoundWithdraw updateStep={updateStep} />;
      break;
    case 'Weekly':
      activeComponent = <RoundWeekly updateStep={updateStep} />;
      break;
    case 'Monthly':
      activeComponent = <RoundMonthly updateStep={updateStep} />;
      break;
    case 'Manually':
      activeComponent = <RoundManual updateStep={updateStep} />;
      break;
    case 'W9Certification':
      activeComponent = <W9Certification updateStep={updateStep} />;
      break;
    case 'RoundDateSelection':
      activeComponent = <RoundDateSelection updateStep={updateStep} />;
      break;
    case 'RoundResult':
      activeComponent = <RoundResult updateStep={updateStep} />;
      break;
  }

  return (
    <Grid container sx={{ flexDirection: 'row', height: '100%' }}>
      <Grid item width={'100%'} display="flex" alignItems="center">
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            zIndex: -1,
            width: '100%',
          }}>
          <img src={KYCBG} width="100%" height="auto" />
        </Box>
        <Container
          className={classes.authContainer}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Container maxWidth="sm" sx={{ marginTop: -1 }}>
            <Card>
              <CardContent className={classes.cardContent}>
                <LogoImage />
                <CloseButton
                  className={classes.closeButton}
                  onClick={() => setIsVisible(true)}
                />
                {activeComponent}
              </CardContent>
            </Card>
          </Container>
        </Container>
      </Grid>
      <ConfirmModal
        open={isVisible}
        title=""
        content={
          <Typography align="center">
            Do you want to save your progress?
          </Typography>
        }
        onConfirm={handleConfirm}
        onClose={handleCancel}
        okText="Yes"
        cancelText="No"
        showExit
        onExit={handleClose}
      />
      <PopupModal open={isSPModalVisible}  styles={{ height: 300, width: 350 }}>
        <SPModalContent />
        <Button
            variant="contained"
            className={classes.portalButton}
            onClick={() => {
              navigate('/home');
            }}
            style={{ maxWidth: '100%', margin: 'auto' }}>
            <Typography className={classes.portalText} variant="span">
              Go to Calculator
            </Typography>
        </Button>
      </PopupModal>
    </Grid>
  );
}
