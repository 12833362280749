import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';

import { Context as PaymentContext } from '../../../context/PaymentContext';
import { Context as UserContext } from '../../../context/UserContext';
import TitleText from '../../../components/common/TitleText';
import GlobalStyle from '../../../styles/GlobalStyle';
import PlaidButton from '../../../components/PlaidButton';
import CAlert from '../../../components/common/CAlert';

import { ReactComponent as TransferSvg } from '../../../assets/images/make-transfer.svg';
import { ReactComponent as PayoffSvg } from '../../../assets/images/payoff.svg';
import { ReactComponent as SpareChangeSvg } from '../../../assets/images/spare-change.svg';
import BootstrapTooltip from '../../../pages/home/BootstrapTooltip';
import {
  CONNECT_BANK_DESCRIPTION,
  CONNECT_BANK_TITLE,
  CONNECT_BANK_TOOLTIP,
} from 'capio-common/src/constants';
import { sendAmplitudeData } from '../../../utilities/amplitude';
import CLoading from '../../../components/common/CLoading';
import { getAmplitudeEventName } from '../../../utilities/getAmplitudeEventName';

const useStyles = makeStyles((theme) => ({
  ...GlobalStyle,
  comment: {
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
  },
  label: {
    fontWeight: 600,
    fontSize: 16,
  },
  checkedLabel: {
    color: 'red',
  },
  tooltipText: {
    marginTop: '10px',
    marginBottom: '10px',
    cursor: 'pointer',
    fontSize: '0.9rem',
  },
}));

const data = [
  {
    icon: <TransferSvg />,
    label: 'Make transfers',
  },
  {
    icon: <PayoffSvg />,
    label: 'Pay off your bills',
  },
  {
    icon: <SpareChangeSvg />,
    label: 'Round up spare change',
  },
];

function ConnectBank(props) {
  const { updateStep, setCompletedStep, activeStep } = props;
  const {
    dispatch,
    state: paymentState,
    getPlaidLinkToken,
    exchangePlaidPublicToken,
  } = useContext(PaymentContext);
  const {
    state: userState,
    checkAuthentication,
    updateUserInfo,
  } = useContext(UserContext);
  const [checked, setChecked] = useState(false);
  const [connectedBank, setConnectedBank] = useState(false);
  const [error, setError] = useState('');
  const [isCalling, setCalling] = useState(false);
  const [amplitudeEventLabel, setAmplitudeEventLabel] = useState(
    getAmplitudeEventName(activeStep),
  );
  const classes = useStyles();
  const { connectType } = useParams();

  const { plaid, loading } = paymentState;

  useEffect(() => {
    if (!userState.jwt || connectType === 'update') {
      return;
    }
    dispatch(getPlaidLinkToken(userState.jwt, checkAuthentication));
  }, [userState.jwt]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onInformationClick = () => {
    sendAmplitudeData('SavingsSetup_2B_KeepInfoSafe');
    updateStep(1);
  };

  const onSuccess = async (success) => {
    const payload = {
      token: userState.jwt,
      plaidInfoId: plaid.plaidInfoId,
      success,
    };
    try {
      setCalling(true);
      updateUserInfo({
        id: userState['userSub'],
        appStatus: {
          parent: 'Home',
          sub: 'DocChecklist',
        },
      });
      const { status, message } = await exchangePlaidPublicToken(payload);
      if (status) {
        sendAmplitudeData('SavingsSetup_3_PlaidBankConnect');
        setCompletedStep(2);
      } else {
        setError(message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setConnectedBank(false);
      setCalling(false);
    }
  };

  const onPlaidPress = () => {
    if (amplitudeEventLabel) {
      sendAmplitudeData(amplitudeEventLabel);
    }
    setConnectedBank(true);
  };

  const renderItem = (item) => {
    return (
      <Box display="flex" alignItems="center" key={item.label} mt={2}>
        <Box mr={3}>{item.icon}</Box>
        <Typography color="corePurple70.main" className={classes.label}>
          {item.label}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {isCalling && <CLoading />}
      <Container
        maxWidth="sm"
        className={classes.container}
        sx={{ position: 'relative' }}>
        <TitleText
          title={CONNECT_BANK_TITLE}
          description={CONNECT_BANK_DESCRIPTION}
        />
        <CAlert severity="error" message={error} />
        <Box mb={3}>{data.map((item) => renderItem(item))}</Box>
        <BootstrapTooltip title={CONNECT_BANK_TOOLTIP} placement="bottom">
          <Typography
            className={classes.tooltipText}
            variant="body1"
            color="purple70.main">
            What is Plaid?
          </Typography>
        </BootstrapTooltip>
        <Typography
          onClick={onInformationClick}
          className={classes.comment}
          color="purple70.main">
          Learn about how we keep your information safe
        </Typography>
        <Box my={3}>
          <FormControlLabel
            label="I authorize BuoyFi to transfer funds from this account to BuoyFi Savings based on the
          settings I choose."
            control={<Checkbox checked={checked} onChange={handleChange} />}
            className={classes.checkedLabel}
            sx={{
              color: 'red',
            }}
          />
        </Box>
        <PlaidButton
          label="Connect"
          linkToken={plaid.linkToken}
          onSuccess={onSuccess}
          loading={loading}
          checked={checked && connectedBank}
          onPress={() => onPlaidPress()}
          btnDisabled={!checked}
          step={activeStep}
          setChecked={setConnectedBank}
        />
      </Container>
    </>
  );
}

export default ConnectBank;
