import React from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
} from '@mui/material';

import ClockSvg from '../../../assets/images/clock.svg';
import LogoImage from '../../../components/LogoImage';

export default function VerifyPayment({ updateStep }) {
  return (
    <>
      <LogoImage />
      <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <Box mt={4}>
          <img width={'auto'} src={ClockSvg} alt="clock" />
        </Box>
        <Typography variant="h5" fontFamily={'RobotoSlab'} mb={3} mt={4}>
          Your account is under way...
        </Typography>
        <Typography>
          Please wait while we verify your checking account. This could take up
          to 48 hours. We apologize for the inconvience.
        </Typography>
      </Container>
    </>
  );
}
