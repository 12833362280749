import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  Drawer,
  Grid,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme } from '@mui/system';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import GlobalStyle from '../../styles/GlobalStyle';
import { ReactComponent as Pencil } from '../../assets/images/pencil.svg';
import { ReactComponent as Bell } from '../../assets/images/bell.svg';
import { ReactComponent as Doc } from '../../assets/images/doc.svg';
import { ReactComponent as Phone } from '../../assets/images/phone.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/download.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/information-circle.svg';
import { Context as UserContext } from '../../context/UserContext';
import { Context as PDFContext } from '../../context/PDFContext';
import { currencyFormatter } from 'capio-common/src/patterns';
import DetailsBox from './DetailsBox';
import { GET_PDF } from '../../context/types';
import { sendAmplitudeData } from '../../utilities/amplitude';
import CLoading from '../../components/common/CLoading';
import { COMPASS_INFO_TITLE, GUIDELINES_DISCLAIMER_TEXT } from 'capio-common/src/constants';
import AccountCard from '../../components/AccountCard';
import { formatToUSPhoneNumber } from 'capio-common/src/main';
import DetailsBoxModal from './DetailsBoxModal';

const useStyles = makeStyles({
  ...GlobalStyle,
  compassContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 40,
    marginBottom: 10,
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px',
    borderBottom: '1px solid #E9E9E9',
  },
  titleText: {
    fontSize: '18px',
    fontWeight: 600,
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editButton: {
    backgroundColor: '#E6E8FA',
    padding: '5px 16px',
    borderRadius: '54px',
    '&:hover': {
      backgroundColor: '#D0D3F8',
    },
  },
  debtContent: {
    width: '27%',
    minWidth: '300px',
    maxWidth: '340px',
    padding: '20px 30px',
    maxHeight: 'calc(100vh - 50px)',
    overflowY: 'auto',
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bodyContentContainer: {
    padding: '40px 80px',
  },
  bodyContent: {
    marginTop: 10,
    marginBottom: 10,
    paddingRight: 16,
  },
  whiteBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '12px',
    padding: '25px 25px',
    margin: '30px auto',
    boxShadow: '0px 1px 2px 0px rgba(31, 41, 55, 0.08)',
  },
  button: {
    padding: '2px 10px',
    border: '1px solid  #250E39',
    borderRadius: 4,
    color: '#250E39',
    display: 'block',
    marginTop: 12,
  },
  whiteBoxLink: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
  },
  whiteBoxIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  paginationText: {
    textAlign: 'center',
    display: 'block',
  },
  smallTitle: {
    fontWeight: 600,
    fontSize: '0.9rem',
    display: 'block',
    marginBottom: 7,
  },
  detailsContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
  },
  orBox: {
    width: '70%',

    border: '1px solid #C5C5C5',
  },
  editLink: {
    fontWeight: 600,
  },
  badgeButton: {
    backgroundColor: '#E6E8FA',
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '12px',
  },
  badgeContent: {
    color: 'red',
  },
  badgeDot: {
    '& .MuiBadge-dot': {
      backgroundColor: 'red',
    },
  },
  accountCard: {
    padding: '16px',
    margin: '16px',
    borderRadius: '12px',
    boxShadow: '0px 1px 2px 0px rgba(31, 41, 55, 0.08)',
    transition: 'background-color 0.3s',
  },
  accountCardSelected: {
    backgroundColor: '#5E35B1',
    '&:hover': {
      backgroundColor: '#5E35B1',
    },
  },
  accountCardEven: {
    backgroundColor: '#F3F3F3',
    '&:hover': {
      backgroundColor: '#E0E0E0',
    },
  },
  accountCardOdd: {
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#E0E0E0',
    },
  },
  tabContainer: {
    flexGrow: 1,
    backgroundColor: '#E6E8FA',
    borderRadius: '50px',
  },
  tab: {
    flexGrow: 1,
    transition: 'background-color 0.7s',
    borderRadius: '50px',
    border: '1px solid transparent',
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'none',

    '&.Mui-selected': {
      backgroundColor: '#4A26D6',
      color: '#fff',
    },
    '&:not(.Mui-selected)': {
      backgroundColor: '#E6E8FA',
      color: '#000',
    },
  },
  tabTitle: {
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: 10,
  },
  indicator: {
    display: 'none',
  },
  detailsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  detailsTitle: {
    fontSize: '28px',
    color: '#4A26D6',
  },
  detailsPhoneNumber: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  portalButton: {
    width: '100%',
    backgroundColor: '#4A26D6',
    color: '#FFFFFF',
    padding: '10px 20px',
    textTransform: 'none',
    borderRadius: '54px',
    margin: '20px 0px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  portalText: {
    fontSize: '16px',
    fontWeight: 600,
  },
});

function CompassInfo({
  setIsSettlement,
  accounts,
  accountIndex,
  setAccountIndex,
  setCapioAccountName,
  setPage,
}) {
  const classes = useStyles();
  const { dispatch, state: pdfState, getPDFLink } = useContext(PDFContext);
  const { state: userState, getUnreadNotificationCount } =
    useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [autoOpenPDF, setAutoOpenPDF] = useState(false);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [hasPartnerAccounts, setHasPartnerAccounts] = useState(false);

  const navigate = useNavigate();

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 680,
        md: 1000,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  const matchesTab = useMediaQuery(theme.breakpoints.up('md'));

  const handleDownloadPDF = () => {
    dispatch(getPDFLink(userState.jwt));
  };

  const toggleDrawer = (newOpen) => {
    setOpenDetailsDrawer(newOpen);
  };

  const onLearnMore = (isSettlement) => {
    setIsSettlement(isSettlement);
    if (accounts[accountIndex]['capioAccountID']) {
      setPage('DivinityLearnMore');
    } else {
      setPage('ManualLearnMore');
    }
  };

  useEffect(() => {
    if (pdfState.pdfLink) {
      if (autoOpenPDF) {
        window.open(pdfState.pdfLink, '_blank');
        setTimeout(() => {
          dispatch({
            type: GET_PDF,
            payload: null,
          });
        });
      } else {
        let emailUrl = `mailto:?subject=Buoyfi Guidelines&body=A PDF of my guidelines can be found at ${pdfState.pdfLink}`;
        window.open(emailUrl, '_blank');
      }
    }
  }, [pdfState.pdfLink]);

  useEffect(async () => {
    await getUnreadNotificationCount({
      userId: userState.id,
    });
  }, [userState.id]);

  useEffect(() => {
    const accountsWithCapioID = accounts
      ? accounts.filter((acc) => acc.capioAccountID != null).length
      : 0;
    setHasPartnerAccounts(accountsWithCapioID > 0);
  }, [accounts]);

  const handleTabChange = (event, newValue) => {
    setAccountIndex(0);
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (accounts) {
      let newFilteredAccounts = [];

      if (selectedTab === 0 && hasPartnerAccounts) {
        const groups = {};

        accounts.forEach((account) => {
          if (account.capioAccountID) {
            const nameKey = account.debt.debtName;
            if (!groups[nameKey]) {
              groups[nameKey] = {
                ...account,
                groupInfo: {
                  count: 1,
                  totalBalance: account.debt.amount,
                  totalForgiven: account.guidelines?.settlement?.forgiven || 0,
                  totalSettlementAmount:
                    account.guidelines?.settlement?.amount || 0,
                  totalPaymentAmount: account.guidelines?.payment?.amount || 0,
                  totalPaymentForgiven:
                    account.guidelines?.payment?.forgiven || 0,
                  paymentMonths: account.guidelines?.payment?.period, // each payment for an agency will have the same payment period
                },
              };
            } else {
              groups[nameKey].groupInfo.totalBalance += account.debt.amount;
              groups[nameKey].groupInfo.totalForgiven +=
                account.guidelines?.settlement?.forgiven || 0;
              groups[nameKey].groupInfo.count += 1;
              groups[nameKey].groupInfo.totalSettlementAmount +=
                account.guidelines?.settlement?.amount || 0;
              groups[nameKey].groupInfo.totalPaymentAmount +=
                account.guidelines?.payment?.amount || 0;
              groups[nameKey].groupInfo.totalPaymentForgiven +=
                account.guidelines?.payment?.forgiven || 0;
            }
          }
        });
        newFilteredAccounts = Object.values(groups);
      } else {
        newFilteredAccounts = accounts.filter(
          (account) => account.capioAccountID == null,
        );
      }
      setFilteredAccounts(newFilteredAccounts);
    }
  }, [accounts, selectedTab, hasPartnerAccounts]);

  useEffect(() => {
    if (filteredAccounts.length > 0) {
      setSelectedAccount(filteredAccounts[accountIndex]);
    } else if (accounts && accounts.length === 1) {
      setSelectedAccount(accounts[0]);
      setAccountIndex(0);
    } else {
      setSelectedAccount(null);
    }
  }, [filteredAccounts, accounts, accountIndex]);

  const displayTitleOrSwitcher = () => {
    if (!accounts) {
      return null;
    }

    const accountsWithCapioID = accounts.filter(
      (acc) => acc.capioAccountID != null,
    ).length;
    const accountsWithoutCapioID = accounts.filter(
      (acc) => acc.capioAccountID == null,
    ).length;

    if (accountsWithCapioID > 0 && accountsWithoutCapioID === 0) {
      return (
        <Typography
          className={
            classes.tabTitle
          }>{`Account Servicer (${accountsWithCapioID})`}</Typography>
      );
    } else if (accountsWithCapioID === 0 && accountsWithoutCapioID > 0) {
      return (
        <Typography
          className={
            classes.tabTitle
          }>{`My Accounts (${accountsWithoutCapioID})`}</Typography>
      );
    } else if (accountsWithCapioID > 0 && accountsWithoutCapioID > 0) {
      return (
        <Box className={classes.tabContainer}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            classes={{ indicator: classes.indicator }}
            centered>
            <Tab
              label={`Account Servicer (${accountsWithCapioID})`}
              className={classes.tab}
            />
            <Tab
              label={`My Accounts (${accountsWithoutCapioID})`}
              className={classes.tab}
            />
          </Tabs>
        </Box>
      );
    }
    return null;
  };

  const displayAccounts = () => {
    if (filteredAccounts.length > 0) {
      return filteredAccounts.map((account, i) => (
        <Grid item key={i}>
          <AccountCard
            account={account}
            accountName={
              account.groupInfo
                ? `${account.debt.debtName} (${account.groupInfo.count} ${
                    account.groupInfo.count > 1 ? 'accounts' : 'account'
                  })`
                : account.debt.debtName
            }
            potentialSavings={currencyFormatter(
              account.groupInfo
                ? account.groupInfo.totalForgiven
                : account.guidelines?.settlement?.forgiven,
            )}
            balance={currencyFormatter(
              account.groupInfo
                ? account.groupInfo.totalBalance
                : account.debt.amount,
            )}
            index={i}
            isSelected={i === accountIndex}
            onSelect={setAccountIndex}
            setCapioAccountName={setCapioAccountName}
          />
        </Grid>
      ));
    } else if (accounts && accounts.length === 1) {
      const account = accounts[0];
      return (
        <Grid item>
          <AccountCard
            account={account}
            accountName={account.debt.debtName}
            potentialSavings={currencyFormatter(
              account.guidelines?.settlement?.forgiven,
            )}
            balance={currencyFormatter(account.debt.amount)}
            index={0}
            isSelected={accountIndex === 0}
            onSelect={setAccountIndex}
            setCapioAccountName={setCapioAccountName}
          />
        </Grid>
      );
    }
  };

  const renderDetailsHeader = () => {
    if (!selectedAccount) return null;

    const showViewAccountDetailsButton =
      selectedAccount.capioAccountID &&
      (accounts.length > 1 || filteredAccounts.length > 1);

    const similarAccountsCount = selectedAccount.groupInfo
      ? selectedAccount.groupInfo.count
      : 1;

    return (
      <Box>
        <Box className={classes.detailsHeader}>
          <Box className={classes.detailsTitle}>
            {selectedAccount.debt.debtName}
          </Box>

          {showViewAccountDetailsButton && (
            <Button
              style={{
                borderRadius: '54px',
                backgroundColor: '#E6E8FA',
                textTransform: 'none',
                padding: '5px 20px',
                display: 'flex',
                alignItems: 'center',
                color: '#4A26D6',
                fontWeight: 600,
                fontSize: '14px',
              }}
              onClick={() => {
                sendAmplitudeData('Calc_ViewAccountDetails');
                toggleDrawer(true);
              }}
              startIcon={<Doc />}>
              View Account Details ({similarAccountsCount})
            </Button>
          )}
        </Box>
        {selectedAccount && selectedAccount.capioAccountID ? (
          <Box className={classes.detailsPhoneNumber}>
            <Phone />
            <Typography
              sx={{
                fontSize: '18px',
              }}>
              <Typography>
                {formatToUSPhoneNumber(selectedAccount.phoneNumber) ||
                  'No phone number available'}
              </Typography>
            </Typography>
          </Box>
        ) : null}
      </Box>
    );
  };

  const DrawerList = () => {
    if (!selectedAccount) {
      return null;
    }

    const relatedAccounts = accounts.filter(
      (account) =>
        account.debt.debtName === selectedAccount.debt.debtName &&
        account.capioAccountID,
    );

    return (
      <Box
        sx={{
          width: 400,
          padding: '32px',
          backgroundColor: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
        role="presentation">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Typography
            sx={{
              fontSize: '24px',
              color: '#4A26D6',
            }}>
            {selectedAccount.debt.debtName}
          </Typography>
          <IconButton onClick={() => setOpenDetailsDrawer(false)} edge="end">
            <CloseIcon
              sx={{
                color: '#000000',
                width: '20px',
                height: '20px',
              }}
            />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontSize: '14px',
            marginBottom: '20px',
          }}>
          Medical Accounts ({relatedAccounts.length})
        </Typography>
        {relatedAccounts.map((account, index) => (
          <DetailsBoxModal
            key={account.accountNumber}
            index={index}
            account={account}
          />
        ))}
      </Box>
    );
  };

  if (pdfState.loading) {
    return <CLoading />;
  }

const getSettlementPayment = (account) => {
  return account.groupInfo ?
    currencyFormatter(
      account.groupInfo?.totalSettlementAmount,
    ) 
    :
    currencyFormatter(
      account.guidelines?.settlement?.amount,
    );
  }

  const getSettlementForgiven = (account) => {
    return account.groupInfo ?
      currencyFormatter(
        selectedAccount.groupInfo?.totalForgiven,
      ) 
    :
      currencyFormatter(
        account.guidelines?.settlement?.forgiven,
      );
  }

  const getSettlementLength = (account) => {
    return account.guidelines?.settlement?.period === -1
      ? 'One payment'
      : `${account.guidelines?.settlement?.period} Months`
  }

  const getPaymentAmount = (account) => {
    return account.groupInfo ?
    currencyFormatter(
      account.groupInfo?.totalPaymentAmount,
    ) :
    currencyFormatter(
      account.guidelines?.payment?.amount,
    );
  }

  const getPaymentForgiven = (account) => {
    return account.groupInfo ?
    currencyFormatter(
      selectedAccount.groupInfo?.totalPaymentForgiven,
    ) 
  :
    currencyFormatter(
      account.guidelines?.payment?.forgiven,
    );
  }

  const getPaymentLength = (account) => {
    return account.groupInfo ?
    `${account.groupInfo?.paymentMonths} Months`
    :`${account.guidelines?.payment.period} Months`;
  }

  return (
    <Box>
      <Grid className={classes.titleBar}>
        <Typography className={classes.titleText}>
          {COMPASS_INFO_TITLE}
        </Typography>
        <Grid className={classes.titleSection}>
          <Button
            onClick={() => {
              sendAmplitudeData('CalcHome_Edit');
              navigate('/profile/compass-settings');
            }}
            className={classes.editButton}
            variant="contained"
            disableElevation
            startIcon={<Pencil />}>
            <Typography
              className={classes.editLink}
              variant="p"
              color="purple100.main">
              Edit
            </Typography>
          </Button>

          <Grid className={classes.badgeButton}>
            <Badge
              className={classes.badgeContent}
              color="error"
              invisible={userState.unreadNotificationCount === 0}
              variant="dot"
              overlap="circular">
              <IconButton
                onClick={() => {
                  sendAmplitudeData('Navigation_Notifications');
                  navigate('/notifications');
                }}>
                <Bell />
              </IconButton>
            </Badge>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.boxContainer}>
        {userState && accounts ? (
          <Box className={classes.debtContent}>
            {displayTitleOrSwitcher()}
            {displayAccounts()}
          </Box>
        ) : null}
        <Box
          sx={{
            width: '73%',
            backgroundColor: '#f8f8f8',
            padding: matchesTab ? '40px 40px' : '10px',
            borderLeft: '1px solid #E9E9E9',
          }}>
          {renderDetailsHeader()}
          <Grid className={classes.bodyContent}>
            <Typography variant="p">
              Based on BuoyFi's analysis of your income and medical accounts,
              your guidelines for resolving these account(s) follow.
              <br />
              <br />
              Use these guidelines as a tool to discuss the account(s) with your
              medical account servicer using the contact options provided.
            </Typography>
          </Grid>
          {accounts && selectedAccount ? (
            <Grid container direction={!matchesTab ? 'row' : 'column'}>
              <Grid
                sx={{
                  width: '100%',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '12px',
                  border: '1px solid #E9E9E9',
                  boxShadow: '0px 1px 2px 0px rgba(31, 41, 55, 0.08)',
                }}
                direction={!matchesTab ? 'row' : 'column'}>
                <Grid item lg={5.8} md={5.8} sm={12}>
                  <DetailsBox
                    optionNumber={'1'}
                    title={'Account Settlement Option'}
                    monthlyPayment={getSettlementPayment(selectedAccount)}
                    lengthOfPlan={getSettlementLength(selectedAccount)}
                    forgivenAmount={getSettlementForgiven(selectedAccount)}
                    onSelect={() => {
                      sendAmplitudeData('CalcHome_SettlementLearnMore');
                      onLearnMore(true);
                    }}
                  />
                </Grid>
                {selectedAccount.guidelines?.payment !== undefined &&
                  selectedAccount.guidelines?.payment !== null && (
                    <Grid item lg={5.8} md={5.8} sm={12}>
                      <Divider sx={{ margin: ' 0px 20px' }} />
                      <DetailsBox
                        optionNumber={'2'}
                        title={'Payment Plan Option'}
                        monthlyPayment={getPaymentAmount(selectedAccount)}
                        lengthOfPlan={getPaymentLength(selectedAccount) }
                        forgivenAmount={getPaymentForgiven(selectedAccount)}
                        onSelect={() => {
                          onLearnMore(false);
                          sendAmplitudeData('CalcHome_PaymentLearnMore');
                        }}
                      />
                    </Grid>
                  )}
                {selectedAccount.capioAccountID ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '0px 20px 20px',
                    }}>
                    <Button
                      variant="contained"
                      className={classes.portalButton}
                      onClick={() => {
                        sendAmplitudeData('Calc_AccountPaymentGuidelines', {
                          click_name: 'Take me to servicer portal',
                        });
                        if (selectedAccount.debt.debtName === 'AssetCare') {
                          window.open(
                            'https://ac.mypatientconnect.com',
                            '_blank',
                          );
                        }
                        if (selectedAccount.debt.debtName === 'MBA Law') {
                          window.open(
                            'https://mba.mypatientconnect.com',
                            '_blank',
                          );
                        }
                        if (selectedAccount.debt.debtName === 'Recoveri') {
                          window.open(
                            'https://recoveri.mypatientconnect.com',
                            '_blank',
                          );
                        }
                        if (
                          selectedAccount.debt.debtName === 'Capio Partners'
                        ) {
                          window.open('https://go.capiopay.com/', '_blank');
                        }
                      }}
                      style={{ maxWidth: '100%', margin: 'auto' }}>
                      <Typography className={classes.portalText} variant="span">
                        Take me to servicer portal
                      </Typography>
                    </Button>
                  </Grid>
                ) : (
                  <Box
                    style={{
                      display: 'flex',
                      gap: '20px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '0px 20px 0px',
                    }}>
                    <Button
                      variant="contained"
                      className={classes.portalButton}
                      onClick={() => {
                        sendAmplitudeData('Calc_AccountPaymentGuidelines', {
                          click_name: 'View PDF',
                        });
                        setAutoOpenPDF(true);
                        handleDownloadPDF();
                      }}>
                      <Typography className={classes.portalText} variant="span">
                        View in PDF
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.portalButton}
                      onClick={() => {
                        sendAmplitudeData('Calc_AccountPaymentGuidelines', {
                          click_name: 'Share',
                        });
                        setAutoOpenPDF(false);
                        handleDownloadPDF();
                      }}
                      style={{
                        backgroundColor: '#E6E8FA',
                        color: '#4A26D6',
                      }}>
                      <Typography className={classes.portalText} variant="span">
                        Share my guidelines
                      </Typography>
                    </Button>
                  </Box>
                )}
              </Grid>
              <Box display="flex" className={classes.whiteBox}>
                <Typography
                  color="purple90.main"
                  variant="body4"
                  className={classes.whiteBoxLink}
                  onClick={() => {
                    sendAmplitudeData('CalcHome_ReviewPDF');
                    setAutoOpenPDF(true);
                    handleDownloadPDF();
                  }}>
                  <DownloadIcon className={classes.whiteBoxIcon} />
                  Export Guideline PDF
                </Typography>
                <Typography
                  color="purple90.main"
                  variant="body4"
                  className={classes.whiteBoxLink}
                  onClick={() => {
                    sendAmplitudeData('CalcHome_AdditionalAssistance');
                    setPage('AdditionalInfo');
                  }}>
                  <InfoIcon className={classes.whiteBoxIcon} />
                  Additional Financial Assistance
                </Typography>
              </Box>
            </Grid>
          ) : null}
          <Box className={classes.bodyContent}>
            <Typography variant="body2">
              {GUIDELINES_DISCLAIMER_TEXT}
            </Typography>
          </Box>
        </Box>
      </Box>
      {selectedAccount && selectedAccount.capioAccountID ? (
        <Drawer
          anchor="right"
          open={openDetailsDrawer}
          onClose={() => toggleDrawer(false)}>
          {DrawerList()}
        </Drawer>
      ) : null}
    </Box>
  );
}

export default CompassInfo;
