import React, { useContext } from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CloseIcon from '@mui/icons-material/Close';

import { Context as UserContext } from '../../context/UserContext';
import CButton from '../../components/common/CButton';
import ResultItem from '../../components/ResultItem';
import GlobalStyle from '../../styles/GlobalStyle';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function VerifyResult(props) {
  const { updateStep } = props;
  const { state } = useContext(UserContext);

  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.container}>
      {state.userConfirmed ? (
        <>
          <ResultItem
            title="Your email address"
            description={`Your account is associated with ${state.email}`}
            variant="success"
            icon={<EmailOutlinedIcon style={{ fontSize: 40, color: '#fff' }} />}
          />
          <Grid item className={classes.buttonStyle1}>
            <CButton
              variant="contained"
              fullWidth
              loading={state.loading}
              onClick={() => updateStep(-5)}>
              Continue to Sign in
            </CButton>
          </Grid>
        </>
      ) : (
        <>
          <ResultItem
            title="Error"
            description={`Your phone number is not verified.`}
            variant="error"
            icon={<CloseIcon style={{ fontSize: 40, color: '#fff' }} />}
          />
          <Grid item className={classes.buttonStyle1}>
            <CButton
              variant="contained"
              fullWidth
              onClick={() => updateStep(-4)}>
              Retry verification
            </CButton>
          </Grid>
        </>
      )}
    </Container>
  );
}

export default VerifyResult;
