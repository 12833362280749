import React, { useContext, useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Context as PaymentContext } from '../../context/PaymentContext';
import { Context as UserContext } from '../../context/UserContext';
import { SET_ROUND_UP } from '../../context/types';
import TitleText from '../../components/common/TitleText';
import CButton from '../../components/common/CButton';
import GlobalStyle from '../../styles/GlobalStyle';
import { convertDateLabel, getLastDayOfMonth } from 'capio-common/src/main';
import CLoading from '../../components/common/CLoading';
import { getAmplitudeEventName } from '../../utilities/getAmplitudeEventName';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  item: {
    height: 50,
    marginTop: 16,
    border: '1px solid #D7D8EB',
  },
  selectedItem: {
    border: '2px solid #5255A4',
  },
  datePicker: {
    marginTop: 16,
    marginBottom: 16,
  },
}));

const DAYS = ['1st', '15th', 'End of the Month', 'Other'];

function RoundMonthly(props) {
  const { updateStep } = props;
  const { state, dispatch, updateRoundUp, setUpRoundUp } =
    useContext(PaymentContext);
  const {
    state: userState,
    checkAuthentication,
    updateUserInfo,
  } = useContext(UserContext);
  const [day, setDay] = useState('');
  const classes = useStyles();

  useEffect(() => {
    const { depositDate } = state.roundUp;
    if (depositDate) {
      setDay(convertDateLabel(depositDate));
    }
  }, []);

  const onClickContinue = async () => {
    const propertyName = getAmplitudeEventName(day);

    sendAmplitudeData('Roundup Monthly', {
      'Day of month': `${propertyName}`,
    });

    let depositDate;
    if (day === 'End of the Month') {
      depositDate = getLastDayOfMonth();
    } else {
      depositDate = day.replace(/\D/g, '');
    }

    dispatch({
      type: SET_ROUND_UP,
      payload: {
        autoDeposit: true,
        depositDate,
        depositDateLabel: convertDateLabel(depositDate),
      },
    });

    if (state.roundUp.roundUpEditing) {
      if (state.roundUp.id && state.roundUp.id !== 'null') {
        const { status, message } = await updateRoundUp(
          userState.jwt,
          { ...state.roundUp, depositDate },
          checkAuthentication,
        );

        if (status) {
          updateStep(-3);
        }
      } else {
        const { status, message } = await setUpRoundUp(
          userState.jwt,
          { ...state.roundUp, depositDate },
          checkAuthentication,
        );
        if (status) {
          updateStep(-3);
        }
      }
    } else {
      updateStep(3);
      updateUserInfo({
        id: userState['userSub'],
        appStatus: {
          parent: 'Home',
          sub: 'Monthly',
        },
      });
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      {state.loading && <CLoading />}
      <TitleText
        title="On which day would your like to add funds?"
        description=""
      />
      <Grid item>
        {DAYS.map((item, index) => (
          <CButton
            key={index}
            variant="outlined"
            fullWidth
            className={clsx(classes.item, {
              [classes.selectedItem]: day === item,
            })}
            onClick={() => {
              if (item === 'Other') {
                if (state.roundUp.roundUpEditing) {
                  updateStep(1);
                } else {
                  updateStep(2);
                }
              } else {
                setDay(item);
              }
            }}>
            {item}
          </CButton>
        ))}
      </Grid>
      <Grid item mt={7}>
        <CButton
          variant="contained"
          disabled={!day}
          fullWidth
          loading={state.loading}
          onClick={onClickContinue}>
          {`Set Every ${day}`}
        </CButton>
      </Grid>
    </Container>
  );
}

export default RoundMonthly;
