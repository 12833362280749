import { useContext, useEffect, useState } from 'react';
import { Context as UserContext } from './../context/UserContext';
import { useIdleTimer } from 'react-idle-timer';
import ConfirmModal from './common/ConfirmModal';
import { Typography } from '@mui/material';

function InActivityModal() {
  const { signOut } = useContext(UserContext);
  const timeout = 1000 * 60 * 15;
  const promptTimeout = 1000 * 30;

  const [open, setOpen] = useState(false);

  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    setOpen(true);
    setRemaining(promptTimeout);
  };

  const onIdle = () => {
    sessionStorage.removeItem('buoyfiToken');
    sessionStorage.removeItem('buoyfiUserId');
    signOut();
    setOpen(false);
    setRemaining(0);
  };

  const onActive = () => {
    setOpen(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive,
  });

  const handleStillHere = () => {
    setOpen(false);
    reset();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  const renderInActivityModal = () => {
    return (
      <ConfirmModal
        cancelText="Sign Me Out"
        okText="I'm Here"
        open={open}
        title="Still there?"
        content={
          <Typography align="left">
            You have not been active for a bit. For your security, we are going
            to sign you out momentarily.
          </Typography>
        }
        onClose={() => {
          sessionStorage.removeItem('buoyfiToken');
          sessionStorage.removeItem('buoyfiUserId');
          setOpen(false);
          signOut();
        }}
        onConfirm={handleStillHere}
      />
    );
  };

  return <>{renderInActivityModal()}</>;
}

export default InActivityModal;
