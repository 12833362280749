import React, { useMemo } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { format, parseISO } from 'date-fns';

import { ReactComponent as TipsSvg } from '../../assets/images/notifications/notification-tips.svg';
import { ReactComponent as SuccessSvg } from '../../assets/images/notifications/notification-success.svg';
import { ReactComponent as ScheduledSvg } from '../../assets/images/notifications/notification-scheduled.svg';
import { ReactComponent as AttentionSvg } from '../../assets/images/notifications/notification-attention.svg';

import {
  NOTIFICATION_TYPES,
  NOTIFICATION_STATUS,
} from 'capio-common/src/constants';

const secondary = {
  color: '#998BA5',
  fontWeight: 400,
};

const Notification = ({ item, onClickItem }) => {
  const icon = useMemo(() => {
    let svg;
    switch (item.notificationType) {
      case NOTIFICATION_TYPES.SCHEDULE:
        svg = <ScheduledSvg />;
        break;
      case NOTIFICATION_TYPES.TIPS:
        svg = <TipsSvg />;
        break;
      case NOTIFICATION_TYPES.SUCCESS:
        svg = <SuccessSvg />;
        break;
      case NOTIFICATION_TYPES.ATTENTION:
        svg = <AttentionSvg />;
        break;
      case NOTIFICATION_TYPES.PLAID:
        svg =
          item.status === NOTIFICATION_STATUS.PROCESSED ? (
            <SuccessSvg />
          ) : (
            <AttentionSvg />
          );
        break;
      default:
        svg = <AttentionSvg />;
    }
    return svg;
  }, [item]);

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => onClickItem(item)}
          sx={{
            pointerEvents:
              item.notificationType === NOTIFICATION_TYPES.PLAID &&
              item.status === NOTIFICATION_STATUS.UNPROCESSED
                ? 'auto'
                : 'none',
          }}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            primary={item.appMessage}
            secondary={format(
              parseISO(item.createdAt),
              "MMM d, yyyy 'at' h:mm aaaa",
            )}
            secondaryTypographyProps={{ style: secondary }}
          />
        </ListItemButton>
      </ListItem>
      <Divider />
    </>
  );
};

export default Notification;
