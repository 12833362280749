import React from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';

import GlobalStyle from '../../../../styles/GlobalStyle';
import CButton from '../../../../components/common/CButton';
import ResultItem from '../../../../components/ResultItem';
import { Context as PaymentContext } from '../../../../context/PaymentContext';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  failedContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 50,
    marginBottom: 24,
    marginLeft: 20,
    marginRight: 20,
  },
}));

export default function PayrollDocSuccess({ setStep }) {
  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Grid className={classes.failedContainer}>
        <ResultItem
          title="Verification complete"
          description="Your document was successfully verified"
          variant="success"
          icon={<CheckIcon style={{ fontSize: 25, color: '#fff' }} />}
        />
      </Grid>
      <CButton
        sx={{ marginTop: 15 }}
        variant="contained"
        fullWidth
        onClick={() => {
          setStep('CompassSettingInfo');
        }}>
        Done
      </CButton>
    </Container>
  );
}
