import React, { useContext, useEffect, useState, useRef } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { onUpdateSynapseUserById } from 'capio-common/src/graphql/subscriptions';

import { Context as UserContext } from '../../context/UserContext';
import CButton from '../../components/common/CButton';
import GlobalStyle from '../../styles/GlobalStyle';
import ResultItem from '../../components/ResultItem';
import ConfirmModal from '../../components/common/ConfirmModal';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function DocSuccess(props) {
  const { updateStep, setStep } = props;
  const { state: userState, updateUserInfo } = useContext(UserContext);
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const subscription = useRef();

  useEffect(() => {
    if (!userState.userSub) return;
    console.log('subscription start', userState.userSub);
    subscription.current = API.graphql({
      query: onUpdateSynapseUserById,
      variables: {
        id: userState.userSub,
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    }).subscribe({
      next: async ({ provider, value }) => {
        console.log('subscription===>', value);
        // we do not have Real Time ID checking currently, verified will never be true
        // workaround is to check that they have sent their docIds for now
        if (
          value?.data?.onUpdateSynapseUserById?.synapseUser?.verified ||
          (value?.data?.onUpdateSynapseUserById?.synapseUser?.docIds
            ?.physicalFrontDocId &&
            value?.data?.onUpdateSynapseUserById?.synapseUser?.docIds
              ?.physicalBackDocId)
        ) {
          setStep('DocVerificationSuccess');
          updateUserInfo({
            id: userState['userSub'],
            appStatus: {
              parent: 'Home',
              sub: 'DocUploadSuccess',
            },
          });
          updateStep(1);
        } else {
          console.log('=========false');
          setIsVisible(true);
        }
      },
      error: (error) => {
        console.error('subscription error:', error);
      },
      complete: () => console.log('Done'),
    });
    return () => {
      console.log('unsubscription');
      subscription.current && subscription.current.unsubscribe();
    };
  }, [userState.userSub]);

  useEffect(() => {
    if (userState.synapseUser?.verified) {
      setDisabled(false);
    }
  }, [userState.synapseUser]);

  const handleNotify = () => {
    updateStep(1);
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Grid
        item
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        flex={1}>
        <ResultItem
          title="Waiting for image verification response"
          description="Most documents will be verified in less than sixty seconds, but in some situations, this verification may take up to 1 business day.  You can close this window and we will send you an email notification when the verification is completed."
          variant="success"
          icon={<CheckIcon style={{ fontSize: 25, color: '#fff' }} />}
        />
      </Grid>
      <Grid item className={classes.buttonStyle1}>
        {/* <CButton
          variant="contained"
          fullWidth
          loading={userState.loading}
          disabled={disabled}
          onClick={handleNotify}>
          Continue
        </CButton> */}
      </Grid>
      <ConfirmModal
        showCancel={false}
        open={isVisible}
        title="There is an issue with your account"
        content={
          <Typography align="left">
            Our banking partner is having trouble confirming your information.
            We are unable to continue with your account creation at this time.
            Contact us at customerservice@buoyfi.com or at 1(888) 308-6004. Our support
            hours are Monday - Friday, 8am - 4pm CST to learn more.
          </Typography>
        }
        okText="Continue"
        onConfirm={() => {
          setStep('DocChecklist');
          updateUserInfo({
            id: userState['userSub'],
            appStatus: {
              parent: 'Home',
              sub: 'DocChecklist',
            },
          });
          updateStep(-2);
          setIsVisible(false);
        }}
      />
    </Container>
  );
}

export default DocSuccess;
