import React from 'react';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import TitleText from './common/TitleText';

function ResultItem(props) {
  const { title, description, icon, variant } = props;

  return (
    <Grid
      item
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      flex={1}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor:
            variant === 'success'
              ? 'success.main'
              : variant === 'warning'
              ? 'coreYellow50.main'
              : 'error.main',
          borderRadius: 10,
          padding: 1.5,
          marginTop: 4,
        }}>
        {icon}
      </Box>
      <TitleText title={title} description={description} textAlign="center" />
    </Grid>
  );
}

ResultItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.node,
};

export default ResultItem;
