import * as React from 'react';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { AlertTitle } from '@mui/material';

export default function CAlert(props) {
  const {
    severity,
    title,
    message,
    variant = 'standard',
    icon = false,
  } = props;
  if (message) {
    return (
      <Alert
        icon={icon}
        severity={severity}
        variant={variant}
        sx={{ marginTop: 1, marginBottom: 2 }}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    );
  }
  return null;
}

CAlert.propTypes = {
  severity: PropTypes.string.isRequired,
  message: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.node,
};
