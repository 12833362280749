import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 552,
  height: 451,
  bgcolor: '#fff',
  borderRadius: 2,
  boxShadow: 2,
  p: 3,
};

export default function PopupModal({ open, close, children, styles }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={[style, styles]}>{children}</Box>
      </Modal>
    </div>
  );
}
