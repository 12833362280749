import * as React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
    marginBottom: 24,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    marginBottom: 24,
    fontFamily: 'OpenSans',
  },
  description: {
    fontFamily: 'OpenSans',
  },
}));

export default function TitleText(props) {
  const classes = useStyles();
  const { title, description, caption = null, icon = null } = props;

  const centerTitle =
    title === 'Verification unsuccessfull' || title === 'Verification complete';

  const newProps = { ...props };
  delete newProps.title;
  delete newProps.description;
  delete newProps?.caption;
  delete newProps?.icon;

  return (
    <Grid item className={classes.container} {...newProps}>
      <Box
        className={classes.titleContainer}
        style={{ justifyContent: centerTitle ? 'center' : null }}>
        {icon && icon}
        <Typography
          variant="h5"
          className={classes.title}
          style={{ marginLeft: icon ? '15px' : 0 }}>
          {title}
        </Typography>
      </Box>
      <Typography className={classes.description}>{description}</Typography>
      {caption && <br />}
      <Typography className={classes.description}>{caption}</Typography>
    </Grid>
  );
}
