import React, { useContext, useState } from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

import GlobalStyle from '../../../styles/GlobalStyle';
import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import {
  CONNECT_PAYROLL_FAILED_MAIN_DESCRIPTION,
  CONNECT_PAYROLL_FAILED_TITLE,
  CONNECT_PAYROLL_MAIN_DESCRIPTION,
  CONNECT_PAYROLL_SUCCESS,
  CONNECT_PAYROLL_TITLE,
} from 'capio-common/src/constants';
import accountAvtar from '../../../assets/images/accountAvtar.svg';
import { ReactComponent as Check } from '../../../assets/images/check-circle.svg';
import { ReactComponent as CheckGreen } from '../../../assets/images/check-circle-green.svg';
import { ReactComponent as WarningIcon } from '../../../assets/images/warning.svg';
import ResultItem from '../../../components/ResultItem';
import { Context as PaymentContext } from '../../../context/PaymentContext';
import { sendAmplitudeData } from '../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  failedContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 50,
    marginBottom: 24,
    marginLeft: 20,
    marginRight: 20,
  },
  successMessage: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#DCF1DA',
    padding: '8px 8px',
    borderRadius: '4px',
  },
  avtarItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '20px',
  },
  avtarImage: {
    marginRight: '5px',
  },
  subItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  enterManuallyLink: {
    marginTop: '10px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  closeIcon: {
    backgroundColor: '#EE5071',
    color: 'white',
    marginTop: '50px',
  },
  buttonStyle1: {
    marginTop: 24,
    textAlign: 'center',
  },
  retryButton: {
    height: 50,
    marginBottom: 10,
    marginTop: 40,
  },
  addLink: {
    marginTop: 10,
    marginBottom: 60,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
  },
  captionIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '8px',
    height: '20px',
    width: '20px',
  },
}));

export default function ConnectPayroll({
  income,
  updateStep,
  setManualIncomeParentName,
  setIncome,
}) {
  const { state: paymentState } = useContext(PaymentContext);
  const classes = useStyles();
  const [account, setAccount] = useState({});

  const RenderIcon = () => {
    return (
      <Box className={classes.iconContainer}>
        <Check />
      </Box>
    );
  };

  const RenderCaptionIcon = () => {
    return (
      <Box className={classes.captionIconContainer}>
        <CheckGreen />
      </Box>
    );
  };

  const renderAccountView = () => {
    return (
      <Container maxWidth="sm" className={classes.container}>
        <TitleText
          title={CONNECT_PAYROLL_TITLE}
          icon={<RenderIcon />}
          description={CONNECT_PAYROLL_MAIN_DESCRIPTION}
        />
        <Grid item className={classes.avtarItem}>
          {/* <img
            className={classes.avtarImage}
            width={60}
            height={60}
            src={accountAvtar}
            alt="account"
          /> */}
          <Grid item className={classes.subItem}>
            <Typography variant="body1" color="corePurple50.main">
              {paymentState &&
              paymentState['plaid'] &&
              paymentState['plaid']['institution']
                ? paymentState['plaid']['institution']['name']
                : ''}
            </Typography>
            <Typography
              variant="span"
              color="secondary"
              sx={{ marginTop: 0.5 }}>
              {'Verified'}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.successMessage}>
          <RenderCaptionIcon />
          <Typography variant="body2" color="corePurple60.main">
            {CONNECT_PAYROLL_SUCCESS}
          </Typography>
        </div>
        <CButton
          sx={{ marginTop: 15 }}
          variant="contained"
          fullWidth
          onClick={() => {
            sendAmplitudeData('CalcSetup_6_PlaidComplete');
            if (income['monthlyIncome']['edit']) {
              setIncome({
                ...income,
                monthlyIncome: { ...income.monthlyIncome, edit: false },
              });
              updateStep(3);
              return;
            }
            if (income['monthlyIncome']['isMarried'] !== false) {
              updateStep(1);
            } else {
              updateStep(2);
            }
          }}>
          Continue
        </CButton>
      </Container>
    );
  };

  return (
    <>
      {account ? (
        renderAccountView()
      ) : (
        <Grid className={classes.failedContainer}>
          <ResultItem
            title={CONNECT_PAYROLL_FAILED_TITLE}
            description={CONNECT_PAYROLL_FAILED_MAIN_DESCRIPTION}
            variant="warning"
            icon={<WarningIcon />}
          />
          <Grid item className={classes.buttonStyle1}>
            <Typography
              className={classes.addLink}
              onClick={() => updateStep(-1)}
              variant="body3"
              color="purple70.main">
              What if you can't verify your income through Plaid?
            </Typography>
            <CButton
              className={classes.retryButton}
              variant="contained"
              fullWidth
              onClick={() => updateStep(-2)}>
              Try Again
            </CButton>
          </Grid>
        </Grid>
      )}
    </>
  );
}
