import React, { useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import CloseButton from '../../../components/CloseButton';
import CButton from '../../../components/common/CButton';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 375,
    height: '100%',
    padding: 25,
  },
  filterBtn: {
    marginTop: 50,
    height: 50,
  },
  closeBtn: {
    position: 'absolute',
    right: 28,
    top: 28,
  },
}));

function FilterMenu(props) {
  const { toggleDrawer, filterGroup } = props;
  const classes = useStyles();

  return (
    <Box
      className={classes.wrapper}
      role="presentation"
      onKeyDown={toggleDrawer(false)}>
      <CloseButton className={classes.closeBtn} onClick={toggleDrawer(false)} />
      <FormControl>
        <Typography variant="h5" mb={5}>
          Filters
        </Typography>
        {filterGroup}
      </FormControl>
      <FormControl>
        <Typography mt={2} mb={1}>
          Filter by date
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <RadioGroup defaultValue="allTime" name="dateGroup">
          <FormControlLabel
            value="allTime"
            control={<Radio />}
            label="All time"
          />
          <FormControlLabel
            value="last3Months"
            control={<Radio />}
            label="Past 90 days"
          />
          <FormControlLabel
            value="lastMonth"
            control={<Radio />}
            label="Last month"
          />
          <FormControlLabel
            value="lastYear"
            control={<Radio />}
            label="Last year"
          />
        </RadioGroup>
      </FormControl>
      <CButton
        variant="contained"
        className={classes.filterBtn}
        onClick={toggleDrawer(false)}>
        Apply Filters
      </CButton>
    </Box>
  );
}

FilterMenu.propTypes = {
  toggleDrawer: PropTypes.func,
  filterGroup: PropTypes.element,
};

export default FilterMenu;
