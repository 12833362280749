import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  item: {
    justifyContent: 'space-between',
    padding: '20px 40px 20px 20px',
  },
  subItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

function RowItem(props) {
  const classes = useStyles();
  const { title, subTitle, icon, onClick } = props;

  return (
    <Button className={classes.item} onClick={onClick}>
      <Grid item className={classes.subItem}>
        <Typography>{title}</Typography>
        {subTitle && (
          <Typography variant="body2" sx={{ marginTop: 1 }}>
            {subTitle}
          </Typography>
        )}
      </Grid>
      {icon}
    </Button>
  );
}

export default RowItem;
