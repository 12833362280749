const ModalStyle = () => ({
  modalRoot: {
    overflow: 'auto',
    display: 'block',
  },
  modal: {
    borderRadius: '6px',
    overflow: 'auto',
    maxHeight: '80vh !important',
    width: '100%',
  },
  modalCloseButton: {
    '&, &:hover': {
      color: 'primary',
    },
    '&:hover': {
      opacity: '1',
    },
    cursor: 'pointer',
    padding: '1rem',
    margin: '-1rem -1rem -1rem auto',
    backgroundColor: 'transparent',
    border: '0',
    WebkitAppearance: 'none',
    float: 'right',
    fontSize: '1.5rem',
    fontWeight: '500',
    lineHeight: '1',
    opacity: '.5',
  },
  confirmButton: {
    width: '100%',
    padding: '12px 0px',
  },
  cancelButton: {
    width: '100%',
    border: '1px solid #B5B6DA',
    padding: '12px 0px',
  },
  exitButton: {
    padding: '12px 0px',
    width: '100%',
  },
});

export default ModalStyle;
