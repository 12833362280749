import React, { useEffect, useContext } from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';

import GlobalStyle from '../../../styles/GlobalStyle';
import ResultItem from '../../../components/ResultItem';
import { Context as PaymentContext } from '../../../context/PaymentContext';
import { Context as UserContext } from '../../../context/UserContext';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  failedContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 50,
    marginBottom: 24,
    marginLeft: 20,
    marginRight: 20,
  },
}));

export default function PayrollDocVerify({ income, setIncome, setStep }) {
  const classes = useStyles();
  const { exchangePlaidPayrollPublicToken } = useContext(PaymentContext);
  const { state: userState, updateUserInfo } = useContext(UserContext);

  useEffect(() => {
    updateUserInfo({
      id: userState['userSub'],
      appStatus: {
        parent: 'Home',
        sub: 'PayrollDocVerify',
      },
    });
    const onSuccess = async () => {
      const success = JSON.parse(sessionStorage.getItem('payrollSuccess'));
      const payload = {
        token: userState.jwt,
        success,
        type: 'upload_doc',
      };
      const { status, data } = await exchangePlaidPayrollPublicToken(payload);
      if (status) {
        setIncome({
          ...income,
          incomeType: 'plaid',
          monthlyIncome: {
            ...income['monthlyIncome'],
            amount: data['userIncome'],
          },
        });

        setStep('PayrollDocSuccess');
      }
    };
    onSuccess();
  }, [sessionStorage, userState.appStatus]);

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Grid className={classes.failedContainer}>
        <ResultItem
          title="We received your document"
          description="We are reviewing your income documents, which may take approximately 10 minutes.  We appreciate your patience."
          variant="success"
          icon={<CheckIcon style={{ fontSize: 25, color: '#fff' }} />}
        />
      </Grid>
    </Container>
  );
}
