import React from 'react';

export default function StepIcon({ fill, className }) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0ZM7.16667 11.528L4.16667 8.61867L5.40467 7.38L7.16667 9.05067L10.9287 5.19467L12.1667 6.43267L7.16667 11.528Z"
        fill={fill ? fill : '#C0B9C7'}
      />
    </svg>
  );
}
