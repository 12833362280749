import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as Arrow } from '../assets/images/arrow-right-alt.svg';
import { ReactComponent as Dollar } from '../assets/images/dollar-pay.svg';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  accountCard: {
    padding: '16px',
    margin: '20px 0px',
    border: '1px solid #E9E9E9',
    borderRadius: '12px',
    boxShadow: '0px 1px 2px 0px rgba(31, 41, 55, 0.08)',
    transition: 'background-color 0.3s, color 0.3s',
    cursor: 'pointer',
  },
  accountCardSelected: {
    backgroundColor: '#4A26D6',
    color: '#FFFFFF',
  },
  accountCardEven: {
    backgroundColor: '#E6E8FA',
    color: '#000',
  },
  accountCardOdd: {
    backgroundColor: '#FFFFFF',
    color: '#000',
  },

  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '600',
  },
  balanceAmount: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '12px',
    color: '#FFFFFF',
  },
  potentialSavings: {
    alignItems: 'center',
    display: 'flex',
    gap: '6px',
    fontSize: '14px',
    fontWeight: '600',
    color: '#4A26D6',
  },
  infoIcon: {
    width: '24px',
    height: '24px',
    color: '#fff',
  },
});

const AccountCard = ({
  account,
  accountName,
  potentialSavings,
  balance,
  index,
  isSelected,
  onSelect,
  setCapioAccountName,
}) => {
  const classes = useStyles();
  const titleColor = isSelected ? '#CFCFCF' : '#656565';
  const textColor = isSelected ? '#FFF' : '#000';

  const cardClassName = `${classes.accountCard} ${
    isSelected
      ? classes.accountCardSelected
      : index % 2 === 0
      ? classes.accountCardEven
      : classes.accountCardOdd
  }`;

  return (
    <Box
      className={cardClassName}
      onClick={() => {
        onSelect(index);
        if (account.capioAccountID) {
          setCapioAccountName(account.debt?.debtName);
        } else {
          setCapioAccountName('');
        }
      }}
      boxShadow={1}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        style={{ height: '100%' }}>
        <Typography
          className={classes.cardHeader}
          style={{ color: titleColor }}>
          {accountName}
          {isSelected && <Arrow className={classes.infoIcon} />}
        </Typography>

        <Typography
          className={classes.balanceAmount}
          style={{ color: textColor }}>
          {balance}
        </Typography>

        <Typography
          className={classes.potentialSavings}
          style={{ color: textColor }}>
          {<Dollar />} Potential savings:{' '}
          <Typography
            className={classes.potentialSavings}
            style={{ color: isSelected && textColor }}>
            {potentialSavings}
          </Typography>
        </Typography>
      </Grid>
    </Box>
  );
};

export default AccountCard;
