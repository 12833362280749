import React from 'react';
import { Typography, Box, Button } from '@mui/material';

import CButton from '../../../../components/common/CButton';

function AccountItem(props) {
  const { item, onEdit } = props;

  return (
    <Box display={'flex'} justifyContent="space-between" py={1}>
      <Box>
        <Typography variant="body1" color="corePurple50.main">
          {item['account']['name']} *{item['account']['mask']}
        </Typography>
        <Typography variant="span" color="secondary" sx={{ marginTop: 0.5 }}>
          Verified
        </Typography>
      </Box>
      <Button
        variant="text"
        sx={{
          backgroundColor: '#F3F1F5',
          height: 32,
        }}
        onClick={() => onEdit()}>
        Edit
      </Button>
    </Box>
  );
}

export default AccountItem;
