import React, { useContext } from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

import CButton from '../../../../components/common/CButton';
import ResultItem from '../../../../components/ResultItem';
import GlobalStyle from '../../../../styles/GlobalStyle';
import { currencyFormatter } from 'capio-common/src/patterns';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function WithdrawResult({ data, withdrawSuccess, updateStep }) {
  const navigate = useNavigate();

  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.container}>
      {withdrawSuccess ? (
        <>
          <ResultItem
            title="Success!"
            description={`${currencyFormatter(
              data.amount,
            )} will be transfer to your bank account on ${moment().format(
              'MMMM DD',
            )}`}
            variant="success"
            icon={<CheckIcon style={{ fontSize: 40, color: '#fff' }} />}
          />
          <Grid item className={classes.buttonStyle1}>
            <CButton variant="contained" fullWidth onClick={() => navigate(-1)}>
              Done
            </CButton>
          </Grid>
        </>
      ) : (
        <>
          <ResultItem
            title="Error"
            description="Your funds cannot be withdrawn"
            variant="error"
            icon={<CloseIcon style={{ fontSize: 40, color: '#fff' }} />}
          />
          <Grid item className={classes.buttonStyle1}>
            <CButton
              variant="contained"
              fullWidth
              onClick={() => updateStep(-2)}>
              Retry
            </CButton>
          </Grid>
        </>
      )}
    </Container>
  );
}

export default WithdrawResult;
