import React, { useEffect, useState, useContext } from 'react';
import {
  Container,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import GlobalStyle from '../../../styles/GlobalStyle';
import {
  INVALID_FORMAT,
  REQUIRED_SSN,
  SAVE_SAMPLE_DESCRIPTION,
  SAVE_SAMPLE_TITLE,
  SETUP_BILLPAY_FOOTER_TEXT,
} from 'capio-common/src/constants';
import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import CTextField from '../../../components/common/CTextField';
import CLoading from '../../../components/common/CLoading';
import { SSN_PATTERN } from 'capio-common/src/patterns';
import { polishValue } from 'capio-common/src/main';
import { sendAmplitudeData } from '../../../utilities/amplitude';
import { Context as UserContext } from '../../../context/UserContext';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  description: {
    fontFamily: 'OpenSans',
    marginTop: 10,
    marginBottom: 20,
  },
}));

export default function SaveSimple({ updateStep }) {
  const classes = useStyles();
  const [formValid, setFormValid] = useState(false);
  const {
    state: userState,
    updateUserInfo,
    updateUserTags,
  } = useContext(UserContext);

  const {
    control,
    watch,
    trigger,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      ssn: '',
    },
  });

  const watchSSN = watch('ssn');

  useEffect(() => {
    const checkSSN = async () => {
      setFormValid(false);
      if (polishValue(watchSSN)?.length > 8) {
        await trigger();
        if (_.isEmpty(errors)) {
          setFormValid(true);
        }
      }
    };
    checkSSN();
  }, [watchSSN]);

  const onSubmit = async (data) => {
    const { ssn } = data;

    await updateUserInfo({
      id: userState.userSub,
      personalInfo: {
        ...userState.personalInfo,
        lastFourSSN: ssn.replace(/-/g, ''),
      },
      appStatus: {
        parent: 'Home',
        sub: 'ConnectBank',
      },
    });

    await updateUserTags(userState.jwt, {
      tags: {
        Payment_Setup_Complete: false,
      },
      userId: userState.userSub,
    });

    updateStep(1);
    sendAmplitudeData('SavingsSetup_2_SSNEntry');
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      {userState.loading && <CLoading />}
      <TitleText
        title={SAVE_SAMPLE_TITLE}
        description={SAVE_SAMPLE_DESCRIPTION}
      />
      <Typography className={classes.description}>
        (BuoyFi Savings is a secure bank account, so we do need your social to
        get started.)
      </Typography>
      <CTextField
        name="ssn"
        control={control}
        rules={{
          required: { value: true, message: REQUIRED_SSN },
          pattern: { value: SSN_PATTERN, message: INVALID_FORMAT },
        }}
        mask="999-99-9999"
        fullWidth
        label="Social Security Number (xxx-xx-xxxx)"
        error={!!errors.ssn}
        helperText={errors.ssn && errors.ssn.message}
      />
      <Grid item className={classes.buttonStyle1}>
        <CButton
          variant="contained"
          disabled={!(formValid && isValid)}
          fullWidth
          onClick={handleSubmit(onSubmit)}>
          Continue
        </CButton>
      </Grid>
    </Container>
  );
}
