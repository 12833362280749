import React, { useContext, useState } from 'react';
import {
  Checkbox,
  Container,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../../../styles/GlobalStyle';
import CButton from '../../../../components/common/CButton';
import TitleText from '../../../../components/common/TitleText';
import {
  CONFIRM_INCOME_INFO_DESCRIPTIONP1,
  CONFIRM_INCOME_INFO_DESCRIPTIONP2,
  CONFIRM_INCOME_INFO_MAIN_DESCRIPTION,
} from 'capio-common/src/constants';
import IncomeInfo from './IncomeInfo';
import { Context as UserContext } from '../../../../context/UserContext';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  description: {
    fontFamily: 'OpenSans',
    marginBottom: '10px',
    marginTop: '10px',
  },
  description2: {
    marginTop: '20px',
  },
}));

export default function EditIncometInfo({ userInfo, setUserInfo, setStep }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const {
    state: userState,
    updateCapioAccount,
    getUserInfo,
    dispatch,
  } = useContext(UserContext);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const getManualDebt = () => {
    const manualDebt = [];
    userInfo?.compassInfo?.debtInfo?.forEach((item) => {
      if (item.capioAccountID === null) {
        manualDebt.push({
          debtName: item.debt?.debtName,
          debtAmount: item.debt?.amount,
        });
      }
    });
    return manualDebt;
  };

  const onSave = async () => {
    setLoading(true);
    let data = {
      spousalIncome: userInfo['compassInfo']['spousalIncome']
        ? parseFloat(userInfo['compassInfo']['spousalIncome'])
        : 0,
      userIncome: userInfo['compassInfo']['userIncome']
        ? parseFloat(userInfo['compassInfo']['userIncome'])
        : 0,
      otherIncome: userInfo['compassInfo']['otherIncome']
        ? parseFloat(userInfo['compassInfo']['otherIncome'])
        : 0,
      unemployed: userInfo['compassInfo']['unemployed'],
      consenttoshare: userInfo['compassInfo']['consenttoshare'],
      incomeType: userInfo?.compassInfo?.incomeType ?? 'plaid',
      contact: userInfo['compassInfo']['contact'],
      married: userInfo['compassInfo']['married'],
      partnerDebt: userInfo['capioAccount'],
      manualDebt: getManualDebt(),
    };
    await updateCapioAccount(userState.jwt, data);
    await getUserInfo(userState.userSub);
    setLoading(false);
    setStep('CompassSettingInfo');
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title={'Edit your income info'}
        description={CONFIRM_INCOME_INFO_MAIN_DESCRIPTION}
      />
      <IncomeInfo
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        fromEdit={true}
        setStep={setStep}
      />
      <Typography className={classes.description}>
        {CONFIRM_INCOME_INFO_DESCRIPTIONP1}
      </Typography>
      <FormControlLabel
        sx={{ marginTop: 10 }}
        className={classes.checkbox}
        label={CONFIRM_INCOME_INFO_DESCRIPTIONP2}
        control={<Checkbox checked={checked} onChange={handleChange} />}
      />
      <CButton
        sx={{ marginTop: 2 }}
        disabled={loading || !checked}
        loading={loading}
        variant="contained"
        fullWidth
        onClick={() => onSave()}>
        Save
      </CButton>
    </Container>
  );
}
