/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserInformationEncrypt = /* GraphQL */ `
  query GetUserInformationEncrypt($id: ID!) {
    getUserInformationEncrypt(id: $id) {
      id
      userId
      email
      personalInfo {
        lastFourSSN
        legalFirstName
        legalLastName
        dateOfBirth
        mobileNumber
      }
      address {
        streetAddress
        city
        state
        postalCode
        country
      }
      appStatus {
        parent
        sub
      }
      failedAt
      failedCount
      policyStatus
      tosStatus
      capioToken
      capioAccounts {
        capioAccountID
        phoneNumber
        debtName
        amount
      }
      compassInfo {
        totalMonthlyIncome
        userIncome
        spousalIncome
        otherIncome
        totalDebt
        debtInfo {
          capioAccountID
          phoneNumber
          guidelines {
            payment {
              amount
              period
              forgiven
            }
            settlement {
              amount
              period
              forgiven
            }
          }
          debt {
            debtName
            debtKey
            amount
          }
        }
        unemployed
        consenttoshare
        guidelinePresented
        married
        contact
        incomeType
        roundedTotalPaymentPercent
        roundedTotalSettlementPercent
      }
      paymentSetupFlags {
        compass
        billpay
      }
      householdSize
      billGoUserId
      incomeInformation {
        payFrequency
        maritalStatus
        grossEarnings
        startDate
        endDate
        employerName
        userMonthlyIncome
        spouseIncome
        otherIncome
        incomeSource
        verified
      }
      monthlyIncome
      annualHouseholdIncome
      synapseUser {
        id
        achNodeId
        custodyNodeId
        docIds {
          docId
          physicalFrontDocId
          physicalBackDocId
          virtualDocId
          socialDocId
        }
        docType
        ip
        fp
        verified
        pass
        photoFront
        photoBack
        isActive
        isTrueVerified
      }
      isDisabled
      notification {
        pushToken
        deviceType
        deviceMeta
        subscriptionID
        browser
      }
      notifySetting {
        emailNotification
        pushNotification
      }
      securitySetting {
        faceID
      }
      emailSearch
      lastLogged
      searchContent {
        legalFirstName
        legalLastName
        mobileNumber
        email
        lastLogged
        stateName
      }
      changeLog {
        web
        mobile
      }
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserInformationsEncrypt = /* GraphQL */ `
  query ListUserInformationsEncrypt(
    $filter: ModelUserInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInformationsEncrypt(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        email
        personalInfo {
          lastFourSSN
          legalFirstName
          legalLastName
          dateOfBirth
          mobileNumber
        }
        address {
          streetAddress
          city
          state
          postalCode
          country
        }
        appStatus {
          parent
          sub
        }
        failedAt
        failedCount
        policyStatus
        tosStatus
        capioToken
        capioAccounts {
          capioAccountID
          phoneNumber
          debtName
          amount
        }
        compassInfo {
          totalMonthlyIncome
          userIncome
          spousalIncome
          otherIncome
          totalDebt
          debtInfo {
            capioAccountID
            phoneNumber
            guidelines {
              payment {
                amount
                period
                forgiven
              }
              settlement {
                amount
                period
                forgiven
              }
            }
            debt {
              debtName
              debtKey
              amount
            }
          }
          unemployed
          consenttoshare
          guidelinePresented
          married
          contact
          incomeType
          roundedTotalPaymentPercent
          roundedTotalSettlementPercent
        }
        paymentSetupFlags {
          compass
          billpay
        }
        householdSize
        billGoUserId
        incomeInformation {
          payFrequency
          maritalStatus
          grossEarnings
          startDate
          endDate
          employerName
          userMonthlyIncome
          spouseIncome
          otherIncome
          incomeSource
          verified
        }
        monthlyIncome
        annualHouseholdIncome
        synapseUser {
          id
          achNodeId
          custodyNodeId
          docIds {
            docId
            physicalFrontDocId
            physicalBackDocId
            virtualDocId
            socialDocId
          }
          docType
          ip
          fp
          verified
          pass
          photoFront
          photoBack
          isActive
          isTrueVerified
        }
        isDisabled
        notification {
          pushToken
          deviceType
          deviceMeta
          subscriptionID
          browser
        }
        notifySetting {
          emailNotification
          pushNotification
        }
        securitySetting {
          faceID
        }
        emailSearch
        lastLogged
        searchContent {
          legalFirstName
          legalLastName
          mobileNumber
          email
          lastLogged
          stateName
        }
        changeLog {
          web
          mobile
        }
        dataTags {
          key
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userInformationByUserIdEncrypt = /* GraphQL */ `
  query UserInformationByUserIdEncrypt(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInformationByUserIdEncrypt(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        email
        personalInfo {
          lastFourSSN
          legalFirstName
          legalLastName
          dateOfBirth
          mobileNumber
        }
        address {
          streetAddress
          city
          state
          postalCode
          country
        }
        appStatus {
          parent
          sub
        }
        failedAt
        failedCount
        policyStatus
        tosStatus
        capioToken
        capioAccounts {
          capioAccountID
          phoneNumber
          debtName
          amount
        }
        compassInfo {
          totalMonthlyIncome
          userIncome
          spousalIncome
          otherIncome
          totalDebt
          debtInfo {
            capioAccountID
            phoneNumber
            guidelines {
              payment {
                amount
                period
                forgiven
              }
              settlement {
                amount
                period
                forgiven
              }
            }
            debt {
              debtName
              debtKey
              amount
            }
          }
          unemployed
          consenttoshare
          guidelinePresented
          married
          contact
          incomeType
          roundedTotalPaymentPercent
          roundedTotalSettlementPercent
        }
        paymentSetupFlags {
          compass
          billpay
        }
        householdSize
        billGoUserId
        incomeInformation {
          payFrequency
          maritalStatus
          grossEarnings
          startDate
          endDate
          employerName
          userMonthlyIncome
          spouseIncome
          otherIncome
          incomeSource
          verified
        }
        monthlyIncome
        annualHouseholdIncome
        synapseUser {
          id
          achNodeId
          custodyNodeId
          docIds {
            docId
            physicalFrontDocId
            physicalBackDocId
            virtualDocId
            socialDocId
          }
          docType
          ip
          fp
          verified
          pass
          photoFront
          photoBack
          isActive
          isTrueVerified
        }
        isDisabled
        notification {
          pushToken
          deviceType
          deviceMeta
          subscriptionID
          browser
        }
        notifySetting {
          emailNotification
          pushNotification
        }
        securitySetting {
          faceID
        }
        emailSearch
        lastLogged
        searchContent {
          legalFirstName
          legalLastName
          mobileNumber
          email
          lastLogged
          stateName
        }
        changeLog {
          web
          mobile
        }
        dataTags {
          key
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userInformationByEmailEncrypt = /* GraphQL */ `
  query UserInformationByEmailEncrypt(
    $emailSearch: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInformationByEmailEncrypt(
      emailSearch: $emailSearch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        email
        personalInfo {
          lastFourSSN
          legalFirstName
          legalLastName
          dateOfBirth
          mobileNumber
        }
        address {
          streetAddress
          city
          state
          postalCode
          country
        }
        appStatus {
          parent
          sub
        }
        failedAt
        failedCount
        policyStatus
        tosStatus
        capioToken
        capioAccounts {
          capioAccountID
          phoneNumber
          debtName
          amount
        }
        compassInfo {
          totalMonthlyIncome
          userIncome
          spousalIncome
          otherIncome
          totalDebt
          debtInfo {
            capioAccountID
            phoneNumber
            guidelines {
              payment {
                amount
                period
                forgiven
              }
              settlement {
                amount
                period
                forgiven
              }
            }
            debt {
              debtName
              debtKey
              amount
            }
          }
          unemployed
          consenttoshare
          guidelinePresented
          married
          contact
          incomeType
          roundedTotalPaymentPercent
          roundedTotalSettlementPercent
        }
        paymentSetupFlags {
          compass
          billpay
        }
        householdSize
        billGoUserId
        incomeInformation {
          payFrequency
          maritalStatus
          grossEarnings
          startDate
          endDate
          employerName
          userMonthlyIncome
          spouseIncome
          otherIncome
          incomeSource
          verified
        }
        monthlyIncome
        annualHouseholdIncome
        synapseUser {
          id
          achNodeId
          custodyNodeId
          docIds {
            docId
            physicalFrontDocId
            physicalBackDocId
            virtualDocId
            socialDocId
          }
          docType
          ip
          fp
          verified
          pass
          photoFront
          photoBack
          isActive
          isTrueVerified
        }
        isDisabled
        notification {
          pushToken
          deviceType
          deviceMeta
          subscriptionID
          browser
        }
        notifySetting {
          emailNotification
          pushNotification
        }
        securitySetting {
          faceID
        }
        emailSearch
        lastLogged
        searchContent {
          legalFirstName
          legalLastName
          mobileNumber
          email
          lastLogged
          stateName
        }
        changeLog {
          web
          mobile
        }
        dataTags {
          key
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserInformation = /* GraphQL */ `
  query GetUserInformation($id: ID!) {
    getUserInformation(id: $id) {
      id
      userId
      email
      personalInfo {
        lastFourSSN
        legalFirstName
        legalLastName
        dateOfBirth
        mobileNumber
      }
      address {
        streetAddress
        city
        state
        postalCode
        country
      }
      appStatus {
        parent
        sub
      }
      failedAt
      failedCount
      policyStatus
      tosStatus
      capioToken
      capioAccounts {
        capioAccountID
        phoneNumber
        debtName
        amount
      }
      compassInfo {
        totalMonthlyIncome
        userIncome
        spousalIncome
        otherIncome
        totalDebt
        debtInfo {
          capioAccountID
          phoneNumber
          guidelines {
            payment {
              amount
              period
              forgiven
            }
            settlement {
              amount
              period
              forgiven
            }
          }
          debt {
            debtName
            debtKey
            amount
          }
        }
        unemployed
        consenttoshare
        guidelinePresented
        married
        contact
        incomeType
        roundedTotalPaymentPercent
        roundedTotalSettlementPercent
      }
      paymentSetupFlags {
        compass
        billpay
      }
      householdSize
      billGoUserId
      incomeInformation {
        payFrequency
        maritalStatus
        grossEarnings
        startDate
        endDate
        employerName
        userMonthlyIncome
        spouseIncome
        otherIncome
        incomeSource
        verified
      }
      monthlyIncome
      annualHouseholdIncome
      synapseUser {
        id
        achNodeId
        custodyNodeId
        docIds {
          docId
          physicalFrontDocId
          physicalBackDocId
          virtualDocId
          socialDocId
        }
        docType
        ip
        fp
        verified
        pass
        photoFront
        photoBack
        isActive
        isTrueVerified
      }
      isDisabled
      notification {
        pushToken
        deviceType
        deviceMeta
        subscriptionID
        browser
      }
      notifySetting {
        emailNotification
        pushNotification
      }
      securitySetting {
        faceID
      }
      emailSearch
      lastLogged
      searchContent {
        legalFirstName
        legalLastName
        mobileNumber
        email
        lastLogged
        stateName
      }
      changeLog {
        web
        mobile
      }
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserInformations = /* GraphQL */ `
  query ListUserInformations(
    $filter: ModelUserInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInformations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        email
        personalInfo {
          lastFourSSN
          legalFirstName
          legalLastName
          dateOfBirth
          mobileNumber
        }
        address {
          streetAddress
          city
          state
          postalCode
          country
        }
        appStatus {
          parent
          sub
        }
        failedAt
        failedCount
        policyStatus
        tosStatus
        capioToken
        capioAccounts {
          capioAccountID
          phoneNumber
          debtName
          amount
        }
        compassInfo {
          totalMonthlyIncome
          userIncome
          spousalIncome
          otherIncome
          totalDebt
          debtInfo {
            capioAccountID
            phoneNumber
            guidelines {
              payment {
                amount
                period
                forgiven
              }
              settlement {
                amount
                period
                forgiven
              }
            }
            debt {
              debtName
              debtKey
              amount
            }
          }
          unemployed
          consenttoshare
          guidelinePresented
          married
          contact
          incomeType
          roundedTotalPaymentPercent
          roundedTotalSettlementPercent
        }
        paymentSetupFlags {
          compass
          billpay
        }
        householdSize
        billGoUserId
        incomeInformation {
          payFrequency
          maritalStatus
          grossEarnings
          startDate
          endDate
          employerName
          userMonthlyIncome
          spouseIncome
          otherIncome
          incomeSource
          verified
        }
        monthlyIncome
        annualHouseholdIncome
        synapseUser {
          id
          achNodeId
          custodyNodeId
          docIds {
            docId
            physicalFrontDocId
            physicalBackDocId
            virtualDocId
            socialDocId
          }
          docType
          ip
          fp
          verified
          pass
          photoFront
          photoBack
          isActive
          isTrueVerified
        }
        isDisabled
        notification {
          pushToken
          deviceType
          deviceMeta
          subscriptionID
          browser
        }
        notifySetting {
          emailNotification
          pushNotification
        }
        securitySetting {
          faceID
        }
        emailSearch
        lastLogged
        searchContent {
          legalFirstName
          legalLastName
          mobileNumber
          email
          lastLogged
          stateName
        }
        changeLog {
          web
          mobile
        }
        dataTags {
          key
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotificationTemplates = /* GraphQL */ `
  query GetNotificationTemplates($id: ID!) {
    getNotificationTemplates(id: $id) {
      id
      templateId
      title
      subTitle
      appMessage
      pushMessage
      imageURL
      notificationType
      resourceType
      appUrl
      webUrl
      status
      requiredFields
      createdAt
      updatedAt
    }
  }
`;
export const listNotificationTemplates = /* GraphQL */ `
  query ListNotificationTemplates(
    $filter: ModelNotificationTemplatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        templateId
        title
        subTitle
        appMessage
        pushMessage
        imageURL
        notificationType
        resourceType
        appUrl
        webUrl
        status
        requiredFields
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotificationSegments = /* GraphQL */ `
  query GetNotificationSegments($id: ID!) {
    getNotificationSegments(id: $id) {
      id
      segmentId
      segmentName
      status
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNotificationSegments = /* GraphQL */ `
  query ListNotificationSegments(
    $filter: ModelNotificationSegmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationSegments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        segmentId
        segmentName
        status
        dataTags {
          key
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserNotifications = /* GraphQL */ `
  query GetUserNotifications($id: ID!) {
    getUserNotifications(id: $id) {
      id
      userId
      notificationId
      title
      appIconUrl
      appMessage
      pushMessage
      appUrl
      webUrl
      createdAt
      updatedAt
      showOnFrontend
      isDelayed
      isRead
      emailSubject
      emailBody
      status
      notificationType
      itemId
    }
  }
`;
export const listUserNotifications = /* GraphQL */ `
  query ListUserNotifications(
    $filter: ModelUserNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        notificationId
        title
        appIconUrl
        appMessage
        pushMessage
        appUrl
        webUrl
        createdAt
        updatedAt
        showOnFrontend
        isDelayed
        isRead
        emailSubject
        emailBody
        status
        notificationType
        itemId
      }
      nextToken
    }
  }
`;
export const userInformationByUserId = /* GraphQL */ `
  query UserInformationByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInformationByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        email
        personalInfo {
          lastFourSSN
          legalFirstName
          legalLastName
          dateOfBirth
          mobileNumber
        }
        address {
          streetAddress
          city
          state
          postalCode
          country
        }
        appStatus {
          parent
          sub
        }
        failedAt
        failedCount
        policyStatus
        tosStatus
        capioToken
        capioAccounts {
          capioAccountID
          phoneNumber
          debtName
          amount
        }
        compassInfo {
          totalMonthlyIncome
          userIncome
          spousalIncome
          otherIncome
          totalDebt
          debtInfo {
            capioAccountID
            phoneNumber
            guidelines {
              payment {
                amount
                period
                forgiven
              }
              settlement {
                amount
                period
                forgiven
              }
            }
            debt {
              debtName
              debtKey
              amount
            }
          }
          unemployed
          consenttoshare
          guidelinePresented
          married
          contact
          incomeType
          roundedTotalPaymentPercent
          roundedTotalSettlementPercent
        }
        paymentSetupFlags {
          compass
          billpay
        }
        householdSize
        billGoUserId
        incomeInformation {
          payFrequency
          maritalStatus
          grossEarnings
          startDate
          endDate
          employerName
          userMonthlyIncome
          spouseIncome
          otherIncome
          incomeSource
          verified
        }
        monthlyIncome
        annualHouseholdIncome
        synapseUser {
          id
          achNodeId
          custodyNodeId
          docIds {
            docId
            physicalFrontDocId
            physicalBackDocId
            virtualDocId
            socialDocId
          }
          docType
          ip
          fp
          verified
          pass
          photoFront
          photoBack
          isActive
          isTrueVerified
        }
        isDisabled
        notification {
          pushToken
          deviceType
          deviceMeta
          subscriptionID
          browser
        }
        notifySetting {
          emailNotification
          pushNotification
        }
        securitySetting {
          faceID
        }
        emailSearch
        lastLogged
        searchContent {
          legalFirstName
          legalLastName
          mobileNumber
          email
          lastLogged
          stateName
        }
        changeLog {
          web
          mobile
        }
        dataTags {
          key
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userInformationByEmail = /* GraphQL */ `
  query UserInformationByEmail(
    $emailSearch: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInformationByEmail(
      emailSearch: $emailSearch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        email
        personalInfo {
          lastFourSSN
          legalFirstName
          legalLastName
          dateOfBirth
          mobileNumber
        }
        address {
          streetAddress
          city
          state
          postalCode
          country
        }
        appStatus {
          parent
          sub
        }
        failedAt
        failedCount
        policyStatus
        tosStatus
        capioToken
        capioAccounts {
          capioAccountID
          phoneNumber
          debtName
          amount
        }
        compassInfo {
          totalMonthlyIncome
          userIncome
          spousalIncome
          otherIncome
          totalDebt
          debtInfo {
            capioAccountID
            phoneNumber
            guidelines {
              payment {
                amount
                period
                forgiven
              }
              settlement {
                amount
                period
                forgiven
              }
            }
            debt {
              debtName
              debtKey
              amount
            }
          }
          unemployed
          consenttoshare
          guidelinePresented
          married
          contact
          incomeType
          roundedTotalPaymentPercent
          roundedTotalSettlementPercent
        }
        paymentSetupFlags {
          compass
          billpay
        }
        householdSize
        billGoUserId
        incomeInformation {
          payFrequency
          maritalStatus
          grossEarnings
          startDate
          endDate
          employerName
          userMonthlyIncome
          spouseIncome
          otherIncome
          incomeSource
          verified
        }
        monthlyIncome
        annualHouseholdIncome
        synapseUser {
          id
          achNodeId
          custodyNodeId
          docIds {
            docId
            physicalFrontDocId
            physicalBackDocId
            virtualDocId
            socialDocId
          }
          docType
          ip
          fp
          verified
          pass
          photoFront
          photoBack
          isActive
          isTrueVerified
        }
        isDisabled
        notification {
          pushToken
          deviceType
          deviceMeta
          subscriptionID
          browser
        }
        notifySetting {
          emailNotification
          pushNotification
        }
        securitySetting {
          faceID
        }
        emailSearch
        lastLogged
        searchContent {
          legalFirstName
          legalLastName
          mobileNumber
          email
          lastLogged
          stateName
        }
        changeLog {
          web
          mobile
        }
        dataTags {
          key
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationTemplateId = /* GraphQL */ `
  query NotificationTemplateId(
    $templateId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationTemplatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationTemplateId(
      templateId: $templateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        templateId
        title
        subTitle
        appMessage
        pushMessage
        imageURL
        notificationType
        resourceType
        appUrl
        webUrl
        status
        requiredFields
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationSegmentId = /* GraphQL */ `
  query NotificationSegmentId(
    $segmentId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationSegmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationSegmentId(
      segmentId: $segmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        segmentId
        segmentName
        status
        dataTags {
          key
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userNotificationsByUserId = /* GraphQL */ `
  query UserNotificationsByUserId(
    $userId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNotificationsByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        notificationId
        title
        appIconUrl
        appMessage
        pushMessage
        appUrl
        webUrl
        createdAt
        updatedAt
        showOnFrontend
        isDelayed
        isRead
        emailSubject
        emailBody
        status
        notificationType
        itemId
      }
      nextToken
    }
  }
`;
export const userNotificationId = /* GraphQL */ `
  query UserNotificationId(
    $notificationId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNotificationId(
      notificationId: $notificationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        notificationId
        title
        appIconUrl
        appMessage
        pushMessage
        appUrl
        webUrl
        createdAt
        updatedAt
        showOnFrontend
        isDelayed
        isRead
        emailSubject
        emailBody
        status
        notificationType
        itemId
      }
      nextToken
    }
  }
`;
