/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-2',
  aws_cloud_logic_custom: [
    {
      name: 'adminManagement',
      endpoint: 'https://txku6tvj2a.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'billGoIntegration',
      endpoint: 'https://ud8f95x0ja.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'common',
      endpoint: 'https://hjp0ygrrqh.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'divinityIntegration',
      endpoint: 'https://089bnm4sg1.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'notesManagement',
      endpoint: 'https://fk75jn20g1.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'notificationInfo',
      endpoint: 'https://2hchfzyy7e.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'pdfExport',
      endpoint: 'https://e3nz8pex0b.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'plaidIntegration',
      endpoint: 'https://xyirp0ql02.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'roundUp',
      endpoint: 'https://hrytwyvvy5.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'scheduleBGProcess',
      endpoint: 'https://i7764a6kz0.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'synapseIntegration',
      endpoint: 'https://w04wugnrwk.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'transaction',
      endpoint: 'https://nuf7mninnb.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'userManagement',
      endpoint: 'https://s5h36clogi.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
  ],
  aws_appsync_graphqlEndpoint:
    'https://ajajfxdvafhufpyjkdwyg6f6vq.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-2',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-z7azarzhgnfatejr2syjl47yfu',
  aws_cognito_identity_pool_id:
    'us-east-2:b54ce5ec-8d5f-485c-a70a-e3c2cb0467ac',
  aws_cognito_region: 'us-east-2',
  aws_user_pools_id: 'us-east-2_GNxy7tEhR',
  aws_user_pools_web_client_id: '472ad5j0rkg8gosqrhk8on9c64',
  oauth: {
    domain: 'buoyapi-prod.auth.us-east-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'https://buoyapi.auth.us-east-2.amazoncognito.com/signin/',
    redirectSignOut:
      'https://buoyapi.auth.us-east-2.amazoncognito.com/signout/',
    responseType: 'token',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL', 'PHONE_NUMBER'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS', 'TOTP'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['PHONE_NUMBER'],
  aws_dynamodb_all_tables_region: 'us-east-2',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'AdminInformation-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'Contact-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'CustomerPlaidInfo-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'Notes-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'PaymentEngineLog-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'PaymentLog-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'PlaidItems-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'PlaidRequestLogs-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'RUSettings-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'RoundUps-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'ScheduledTransaction-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'TransactionLogs-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'UserTokens-prod',
      region: 'us-east-2',
    },
  ],
  aws_user_files_s3_bucket: 'buoyapibucket60925-prod',
  aws_user_files_s3_bucket_region: 'us-east-2',
};

export default awsmobile;
