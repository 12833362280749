import { createTheme } from '@mui/material/styles';
import OpenSans from '../fonts/OpenSans-Regular.ttf';
import RobotoSlab from '../fonts/RobotoSlab-Regular.ttf';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#494C93',
    },
    secondary: {
      main: '#51B748',
    },
    success: {
      main: '#51B748',
    },
    error: {
      main: '#EE5071',
    },
    error10: {
      main: '#FF0000',
    },
    black: {
      main: '#000000',
    },
    white: {
      main: '#ffffff',
    },
    grey10: {
      main: '#DEDEDE',
    },
    purple10: {
      main: '#D7D8EB',
      background: '#E6E8FA' // used in mobile background
    },
    purple20: {
      main: '#B5B6DA',
    },
    purple40: {
      main: '#7A638E',
    },
    purple50: {
      main: '#5255A4',
    },
    purple60: {
      main: '#494C93',
    },
    purple70: {
      main: '#414382',
    },
    purple90: {
      main: '#4A26D6',
    },
    purple100: {
      main: '#5A39DB',
    },
    purple110: {
      main: '#8138DD',
    },
    corePurple10: {
      main: '#F3F1F5',
    },
    corePurple20: {
      main: '#C0B9C7',
    },
    corePurple30: {
      main: '#998BA5',
    },
    corePurple50: {
      main: '#4E3167',
    },
    corePurple60: {
      main: '#3C1D57',
    },
    corePurple70: {
      main: '#250E39',
    },
    coreGreen10: {
      main: '#DCF1DA',
    },
    coreGreen60: {
      main: '#41923A',
    },
    coreYellow30: {
      main: '#FFE066',
    },
    coreYellow40: {
      main: '#FFD533',
    },
    coreYellow50: {
      main: '#FFCB00',
    },
    coreRed60: {
      main: '#C51338',
    },
    bw10: {
      main: '#F8F8F8',
    },
  },
  typography: {
    fontFamily: ['OpenSans', 'RobotoSlab'].join(','),
    h5: {
      color: '#250E39',
      fontWeight: 600,
    },
    body1: {
      color: '#250E39',
      fontWeight: 400,
    },
    body3: {
      color: '#250E39',
      fontWeight: 600,
    },
    body2: {
      color: '#250E39',
      fontWeight: 400,
    },
    body4: {
      color: '#250E39',
      fontWeight: 600,
      fontSize: '1.1rem',
    },
    span: {
      fontSize: '0.75rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'OpenSans';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('OpenSans'), url(${OpenSans}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'RobotoSlab';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('RobotoSlab'), url(${RobotoSlab}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-standardSuccess': {
            backgroundColor: '#DCF1DA',
            color: '#3C1D57',
          },
          '&.MuiAlert-standardError': {
            backgroundColor: '#FCDBE2',
            color: '#EA214B',
          },
        },
      },
    },
  },
});

export default theme;
