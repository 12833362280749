import React, { useState, useContext, useEffect } from 'react';
import {
  Grid,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import CButton from '../../components/common/CButton';
import TitleText from '../../components/common/TitleText';
import CAlert from '../../components/common/CAlert';
import { Context as UserContext } from '../../context/UserContext';
import { SET_VERIFICATION_TYPE } from '../../context/types';
import { getSecureEmail, getSecurePhone } from 'capio-common/src/main';
import GlobalStyle from '../../styles/GlobalStyle';
import { sendAmplitudeData } from '../../utilities/amplitude'

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  mfaGroup: {
    marginTop: 50,
  },
  mfaItemContainer: {
    marginLeft: 20,
  },
}));

function MFAItem(props) {
  const classes = useStyles();
  const { title, value } = props;

  return (
    <Grid item className={classes.mfaItemContainer}>
      <Typography>{title}</Typography>
      <Typography>{value}</Typography>
    </Grid>
  );
}

function MFA(props) {
  const { updateStep } = props;
  const { state, dispatch, signUp } = useContext(UserContext);
  const [securePhone, setSecurePhone] = useState('');
  const [secureEmail, setSecureEmail] = useState('');
  const [error, setError] = useState('');
  const classes = useStyles();
  const [fields, setFields] = useState({
    mfaGroup: 'phone',
  });

  const setVerificationType = (value) => {
    dispatch({
      type: SET_VERIFICATION_TYPE,
      payload: { verificationType: value },
    });
  };

  useEffect(() => {
    const { phone, email } = state;
    const securePhone = getSecurePhone(phone);
    const secureEmail = getSecureEmail(email);
    setSecurePhone(securePhone);
    setSecureEmail(secureEmail);
    setVerificationType('phone');
  }, []);

  const handleInputChange = ({ target }) => {
    setFields({ ...fields, [target.name]: target.value });
    setVerificationType(target.value);
  };

  const handleVerification = async () => {
    setError('');
    const { userSub, email, password, phone, verificationType } = state;
    const { status, message } = await signUp(
      userSub,
      email,
      password,
      phone,
      verificationType,
    );
    sendAmplitudeData('InitialKYC_4_Verify', {
      'click': verificationType == 'phone' ? `phone number` : 'email'
    });
    if (status) {
      updateStep(1);
    } else {
      setError(message);
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title="Let’s confirm it’s you"
        description="How would you like to do that?"
      />
      <CAlert severity="error" message={error} />
      <RadioGroup
        aria-label="mfa"
        name="mfaGroup"
        value={fields.mfaGroup}
        onChange={handleInputChange}
        className={classes.mfaGroup}>
        <FormControlLabel
          value="phone"
          sx={{ marginBottom: 4 }}
          control={<Radio />}
          label={<MFAItem title="Text Message" value={securePhone} />}
        />
        <FormControlLabel
          value="email"
          control={<Radio />}
          label={<MFAItem title="Email" value={secureEmail} />}
        />
      </RadioGroup>

      <Grid item className={classes.buttonStyle1}>
        <CButton
          variant="contained"
          fullWidth
          loading={state.loading}
          onClick={handleVerification}>
          Send verification code
        </CButton>
      </Grid>
    </Container>
  );
}

export default MFA;
