const routeStepEventMap = {
  VerifySteps1: {
    'Step 2: Link Bank Account': 'SavingsSetup_2A_LinkBankAccount',
  },
  VerifySteps2: {
    'Step 3: Verify Document': 'SavingsSetup_4A_VerifyDocument',
  },
  VerifySteps3: {
    'Step 4: Set Up BuoyFi Savings': 'SavingsSetup_7A_SetupSavings',
  },
  VerifySteps4: {
    'Step 5: Link Bill Pay Accounts': 'PaymentSetup_1_Start',
  },
  ConnectBank: {
    default: 'SavingsSetup_2B_ConnectToBank',
  },
  DocVerificationSuccess: {
    default: 'SavingsSetup_5_DocVerify',
  },
  // Roundup Monthly options
  '1st': {
    default: 'First',
  },
  '15th': {
    default: 'Fifteen',
  },
  'End of the Month': {
    default: 'End of month',
  },
};

export const getAmplitudeEventName = (route, label) => {
  const routeMap = routeStepEventMap[route];
  if (!routeMap) return undefined;
  return label ? routeMap[label] || undefined : routeMap.default;
};
