import React, { useContext, useEffect, useState } from 'react';
import { Box, Card, CardContent, Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../styles/GlobalStyle';
import { Context as UserContext } from '../../context/UserContext';
import { USER_SETUP } from '../../context/types';
import CompassInfo from './CompassInfo';
import AdditionalInfo from './AdditionalInfo';
import KYCBGP from './../../assets/images/kyc-bg-purple.png';
import CloseButton from './../../components/CloseButton';
import ManualLearnMore from './ManualLearnMore';
import DivinityLearnMore from './DivinityLearnMore';
import SendMeMail from './SendMeMail';
import ManualEmail from './ManualEmail';
import EmailGuideLine from './EmailGuideLine';
import Agency from './Agency';
import DebtInfo from './DebtInfo';
import LinkNotice from './LinkNotice';
import CLoading from '../../components/common/CLoading';

const useStyles = makeStyles({
  ...GlobalStyle,
  compassContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 40,
    marginBottom: 10,
  },
  backgroundImage: {
    backgroundImage: `url(${KYCBGP})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100%',
    width: '100%',
  },
  debtContent: {
    display: 'flex',
    alignItems: 'center',
  },
  bodyContentContainer: {
    padding: '40px 80px',
  },
  bodyContent: {
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 16,
    paddingRight: 16,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#414382',
    margin: 30,
    padding: 20,
    borderRadius: 4,
    width: 240,
  },
  yellowBox: {
    backgroundColor: '#FFE066',
    boxShadow: '0px 1px 2px rgba(31, 41, 55, 0.08)',
    borderRadius: 4,
    padding: 20,
    margin: 16,
    minHeight: 100,
  },
  whiteBox: {
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(31, 41, 55, 0.08)',
    borderRadius: 4,
    padding: '25px 20px',
    margin: 16,
    height: 110,
  },
  button: {
    padding: '2px 10px',
    border: '1px solid  #250E39',
    borderRadius: 4,
    color: '#250E39',
    display: 'block',
    marginTop: 12,
  },
  whiteBoxLink: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    cursor: 'pointer',
  },
  whiteBoxIcon: {
    marginRight: 8,
  },
  cardContent: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '25px',
    right: '25px',
  },
});

function CompassPage() {
  const width = window.innerWidth;
  const classes = useStyles();
  const { dispatch, state: userState, addContact } = useContext(UserContext);
  const [page, setPage] = useState('CompassInfo');
  const [isGuideLine, setIsGuideLine] = useState();
  const [accounts, setAccounts] = useState();
  const [capioAccountName, setCapioAccountName] = useState();
  const [accountIndex, setAccountIndex] = useState();
  const [isSettlement, setIsSettlement] = useState(false);
  const [refNumber, setRefNumber] = useState();

  useEffect(() => {
    if (userState) {
      let accountData = [];
      if (userState['compassInfo'] && userState['compassInfo']['debtInfo']) {
        accountData = accountData.concat(userState['compassInfo']['debtInfo']);
      }
      if (accountData && accountData.length) {
        setAccountIndex(0);
        if (accountData[0].capioAccountID) {
          setCapioAccountName(accountData[0].debt?.debtName);
        }
      }
      setAccounts([...accountData]);
    }
  }, [userState.compassInfo]);

  useEffect(() => {;
    window.setZendeskUser(userState.id);
  }, [userState.id])

  useEffect(() => {
    dispatch({
      type: USER_SETUP,
      payload: { showSideMenu: true },
    });
  }, []);

  const onContact = async (initiator, details) => {
    const params = {
      email: userState['email'],
      lastName: userState['lastName'],
      firstName: userState['firstName'],
      initiator: initiator,
      userId: userState['userId'],
      details,
      guidelineType: isSettlement ? 'settlement' : 'payment',
    };

    const res = await addContact(userState.jwt, params);
    if (res && res['status'] && res['data']) {
      if (initiator === 'admin') {
        setPage('SendMeMail');
      } else {
        setRefNumber(res['data']['refNo']);
        setPage('ManualEmail');
      }
    }
  };

  const renderActiveCard = () => {
    if (page === 'AdditionalInfo') {
      return <AdditionalInfo capioAccountName={capioAccountName} />;
    } else if (page === 'ManualLearnMore') {
      return (
        <ManualLearnMore
          onContact={onContact}
          accounts={accounts}
          accountIndex={accountIndex}
          isSettlement={isSettlement}
          setPage={setPage}
          setIsGuideLine={setIsGuideLine}
        />
      );
    } else if (page === 'DivinityLearnMore') {
      return (
        <DivinityLearnMore
          onContact={onContact}
          accounts={accounts}
          accountIndex={accountIndex}
          isSettlement={isSettlement}
          setPage={setPage}
          setIsGuideLine={setIsGuideLine}
        />
      );
    } else if (page === 'SendMeMail') {
      return <SendMeMail setPage={setPage} isGuideLine={isGuideLine} />;
    } else if (page === 'ManualEmail') {
      return <ManualEmail refNumber={refNumber} setPage={setPage} />;
    } else if (page === 'EmailGuideLine') {
      return (
        <EmailGuideLine setPage={setPage} setIsGuideLine={setIsGuideLine} />
      );
    } else if (page === 'Agency') {
      return <Agency accounts={accounts} accountIndex={accountIndex} />;
    } else if (page === 'DebtInfo') {
      return (
        <DebtInfo
          accounts={accounts}
          accountIndex={accountIndex}
          setPage={setPage}
        />
      );
    } else if (page === 'LinkNotice') {
      return (
        <LinkNotice
          accounts={accounts}
          accountIndex={accountIndex}
          setPage={setPage}
        />
      );
    }
  };

  const renderCardScreen = () => {
    return (
      <Grid container sx={{ flexDirection: 'row', height: '100%' }}>
        <Grid item width={'100%'} display="flex" alignItems="center">
          <Box
            className={classes.backgroundImage}
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: -1,
            }}
          />
          <Container
            className={classes.authContainer}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Container maxWidth="sm" sx={{ marginTop: -1 }}>
              <Card>
                <CardContent className={classes.cardContent}>
                  {page !== 'SendMeMail' && page !== 'ManualEmail' ? (
                    <CloseButton
                      className={classes.closeButton}
                      onClick={() => setPage('CompassInfo')}
                    />
                  ) : null}
                  {renderActiveCard()}
                </CardContent>
              </Card>
            </Container>
          </Container>
        </Grid>
      </Grid>
    );
  };

  let activeComponent = '';
  switch (page) {
    case 'CompassInfo':
      activeComponent = (
        <CompassInfo
          setAccountIndex={setAccountIndex}
          setCapioAccountName={setCapioAccountName}
          setIsSettlement={setIsSettlement}
          accounts={accounts}
          accountIndex={accountIndex}
          setPage={setPage}
        />
      );
      break;
    case 'AdditionalInfo':
    case 'ManualLearnMore':
    case 'DivinityLearnMore':
    case 'SendMeMail':
    case 'ManualEmail':
    case 'EmailGuideLine':
    case 'Agency':
    case 'DebtInfo':
    case 'LinkNotice':
      activeComponent = renderCardScreen();
      break;
  }

  if (userState.loading) {
    return <CLoading />;
  }

  return <>{activeComponent}</>;
}

export default CompassPage;
