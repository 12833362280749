import { createContext } from 'react';
import { API } from 'aws-amplify';

import { SET_LOADING, GET_PDF } from './types';
import CreateDataContext from './CreateDataContext';
import { API_PDF } from 'capio-common/src/constants';

export const PDFContext = createContext();

const initialState = {
  loading: false,
  pdfLink: null,
};

const setLoading = (dispatch, loading) => {
  dispatch({
    type: SET_LOADING,
    payload: { loading },
  });
};

const getPDFLink = (dispatch) => {
  return async (jwt) => {
    const path = '/pdf-export';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
    try {
      setLoading(dispatch, true);
      const result = await API.get(API_PDF, path, init);
      setLoading(dispatch, false);
      dispatch({
        type: GET_PDF,
        payload: result.data,
      });
      return { status: true, data: result.data };
    } catch (error) {
      return { status: false };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    case GET_PDF:
      return {
        ...state,
        pdfLink: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const dispatch = (dispatch) => {
  return async (action) => {
    dispatch(action);
  };
};

export const { Provider, Context } = CreateDataContext(
  reducer,
  {
    getPDFLink,
    dispatch,
  },
  initialState,
);
