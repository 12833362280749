import React from 'react';
import { Container, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../../../components/BackButton';
import EditAddress from './EditAddress';
import GlobalStyle from '../../../../styles/GlobalStyle';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function EditAddressIndex() {
  const navigate = useNavigate();
  const classes = useStyles();
  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <div className={classes.mainContent}>
      <Container maxWidth="sm">
        <BackButton onClick={onClickBack} />
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <EditAddress />
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}

export default EditAddressIndex;
