import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../../../styles/GlobalStyle';
import { currencyFormatter } from 'capio-common/src/patterns';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  accountDetailTitle: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'flex-end',
    margin: '0px 0px 8px 0px',
    fontWeight: 600,
  },
  accountDetail: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    fontWeight: 600,
  },
  accountDetailFooter: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
    marginBottom: '10px',
    fontWeight: 600,
    borderTop: '1px solid',
    borderColor: '#C0B9C7',
    paddingTop: '15px',
  },
  accountUpdateIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  accountIcons: {
    height: '20px',
    marginLeft: '5px',
    cursor: 'pointer',
  },
  accountTitle: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function DebtInfo({ accounts, setStep, fromEdit }) {
  const classes = useStyles();
  const [debtAccounts, setDebtAccounts] = useState(Object.assign([], accounts));
  useEffect(() => {
    setDebtAccounts(accounts);
  }, [accounts]);

  const getTotalMedicalDebt = () => {
    let total = 0;
    if (debtAccounts) {
      total += debtAccounts.reduce(
        (totalValue, value) =>
          totalValue + parseFloat(value['debt']?.['amount']),
        0,
      );
    }
    return total;
  };

  return (
    <Box>
      <div className={classes.accountDetailTitle}>
        <Typography variant="body3" color="purple40.main">
          Amount
        </Typography>
      </div>
      {debtAccounts &&
        debtAccounts.map((account, index) => {
          return (
            <div key={index} className={classes.accountDetail}>
              <Typography className={classes.accountTitle} variant="body3">
                {account['debt']?.['debtName']}{' '}
                <Typography variant="span" color="corePurple30.main">
                  {account['debt']?.['debtKey']
                    ? '- ' + account['debt']?.['debtKey']
                    : ''}
                </Typography>
                {fromEdit && !account.capioAccountID ? (
                  <Typography
                    variant="span"
                    className={classes.accountUpdateIcons}>
                    <EditOutlinedIcon
                      onClick={() => setStep('EditMedicalDebt')}
                      color="primary"
                      className={classes.accountIcons}
                    />
                    <DeleteOutlineOutlinedIcon
                      onClick={() => {
                        debtAccounts.splice(index, 1);
                        setDebtAccounts([...debtAccounts]);
                      }}
                      color="primary"
                      className={classes.accountIcons}
                    />
                  </Typography>
                ) : null}
              </Typography>
              <Typography variant="body3">
                {currencyFormatter(account['debt']?.['amount'])}
              </Typography>
            </div>
          );
        })}
      <div className={classes.accountDetailFooter}>
        <Typography variant="body3">Total Medical Bill*</Typography>
        <Typography variant="body3">
          {currencyFormatter(getTotalMedicalDebt())}
        </Typography>
      </div>
    </Box>
  );
}
