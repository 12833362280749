import React from 'react';
import { Box, Typography, Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { currencyFormatter } from 'capio-common/src/patterns';

const useStyles = makeStyles({
  accountCard: {
    backgroundColor: '#FFFFFF',
    padding: '24px',
    marginBottom: '12px',
    border: '1px solid #DEDEDE',
    borderRadius: '12px',
    boxShadow: '0px 1px 2px 0px rgba(31, 41, 55, 0.08)',
  },
  accountNumber: {
    fontWeight: 600,
    fontSize: '12px',
    color: '#4A26D6',
    marginBottom: '6px',
  },
  accountBalance: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  optionTitle: {
    color: '#656565',
    fontSize: '12px',
    fontWeight: '600',
  },
  optionDetailLabel: {
    fontSize: '14px',
  },
  optionDetailValue: {
    fontSize: '14px',
    fontWeight: '600',
  },
  divider: {
    width: '15%',
    margin: '20px 0',
  },
});

function DetailsBoxModal({ index, account }) {
  const classes = useStyles();
  const backgroundColor = index % 2 === 0 ? '#E6E8FA' : '#FFFFFF';
  const border = index % 2 === 0 ? '0px' : '1px solid #DEDEDE';
  const formatLengthOfPlan = (period) =>
    period === -1 ? 'One payment' : `${period} Months`;

  return (
    <Box className={classes.accountCard} style={{ backgroundColor, border }}>
      <Typography className={classes.accountNumber}>
        Account Number: {account?.capioAccountID}
      </Typography>
      <Typography className={classes.accountBalance}>
        {currencyFormatter(account.debt?.amount)}
      </Typography>
      <Typography className={classes.optionTitle} gutterBottom>
        Account Settlement Option
      </Typography>
      <Grid container justifyContent="space-between">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            lineHeight: '24px',
          }}>
          <Grid item className={classes.optionDetailLabel}>
            Settlement Amount:
          </Grid>
          <Grid item className={classes.optionDetailLabel}>
            Length of Plan:
          </Grid>
          <Grid item className={classes.optionDetailLabel}>
            Amount Forgiven:
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            lineHeight: '24px',
          }}>
          <Grid item className={classes.optionDetailValue}>
            {currencyFormatter(account.guidelines?.settlement?.amount)}
          </Grid>
          <Grid item className={classes.optionDetailValue}>
            {formatLengthOfPlan(account.guidelines?.settlement?.period)}
          </Grid>
          <Grid
            item
            className={classes.optionDetailValue}
            sx={{ color: '#4A26D6' }}>
            {currencyFormatter(account.guidelines?.settlement?.forgiven)}
          </Grid>
        </Box>
      </Grid>
      <Divider className={classes.divider} />
      <Typography className={classes.optionTitle} gutterBottom>
        Payment Plan Option
      </Typography>
      <Grid container justifyContent="space-between">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            lineHeight: '24px',
          }}>
          <Grid item className={classes.optionDetailLabel}>
            Monthly Payment:
          </Grid>
          <Grid item className={classes.optionDetailLabel}>
            Length of Plan:
          </Grid>
          <Grid item className={classes.optionDetailLabel}>
            Total Payment:
          </Grid>
          <Grid item className={classes.optionDetailLabel}>
            Amount Forgiven:
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginRight: '24px',
            lineHeight: '24px',
          }}>
          <Grid item className={classes.optionDetailValue}>
            {currencyFormatter(account.guidelines?.payment?.amount)}
          </Grid>
          <Grid item className={classes.optionDetailValue}>
            {formatLengthOfPlan(account.guidelines?.payment?.period)}
          </Grid>
          <Grid item className={classes.optionDetailValue}>
            {currencyFormatter(
              account.guidelines?.payment?.amount *
                account.guidelines?.payment?.period,
            )}
          </Grid>
          <Grid
            item
            className={classes.optionDetailValue}
            sx={{ color: '#4A26D6' }}>
            {currencyFormatter(account.guidelines?.payment?.forgiven)}
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
}

export default DetailsBoxModal;
