import React, { useState, useContext, useEffect } from 'react';
import { Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { usePlacesWidget } from 'react-google-autocomplete';
import _ from 'lodash';

import CTextField from '../../components/common/CTextField';
import CButton from '../../components/common/CButton';
import TitleText from '../../components/common/TitleText';
import CAlert from '../../components/common/CAlert';
import { Context as UserContext } from '../../context/UserContext';
import { USER_SETUP } from '../../context/types';
import {
  INVALID_MOBILE_NUMBER,
  INVALID_ZIP_CODE,
  REQUIRED_ADDRESS,
  REQUIRED_CITY,
  REQUIRED_MOBILENUMBER,
  REQUIRED_ZIP_CODE,
} from 'capio-common/src/constants';
import {
  formatPhoneNumber,
  polishValue,
  revertPhoneFormat,
} from 'capio-common/src/main';
import { PHONE_PATTERN, ZIPCODE_PATTERN } from 'capio-common/src/patterns';
import GlobalStyle from '../../styles/GlobalStyle';
import { sendAmplitudeData } from '../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function ContactInfo(props) {
  const { updateStep } = props;
  const { state: userState, dispatch, verifyAddress } = useContext(UserContext);
  const classes = useStyles();
  const [fields, setFields] = useState({
    phone: '',
    address: '',
    city: '',
    zipCode: '',
    state: '',
  });
  const [error, setError] = useState('');
  const [formValid, setFormValid] = useState(false);

  const { ref: addressFieldRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
    onPlaceSelected: (place) => {
      const { address_components, formatted_address } = place;
      console.log(place);
      console.log(formatted_address);
      console.log(address_components);
      const result = formatted_address?.split(', ');
      const postalCode = address_components.filter(
        (item) => item.types?.[0] === 'postal_code',
      );
      const countryState =
        address_components.find((v) =>
          v.types.includes('administrative_area_level_1'),
        )?.short_name ?? '';
      setValue('address', result?.[0]);
      setValue('city', result?.[1]);
      setValue('zipCode', postalCode?.[0]?.['long_name']);
      setValue('state', countryState);
    },
    options: {
      types: ['address'],
      fields: ['address_components', 'formatted_address', 'types'],
      componentRestrictions: { country: 'us' },
    },
  });

  const handleInputChange = ({ target }) => {
    setFields({ ...fields, [target.name]: target.value });
  };

  const {
    control,
    watch,
    trigger,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    reValidateMode: 'all',
    defaultValues: {
      phone: '',
      address: '',
      city: '',
      zipCode: '',
      state: '',
    },
  });

  const onSubmit = async (data) => {
    const { phone, address, city, zipCode, state } = data;
    setError('');
    dispatch({
      type: USER_SETUP,
      payload: {
        phone: formatPhoneNumber(phone),
        address: {
          streetAddress: address,
          city,
          postalCode: zipCode,
          state,
        },
      },
    });
    updateStep(1);
    sendAmplitudeData('InitialKYC_3_PII2');
  };

  useEffect(() => {
    const { phone, address, city, zipCode } = userState;
    setValue('phone', revertPhoneFormat(phone), { shouldValidate: !!phone });
    setValue('address', address.streetAddress, {
      shouldValidate: !!address.streetAddress,
    });
    setValue('city', address.city, { shouldValidate: !!address.city });
    setValue('zipCode', address.postalCode, {
      shouldValidate: !!address.postalCode,
    });
    setValue('state', address.state);
  }, []);

  const watchZipCode = watch('zipCode');

  useEffect(() => {
    const checkZipCode = async () => {
      setFormValid(false);
      if (polishValue(watchZipCode)?.length > 4) {
        await trigger();
      }
    };
    checkZipCode();
  }, [watchZipCode]);

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title="Contact information"
        description="We will verify your identity using the information provided below."
      />
      <CAlert severity="error" message={error} />
      <Grid item>
        <CTextField
          fullWidth
          name="phone"
          label="Mobile Number"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_MOBILENUMBER },
            pattern: { value: PHONE_PATTERN, message: INVALID_MOBILE_NUMBER },
          }}
          mask="999-999-9999"
          error={!!errors.phone}
          helperText={errors.phone && errors.phone.message}
        />
      </Grid>
      <Grid item>
        <CTextField
          inputRef={addressFieldRef}
          fullWidth
          name="address"
          label="Street Address"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_ADDRESS },
          }}
          error={!!errors.address}
          helperText={errors.address && errors.address.message}
        />
      </Grid>
      <Grid item>
        <CTextField
          fullWidth
          name="city"
          label="City"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_CITY },
          }}
          error={!!errors.city}
          helperText={errors.city && errors.city.message}
          disabled
        />
      </Grid>
      <Grid item>
        <CTextField
          fullWidth
          name="zipCode"
          label="ZIP Code"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_ZIP_CODE },
          }}
          // mask="99999"
          error={!!errors.zipCode}
          helperText={errors.zipCode && errors.zipCode.message}
          disabled
        />
      </Grid>
      <Grid item className={classes.buttonStyle1}>
        <CButton
          variant="contained"
          disabled={!isValid}
          loading={userState.loading}
          fullWidth
          onClick={handleSubmit(onSubmit)}>
          Confirm
        </CButton>
      </Grid>
    </Container>
  );
}

export default ContactInfo;
