import React from 'react';
import { Typography } from '@mui/material';

import { COPY_RIGHT } from 'capio-common/src/constants';

const CopyRight = () => (
  <Typography sx={{ fontSize: 10, textAlign: 'center' }} mb={1}>
    {COPY_RIGHT}
  </Typography>
);

export default CopyRight;
