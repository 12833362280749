import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../../../styles/GlobalStyle';
import AccountList from './AccountList';
import AccountTypesInfo from './AccountTypesInfo';
import ProfileHeader from '../../profileHeader';
import { Context as PaymentContext } from '../../../../context/PaymentContext';
import { Context as UserContext } from '../../../../context/UserContext';
import CLoading from '../../../../components/common/CLoading';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));

const steps = ['AccountList', 'AccountTypesInfo'];

function LinkedBank() {
  const classes = useStyles();
  const [step, setStep] = useState(steps[0]);
  const {
    dispatch,
    getAllLinkedBanks,
    getPlaidLinkToken,
    state: paymentState,
  } = useContext(PaymentContext);
  const { state: userState, checkAuthentication } = useContext(UserContext);

  useEffect(() => {
    if (!userState.jwt || !userState.id) {
      return;
    }
    dispatch(getPlaidLinkToken(userState.jwt, checkAuthentication));
    dispatch(
      getAllLinkedBanks(userState.jwt, userState.id, checkAuthentication),
    );
  }, [userState.jwt, userState.id]);

  const linkedBanks = useMemo(() => {
    if (!paymentState?.allLinkedBanks?.length) return [];
    return paymentState.allLinkedBanks.filter((v) => v.plaidType === 'billpay');
  }, [paymentState.allLinkedBanks]);

  const updateStep = (move) => {
    const newStep = steps[steps.indexOf(step) + move];
    setStep(newStep);
  };

  let activeComponent = '';
  switch (step) {
    case 'AccountList':
      activeComponent = (
        <AccountList accounts={linkedBanks} updateStep={updateStep} />
      );
      break;
    case 'AccountTypesInfo':
      activeComponent = <AccountTypesInfo updateStep={updateStep} />;
      break;
  }

  if (paymentState.loading) {
    return (
      <Box position={'relative'} height="100%">
        <CLoading />
      </Box>
    );
  }

  return (
    <div>
      <ProfileHeader />
      <Container maxWidth="sm" className={classes.container}>
        {activeComponent}
      </Container>
    </div>
  );
}

export default LinkedBank;
