import React, { useContext, useEffect, useState } from 'react';
import { Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import CTextField from '../../components/common/CTextField';
import CButton from '../../components/common/CButton';
import TitleText from '../../components/common/TitleText';
import CAlert from '../../components/common/CAlert';
import { Context as UserContext } from '../../context/UserContext';
import { USER_SETUP } from '../../context/types';

import { INVALID_MOBILE_NUMBER } from 'capio-common/src/constants';
import { PHONE_PATTERN } from 'capio-common/src/patterns';
import { formatPhoneNumber, polishValue } from 'capio-common/src/main';
import GlobalStyle from '../../styles/GlobalStyle';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function ForgotEmail(props) {
  const { updateStep } = props;
  const { state, dispatch, forgotEmail } = useContext(UserContext);
  const [alert, setAlert] = useState({ severity: '', message: '' });
  const [formValid, setFormValid] = useState(false);
  const classes = useStyles();

  const onSubmit = async (data) => {
    const { email, phone } = data;
    dispatch({
      type: USER_SETUP,
      payload: { phone: formatPhoneNumber(phone) },
    });
    setAlert({ severity: '', message: '' });

    const { status, message } = await forgotEmail(formatPhoneNumber(phone));
    if (status) {
      updateStep(2);
    } else {
      setAlert({ severity: 'error', message });
    }
  };

  const {
    control,
    watch,
    trigger,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      phone: '',
    },
  });

  const watchPhone = watch('phone');

  useEffect(() => {
    const checkPhone = async () => {
      setFormValid(false);
      if (polishValue(watchPhone)?.length > 9) {
        await trigger();
        if (_.isEmpty(errors)) {
          setFormValid(true);
        }
      }
    };
    checkPhone();
  }, [watchPhone]);

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title="Forgot your email address?"
        description="Enter the phone number you used to signup."
      />
      <CAlert severity={alert.severity} message={alert.message} />
      <Grid item>
        <CTextField
          fullWidth
          name="phone"
          label="Mobile Number"
          control={control}
          rules={{
            required: true,
            pattern: PHONE_PATTERN,
          }}
          mask="999-999-9999"
          error={!!errors.phone}
          helperText={errors.phone && INVALID_MOBILE_NUMBER}
        />
      </Grid>
      <Grid item>
        <CButton
          variant="contained"
          disabled={formValid ? false : !isValid}
          fullWidth
          loading={state.loading}
          sx={{ marginTop: 2 }}
          onClick={handleSubmit(onSubmit)}>
          Submit
        </CButton>
      </Grid>
    </Container>
  );
}

export default ForgotEmail;
