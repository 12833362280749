import React, { useContext, useState } from 'react';
import { Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';

import CTextField from '../../components/common/CTextField';
import CButton from '../../components/common/CButton';
import TitleText from '../../components/common/TitleText';
import CAlert from '../../components/common/CAlert';
import { Context as UserContext } from '../../context/UserContext';
import { USER_SETUP } from '../../context/types';

import {
  INVALID_EMAIL_ADDRESS,
  REQUIRED_EMAIL,
} from 'capio-common/src/constants';
import { EMAIL_PATTERN } from 'capio-common/src/patterns';
import GlobalStyle from '../../styles/GlobalStyle';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function ForgotPassword(props) {
  const { updateStep } = props;
  const { state, dispatch, forgotPassword } = useContext(UserContext);
  const [alert, setAlert] = useState({ severity: '', message: '' });
  const classes = useStyles();

  const onSubmit = async (data) => {
    const { email } = data;
    dispatch({
      type: USER_SETUP,
      payload: { email },
    });
    setAlert({ severity: '', message: '' });

    const { status, message } = await forgotPassword(email);
    if (status) {
      updateStep(1);
    } else {
      setAlert({ severity: 'error', message });
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title="Forgot your password?"
        description="If you forgot your password, please enter the email address associated with your account to reset your password."
      />
      <CAlert severity={alert.severity} message={alert.message} />
      <Grid item>
        <CTextField
          fullWidth
          name="email"
          label="Email Address"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_EMAIL },
            pattern: { value: EMAIL_PATTERN, message: INVALID_EMAIL_ADDRESS },
          }}
          error={!!errors.email}
          helperText={errors.email && errors.email.message}
        />
      </Grid>
      <Grid item>
        <CButton
          variant="contained"
          disabled={!isValid}
          fullWidth
          loading={state.loading}
          sx={{ marginTop: 2 }}
          onClick={handleSubmit(onSubmit)}>
          Submit
        </CButton>
      </Grid>
    </Container>
  );
}

export default ForgotPassword;
