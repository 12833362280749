import React, { useContext } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Context as UserContext } from './../../context/UserContext';
import GlobalStyle from './../../styles/GlobalStyle';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function ProfileHeader() {
  const { state } = useContext(UserContext);
  const classes = useStyles();

  const { firstName, lastName } = state;

  return (
    <Grid className={classes.headerProfile}>
      <IconButton className={classes.profileImg}>
        <AccountCircleOutlinedIcon />
      </IconButton>
      <Typography
        variant="h6"
        color="white.main">{`${firstName} ${lastName}`}</Typography>
    </Grid>
  );
}

export default ProfileHeader;
