import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { Context as UserContext } from '../context/UserContext';
import CButton from './common/CButton';
import ResultItem from './ResultItem';
import GlobalStyle from '../styles/GlobalStyle';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function CommonResult(props) {
  const {
    success,
    successTitle,
    successMsg,
    successBtn,
    failureTitle,
    failureMsg,
    failureBtn,
    onClickDone,
    onClickRetry,
  } = props;
  const { state } = useContext(UserContext);

  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.container}>
      {success ? (
        <>
          <ResultItem
            title={successTitle || 'Success!'}
            description={successMsg}
            variant="success"
            icon={<CheckIcon style={{ fontSize: 40, color: '#fff' }} />}
          />
          <Grid item className={classes.buttonStyle1}>
            <CButton variant="contained" fullWidth onClick={onClickDone}>
              {successBtn || 'Done'}
            </CButton>
          </Grid>
        </>
      ) : (
        <>
          <ResultItem
            title={failureTitle || 'Error'}
            description={failureMsg}
            variant="error"
            icon={<CloseIcon style={{ fontSize: 40, color: '#fff' }} />}
          />
          <Grid item className={classes.buttonStyle1}>
            <CButton variant="contained" fullWidth onClick={onClickRetry}>
              {failureBtn || 'Retry'}
            </CButton>
          </Grid>
        </>
      )}
    </Container>
  );
}

CommonResult.propTypes = {
  success: PropTypes.bool,
  successTitle: PropTypes.string,
  successMsg: PropTypes.string,
  successBtn: PropTypes.string,
  failureTitle: PropTypes.string,
  failureMsg: PropTypes.string,
  failureBtn: PropTypes.string,
  onClickDone: PropTypes.func,
  onClickRetry: PropTypes.func,
};

export default CommonResult;
