import React, { useContext } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../../styles/GlobalStyle';
import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import {
  CONFIRM_DEBT_INFO_DESCRIPTIONP1,
  CONFIRM_DEBT_INFO_MAIN_DESCRIPTION,
  CONFIRM_DEBT_INFO_TITLE,
  DEBT_INFO_DESCRIPTIONP2,
  EDIT_DEBT_INFO_TITLE,
} from 'capio-common/src/constants';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ReactComponent as Clipboard } from '../../../assets/images/clipboard.svg';
import { ReactComponent as Currency } from '../../../assets/images/currency-solid.svg';
import { openEmail } from 'capio-common/src/main';
import { currencyFormatter } from 'capio-common/src/patterns';
import { Context as UserContext } from '../../../context/UserContext';
import { sendAmplitudeData } from '../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  description: {
    fontFamily: 'OpenSans',
    marginBottom: '10px',
    marginTop: '10px',
  },
  description2: {
    marginTop: '100px',
  },
  accountDetailTitle: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'flex-end',
    margin: '0px 0px 8px 0px',
    fontWeight: 600,
  },
  accountDetail: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    fontWeight: 600,
  },
  accountDetailFooter: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
    marginBottom: '10px',
    fontWeight: 600,
    borderTop: '1px solid',
    borderColor: '#C0B9C7',
    paddingTop: '15px',
  },
  otherAccount: {
    display: 'flex',
  },
  accountUpdateIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  accountIcons: {
    height: '20px',
    marginLeft: '5px',
    cursor: 'pointer',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
  },
  smallIconContainer: {
    paddingTop: 5,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const buttonSx = { 
  fontSize: '16px',
  width: '100%',
  backgroundColor: '#4A26D6',
  color: '#FFFFFF',
  padding: '10px 20px',
  textTransform: 'none',
  borderRadius: '54px',
  margin: '20px 0px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: 2
 };

export default function ConfirmDebtInfo({
  capioAccount,
  updateStep,
  option,
  medicalDebt,
  setMedicalDebt,
  userInfo,
  fromEdit,
}) {
  const classes = useStyles();

  const {
    state: userState,
    updateCapioAccount,
    getUserInfo,
  } = useContext(UserContext);

  const getTotalMedicalDebt = () => {
    let total = 0;
    if (capioAccount) {
      total += capioAccount.reduce(
        (totalValue, value) => totalValue + parseFloat(value['amount']),
        0,
      );
    }
    if (
      option === 'AccountSeparately' &&
      medicalDebt &&
      medicalDebt['debtHolders']
    ) {
      total += medicalDebt['debtHolders'].reduce(
        (totalValue, value) =>
          totalValue + parseFloat(value['amount'] ? value['amount'] : 0),
        0,
      );
    }
    if (option === 'TotalAmount' && medicalDebt && medicalDebt['amount']) {
      total += parseFloat(medicalDebt['amount']);
    }
    return total;
  };

  const deleteDebtHolders = (index) => {
    medicalDebt['debtHolders'].splice(index, 1);
    setMedicalDebt({ ...medicalDebt, debtHolders: medicalDebt['debtHolders'] });
  };

  // same logic in ConfirmIncomeInfo
  const getManualDebt = () => {
    let manualDebt = [];
    if (option === 'TotalAmount') {
      // there is medicalDebt?.amount
      manualDebt = [
        {
          debtName: capioAccount ? 'Other' : 'Medical Bill',
          debtAmount: parseFloat(medicalDebt?.amount),
        },
      ];
    } else {
      manualDebt = medicalDebt?.debtHolders?.map((item) => ({
        debtName: item.name,
        debtAmount: parseFloat(item.amount),
      }));
    }
    return manualDebt;
  };

  const onSave = async () => {
    const data = {
      spousalIncome: userInfo['compassInfo']['spousalIncome']
        ? parseFloat(userInfo['compassInfo']['spousalIncome'])
        : 0,
      userIncome: userInfo['compassInfo']['userIncome']
        ? parseFloat(userInfo['compassInfo']['userIncome'])
        : 0,
      otherIncome: userInfo['compassInfo']['otherIncome']
        ? parseFloat(userInfo['compassInfo']['otherIncome'])
        : 0,
      incomeType: userInfo?.compassInfo?.incomeType ?? 'plaid',
      unemployed: userInfo['compassInfo']['unemployed'],
      consenttoshare: userInfo['compassInfo']['consenttoshare'],
      contact: userInfo['compassInfo']['contact'],
      married: userInfo['compassInfo']['married'],
      partnerDebt: userInfo?.capioAccount ?? null,
      manualDebt: getManualDebt(),
    };
    await updateCapioAccount(userState.jwt, data);
    await getUserInfo(userState.userSub);
    updateStep('CompassSettingInfo');
  };

  const handleConfirm = () => {
    if (fromEdit) {
      onSave();
    } else {
      updateStep(1);
      if(userInfo?.capioAccount) {
        sendAmplitudeData('CalcSetup_4_ConfirmDebtSummary', {
          partner_agency: userInfo?.capioAccount
        });
      } else {
        sendAmplitudeData('CalcSetup_4_ConfirmDebtSummary');
      }
    }
  };

  const RenderIcon = () => {
    return (
      <Box className={classes.iconContainer}>
        <Clipboard />
        <Box className={classes.smallIconContainer}>
          <Currency />
        </Box>
      </Box>
    );
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        icon={<RenderIcon />}
        title={fromEdit ? EDIT_DEBT_INFO_TITLE : CONFIRM_DEBT_INFO_TITLE}
      />

      <div className={classes.accountDetailTitle}>
        <Typography variant="body3" color="purple40.main">
          Amount
        </Typography>
      </div>
      {capioAccount &&
        capioAccount.map((account, index) => {
          return (
            <div key={index} className={classes.accountDetail}>
              <Typography variant="body3">
                {account['debtName']}{' '}
                {option === 'TotalAmount' ? (
                  <Typography variant="span" color="corePurple30.main">
                    - {account['capioAccountID']}
                  </Typography>
                ) : null}
              </Typography>
              <Typography variant="body3">
                {currencyFormatter(account['amount'])}
              </Typography>
            </div>
          );
        })}
      {option === 'TotalAmount' && medicalDebt ? (
        <div className={classes.accountDetail}>
          <Typography variant="body3" className={classes.otherAccount}>
            {capioAccount ? 'Other' : 'Medical Bill'}
            <Typography variant="span" className={classes.accountUpdateIcons}>
              <EditOutlinedIcon
                onClick={() =>
                  fromEdit ? updateStep('EditMedicalDebt') : updateStep(-1)
                }
                color="primary"
                className={classes.accountIcons}
              />
              {!!medicalDebt['amount'] && (
                <DeleteOutlineOutlinedIcon
                  onClick={() => {
                    medicalDebt['amount'] = 0;
                    setMedicalDebt({ ...medicalDebt });
                  }}
                  color="primary"
                  className={classes.accountIcons}
                />
              )}
            </Typography>
          </Typography>
          <Typography variant="body3">
            {currencyFormatter(medicalDebt['amount'])}
          </Typography>
        </div>
      ) : null}
      {option === 'AccountSeparately' && medicalDebt ? (
        <>
          {medicalDebt['debtHolders'].map((debtInfo, index) => {
            return (
              <div key={index} className={classes.accountDetail}>
                <Typography variant="body3" className={classes.otherAccount}>
                  {debtInfo['name']}
                  <Typography
                    variant="span"
                    className={classes.accountUpdateIcons}>
                    <EditOutlinedIcon
                      onClick={() =>
                        fromEdit
                          ? updateStep('EditMedicalDebt')
                          : updateStep(-1)
                      }
                      color="primary"
                      className={classes.accountIcons}
                    />
                    <DeleteOutlineOutlinedIcon
                      onClick={() => deleteDebtHolders(index)}
                      color="primary"
                      className={classes.accountIcons}
                    />
                  </Typography>
                </Typography>
                <Typography variant="body3">
                  {currencyFormatter(debtInfo['amount'])}
                </Typography>
              </div>
            );
          })}
        </>
      ) : null}
      <div className={classes.accountDetailFooter}>
        <Typography variant="body3" className={classes.otherAccount}>
          Total Medical Bill*
          {fromEdit && !medicalDebt.amount && (
            <Typography variant="span" className={classes.accountUpdateIcons}>
              <EditOutlinedIcon
                onClick={() => {
                  updateStep('EditMedicalDebt');
                }}
                color="primary"
                className={classes.accountIcons}
              />
            </Typography>
          )}
        </Typography>
        <Typography variant="body3">
          {currencyFormatter(getTotalMedicalDebt())}
        </Typography>
      </div>
      <Typography className={classes.description}>
        {CONFIRM_DEBT_INFO_DESCRIPTIONP1}
      </Typography>

      <Typography className={classes.description}>
        If any other information listed is not what you expect, please{' '}
        <a
          target={'_blank'}
          className={classes.link}
          onClick={() => {
            sendAmplitudeData('CalcSetup_2_ContactUs');
            openEmail();
          }}>
          click here
        </a>{' '}
        to contact us.
      </Typography>

      <Typography className={classes.description2}>
        *{DEBT_INFO_DESCRIPTIONP2}
      </Typography>
      <CButton
        sx={{...buttonSx}}
        variant="contained"
        fullWidth
        loading={userState.loading}
        disabled={!getTotalMedicalDebt() && medicalDebt && !medicalDebt.amount}
        onClick={handleConfirm}>
        {fromEdit ? 'Save' : 'Confirm'}
      </CButton>
    </Container>
  );
}
