import React, { useContext, useEffect, useState } from 'react';
import { Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import CTextField from '../../../../components/common/CTextField';
import CButton from '../../../../components/common/CButton';
import TitleText from '../../../../components/common/TitleText';
import { Context as UserContext } from '../../../../context/UserContext';
import { USER_SETUP } from '../../../../context/types';
import {
  INVALID_MOBILE_NUMBER,
  REQUIRED_MOBILENUMBER,
} from 'capio-common/src/constants';
import {
  formatPhoneNumber,
  polishValue,
  revertPhoneFormat,
} from 'capio-common/src/main';
import { PHONE_PATTERN } from 'capio-common/src/patterns';
import GlobalStyle from '../../../../styles/GlobalStyle';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function EditPhone(props) {
  const { updateStep } = props;
  const { state, dispatch, updateUserAttributes } = useContext(UserContext);
  const classes = useStyles();
  const [formValid, setFormValid] = useState(false);

  const {
    control,
    watch,
    trigger,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      phone: '',
    },
  });

  const watchPhone = watch('phone');

  const onSubmit = async (data) => {
    const { phone } = data;
    const { status, message } = await updateUserAttributes({
      phone_number: formatPhoneNumber(phone),
    });

    if (status) {
      dispatch({
        type: USER_SETUP,
        payload: {
          phone: formatPhoneNumber(phone),
          verificationType: 'phone',
          updateProfileFlow: true,
        },
      });
      updateStep(1);
    }
  };

  useEffect(() => {
    const { phone } = state;
    setValue('phone', revertPhoneFormat(phone), { shouldValidate: !!phone });
  }, []);

  useEffect(() => {
    const checkPhone = async () => {
      setFormValid(false);
      if (polishValue(watchPhone)?.length > 9) {
        await trigger();
        if (_.isEmpty(errors)) {
          setFormValid(true);
        }
      }
    };
    checkPhone();
  }, [watchPhone]);

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText title="Edit Mobile Number" description="" />
      <Grid item>
        <CTextField
          fullWidth
          name="phone"
          label="Mobile Number"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_MOBILENUMBER },
            pattern: { value: PHONE_PATTERN, message: INVALID_MOBILE_NUMBER },
          }}
          mask="999-999-9999"
          error={!!errors.phone}
          helperText={errors.phone && errors.phone.message}
        />
      </Grid>

      <Grid item className={classes.buttonStyle1}>
        <CButton
          variant="contained"
          disabled={formValid ? false : !isValid}
          loading={state.loading}
          fullWidth
          onClick={handleSubmit(onSubmit)}>
          Update Mobile Number
        </CButton>
      </Grid>
    </Container>
  );
}

export default EditPhone;
