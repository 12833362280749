import React, { useContext, useState, useEffect } from 'react';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OneSignal from 'react-onesignal';

import { Context as UserContext } from '../../context/UserContext';
import { USER_SETUP } from '../../context/types';
import TitleText from '../../components/common/TitleText';
import RowItem from '../../components/RowItem';
import GlobalStyle from '../../styles/GlobalStyle';
import CButton from '../../components/common/CButton';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function DocVerification(props) {
  const { updateStep } = props;
  const { dispatch, state } = useContext(UserContext);
  const classes = useStyles();

  const handleSelection = async (type) => {
    const id = await OneSignal.getUserId();
    dispatch({
      type: USER_SETUP,
      payload: {
        docVerificationType: type,
        fp: id,
      },
    });
    updateStep(1);
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title="We need some more information"
        description="Select one of the document types below to complete your identity verification."
      />
      <RowItem
        title="U.S. Passport"
        subTitle="Valid Passport MUST be signed"
        icon={<ArrowForwardIosIcon />}
        onClick={() => handleSelection('id_card')}
      />
    </Container>
  );
}

export default DocVerification;
