import * as React from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

const useStyles = makeStyles(() => ({
  textField: {
    marginTop: 8,
    marginBottom: 8,
  },
}));

export default function CTextField(props) {
  const { name, control, rules, mask, placeholder } = props;
  const classes = useStyles();

  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          if (mask) {
            return (
              <InputMask mask={mask} {...field}>
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    className={classes.textField}
                    color="success"
                    {...props}
                    autoComplete="new-password"
                    placeholder={placeholder}
                  />
                )}
              </InputMask>
            );
          } else {
            return (
              <TextField
                {...field}
                className={classes.textField}
                color="success"
                {...props}
                autoComplete="new-password"
                placeholder={placeholder}
              />
            );
          }
        }}
      />
    );
  }

  return <TextField className={classes.textField} color="success" {...props} />;
}
