import React, { useState, useContext, useMemo } from 'react';
import { Grid, Container, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import CButton from '../../../../components/common/CButton';
import TitleText from '../../../../components/common/TitleText';
import Typography from '@mui/material/Typography';
import { Context as UserContext } from '../../../../context/UserContext';
import { Context as SynapseContext } from '../../../../context/SynapseContext';
import { USER_SETUP } from '../../../../context/types';
import { INVALID_AMOUNT, REQUIRED_AMOUNT } from 'capio-common/src/constants';
import CDatePicker from '../../../../components/common/CDatePicker';
import GlobalStyle from '../../../../styles/GlobalStyle';
import CPrefixInput from '../../../../components/common/CPrefixInput';
import { validatePaymentDate } from 'capio-common/src/main';
import { AMOUNT_PATTERN } from 'capio-common/src/patterns';
import VerifyPayment from '../../../choose-tool/bill-pay/VerifyPayment';
import { sendAmplitudeData } from '../../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function WithdrawFund(props) {
  const { updateStep, updateData } = props;
  const { state, dispatch } = useContext(UserContext);
  const { state: synapseState } = useContext(SynapseContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [date, setDate] = useState('');
  const [dateError, setDateError] = useState('');

  const isVerifying = useMemo(() => {
    return state.synapseUser?.isActive;
  }, [state.synapseUser]);

  const handleDateChange = (value) => {
    setDateError(validatePaymentDate(value));
    const date = moment(value).format('MM/DD/YYYY');
    setDate(date);
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: '',
    },
  });

  const onSubmit = (data) => {
    sendAmplitudeData('Savings_WithdrawFunds_Step1', {click_name: 'Continue'});
    dispatch({
      type: USER_SETUP,
      payload: {},
    });
    updateData(data.amount);
    updateStep(1);
  };

  const onExit = () => {
    sendAmplitudeData('Savings_WithdrawFunds_Step1', {click_name: 'Dismiss'});
    navigate(-1);
  }

  if (!isVerifying) {
    return <VerifyPayment />;
  }
  return (
    <Container maxWidth="sm" className={classes.container}>
      <Grid item display="flex" justifyContent="flex-end">
        <IconButton onClick={onExit}>
          <CloseIcon color="primary" />
        </IconButton>
      </Grid>
      <TitleText
        title="Withdraw Funds"
        description="Enter the amount your want to withdraw below. Funds will be in your bank account in 1-2 business days."
      />
      <Typography variant="body">
        Your current balance is $
        {(synapseState.synapseInfo?.info?.balances?.USD || 0).toFixed(2)}
      </Typography>
      <Grid item mt={3}>
        <CPrefixInput
          inputId="amount"
          name="amount"
          label="Amount"
          prefix="$"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_AMOUNT },
            pattern: { value: AMOUNT_PATTERN, message: INVALID_AMOUNT },
          }}
          error={!!errors.amount}
          helperText={errors.amount && errors.amount.message}
        />
      </Grid>
      <Grid item mt={4}>
        <CButton
          variant="contained"
          disabled={!isValid || !!dateError}
          fullWidth
          onClick={handleSubmit(onSubmit)}>
          Continue
        </CButton>
      </Grid>
    </Container>
  );
}

export default WithdrawFund;
