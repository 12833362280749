import React, { useCallback } from 'react';
import { Box, Card, Typography, Button, LinearProgress } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/material/styles';

import { ReactComponent as SavingsSVG } from '../assets/images/currency-bag.svg';
import { ReactComponent as RoundUpSVG } from '../assets/images/coins.svg';

const StyledCard = styled(Card)(({ cardHeight }) => ({
  padding: '20px',
  position: 'relative',
  height: `${cardHeight}px`,
  width: '280px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '12px',
  boxShadow:
    '0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.04), 0px 4px 16px 1px rgba(0, 0, 0, 0.06)',
}));

const IconBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  marginTop: '8px',
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.purple110.main,
  },
  backgroundColor: theme.palette.grey10.main,
}));

const ProgressSection = styled(Box)({
  marginTop: '16px',
});

const ProgressTextContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledButton = styled(Button)({
  marginTop: '16px',
  alignSelf: 'flex-start',
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 16px',
  width: 'fit-content',
  color: '#fff',
});

const BalanceInfoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '16px',
});

const BalanceItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginRight: '16px',
});

const BalanceTextContainer = styled(Box)({
  marginLeft: '8px',
});

const ToolCard = ({ tool, buttonLabel, onButtonClick, isInYourTools }) => {
  const handleButtonClick = useCallback(() => {
    if (onButtonClick) {
      onButtonClick();
    }
  }, [onButtonClick]);

  const renderProgressSection = () => {
    if (tool.name === 'Calculator' && tool.state === 'inProgress') {
      return (
        <ProgressSection>
          <CustomLinearProgress variant="determinate" value={tool.progress} />
          <ProgressTextContainer>
            <Typography
              variant="body2"
              sx={{ fontSize: '11px', fontWeight: 600, mt: 1 }}>
              In Progress
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: '11px', fontWeight: 600, mt: 1 }}>
              {Math.round(tool.progress)}%
            </Typography>
          </ProgressTextContainer>
        </ProgressSection>
      );
    }
    return null;
  };

  const renderAdditionalContent = () => {
    if (tool.name === 'Payments' && tool.state === 'completed') {
      return (
        <BalanceInfoContainer>
          <BalanceItem>
            <SavingsSVG style={{ width: 24, height: 24 }} />
            <BalanceTextContainer>
              <Typography sx={{ fontSize: '18px', color: '#000' }}>
                ${tool.savingsBalance}
              </Typography>
              <Typography sx={{ color: 'text.secondary', fontSize: '8px' }}>
                Savings Balance
              </Typography>
            </BalanceTextContainer>
          </BalanceItem>
          <BalanceItem>
            <RoundUpSVG style={{ width: 24, height: 24 }} />
            <BalanceTextContainer>
              <Typography sx={{ fontSize: '18px', color: '#000' }}>
                ${tool.roundUpAmount}
              </Typography>
              <Typography sx={{ color: 'text.secondary', fontSize: '8px' }}>
                Round Up Amount
              </Typography>
            </BalanceTextContainer>
          </BalanceItem>
        </BalanceInfoContainer>
      );
    }
    return null;
  };

  const cardHeight = isInYourTools ? 250 : 200;

  return (
    <StyledCard cardHeight={cardHeight}>
      <IconBox>
        <Typography variant="h5" mt={0} mr={1}>
          {tool.name}
        </Typography>
        <tool.icon style={{ width: 40, height: 40 }} />
      </IconBox>
      <Typography variant="body5" mt={1}>
        {tool.description}
      </Typography>

      {renderProgressSection()}

      {renderAdditionalContent()}

      <StyledButton
        variant="contained"
        color="corePurple70"
        onClick={handleButtonClick}
        endIcon={<ArrowForwardIcon />}>
        {buttonLabel}
      </StyledButton>
    </StyledCard>
  );
};

export default ToolCard;
