import React, { useState, useContext } from 'react';
import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../../styles/GlobalStyle';
import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';

import {
  ADD_HOUSEHOLD_SIZE_DESCRIPTION,
  ADD_HOUSEHOLD_SIZE_SELECT,
  ADD_HOUSEHOLD_SIZE_TITLE,
} from 'capio-common/src/constants';
import { sendAmplitudeData } from '../../../utilities/amplitude';
import HouseholdGrid from '../../../components/HouseholdGrid';
import { Context as UserContext } from '../../../context/UserContext';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  description: {
    fontFamily: 'OpenSans',
    marginBottom: '16px',
    marginTop: '30px',
  },
}));

const buttonSx = { 
  fontSize: '16px',
  width: '100%',
  backgroundColor: '#4A26D6',
  color: '#FFFFFF',
  padding: '10px 20px',
  textTransform: 'none',
  borderRadius: '54px',
  margin: '20px 0px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: '92px'
 };

export default function AddHouseholdSize({
  updateStep,
  setHouseholdSize,
  householdSizeEdit = false,
  setHouseholdSizeEdit,
  income
}) {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const { state: userState } = useContext(UserContext);

  const handleOnClick = (item) => {
    setHouseholdSize(item);
    setDisabled(false);
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title={ADD_HOUSEHOLD_SIZE_TITLE}
        description={ADD_HOUSEHOLD_SIZE_DESCRIPTION}
      />
      <Typography className={classes.description}>
        {ADD_HOUSEHOLD_SIZE_SELECT}
      </Typography>

      <HouseholdGrid handleOnClick={handleOnClick} />
      <CButton
        sx={{...buttonSx}}
        variant="contained"
        fullWidth
        loading={userState.loading}
        disabled={disabled}
        onClick={() => {
          sendAmplitudeData('CalcSetup_AddHouseholdSize');
          if(householdSizeEdit) {
            updateStep(3);
          } else {
            income?.incomeType == 'plaid' ? updateStep('ProvideOtherIncome') : updateStep(4);
          }
          setHouseholdSizeEdit(false);
        }}>
        Continue
      </CButton>
    </Container>
  );
}
