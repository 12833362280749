import React from 'react';
import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import GlobalStyle from './../../styles/GlobalStyle';
import TitleText from '../../components/common/TitleText';
import {
  ADDITIONAL_INFO_DESCRIPTION_CAPIO,
  ADDITIONAL_INFO_DESCRIPTION_MANUAL,
  ADDITIONAL_INFO_TITLE,
  CUSTOMER_SERVICE_EMAIL,
} from 'capio-common/src/constants';
import { openEmail } from 'capio-common/src/main';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 35,
    marginBottom: 35,
    position: 'relative',
  },
}));

export default function AdditionalInfo({ capioAccountName }) {
  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText title={ADDITIONAL_INFO_TITLE} />
      {capioAccountName ? (
        <Typography>
          {`${capioAccountName} has partnered with BuoyFi to provide you with guidelines that are generally acceptable to ${capioAccountName}. `}
          {ADDITIONAL_INFO_DESCRIPTION_CAPIO}{' '}
          <a
            target={'_blank'}
            className={classes.link}
            onClick={() => openEmail(CUSTOMER_SERVICE_EMAIL)}>
            {CUSTOMER_SERVICE_EMAIL}.
          </a>
        </Typography>
      ) : (
        <Typography>
          {ADDITIONAL_INFO_DESCRIPTION_MANUAL}{' '}
          <a
            target={'_blank'}
            className={classes.link}
            onClick={() => openEmail(CUSTOMER_SERVICE_EMAIL)}>
            {CUSTOMER_SERVICE_EMAIL}.
          </a>
        </Typography>
      )}
    </Container>
  );
}
