import React, { useContext, useMemo } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

import { Context as UserContext } from '../../../../context/UserContext';
import { Context as SynapseContext } from '../../../../context/SynapseContext';
import { Context as PaymentContext } from '../../../../context/PaymentContext';
import TitleText from '../../../../components/common/TitleText';
import BackButton from '../../../../components/BackButton';
import GlobalStyle from '../../../../styles/GlobalStyle';
import CButton from '../../../../components/common/CButton';
import ReviewItem from '../../../../components/ReviewItem';

import { currencyFormatter } from 'capio-common/src/patterns';
import {
  REVIEW_TRANSACTION_TITLE,
  REVIEW_TRANSACTION_DESCRIPTION,
} from 'capio-common/src/constants';
import moment from 'moment';
import CLoading from '../../../../components/common/CLoading';

import { sendAmplitudeData } from '../../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function ReviewTransaction(props) {
  const { data, updateStep, setDepositSuccess } = props;
  const { makeDeposit, state } = useContext(SynapseContext);
  const { state: userState } = useContext(UserContext);
  const { state: paymentState } = useContext(PaymentContext);

  const classes = useStyles();

  const linkedBanks = useMemo(() => {
    return paymentState.allLinkedBanks.filter((v) => v.plaidType === 'billpay');
  }, [paymentState.allLinkedBanks]);

  const handleNext = async () => {
    // call API to make a transaction
    sendAmplitudeData('Savings_DepositFunds_Step2', {click_name: 'Confirm'});
    const res = await makeDeposit({
      jwt: userState.jwt,
      amount: +data.amount,
      scheduledAt: moment(data.date).format('YYYY-MM-DD'),
    });
    if (res) {
      updateStep(1);
      setDepositSuccess(true);
    } else {
      updateStep(1);
      setDepositSuccess(false);
      console.error('Failed to make a transaction.');
    }
  };

  const handleBack = () => {
    sendAmplitudeData('Savings_DepositFunds_Step2', {click_name: 'Back'});
    updateStep(-1);
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      {state.loading && <CLoading />}
      <Grid item>
        <BackButton
          className={classes.backButton}
          onClick={handleBack}
        />
      </Grid>
      <TitleText
        title={REVIEW_TRANSACTION_TITLE}
        description={REVIEW_TRANSACTION_DESCRIPTION}
      />
      <ReviewItem
        label="From"
        description={`${linkedBanks[0]?.account?.name} *${linkedBanks[0]?.account?.mask}`}
      />
      <ReviewItem label="To" description="BuoyFi Savings" />
      <ReviewItem label="Amount" description={currencyFormatter(data.amount)} />
      <ReviewItem label="Date Scheduled" description={data.date} />
      <Typography mt={3}>
        The daily cutoff for canceling a pending ACH transaction is 11am PST for
        same-day ACH transactions, and 3pm PST for regular ACH transactions.
      </Typography>
      <Typography mt={3}>
        ACH transfers scheduled on a non-business day will be processed on the
        next business day.
      </Typography>
      <Grid item mt={4}>
        <CButton
          variant="contained"
          fullWidth
          onClick={handleNext}
          disabled={state.loading}>
          Confirm
        </CButton>
      </Grid>
    </Container>
  );
}

export default ReviewTransaction;
