import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import CButton from './common/CButton';
import TitleText from './common/TitleText';

const DATES = Array.from({ length: 31 }, (_, i) => i + 1);

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 54,
    height: 54,
    borderRadius: 27,
    marginLeft: 5,
    marginRight: 5,
  },
}));

function CustomDatePicker(props) {
  const classes = useStyles();
  const { onClickDone } = props;
  const [selectedDate, setSelectedDate] = useState(null);

  return (
    <Grid>
      <TitleText
        title="Select a Custom Date"
        description="On months that don’t have 31 days, we will transfer on the last day of the month"
      />
      <Grid item>
        {DATES.map((date) => (
          <Button
            key={date}
            variant={date === selectedDate ? 'contained' : 'text'}
            className={classes.button}
            onClick={() => setSelectedDate(date)}>
            {date}
          </Button>
        ))}
      </Grid>
      <Grid item mt={4}>
        <CButton
          variant="contained"
          disabled={!selectedDate}
          fullWidth
          onClick={() => onClickDone(selectedDate)}>
          Done
        </CButton>
      </Grid>
    </Grid>
  );
}

CustomDatePicker.propTypes = {
  onClickDone: PropTypes.func,
};

export default CustomDatePicker;
