import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Drawer,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate } from 'react-router-dom';

import CButton from '../../../components/common/CButton';
import BackButton from '../../../components/BackButton';
import GlobalStyle from '../../../styles/GlobalStyle';
import FilterMenu from './FilterMenu';
import { Context as UserContext } from '../../../context/UserContext';
import { Context as PaymentContext } from '../../../context/PaymentContext';
import { SET_TRANSACTION_FILTER } from '../../../context/types';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

const data = [
  { type: 'Matching', description: 'From Capio', price: '+$0.52' },
  { type: 'Payment', description: 'From Save-up', price: '+$0.82' },
  { type: 'Payment', description: 'From Checking', price: '+$50.00' },
];

export default function DebtAccount() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { state: userState } = useContext(UserContext);
  const {
    state: paymentState,
    dispatch,
    getTransactionHistory,
  } = useContext(PaymentContext);
  const [fields, setFields] = useState({
    filterGroup: paymentState.debtTransactionFilter,
  });

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: 10,
      sortDirection: 'desc',
      sortField: 'msCreatedAt',
    };
    getTransactionHistory(userState.jwt, params, userState.userSub);
  }, []);

  const handleRadioChange = ({ target }) => {
    setFields({ ...fields, [target.name]: target.value });
  };

  const PaymentListItem = ({ item }) => {
    const { type, description, price } = item;
    return (
      <ListItem
        sx={{
          borderBottom: 1,
          borderColor: '#C0B9C7',
        }}>
        <Grid container spacing={2} py={1}>
          <Grid container item xs={6} md={8}>
            <Grid item xs={12} md={3}>
              <ListItemText
                primary={type}
                primaryTypographyProps={{ color: 'corePurple60.main' }}
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <ListItemText
                primary={description}
                primaryTypographyProps={{ color: 'corePurple30.main' }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={6} md={4} sx={{ textAlign: 'right' }}>
            <Grid item xs={12}>
              <ListItemText
                primary={price}
                primaryTypographyProps={{ color: 'coreGreen60.main' }}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setMenuOpen(open);

    if (fields.filterGroup) {
      dispatch({
        type: SET_TRANSACTION_FILTER,
        payload: { debtTransactionFilter: fields.filterGroup },
      });
    }
  };

  const renderFilterGroup = () => {
    return (
      <RadioGroup
        defaultValue={paymentState.debtTransactionFilter}
        value={fields.filterGroup}
        onChange={handleRadioChange}
        name="filterGroup">
        <FormControlLabel
          value="matching"
          control={<Radio />}
          label="Matching"
        />
        <FormControlLabel
          value="transfer"
          control={<Radio />}
          label="Payment Transfer"
        />
        <FormControlLabel value="payment" control={<Radio />} label="Payment" />
      </RadioGroup>
    );
  };

  return (
    <Container maxWidth={false} disableGutters className={classes.container}>
      <Box
        sx={{
          width: '100%',
          paddingTop: 1,
          paddingBottom: 3,
          backgroundColor: 'purple50.main',
        }}>
        <Grid container display="flex" alignItems="center" direction="column">
          <Grid className={classes.headerContent}>
            <Grid item>
              <BackButton color="white" onClick={() => navigate(-1)} />
            </Grid>
          </Grid>
          <Typography variant="h1" color="white.main" mt={7}>
            $998.30
          </Typography>
          <Typography variant="body2" color="purple20.main" mt={1}>
            Current Balance
          </Typography>
          <CButton
            variant="contained"
            className={classes.buttonStyle2}
            sx={{ marginTop: 3 }}
            onClick={() => navigate('../home/debt/payment')}>
            Make Payment
          </CButton>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: 'corePurple10.main',
          padding: 2,
          [theme.breakpoints.up('lg')]: {
            paddingLeft: 28,
            paddingRight: 28,
          },
        }}>
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          m={2}>
          <Typography color="corePurple60.main">Payment History</Typography>
          <IconButton onClick={toggleDrawer(true)}>
            <FilterListIcon color="primary" />
          </IconButton>
        </Grid>
        <List
          sx={{
            width: '100%',
            backgroundColor: 'white.main',
          }}>
          {data.map((item, index) => (
            <PaymentListItem item={item} key={index} />
          ))}
        </List>
        <Grid item display="flex" justifyContent="center" mt={2}>
          <CButton variant="text">Load more</CButton>
        </Grid>
      </Box>
      <Drawer anchor="right" open={menuOpen} onClose={toggleDrawer(false)}>
        <FilterMenu
          toggleDrawer={toggleDrawer}
          filterGroup={renderFilterGroup()}
        />
      </Drawer>
    </Container>
  );
}
