import React, { useState } from 'react';
import { Container, Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

import MakeDeposit from './MakeDeposit';
import ReviewTransaction from './ReviewTransaction';
import DepositResult from './DepositResult';
import GlobalStyle from '../../../../styles/GlobalStyle';

const steps = ['MakeDeposit', 'ReviewTransaction', 'DepositResult'];

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function Deposit() {
  const [step, setStep] = useState(steps[0]);
  const [data, setData] = useState({ date: '', amount: '' });
  const [depositSuccess, setDepositSuccess] = useState(false);
  const classes = useStyles();

  const updateStep = (move) => {
    const newStep = steps[steps.indexOf(step) + move];
    setStep(newStep);
  };

  const updateData = (date, amount) => {
    setData({ date, amount });
  };

  let activeComponent = '';
  switch (step) {
    case 'MakeDeposit':
      activeComponent = (
        <MakeDeposit updateStep={updateStep} updateData={updateData} />
      );
      break;
    case 'ReviewTransaction':
      activeComponent = (
        <ReviewTransaction
          data={data}
          updateStep={updateStep}
          setDepositSuccess={setDepositSuccess}
        />
      );
      break;
    case 'DepositResult':
      activeComponent = (
        <DepositResult
          data={data}
          updateStep={updateStep}
          depositSuccess={depositSuccess}
        />
      );
      break;
  }

  return (
    <Container maxWidth="sm" className={classes.mainContent}>
      <Card sx={{ width: '100%' }}>
        <CardContent>{activeComponent}</CardContent>
      </Card>
    </Container>
  );
}

export default Deposit;
