import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';

import GlobalStyle from '../../styles/GlobalStyle';
import CButton from '../../components/common/CButton';
import Bill from '../../assets/images/bill.svg';
import Compass from '../../assets/images/compass.svg';
import CloseButton from '../../components/CloseButton';
import { Context as UserContext } from '../../context/UserContext';
import { USER_SETUP, VERIFY_TOS } from '../../context/types';
import {
  CALCULATE_DESCRIPTION,
  CHOOSE_TOOL_DESCRIPTION,
  CHOOSE_TOOL_TITLE,
  CALCULATE_TOOL_DESCRIPTION,
  SP_NOT_AVAILABLE_BODY,
  SP_NOT_AVAILABLE_TITLE,
} from 'capio-common/src/constants';
import { sendAmplitudeData } from '../../utilities/amplitude';
import PopupModal from '../../components/common/PopupModal';
import TermsModalContent from '../../components/common/TermsModalContent';
import TOS from '../kyc/TOS';
import SPModalContent from '../../components/common/SPModalContent';

const useStyles = makeStyles({
  ...GlobalStyle,
  headerContent: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
  },
  toolCard: {
    position: 'relative',
    padding: '25px',
    paddingBottom: '60px',
    height: '100%',
  },
  toolCardHeader: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  avtarImage: {
    marginRight: '15px',
  },
  setUpButton: {
    position: 'absolute',
    bottom: 25,
    left: 25,
    width: 'calc(100% - 50px)',
  },
});

function ChooseTool() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [isSPModalVisible, setIsSPModalVisible] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [termsUrl, setTermsUrl] = useState();

  const {
    dispatch,
    state: userState,
    compareTOSVersion,
    agreeTOSVersion,
  } = useContext(UserContext);

  useEffect(() => {
    if (userState.jwt) {
      dispatch({
        type: USER_SETUP,
        payload: { showSideMenu: true },
      });
    }
  }, [userState.jwt]);

  useEffect(() => {
    window.setZendeskUser(userState.id);
  }, [userState.id]);

  useEffect(() => {
    const checkTOS = async () => {
      if (!userState.jwt) return;
      const res = await compareTOSVersion(userState.jwt);
      if (res && res['data']) {
        if (res['data']['verified']) {
          setIsVisible(false);
        } else {
          setTermsUrl(res['data'?.tosURL]);
          setIsVisible(true);
        }
      }
    };
    checkTOS();
  }, [userState.jwt]);

  const termsHandler = async () => {
    setIsVisible(false);
    const res = await agreeTOSVersion(userState.jwt);
    console.log(res);
  };

  const termsAgreed = async () => {
    setIsVisible(false);
    const res = await agreeTOSVersion(userState.jwt);
    console.log(res);
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 680,
        md: 1000,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return showTerms ? (
    <TOS termsUrl={termsUrl} terms={1} closeTerms={() => setShowTerms(false)} />
  ) : (
    <Box>
      <Grid sx={{ backgroundColor: 'purple50.main' }} padding={3}>
        <Grid className={classes.headerContent}>
          <Typography variant="h5" color="white.main">
            {`Hi, ${userState.firstName}`}
          </Typography>
          <Box style={{ marginBottom: 70, marginTop: 70 }}>
            <Typography variant="h5" color="white.main" mt={3} mb={2}>
              {CHOOSE_TOOL_TITLE}
            </Typography>
            <Typography variant="body1" color="white.main" mb={3}>
              {CHOOSE_TOOL_DESCRIPTION}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Container>
        <Grid
          container
          sx={{
            position: 'relative',
            marginTop: '-80px',
          }}
          spacing={4}>
          <Grid item xs={12} md={6}>
            <Card className={classes.toolCard}>
              <div className={classes.toolCardHeader}>
                <img
                  className={classes.avtarImage}
                  src={Compass}
                  alt="compass"
                />
                <div>
                  <Typography variant="body4">Calculate a Plan</Typography>
                  <Typography variant="body1">
                    {CALCULATE_DESCRIPTION}
                  </Typography>
                </div>
              </div>
              <CButton
                sx={{ backgroundColor: 'corePurple70.main' }}
                className={classes.setUpButton}
                variant="contained"
                fullWidth
                onClick={() => {
                  sendAmplitudeData('ToolSelect_Calculator');
                  navigate('/choose-tool/compass');
                }}>
                Set up Calculator
              </CButton>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.toolCard}>
              <div className={classes.toolCardHeader}>
                <img className={classes.avtarImage} src={Bill} alt="bill" />
                <div>
                  <Typography variant="body4">Simplify Savings</Typography>
                  <Typography variant="body1">
                    Round up the change from each purchase and make saving for
                    future medical bills easy.
                  </Typography>
                </div>
              </div>
              <CButton
                sx={{ backgroundColor: 'corePurple70.main' }}
                className={classes.setUpButton}
                variant="contained"
                fullWidth
                onClick={() => {
                  sendAmplitudeData('ToolSelect_Savings');
                  // navigate('/choose-tool/bill-pay');
                  setIsSPModalVisible(true);
                }}>
                Set up Savings & Payments
              </CButton>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Box
        position={isMobile ? 'relative' : 'absolute'}
        bottom={0}
        paddingX={4}
        my={isMobile ? 4 : 0}>
        <Typography variant="body2">{CALCULATE_TOOL_DESCRIPTION}</Typography>
      </Box>
      <PopupModal open={isVisible} styles={{ height: 350 }}>
        <TermsModalContent onClickTerms={() => setShowTerms(true)} />
        <CButton
          sx={{ marginTop: 2 }}
          variant="contained"
          fullWidth
          onClick={() => {
            termsHandler();
          }}>
          Continue
        </CButton>
      </PopupModal>
      <PopupModal open={isSPModalVisible} styles={{ height: 250, width: 350 }}>
        <CloseButton onClick={() => setIsSPModalVisible(false)} />
        <SPModalContent />
      </PopupModal>
    </Box>
  );
}

export default ChooseTool;
