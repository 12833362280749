import React from 'react';
import { makeStyles } from '@mui/styles';

import {
  FUNDING_ACC_DESCRIPTION,
  SPENDING_ACC_DESCRIPTION,
} from 'capio-common/src/constants';
import CloseButton from '../../../../components/CloseButton';
import TitleText from '../../../../components/common/TitleText';

const useStyles = makeStyles(() => ({
  closeButton: {
    position: 'absolute',
    top: '75px',
    right: '25px',
  },
}));

function AccountTypesInfo({ updateStep }) {
  const classes = useStyles();

  return (
    <div>
      <CloseButton
        className={classes.closeButton}
        onClick={() => updateStep(-1)}
      />
      <TitleText
        title="What is a funding account?"
        description={FUNDING_ACC_DESCRIPTION}
      />
      <TitleText
        title="What is a spending account?"
        description={SPENDING_ACC_DESCRIPTION}
      />
    </div>
  );
}

export default AccountTypesInfo;
