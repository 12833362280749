import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TitleText from '../../../components/common/TitleText';
import CButton from '../../../components/common/CButton';
import GlobalStyle from '../../../styles/GlobalStyle';
import StepIcon from './StepIcon';
import { getAmplitudeEventName } from '../../../utilities/getAmplitudeEventName';
import { sendAmplitudeData } from '../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  stepsContainer: {
    padding: 20,
  },
  stepItem: {
    position: 'relative',
    padding: '0px 25px 20px',
  },
  progressBar: {
    position: 'absolute',
    width: 2,
    height: 'calc(100% - 8px)',
    zIndex: 1,
    left: 0,
    top: 14,
  },
  stepIcon: {
    position: 'absolute',
    left: -8,
    top: 4,
    zIndex: 9,
  },
  stepName: {
    display: 'block',
  },
}));

function VerifySteps(props) {
  const { updateStep, verifySteps, completedStep, activeStep } = props;
  const classes = useStyles();
  let label = `Step ${completedStep + 2}: ${
    verifySteps[completedStep + 1]['name']
  }`;
  const [amplitudeEventLabel, setAmplitudeEventLabel] = useState(
    getAmplitudeEventName(activeStep, label),
  );

  const onClickContinue = () => {
    if (amplitudeEventLabel) {
      sendAmplitudeData(amplitudeEventLabel);
    }
    updateStep(1);
  };

  const getStepStyle = (step, index) => {
    return {
      progressStyle: {
        display: index === verifySteps.length - 1 ? 'none' : 'block',
        backgroundColor: completedStep >= index ? '#51B748' : '#C0B9C7',
      },
      iconColor: completedStep >= index ? '#51B748' : '#C0B9C7',
    };
  };

  const renderStep = (step, index) => {
    const { progressStyle, iconColor } = getStepStyle(step, index);
    return (
      <Box key={index} className={classes.stepItem}>
        <Box style={progressStyle} className={classes.progressBar}></Box>
        <StepIcon fill={iconColor} className={classes.stepIcon} />
        <Typography variant="p" className={classes.stepName}>
          {step['name']}
        </Typography>
        {step['description'] && completedStep + 1 === index ? (
          <Typography variant="span">{step['description']}</Typography>
        ) : null}
      </Box>
    );
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title="Your information has been verified."
        description="What’s next:"
      />
      <Grid item className={classes.stepsContainer}>
        {verifySteps.map((step, index) => renderStep(step, index))}
      </Grid>
      <Grid item className={classes.buttonStyle1}>
        <CButton variant="contained" fullWidth onClick={onClickContinue}>
          {completedStep === verifySteps.length - 1 ? 'Done' : label}
        </CButton>
      </Grid>
    </Container>
  );
}

export default VerifySteps;
