import * as React from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles(() => ({
  picker: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

export default function CDatePicker(props) {
  const { error, helperText, onBlur } = props;
  const classes = useStyles();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack className={classes.picker}>
        <DatePicker
          {...props}
          inputFormat="MM/dd/yyyy"
          renderInput={(params) => (
            <TextField
              {...params}
              onBlur={onBlur}
              color="success"
              error={error}
              helperText={helperText}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
