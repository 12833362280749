import React, { useContext, useEffect } from 'react';
import { Grid, Container, Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import CTextField from '../../../../components/common/CTextField';
import CButton from '../../../../components/common/CButton';
import TitleText from '../../../../components/common/TitleText';
import { NAME_PATTERN } from 'capio-common/src/patterns';
import { USER_SETUP } from '../../../../context/types';
import { Context as UserContext } from '../../../../context/UserContext';
import {
  INVALID_FIRST_NAME,
  INVALID_LAST_NAME,
  MAX_LENGTH_50,
  REQUIRED_FIRST_NAME,
  REQUIRED_LAST_NAME,
} from 'capio-common/src/constants';
import GlobalStyle from '../../../../styles/GlobalStyle';
import BackButton from '../../../../components/BackButton';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function EditName(props) {
  const {
    state,
    dispatch,
    updateUserAttributes,
    updateUserInfo,
    updateBillGoUser,
  } = useContext(UserContext);
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
    },
  });

  const onSubmit = async (data) => {
    const { firstName, lastName } = data;
    await updateUserInfo({
      id: state.userSub,
      personalInfo: {
        ...state.personalInfo,
        legalFirstName: firstName,
        legalLastName: lastName,
      },
      searchContent: {
        ...state.searchContent,
        legalFirstName: firstName.toLowerCase(),
        legalLastName: lastName.toLowerCase(),
      },
    });
    const { status, message } = await updateUserAttributes({
      given_name: firstName,
      family_name: lastName,
    });

    await updateBillGoUser(state.jwt, {
      firstName,
      lastName,
    });

    if (status) {
      dispatch({
        type: USER_SETUP,
        payload: { firstName, lastName },
      });
      navigate(-1);
    }
  };

  useEffect(() => {
    const { firstName, lastName } = state;
    setValue('firstName', firstName, { shouldValidate: !!firstName });
    setValue('lastName', lastName, { shouldValidate: !!lastName });
  }, []);

  return (
    <div className={classes.mainContent}>
      <Container maxWidth="sm">
        <BackButton onClick={() => navigate(-1)} />
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <TitleText title="Edit Name" description="" />
            <CTextField
              name="firstName"
              control={control}
              rules={{
                required: { value: true, message: REQUIRED_FIRST_NAME },
                pattern: { value: NAME_PATTERN, message: INVALID_FIRST_NAME },
                maxLength: { value: 50, message: MAX_LENGTH_50 },
              }}
              fullWidth
              label="Legal First Name"
              error={!!errors.firstName}
              helperText={errors.firstName && errors.firstName.message}
            />
            <CTextField
              name="lastName"
              control={control}
              rules={{
                required: { value: true, message: REQUIRED_LAST_NAME },
                pattern: { value: NAME_PATTERN, message: INVALID_LAST_NAME },
                maxLength: { value: 50, message: MAX_LENGTH_50 },
              }}
              fullWidth
              label="Legal Last Name"
              error={!!errors.lastName}
              helperText={errors.lastName && errors.lastName.message}
            />
            <Grid item className={classes.buttonStyle1}>
              <CButton
                variant="contained"
                disabled={!isValid}
                fullWidth
                loading={state.loading}
                onClick={handleSubmit(onSubmit)}>
                Update Name
              </CButton>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}

export default EditName;
