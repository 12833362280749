import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../../styles/GlobalStyle';
import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import {
  DEBT_INFO_DESCRIPTIONP1,
  DEBT_INFO_DESCRIPTIONP2,
  DEBT_INFO_MAIN_DESCRIPTION,
  DEBT_INFO_TITLE,
} from 'capio-common/src/constants';
import { ReactComponent as Clipboard } from '../../../assets/images/clipboard.svg';
import { ReactComponent as Currency } from '../../../assets/images/currency-solid.svg';
import { openEmail } from 'capio-common/src/main';
import { currencyFormatter } from 'capio-common/src/patterns';
import { sendAmplitudeData } from '../../../utilities/amplitude';
import PrivacyPolicy from '../../profile/menu/PrivacyPolicy';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  title: {
    marginBottom: 24,
    fontFamily: 'RobotoSlab',
  },
  description: {
    fontFamily: 'OpenSans',
    marginBottom: '10px',
    marginTop: '10px',
  },
  description2: {
    marginTop: '20px',
    fontSize: '13px',
  },
  accountDetailTitle: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'flex-end',
    margin: '8px 0px',
    fontWeight: 600,
  },
  accountDetail: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    fontWeight: 600,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 48,
  },
  smallIconContainer: {
    paddingTop: 5,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const buttonSx = { 
  fontSize: '16px',
  width: '100%',
  backgroundColor: '#4A26D6',
  color: '#FFFFFF',
  padding: '10px 20px',
  textTransform: 'none',
  borderRadius: '54px',
  margin: '20px 0px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: 2
 };

export default function DebtInfo({
  capioAccount,
  updateStep,
  showPolicy,
  setShowPolicy,
}) {
  const classes = useStyles();

  const getDebtDescription = () => {
    return `Based on our records, you have ${capioAccount.length} bill account${capioAccount.length > 1 ? 's' : ''}:`
  }

  const RenderIcon = () => {
    return (
      <Box className={classes.iconContainer}>
        <Clipboard />
        <Box className={classes.smallIconContainer}>
          <Currency />
        </Box>
      </Box>
    );
  };

  if (showPolicy) {
    return <PrivacyPolicy />;
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title={DEBT_INFO_TITLE}
        icon={<RenderIcon />}
        description={getDebtDescription()}
      />
      <div className={classes.accountDetailTitle}>
        <Typography variant="body3" color="purple40.main">
          Amount
        </Typography>
      </div>
      {capioAccount &&
        capioAccount.map((account, index) => {
          return (
            <div key={index} className={classes.accountDetail}>
              <Typography variant="body3">
                {account['debtName']}{' '}
                <Typography variant="span" color="corePurple30.main">
                  - {account['capioAccountID']}
                </Typography>
              </Typography>
              <Typography variant="body3">
                {currencyFormatter(account['amount'])}
              </Typography>
            </div>
          );
        })}
      <Typography className={classes.description}>
        If the information listed is not what you expect, please{' '}
        <a
          target={'_blank'}
          className={classes.link}
          onClick={() => {
            sendAmplitudeData('CalcSetup_2_ContactUs');
            openEmail();
          }}>
          click here
        </a>{' '}
        to contact us.
      </Typography>
      <Typography className={classes.description}>
        {DEBT_INFO_DESCRIPTIONP1}
        <Link className={classes.link} onClick={() => setShowPolicy(true)}>
          Privacy Policy
        </Link>
      </Typography>
      <Typography className={classes.description2}>
        {DEBT_INFO_DESCRIPTIONP2}
      </Typography>
      <CButton
        sx={{...buttonSx}}
        variant="contained"
        fullWidth
        onClick={() => {
          sendAmplitudeData('CalcSetup_2_ConfirmDivinityDebt');
          updateStep(1);
        }}>
        Continue
      </CButton>
    </Container>
  );
}
