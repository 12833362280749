import React, { useContext } from 'react';
import { Container, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';

import GlobalStyle from '../../../../styles/GlobalStyle';
import TitleText from '../../../../components/common/TitleText';
import CTextField from '../../../../components/common/CTextField';
import CButton from '../../../../components/common/CButton';
import { Context as UserContext } from '../../../../context/UserContext';
import { USER_SETUP } from '../../../../context/types';
import { EMAIL_PATTERN } from 'capio-common/src/patterns';
import {
  INVALID_EMAIL_ADDRESS,
  REQUIRED_EMAIL,
} from 'capio-common/src/constants';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function EditEmailAddress(props) {
  const { updateStep } = props;
  const classes = useStyles();

  const { state, dispatch, updateUserAttributes } = useContext(UserContext);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data) => {
    const { email } = data;
    const { status } = await updateUserAttributes({
      email,
    });

    if (status) {
      dispatch({
        type: USER_SETUP,
        payload: {
          email,
          updateProfileFlow: true,
          verificationType: 'email',
        },
      });
      updateStep(1);
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText title="Edit Email Address" description="" />
      <Grid item>
        <CTextField
          fullWidth
          name="email"
          label="Email Address"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_EMAIL },
            pattern: { value: EMAIL_PATTERN, message: INVALID_EMAIL_ADDRESS },
          }}
          error={!!errors.email}
          helperText={errors.email && errors.email.message}
        />
      </Grid>
      <Grid item className={classes.buttonStyle1}>
        <CButton
          variant="contained"
          fullWidth
          disabled={!isValid}
          loading={state.loading}
          onClick={handleSubmit(onSubmit)}>
          Update Email Address
        </CButton>
      </Grid>
    </Container>
  );
}

export default EditEmailAddress;
