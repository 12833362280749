import React from 'react';
import { Button, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from './../../styles/GlobalStyle';
import ResultItem from '../../components/ResultItem';
import CheckIcon from '@mui/icons-material/Check';
import {
  SEND_ME_MAIL_DESCRIPTION,
  SEND_ME_MAIL_GUIDELINE,
  SEND_ME_MAIL_TITLE,
} from 'capio-common/src/constants';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 35,
    marginBottom: 35,
    position: 'relative',
    paddingTop: 70,
  },
  button: {
    padding: '8px 10px',
    borderRadius: 4,
    display: 'block',
    marginTop: 100,
    fontWeight: '600',
  },
}));

export default function SendMeMail({ setPage, isGuideLine, fromSetUp }) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm" className={classes.container}>
      <ResultItem
        title={SEND_ME_MAIL_TITLE}
        description={
          isGuideLine ? SEND_ME_MAIL_GUIDELINE : SEND_ME_MAIL_DESCRIPTION
        }
        variant="success"
        icon={<CheckIcon style={{ fontSize: 25, color: '#fff' }} />}
      />
      <Button
        variant={'contained'}
        className={classes.button}
        onClick={() => {
          if (fromSetUp) {
            navigate('/choose-tool');
          } else {
            setPage('CompassInfo');
          }
        }}>
        Done
      </Button>
    </Container>
  );
}
