import React, { useState, useContext } from 'react';
import { Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import CButton from '../../components/common/CButton';
import TitleText from '../../components/common/TitleText';
import { Context as PaymentContext } from '../../context/PaymentContext';
import { Context as UserContext } from '../../context/UserContext';
import { SET_ROUND_UP } from '../../context/types';
import { REQUIRED_AMOUNT } from 'capio-common/src/constants';
import CDatePicker from '../../components/common/CDatePicker';
import GlobalStyle from '../../styles/GlobalStyle';
import CPrefixInput from '../../components/common/CPrefixInput';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function RoundManual(props) {
  const { updateStep } = props;
  const { dispatch } = useContext(PaymentContext);
  const { state: userState, updateUserInfo } = useContext(UserContext);

  const classes = useStyles();
  const [date, setDate] = useState(new Date());

  const handleDateChange = (val) => {
    const date = moment(val).format('MM/DD/YYYY');
    setDate(date);
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: '',
    },
  });

  const onSubmit = (data) => {
    updateUserInfo({
      id: userState['userSub'],
      appStatus: {
        parent: 'Home',
        sub: 'Manually',
      },
    });
    dispatch({
      type: SET_ROUND_UP,
      payload: { amount: data.amount, manualDate: date },
    });
    updateStep(2);
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title="Build your BuoyFi Savings!"
        description="Making an initial deposit is a great way to start saving for future medical bills."
      />
      <Grid item mt={3}>
        <CPrefixInput
          inputId="amount"
          name="amount"
          label="Amount"
          prefix="$"
          control={control}
          rules={{
            required: true,
          }}
          error={!!errors.amount}
          helperText={errors.amount && REQUIRED_AMOUNT}
        />
      </Grid>
      <Grid item mt={1}>
        <CDatePicker
          name="date"
          label="Date"
          value={date}
          onChange={handleDateChange}
        />
      </Grid>
      <Grid item mt={4}>
        <CButton
          variant="contained"
          disabled={!isValid || !date}
          fullWidth
          onClick={handleSubmit(onSubmit)}>
          Make Deposit
        </CButton>
      </Grid>
      <Grid item mt={2}>
        <CButton
          variant="outlined"
          fullWidth
          onClick={() => {
            updateStep(2);
            updateUserInfo({
              id: userState['userSub'],
              appStatus: {
                parent: 'Home',
                sub: 'LinkBankAccount',
              },
            });
          }}>
          I’ll do this later
        </CButton>
      </Grid>
    </Container>
  );
}

export default RoundManual;
