import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useStep, { stepTwoStrings } from '../hooks/useStep';

const steps = ['Step 1:', 'Step 2:', 'Step 3:'];

export default function ProgressIndicator({ step }) {
  const { currentStep, caption } = useStep(step);

  const RenderConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 12px)',
      right: 'calc(50% + 12px)',
    },

    [!stepTwoStrings.includes(step) && `&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#51B748',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#51B748',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#C0B9C7',
      borderTopWidth: 2,
      borderRadius: 1,
    },
  }));

  function renderStepIcon(props) {
    const { completed } = props;

    return completed ? (
      <CheckCircleIcon color={'success'} fontSize="medium" />
    ) : (
      <CheckCircleIcon color={'disabled'} fontSize="medium" />
    );
  }

  return (
    <Box
      sx={{
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: 'auto',
      }}>
      <Stepper
        activeStep={currentStep}
        alternativeLabel
        connector={<RenderConnector />}>
        {steps.map((label, index) => {
          const stepProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel StepIconComponent={renderStepIcon}></StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        <Typography
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {steps[currentStep - 1]} {caption}
        </Typography>
      </React.Fragment>
    </Box>
  );
}
