/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateUserInformationById = /* GraphQL */ `
  subscription OnUpdateUserInformationById($id: ID!) {
    onUpdateUserInformationById(id: $id) {
      id
      userId
      email
      personalInfo {
        lastFourSSN
        legalFirstName
        legalLastName
        dateOfBirth
        mobileNumber
      }
      address {
        streetAddress
        city
        state
        postalCode
        country
      }
      appStatus {
        parent
        sub
      }
      failedAt
      failedCount
      policyStatus
      tosStatus
      capioToken
      capioAccounts {
        capioAccountID
        phoneNumber
        debtName
        amount
      }
      compassInfo {
        totalMonthlyIncome
        userIncome
        spousalIncome
        otherIncome
        totalDebt
        debtInfo {
          capioAccountID
          phoneNumber
          guidelines {
            payment {
              amount
              period
              forgiven
            }
            settlement {
              amount
              period
              forgiven
            }
          }
          debt {
            debtName
            debtKey
            amount
          }
        }
        unemployed
        consenttoshare
        guidelinePresented
        married
        contact
        incomeType
        roundedTotalPaymentPercent
        roundedTotalSettlementPercent
      }
      paymentSetupFlags {
        compass
        billpay
      }
      householdSize
      billGoUserId
      incomeInformation {
        payFrequency
        maritalStatus
        grossEarnings
        startDate
        endDate
        employerName
        userMonthlyIncome
        spouseIncome
        otherIncome
        incomeSource
        verified
      }
      monthlyIncome
      annualHouseholdIncome
      synapseUser {
        id
        achNodeId
        custodyNodeId
        docIds {
          docId
          physicalFrontDocId
          physicalBackDocId
          virtualDocId
          socialDocId
        }
        docType
        ip
        fp
        verified
        pass
        photoFront
        photoBack
        isActive
        isTrueVerified
      }
      isDisabled
      notification {
        pushToken
        deviceType
        deviceMeta
        subscriptionID
        browser
      }
      notifySetting {
        emailNotification
        pushNotification
      }
      securitySetting {
        faceID
      }
      emailSearch
      lastLogged
      searchContent {
        legalFirstName
        legalLastName
        mobileNumber
        email
        lastLogged
        stateName
      }
      changeLog {
        web
        mobile
      }
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSynapseUserById = /* GraphQL */ `
  subscription OnUpdateSynapseUserById($id: ID!) {
    onUpdateSynapseUserById(id: $id) {
      id
      userId
      email
      personalInfo {
        lastFourSSN
        legalFirstName
        legalLastName
        dateOfBirth
        mobileNumber
      }
      address {
        streetAddress
        city
        state
        postalCode
        country
      }
      appStatus {
        parent
        sub
      }
      failedAt
      failedCount
      policyStatus
      tosStatus
      capioToken
      capioAccounts {
        capioAccountID
        phoneNumber
        debtName
        amount
      }
      compassInfo {
        totalMonthlyIncome
        userIncome
        spousalIncome
        otherIncome
        totalDebt
        debtInfo {
          capioAccountID
          phoneNumber
          guidelines {
            payment {
              amount
              period
              forgiven
            }
            settlement {
              amount
              period
              forgiven
            }
          }
          debt {
            debtName
            debtKey
            amount
          }
        }
        unemployed
        consenttoshare
        guidelinePresented
        married
        contact
        incomeType
        roundedTotalPaymentPercent
        roundedTotalSettlementPercent
      }
      paymentSetupFlags {
        compass
        billpay
      }
      householdSize
      billGoUserId
      incomeInformation {
        payFrequency
        maritalStatus
        grossEarnings
        startDate
        endDate
        employerName
        userMonthlyIncome
        spouseIncome
        otherIncome
        incomeSource
        verified
      }
      monthlyIncome
      annualHouseholdIncome
      synapseUser {
        id
        achNodeId
        custodyNodeId
        docIds {
          docId
          physicalFrontDocId
          physicalBackDocId
          virtualDocId
          socialDocId
        }
        docType
        ip
        fp
        verified
        pass
        photoFront
        photoBack
        isActive
        isTrueVerified
      }
      isDisabled
      notification {
        pushToken
        deviceType
        deviceMeta
        subscriptionID
        browser
      }
      notifySetting {
        emailNotification
        pushNotification
      }
      securitySetting {
        faceID
      }
      emailSearch
      lastLogged
      searchContent {
        legalFirstName
        legalLastName
        mobileNumber
        email
        lastLogged
        stateName
      }
      changeLog {
        web
        mobile
      }
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserInformation = /* GraphQL */ `
  subscription OnCreateUserInformation(
    $filter: ModelSubscriptionUserInformationFilterInput
  ) {
    onCreateUserInformation(filter: $filter) {
      id
      userId
      email
      personalInfo {
        lastFourSSN
        legalFirstName
        legalLastName
        dateOfBirth
        mobileNumber
      }
      address {
        streetAddress
        city
        state
        postalCode
        country
      }
      appStatus {
        parent
        sub
      }
      failedAt
      failedCount
      policyStatus
      tosStatus
      capioToken
      capioAccounts {
        capioAccountID
        phoneNumber
        debtName
        amount
      }
      compassInfo {
        totalMonthlyIncome
        userIncome
        spousalIncome
        otherIncome
        totalDebt
        debtInfo {
          capioAccountID
          phoneNumber
          guidelines {
            payment {
              amount
              period
              forgiven
            }
            settlement {
              amount
              period
              forgiven
            }
          }
          debt {
            debtName
            debtKey
            amount
          }
        }
        unemployed
        consenttoshare
        guidelinePresented
        married
        contact
        incomeType
        roundedTotalPaymentPercent
        roundedTotalSettlementPercent
      }
      paymentSetupFlags {
        compass
        billpay
      }
      householdSize
      billGoUserId
      incomeInformation {
        payFrequency
        maritalStatus
        grossEarnings
        startDate
        endDate
        employerName
        userMonthlyIncome
        spouseIncome
        otherIncome
        incomeSource
        verified
      }
      monthlyIncome
      annualHouseholdIncome
      synapseUser {
        id
        achNodeId
        custodyNodeId
        docIds {
          docId
          physicalFrontDocId
          physicalBackDocId
          virtualDocId
          socialDocId
        }
        docType
        ip
        fp
        verified
        pass
        photoFront
        photoBack
        isActive
        isTrueVerified
      }
      isDisabled
      notification {
        pushToken
        deviceType
        deviceMeta
        subscriptionID
        browser
      }
      notifySetting {
        emailNotification
        pushNotification
      }
      securitySetting {
        faceID
      }
      emailSearch
      lastLogged
      searchContent {
        legalFirstName
        legalLastName
        mobileNumber
        email
        lastLogged
        stateName
      }
      changeLog {
        web
        mobile
      }
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserInformation = /* GraphQL */ `
  subscription OnUpdateUserInformation(
    $filter: ModelSubscriptionUserInformationFilterInput
  ) {
    onUpdateUserInformation(filter: $filter) {
      id
      userId
      email
      personalInfo {
        lastFourSSN
        legalFirstName
        legalLastName
        dateOfBirth
        mobileNumber
      }
      address {
        streetAddress
        city
        state
        postalCode
        country
      }
      appStatus {
        parent
        sub
      }
      failedAt
      failedCount
      policyStatus
      tosStatus
      capioToken
      capioAccounts {
        capioAccountID
        phoneNumber
        debtName
        amount
      }
      compassInfo {
        totalMonthlyIncome
        userIncome
        spousalIncome
        otherIncome
        totalDebt
        debtInfo {
          capioAccountID
          phoneNumber
          guidelines {
            payment {
              amount
              period
              forgiven
            }
            settlement {
              amount
              period
              forgiven
            }
          }
          debt {
            debtName
            debtKey
            amount
          }
        }
        unemployed
        consenttoshare
        guidelinePresented
        married
        contact
        incomeType
        roundedTotalPaymentPercent
        roundedTotalSettlementPercent
      }
      paymentSetupFlags {
        compass
        billpay
      }
      householdSize
      billGoUserId
      incomeInformation {
        payFrequency
        maritalStatus
        grossEarnings
        startDate
        endDate
        employerName
        userMonthlyIncome
        spouseIncome
        otherIncome
        incomeSource
        verified
      }
      monthlyIncome
      annualHouseholdIncome
      synapseUser {
        id
        achNodeId
        custodyNodeId
        docIds {
          docId
          physicalFrontDocId
          physicalBackDocId
          virtualDocId
          socialDocId
        }
        docType
        ip
        fp
        verified
        pass
        photoFront
        photoBack
        isActive
        isTrueVerified
      }
      isDisabled
      notification {
        pushToken
        deviceType
        deviceMeta
        subscriptionID
        browser
      }
      notifySetting {
        emailNotification
        pushNotification
      }
      securitySetting {
        faceID
      }
      emailSearch
      lastLogged
      searchContent {
        legalFirstName
        legalLastName
        mobileNumber
        email
        lastLogged
        stateName
      }
      changeLog {
        web
        mobile
      }
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotificationTemplates = /* GraphQL */ `
  subscription OnCreateNotificationTemplates(
    $filter: ModelSubscriptionNotificationTemplatesFilterInput
  ) {
    onCreateNotificationTemplates(filter: $filter) {
      id
      templateId
      title
      subTitle
      appMessage
      pushMessage
      imageURL
      notificationType
      resourceType
      appUrl
      webUrl
      status
      requiredFields
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotificationTemplates = /* GraphQL */ `
  subscription OnUpdateNotificationTemplates(
    $filter: ModelSubscriptionNotificationTemplatesFilterInput
  ) {
    onUpdateNotificationTemplates(filter: $filter) {
      id
      templateId
      title
      subTitle
      appMessage
      pushMessage
      imageURL
      notificationType
      resourceType
      appUrl
      webUrl
      status
      requiredFields
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotificationTemplates = /* GraphQL */ `
  subscription OnDeleteNotificationTemplates(
    $filter: ModelSubscriptionNotificationTemplatesFilterInput
  ) {
    onDeleteNotificationTemplates(filter: $filter) {
      id
      templateId
      title
      subTitle
      appMessage
      pushMessage
      imageURL
      notificationType
      resourceType
      appUrl
      webUrl
      status
      requiredFields
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotificationSegments = /* GraphQL */ `
  subscription OnCreateNotificationSegments(
    $filter: ModelSubscriptionNotificationSegmentsFilterInput
  ) {
    onCreateNotificationSegments(filter: $filter) {
      id
      segmentId
      segmentName
      status
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotificationSegments = /* GraphQL */ `
  subscription OnUpdateNotificationSegments(
    $filter: ModelSubscriptionNotificationSegmentsFilterInput
  ) {
    onUpdateNotificationSegments(filter: $filter) {
      id
      segmentId
      segmentName
      status
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotificationSegments = /* GraphQL */ `
  subscription OnDeleteNotificationSegments(
    $filter: ModelSubscriptionNotificationSegmentsFilterInput
  ) {
    onDeleteNotificationSegments(filter: $filter) {
      id
      segmentId
      segmentName
      status
      dataTags {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserNotifications = /* GraphQL */ `
  subscription OnCreateUserNotifications(
    $filter: ModelSubscriptionUserNotificationsFilterInput
  ) {
    onCreateUserNotifications(filter: $filter) {
      id
      userId
      notificationId
      title
      appIconUrl
      appMessage
      pushMessage
      appUrl
      webUrl
      createdAt
      updatedAt
      showOnFrontend
      isDelayed
      isRead
      emailSubject
      emailBody
      status
      notificationType
      itemId
    }
  }
`;
export const onUpdateUserNotifications = /* GraphQL */ `
  subscription OnUpdateUserNotifications(
    $filter: ModelSubscriptionUserNotificationsFilterInput
  ) {
    onUpdateUserNotifications(filter: $filter) {
      id
      userId
      notificationId
      title
      appIconUrl
      appMessage
      pushMessage
      appUrl
      webUrl
      createdAt
      updatedAt
      showOnFrontend
      isDelayed
      isRead
      emailSubject
      emailBody
      status
      notificationType
      itemId
    }
  }
`;
export const onDeleteUserNotifications = /* GraphQL */ `
  subscription OnDeleteUserNotifications(
    $filter: ModelSubscriptionUserNotificationsFilterInput
  ) {
    onDeleteUserNotifications(filter: $filter) {
      id
      userId
      notificationId
      title
      appIconUrl
      appMessage
      pushMessage
      appUrl
      webUrl
      createdAt
      updatedAt
      showOnFrontend
      isDelayed
      isRead
      emailSubject
      emailBody
      status
      notificationType
      itemId
    }
  }
`;
