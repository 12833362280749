import React, { useContext, useState, useEffect } from 'react';
import { Grid } from '@mui/material';

import Spinner from '../../assets/images/spinner.gif';

const CLoading = ({ children }) => {
  return (
    <Grid
      item
      alignItems="center"
      justifyContent="center"
      display="flex"
      flex={1}
      flexDirection="column"
      position="absolute"
      left={0}
      right={0}
      bottom={0}
      top={0}
      zIndex={9999}
      sx={{ backgroundColor: '#ffffff' }}>
      <img src={Spinner} width={48} height={48} alt="loading..." />
      {children}
    </Grid>
  );
};

export default CLoading;
