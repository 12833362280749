import React, { useState } from 'react';
import {
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../../styles/GlobalStyle';
import {
  INVALID_AMOUNT,
  INVALID_NAME,
  MAX_LENGTH_50,
  REQUIRED_AMOUNT,
  REQUIRED_NAME,
  PROVIDE_MONTHLY_INCOME_TITLE,
} from 'capio-common/src/constants';
import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import CPrefixInput from '../../../components/common/CPrefixInput';
import { AMOUNT_PATTERN, DEBT_NAME_PATTERN } from 'capio-common/src/patterns';
import { useForm } from 'react-hook-form';
import CTextField from '../../../components/common/CTextField';
import { sendAmplitudeData } from '../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  description: {
    fontFamily: 'OpenSans',
    marginBottom: '10px',
  },
  inputWrapper: {
    marginBottom: '10px',
    marginTop: '10px',
  },
}));

const buttonSx = { 
  fontSize: '16px',
  width: '100%',
  backgroundColor: '#4A26D6',
  color: '#FFFFFF',
  padding: '10px 20px',
  textTransform: 'none',
  borderRadius: '54px',
  margin: '20px 0px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: 2
 };

export default function ProvideIncomeManually({
  manualIncomeParentName,
  updateStep,
  income,
  setIncome,
}) {
  const [option, setOption] = useState();
  const classes = useStyles();

  const handleRadioChange = ({ target }) => {
    reset({ employerName: '', businessName: '', amount: '' });
    setOption(target.value);
  };

  // const handleMarriedRadioChange = ({ target }) => {
  //   setIncome({
  //     ...income,
  //     monthlyIncome: {
  //       ...income['monthlyIncome'],
  //       isMarried: target.value === 'true' ? true : false,
  //     },
  //   });
  // };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: income['monthlyIncome'],
  });

  const onSubmit = (data) => {
    sendAmplitudeData('Provide_Income_Info_Manual');
    setIncome({
      ...income,
      monthlyIncome: { ...income['monthlyIncome'], ...data },
      incomeType: 'manual',
    });
    updateStep(-2);
  };

  const getActiveInput = () => {
    if (option === 'Employed') {
      return (
        <Grid className={classes.inputWrapper} item>
          <CTextField
            placeholder="Name of Employer"
            name={`employerName`}
            control={control}
            rules={{
              required: {
                value: true,
                message: REQUIRED_NAME,
              },
              pattern: { value: DEBT_NAME_PATTERN, message: INVALID_NAME },
              maxLength: { value: 50, message: MAX_LENGTH_50 },
            }}
            fullWidth
            error={!!errors.employerName}
            helperText={errors.employerName && errors.employerName.message}
          />
        </Grid>
      );
    } else if (option === 'SelfEmployed') {
      return (
        <Grid className={classes.inputWrapper} item>
          <CTextField
            placeholder="Name of Business (optional)"
            name={`businessName`}
            control={control}
            rules={{
              // pattern: { value: DEBT_NAME_PATTERN, message: INVALID_NAME },
              maxLength: { value: 50, message: MAX_LENGTH_50 },
            }}
            fullWidth
            error={!!errors.businessName}
            helperText={errors.businessName && errors.businessName.message}
          />
        </Grid>
      );
    }
    return null;
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText title={PROVIDE_MONTHLY_INCOME_TITLE} />
      <Typography className={classes.description}>You are:</Typography>
      <RadioGroup
        value={option}
        onChange={handleRadioChange}
        name="filterGroup">
        <FormControlLabel
          value="Employed"
          control={<Radio />}
          label="employed"
        />
        <FormControlLabel
          value="SelfEmployed"
          control={<Radio />}
          label="self-employed"
        />
        <FormControlLabel
          value="Unemployed"
          control={<Radio />}
          label="unemployed"
        />
      </RadioGroup>
      {option ? (
        <>
          {getActiveInput()}
          {option !== 'Unemployed' ? (
            <Grid className={classes.inputWrapper} item>
              <CPrefixInput
                placeholder="Monthly Income"
                inputId="amount"
                name="amount"
                label="Amount"
                prefix="$"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: REQUIRED_AMOUNT,
                  },
                  pattern: { value: AMOUNT_PATTERN, message: INVALID_AMOUNT },
                }}
                error={!!errors.amount}
                helperText={errors.amount && errors.amount.message}
              />
            </Grid>
          ) : null}
        </>
      ) : null}
      <CButton
        sx={{...buttonSx}}
        disabled={
          !option
            ? true
            : option === 'unemployed'
            ? false
            : !isValid || income['monthlyIncome']['isMarried'] === ''
        }
        variant="contained"
        fullWidth
        onClick={handleSubmit(onSubmit)}>
        Continue
      </CButton>
    </Container>
  );
}
