import React, { useState, useContext, useEffect } from 'react';
import { Grid, Container, Typography, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PasswordChecklist from 'react-password-checklist';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useForm } from 'react-hook-form';

import CTextField from '../../components/common/CTextField';
import CButton from '../../components/common/CButton';
import TitleText from '../../components/common/TitleText';
import CAlert from '../../components/common/CAlert';
import { Context as UserContext } from '../../context/UserContext';
import {
  INVALID_EMAIL_ADDRESS,
  PWD_MINIMUM_8_CHARS,
  PWD_ONE_NUMBER,
  PWD_ONE_SPECIAL_CHAR,
  PWD_UPPER_LOWER_LETTERS,
  PWD_WHITESPACE, 
  REQUIRED_EMAIL,
} from 'capio-common/src/constants';
import { USER_SETUP } from '../../context/types';
import { EMAIL_PATTERN } from 'capio-common/src/patterns';
import GlobalStyle from '../../styles/GlobalStyle';
import { sendAmplitudeData } from '../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  terms: {
    marginTop: 32,
    marginBottom: 16,
  },
  link: {
    cursor: 'pointer',
  },
}));

function CreateAccount(props) {
  const { updateStep } = props;
  const { state, dispatch, checkUser } = useContext(UserContext);
  const classes = useStyles();
  const [fields, setFields] = useState({
    password: '',
  });
  const [validPassword, setValidPassword] = useState(false);
  const [error, setError] = useState('');

  const handleInputChange = ({ target }) => {
    setFields({ ...fields, [target.name]: target.value });
  };

  const onSubmit = async (data) => {
    setError('');
    const { password } = fields;
    const { email } = data;
    const { status, message } = await checkUser(email);
    if (status) {
      dispatch({
        type: USER_SETUP,
        payload: { email, password },
      });
      window.localStorage.setItem('termsRead', JSON.stringify(true));
      updateStep(1);
      sendAmplitudeData('InitialKYC_1_CreateAccount');
    } else {
      setError(message);
    }
  };

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const saveUserData = () => {
    const email = getValues('email');
    dispatch({
      type: USER_SETUP,
      payload: { email },
    });
  };

  const onClickTerms = (terms) => {
    saveUserData();
    updateStep(6, terms);
  };

  useEffect(() => {
    const { email } = state;
    setValue('email', email, { shouldValidate: !!email });
  }, []);

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title="Create an account"
        description="Take your first step toward a healthier financial destination!"
      />
      <CAlert severity="error" message={error} />
      <Grid item>
        <CTextField
          fullWidth
          name="email"
          label="Email Address"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_EMAIL },
            pattern: { value: EMAIL_PATTERN, message: INVALID_EMAIL_ADDRESS },
          }}
          error={!!errors.email}
          helperText={errors.email && errors.email.message}
        />
      </Grid>
      <Grid item>
        <CTextField
          fullWidth
          type="password"
          name="password"
          label="Create Password"
          value={fields.password}
          onChange={handleInputChange}
        />
      </Grid>
      <PasswordChecklist
        rules={['minLength', 'capital', 'number', 'specialChar', 'noSpaces']}
        minLength={8}
        value={fields.password}
        messages={{
          minLength: PWD_MINIMUM_8_CHARS,
          specialChar: PWD_ONE_SPECIAL_CHAR,
          number: PWD_ONE_NUMBER,
          capital: PWD_UPPER_LOWER_LETTERS,
          noSpaces: PWD_WHITESPACE
        }}
        iconComponents={{
          ValidIcon: (
            <CheckCircleIcon
              color="success"
              fontSize="small"
              style={{ marginRight: 8 }}
            />
          ),
          InvalidIcon: (
            <CheckCircleIcon
              color="disabled"
              fontSize="small"
              style={{ marginRight: 8 }}
            />
          ),
        }}
        onChange={setValidPassword}
      />
      <Typography className={classes.terms}>
        By creating an account, I agree to the{' '}
        <Link
          color="secondary"
          className={classes.link}
          onClick={() => onClickTerms(1)}>
          Terms of Service
        </Link>
        ,{' '}
        <Link
          color="secondary"
          className={classes.link}
          onClick={() => onClickTerms(2)}>
          Privacy Policy
        </Link>
        and{' '}
        <Link
          color="secondary"
          className={classes.link}
          onClick={() => onClickTerms(3)}>
          Custodial Agreement
        </Link>
        .
      </Typography>
      <Grid item>
        <CButton
          variant="contained"
          disabled={!isValid || !validPassword}
          fullWidth
          loading={state.loading}
          onClick={handleSubmit(onSubmit)}>
          Create account
        </CButton>
      </Grid>
    </Container>
  );
}

export default CreateAccount;
