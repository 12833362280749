import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import TitleText from '../../../components/common/TitleText';
import { PRIVACY_URL } from 'capio-common/src/constants';
import GlobalStyle from '../../../styles/GlobalStyle';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    ...GlobalStyle.container,
    height: '65vh',
  },
}));

function PrivacyPolicy() {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <TitleText title="Privacy Policy" />
      <iframe
        src={PRIVACY_URL}
        frameBorder="0"
        scrolling="auto"
        height="100%"
        width="100%"></iframe>
    </Container>
  );
}

export default PrivacyPolicy;
