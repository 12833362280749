import React, { useState, useContext } from 'react';
import { Grid, Container, IconButton, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import CButton from '../../../../components/common/CButton';
import TitleText from '../../../../components/common/TitleText';
import { Context as UserContext } from '../../../../context/UserContext';
import { Context as PaymentContext } from '../../../../context/PaymentContext';
import { USER_SETUP } from '../../../../context/types';
import { INVALID_AMOUNT, REQUIRED_AMOUNT } from 'capio-common/src/constants';
import CDatePicker from '../../../../components/common/CDatePicker';

import GlobalStyle from '../../../../styles/GlobalStyle';
import CPrefixInput from '../../../../components/common/CPrefixInput';
import { validatePaymentDate } from 'capio-common/src/main';
import { AMOUNT_PATTERN } from 'capio-common/src/patterns';
import { getNextDepositDate } from 'capio-common/src/main';
import { sendAmplitudeData } from '../../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function MakeDeposit(props) {
  const { updateStep, updateData } = props;
  const { state, dispatch } = useContext(UserContext);
  const { state: paymentState } = useContext(PaymentContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [date, setDate] = useState('');
  const [dateError, setDateError] = useState('');

  const handleDateChange = (value) => {
    setDateError(validatePaymentDate(value));
    const date = moment(value).format('MM/DD/YYYY');
    setDate(date);
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: '',
    },
  });

  const onSubmit = (data) => {
    dispatch({
      type: USER_SETUP,
      payload: {},
    });
    sendAmplitudeData('Savings_DepositFunds_Step1', {click_name: 'Continue'});
    updateData(date, data.amount);
    updateStep(1);
  };

  const onExit = () => {
    sendAmplitudeData('Savings_DepositFunds_Step1', {click_name: 'Dismiss'});
    navigate(-1);
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Grid item display="flex" justifyContent="flex-end">
        <IconButton onClick={onExit}>
          <CloseIcon color="primary" />
        </IconButton>
      </Grid>
      <TitleText
        title="Build your BuoyFi Savings!"
        description="Enter the amount of your deposit below. We’ll withdraw it from your bank account in 1-2 business days."
      />
      <Grid item mt={3}>
        <CPrefixInput
          inputId="amount"
          name="amount"
          label="Amount"
          prefix="$"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_AMOUNT },
            pattern: { value: AMOUNT_PATTERN, message: INVALID_AMOUNT },
          }}
          error={!!errors.amount}
          helperText={errors.amount && errors.amount.message}
        />
      </Grid>
      <Grid item>
        <CDatePicker
          name="date"
          label="Date"
          error={!!dateError}
          helperText={dateError}
          rules={{
            required: { value: true },
          }}
          value={date}
          disablePast
          onChange={() => {}}
          onAccept={(event) => handleDateChange(event)}
          onBlur={({ target }) => handleDateChange(target.value)}
        />
      </Grid>
      {paymentState.roundUp.autoDeposit && (
        <Grid item>
          <Box
            sx={{
              backgroundColor: '#FFE066',
              textAlign: 'center',
              paddingX: 2,
              paddingY: 1,
              borderRadius: 1,
              marginTop: 1,
            }}>
            <Typography color="corePurple60.main">
              Your next auto deposit is scheduled for{' '}
              {getNextDepositDate(
                paymentState.roundUp.depositDate,
                paymentState.roundUp.frequency,
              )}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item mt={4}>
        <CButton
          variant="contained"
          disabled={!isValid || !!dateError || !date}
          fullWidth
          onClick={handleSubmit(onSubmit)}>
          Continue
        </CButton>
      </Grid>
    </Container>
  );
}

export default MakeDeposit;
