import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Container, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from './../../styles/GlobalStyle';
import TitleText from '../../components/common/TitleText';
import {
  CUSTOMER_SERVICE_EMAIL,
  SUPPORT_PHONE,
} from 'capio-common/src/constants';
import { Context as UserContext } from '../../context/UserContext';
import { Context as PDFContext } from '../../context/PDFContext';
import { GET_PDF } from '../../context/types';
import { currencyFormatter } from 'capio-common/src/patterns';
import { openEmail } from 'capio-common/src/main';
import DetailsBox from './DetailsBox';
import { sendAmplitudeData } from '../../utilities/amplitude';
import CLoading from '../../components/common/CLoading';
import CButton from '../../components/common/CButton';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 35,
    marginBottom: 35,
    position: 'relative',
  },
  description2: {
    marginTop: '20px',
    marginBottom: '70px',
  },
  emailText: {
    marginTop: '20px',
    marginBottom: '50px',
    fontWeight: '600',
  },
  button: {
    padding: '8px 10px',
    border: '2px solid  #B5B6DA',
    borderRadius: 4,
    color: '#494C93',
    display: 'block',
    marginTop: 20,
    fontWeight: '600',
  },
  grayBox: {
    padding: '12px 15px',
    backgroundColor: '#F3F1F5',
    borderRadius: 4,
    margin: '5px 0px',
  },
}));

export default function ManualLearnMore({
  onContact,
  accounts,
  accountIndex,
  isSettlement,
  setPage,
}) {
  const classes = useStyles();
  const { state: userState } = useContext(UserContext);
  const { dispatch, state: pdfState, getPDFLink } = useContext(PDFContext);
  const [autoOpenPDF, setAutoOpenPDF] = useState(true);

  const handleDownloadPDF = () => {
    dispatch(getPDFLink(userState.jwt));
  };

  useEffect(() => {
    sendAmplitudeData('Calculator_NegotiateYourBill_ManualDebt_View');
  }, []);

  useEffect(() => {
    if (pdfState.pdfLink) {
      if (autoOpenPDF) {
        window.open(pdfState.pdfLink, '_blank');
        setTimeout(() => {
          dispatch({
            type: GET_PDF,
            payload: null,
          });
        });
      } else {
        let emailUrl = `mailto:?subject=Buoyfi Guidelines&body=A PDF of my guidelines can be found at ${pdfState.pdfLink}`;
        window.open(emailUrl, '_blank');
      }
    }
  }, [pdfState.pdfLink]);

  const details = isSettlement
    ? `A one-time payment of ${currencyFormatter(
        accounts[accountIndex].guidelines?.settlement?.amount,
      )}`
    : `Affordable monthly payments of ${currencyFormatter(
        accounts[accountIndex].guidelines?.payment?.amount,
      )}`;

  const monthlyPayment = isSettlement
    ? currencyFormatter(accounts[accountIndex].guidelines?.settlement?.amount)
    : currencyFormatter(accounts[accountIndex].guidelines?.payment?.amount);

  const forgivenAmount = isSettlement
    ? currencyFormatter(accounts[accountIndex].guidelines?.settlement?.forgiven)
    : currencyFormatter(accounts[accountIndex].guidelines?.payment?.forgiven);

  const lengthOfPlan = isSettlement
    ? accounts[accountIndex].guidelines?.settlement?.period === -1
      ? 'one payment'
      : `${accounts[accountIndex].guidelines?.settlement?.period} Months`
    : accounts[accountIndex].guidelines?.payment?.period === -1
    ? 'one payment'
    : `${accounts[accountIndex].guidelines?.payment?.period} Months`;

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText title={'Negotiate your bill'} description={'You selected:'} />
      {userState.loading && <CLoading />}
      <DetailsBox
        optionTitle={isSettlement ? 'Option1' : 'Option2'}
        title={isSettlement ? 'Account Settlement' : 'Payment Plan'}
        monthlyPayment={monthlyPayment}
        lengthOfPlan={lengthOfPlan}
        forgivenAmount={forgivenAmount}
        hideSelect={true}
        customStyles={{ margin: 0 }}
      />
      <Typography variant="p" className={classes.description2}>
        Use this payment guideline as a tool to negotiate with your account
        servicer by calling them using the contact info on your statement.
      </Typography>

      <CButton
        variant="contained"
        fullWidth
        loading={pdfState.loading}
        sx={{ marginTop: 3 }}
        onClick={() => {
          sendAmplitudeData('Calculator_NegotiateYourBill_ManualDebt', {
            click_name: 'View PDF',
          });
          setAutoOpenPDF(true);
          handleDownloadPDF();
        }}>
        View in PDF
      </CButton>

      <Button
        className={classes.button}
        onClick={() => {
          sendAmplitudeData('Calculator_NegotiateYourBill_ManualDebt', {
            click_name: 'Email guidelines',
          });
          setAutoOpenPDF(false);
          handleDownloadPDF();
        }}>
        Share My Guidelines
      </Button>
    </Container>
  );
}
