import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { Context as PaymentContext } from '../../context/PaymentContext';
import { Context as UserContext } from '../../context/UserContext';
import CButton from '../../components/common/CButton';
import ResultItem from '../../components/ResultItem';
import GlobalStyle from '../../styles/GlobalStyle';
import { sendAmplitudeData } from '../../utilities/amplitude';
import CLoading from '../../components/common/CLoading';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function RoundResult(props) {
  const { updateStep } = props;
  const { state: paymentState, setUpRoundUp } = useContext(PaymentContext);
  const {
    state: userState,
    getUserInfo,
    updateUserInfo,
    checkAuthentication,
    updateUserTags,
  } = useContext(UserContext);
  const classes = useStyles();
  const navigate = useNavigate();

  const handleFinish = async () => {
    await updateUserInfo({
      id: userState.userSub,
      paymentSetupFlags: {
        ...userState.paymentSetupFlags,
        billpay: true,
      },
      appStatus: {
        parent: '',
        sub: '',
      },
    });

    await updateUserTags(userState.jwt, {
      tags: {
        Payment_Setup_Complete: true,
      },
      userId: userState.userSub,
    });

    if (paymentState.roundUp.depositMode) {
      await setUpRoundUp(
        userState.jwt,
        paymentState.roundUp,
        checkAuthentication,
      );
    }
    await getUserInfo(userState.userSub);
    navigate('../home');
    sendAmplitudeData('SavingsSetup_7_SetupComplete');
    sendAmplitudeData('SavingsSetup_10_Complete');
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      {(paymentState.loading || userState.loading) && <CLoading />}
      <ResultItem
        title="You’re all set!"
        description="BuoyFi Savings will help you pay off your bills faster."
        variant="success"
        icon={<CheckIcon style={{ fontSize: 40, color: '#fff' }} />}
      />
      <Grid item className={classes.buttonStyle1}>
        <CButton variant="contained" fullWidth onClick={handleFinish}>
          Finish
        </CButton>
      </Grid>
    </Container>
  );
}

export default RoundResult;
