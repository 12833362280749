import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import theme from './styles/theme';
import AppRouter from './router';
import { Provider as UserContextProvider } from './context/UserContext';
import { Provider as PaymentContextProvider } from './context/PaymentContext';
import { Provider as PDFContextProvider } from './context/PDFContext';
import { Provider as SynapseContextProvider } from './context/SynapseContext';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { initAmplitude } from './utilities/amplitude';
import {datadogLogs} from '@datadog/browser-logs';

Amplify.configure(awsconfig);
initAmplitude();

datadogLogs.init({
  clientToken: 'pubc47543576ae04afb1cdf3244449b243f',
  site: 'us5.datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: 'Desktop',
  env: 'qa'
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <UserContextProvider>
      <PaymentContextProvider>
        <PDFContextProvider>
          <SynapseContextProvider>
            <Router>
              <AppRouter />
            </Router>
          </SynapseContextProvider>
        </PDFContextProvider>
      </PaymentContextProvider>
    </UserContextProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
