import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { WIDGET_TYPE, BILLGO_WIDGET } from 'capio-common/src/constants';

function BillsDue() {
  const [searchParams] = useSearchParams();
  const [subWidget, setSubWidget] = useState({
    widget: '',
    passData: '',
  });
  const [billGoUser, setBillGoUser] = useState(null);

  useEffect(() => {
    const handleMessage = (message) => {
      console.log('PostMessage from the app:', message.data);
      if (!message.data) return;
      const parsedData = JSON.parse(message.data);
      setBillGoUser(parsedData);
      window.resetUser(parsedData.token);
      window.addWidgetToPage(
        WIDGET_TYPE.BILLS_DUE,
        { token: parsedData.token, userId: parsedData.userId },
        BILLGO_WIDGET[WIDGET_TYPE.BILLS_DUE],
        null,
        (evt) => {
          if (evt.role === 'PAYEE_DETAIL_CLICKED') {
            // event from BillsDue & MyPayees
            setSubWidget({
              widget: WIDGET_TYPE.PAYEE_DETAIL,
              passData: evt.accountId,
            });
          }
          if (evt.role === 'LINK_PAYEE_BUTTON_CLICKED') {
            setSubWidget({
              widget: WIDGET_TYPE.LINK_PAYEE,
              passData: {
                ...(evt.payee?.accounts?.length > 0
                  ? {
                      payeeAccountId: evt.payee?.accounts[0].id,
                    }
                  : { payeeId: evt.payee.id }),
              },
            });
          }
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              `BillGo BILLS_DUE Widget Event: ${evt.role}`,
            );
            window.ReactNativeWebView.postMessage(JSON.stringify(evt));
          }
        },
      );
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (!billGoUser || !subWidget.widget) return;
    window.addWidgetToPage(
      WIDGET_TYPE.BILLS_DUE,
      billGoUser,
      BILLGO_WIDGET[subWidget.widget],
      subWidget.widget === WIDGET_TYPE.PAYEE_DETAIL
        ? {
            payeeAccountId: subWidget.passData,
            uiOptions: {
              hideAutoPayToggle: true,
            },
          }
        : subWidget.widget === WIDGET_TYPE.SINGLE_PAYMENT
        ? { accountId: subWidget.passData }
        : subWidget.widget === WIDGET_TYPE.VIEW_PAYMENT
        ? {
            paymentId: subWidget.passData,
          }
        : subWidget.widget === WIDGET_TYPE.PAYMENT
        ? {
            payeeId: subWidget.passData,
          }
        : subWidget.widget === WIDGET_TYPE.LINK_PAYEE
        ? {
            ...(subWidget.passData?.payeeAccountId
              ? {
                  payeeAccountId: subWidget.passData?.payeeAccountId,
                }
              : { payeeId: subWidget.passData?.payeeId }),
            linkingMode: 'update',
          }
        : null,
      (evt) => {
        console.log('[BillGO Bill Pay Widget]', evt);
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            `BillGo BILLS_DUE Widget Event: ${evt.role}`,
          );
          window.ReactNativeWebView.postMessage(JSON.stringify(evt));
        }
      },
    );
  }, [subWidget, billGoUser]);

  const maxWidth = useMemo(() => {
    return searchParams.get('width') - 48 ?? 342;
  }, [searchParams]);

  return (
    <div
      id="bills-due"
      style={{ width: '100%', maxWidth: maxWidth, margin: '0 auto' }}></div>
  );
}

export default BillsDue;
