import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import GlobalStyle from '../../styles/GlobalStyle';
import arrow from './../../assets/images/arrow.svg';

const useStyles = makeStyles({
  ...GlobalStyle,
  yellowBox: {
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    borderRadius: 0,
    padding: '5px 10px 8px',
    margin: '15px 10px 5px',
    minHeight: 100,
  },
  circle: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: '#4A26d6',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '15px',
  },
  circleText: {
    fontSize: '14px',
    fontWeight: 600,
  },
  title: {
    fontWeight: 600,
    fontSize: '1rem',
    color: '#4A26D6',
  },
  detailLabel: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#250E39',
    minWidth: '150px',
  },
  detailValue: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '16px',
    fontWeight: 600,
    color: '#4A26D6',
    minWidth: '150px',
  },
  boxTitleBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  boxTitle: {
    padding: '5px 20px',
    background: 'white',
    fontWeight: 600,
    fontSize: '16px',
    color: '#414382',
  },
  boxOption: {
    padding: '5px 20px',
    fontWeight: 600,
    fontSize: '16px',
    color: '#250E39',
  },
  boxTitleBody: {
    padding: '10px 20px 10px',
    background: 'white',
  },
  boxTitleFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 3,
  },
  boxFooterTitle: {
    color: '#5255A4',
    fontWeight: 600,
    fontSize: '16px',
    cursor: 'pointer',
  },
  boxBody: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  boxBodyRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '330px',
  },
  arrowImage: {
    marginLeft: 8,
  },
});

function DetailsBox({
  optionNumber,
  title,
  monthlyPayment,
  lengthOfPlan,
  forgivenAmount,
  customStyles = {},
  children,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.yellowBox} style={{ ...customStyles }}>
      <Grid
        container
        sx={{
          justifyContent: 'space-between',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          <Grid item className={classes.circle}>
            <Typography className={classes.circleText} variant="span">
              {optionNumber}
            </Typography>
          </Grid>
          <Grid sx={{ width: '164px' }} item>
            <Typography variant="span" className={classes.title}>
              {title}
            </Typography>
          </Grid>
        </Box>
        <Box>
          <Grid className={classes.boxBody} item>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginRight: '24px',
              }}>
              <Typography variant="span" className={classes.detailLabel}>
                {title === 'Account Settlement Option'
                  ? 'Settlement Amount:'
                  : 'Monthly Payment:'}
              </Typography>
              <Typography variant="span" className={classes.detailLabel}>
                Length of Plan:
              </Typography>
              <Typography variant="span" className={classes.detailLabel}>
                Amount Forgiven:
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}>
              <Typography variant="span" className={classes.detailValue}>
                {monthlyPayment}
              </Typography>
              <Typography variant="span" className={classes.detailValue}>
                {lengthOfPlan}
              </Typography>
              <Typography variant="span" className={classes.detailValue}>
                {forgivenAmount}
              </Typography>
            </Box>
          </Grid>
        </Box>

        {children}
      </Grid>
    </Box>
  );
}

export default DetailsBox;
