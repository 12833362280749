import React, { useEffect, useState, useContext } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../../styles/GlobalStyle';
import {
  SETUP_BILLPAY_AGREEMENTP1,
  SETUP_BILLPAY_AGREEMENTP2,
  SETUP_BILLPAY_FOOTER_TEXT,
  SETUP_BILLPAY_TITLE,
} from 'capio-common/src/constants';
import CButton from '../../../components/common/CButton';
import DollorSvg from '../../../assets/images/yelloDollor.svg';
import { sendAmplitudeData } from '../../../utilities/amplitude';
import PopupModal from '../../../components/common/PopupModal';
import TermsModalContent from '../../../components/common/TermsModalContent';
import TOS from '../../kyc/TOS';
import { Context as UserContext } from '../../../context/UserContext';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
    position: 'relative',
    zIndex: 99,
    // backgroundImage: `url(${DollorSvg})`,
    // backgroundRepeat: 'no-repeat',
  },
  title: {
    marginBottom: 24,
    fontFamily: 'RobotoSlab',
  },
  description: {
    fontFamily: 'OpenSans',
    marginBottom: '10px',
  },
  description2: {
    fontFamily: 'OpenSans',
    marginBottom: '30px',
  },
  dollorImage: {
    position: 'absolute',
    bottom: 0,
    right: '-15px',
    zIndex: -1,
  },
  footerText: {
    marginTop: 2,
  },
}));

export default function SetUpBillPay({ updateStep }) {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [tos, setTOS] = useState();
  const { compareTOSVersion, agreeTOSVersion, state: userState } = useContext(UserContext);

  /*
  useEffect(() => {
    let hasReadTerms = JSON.parse(window.localStorage.getItem('termsRead'));

    if (hasReadTerms) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, []); */

  useEffect(() => {
    console.log("yolo");
    const checkTOS = async () => {
      console.log(`hello!!!`);
      const res = await compareTOSVersion(userState.jwt);
      console.log(res);
      if (res && res['data']) {
        if(res['data']['verified']){
          setIsVisible(false)
        } else {
          setIsVisible(true);
          let tempTOS = res['data']?.tosURL ?? ''
          setTOS(tempTOS);
          console.log('we should be having the first modal');
         // setIsVisible(true);
        }
      }
    };
    checkTOS();
  }, []);

  const termsHandler = async () => {
    setIsVisible(false);
    const res = await agreeTOSVersion();
  };
  return showTerms ? (
    <TOS terms={1} closeTerms={() => setShowTerms(false)} />
  ) : (
    <Container maxWidth="sm" className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        {SETUP_BILLPAY_TITLE}
      </Typography>
      <Typography className={classes.description}>
        {SETUP_BILLPAY_AGREEMENTP1}
      </Typography>
      <Typography className={classes.description2}>
        {SETUP_BILLPAY_AGREEMENTP2}
      </Typography>
      <img
        className={classes.dollorImage}
        width={'auto'}
        height={180}
        src={DollorSvg}
        alt="dollor"
      />
      <CButton
        sx={{ marginTop: 25 }}
        variant="contained"
        fullWidth
        onClick={() => {
          sendAmplitudeData('SavingsSetup_1_Intro');
          updateStep(1);
        }}>
        Continue
      </CButton>

      <PopupModal open={isVisible} styles={{ height: 310 }}>
        <TermsModalContent onClickTerms={() => setShowTerms(true)} />
        <CButton
          sx={{ marginTop: 2 }}
          variant="contained"
          fullWidth
          onClick={() => {
            termsHandler();
          }}>
          Continue
        </CButton>
      </PopupModal>
    </Container>
  );
}
