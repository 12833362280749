import { createContext } from 'react';
import { API } from 'aws-amplify';

import {
  SET_LOADING,
  GET_STATEMENTS,
  GET_TRANSACTION_HISTORY,
  DOC_PREVIEW,
  SET_ERROR,
  SYNAPSE_INFO,
  ROUNDUP_AMOUNT,
} from './types';
import CreateDataContext from './CreateDataContext';
import { API_SYNAPSE } from 'capio-common/src/constants';

export const SynapseContext = createContext();

const initialState = {
  loading: false,
  error: null,
  transactionsTotal: 0,
  transactions: [],
  statements: [],
  docPreview: null,
  synapseInfo: null,
  roundupSum: 0,
};

const setLoading = (dispatch, loading) => {
  dispatch({
    type: SET_LOADING,
    payload: { loading },
  });
};

const getStatements = (dispatch) => {
  return async ({ jwt, params }) => {
    const path = '/synapse/node-statements';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      queryStringParameters: params,
    };
    try {
      setLoading(dispatch, true);
      const result = await API.get(API_SYNAPSE, path, init);
      setLoading(dispatch, false);
      dispatch({
        type: GET_STATEMENTS,
        payload: result.statements,
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        payload: error,
      });
      return { status: false };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getSynapseInfo = (dispatch) => {
  return async (jwt) => {
    const path = '/synapse/view-node';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
    try {
      setLoading(dispatch, true);
      const result = await API.get(API_SYNAPSE, path, init);
      dispatch({
        type: SYNAPSE_INFO,
        payload: result,
      });

      setLoading(dispatch, false);
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        payload: error,
      });
      return { status: false };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getRoundupAmount = (dispatch) => {
  return async (jwt) => {
    const path = '/synapse/get-roundup-sum';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
    try {
      setLoading(dispatch, true);
      const data = await API.get(API_SYNAPSE, path, init);
      console.log('getRoundupAmount========>', data);
      dispatch({
        type: ROUNDUP_AMOUNT,
        payload: data.RoundupSum,
      });
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        payload: err,
      });
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getTransactionHistories = (dispatch) => {
  return async ({ jwt, params }) => {
    const path = '/transaction-history';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      queryStringParameters: params,
    };
    try {
      setLoading(dispatch, true);
      const result = await API.get(API_SYNAPSE, path, init);
      setLoading(dispatch, false);
      dispatch({
        type: GET_TRANSACTION_HISTORY,
        payload: result.data,
      });
      return { status: true, data: result.data };
    } catch (error) {
      return { status: false };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const makeDeposit = (dispatch) => {
  return async (params) => {
    const { jwt, amount, scheduledAt } = params;
    const path = '/synapse/make-deposit';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        amount,
        scheduledAt,
      },
    };

    try {
      setLoading(dispatch, true);
      await API.post(API_SYNAPSE, path, init);
      return true;
    } catch (error) {
      console.log('deposit error', error);
      return false;
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const withdraw = (dispatch) => {
  return async (params) => {
    const { jwt, body } = params;
    const path = '/synapse/withdraw';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body,
    };

    try {
      setLoading(dispatch, true);
      await API.post(API_SYNAPSE, path, init);
      return true;
    } catch (error) {
      console.log('withdraw error:', error);
      return false;
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const makeTransaction = (dispatch) => {
  return async (params) => {
    const { jwt, amount, note } = params;
    const path = '/synapse/transactions';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        fromAccountType: 'ACH-US',
        toAccountType: 'CMA',
        amount,
        currency: 'USD',
        note,
      },
    };

    try {
      setLoading(dispatch, true);
      const transactionResult = await API.post(API_SYNAPSE, path, init);
      return true;
    } catch (error) {
      console.log('error makeTransaction:', error);
      return false;
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const cancelTransaction = (dispatch) => {
  return async (params) => {
    const { jwt, transaction, status } = params;
    const path = `/synapse/transactions/${transaction.transactionID}`;
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      await API.del(API_SYNAPSE, path, init);
      return true;
    } catch (error) {
      console.log('cancel transaction error:', error);
      return false;
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const disputeTransaction = (dispatch) => {
  return async (params) => {
    const { jwt, transaction, status, body } = params;
    const path = `/synapse/dispute-transactions/${transaction.transactionID}`;
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body,
    };

    try {
      setLoading(dispatch, true);
      await API.post(API_SYNAPSE, path, init);
      return true;
    } catch (error) {
      console.log('error dispute transaction:', error);
      return false;
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const docPreview = (dispatch) => {
  return async (params) => {
    const { jwt, body } = params;
    const path = '/synapse/node';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body,
    };

    try {
      setLoading(dispatch, true);
      const data = await API.post(API_SYNAPSE, path, init);
      dispatch({
        type: DOC_PREVIEW,
        payload: data,
      });
      return true;
    } catch (error) {
      console.log('withdraw error:', error);
      return false;
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    case GET_STATEMENTS:
      return {
        ...state,
        statements: action.payload,
        loading: false,
      };
    case GET_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionsTotal: action.payload.total,
        transactions: action.payload.transactions,
        loading: false,
      };
    case DOC_PREVIEW:
      return {
        ...state,
        docPreview: action.payload,
        loading: false,
      };
    case SYNAPSE_INFO:
      return {
        ...state,
        synapseInfo: action.payload,
        loading: false,
      };
    case ROUNDUP_AMOUNT:
      return {
        ...state,
        roundupSum: action.payload,
        loading: false,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export const dispatch = (dispatch) => {
  return async (action) => {
    dispatch(action);
  };
};

export const { Provider, Context } = CreateDataContext(
  reducer,
  {
    getStatements,
    getTransactionHistories,
    dispatch,
    makeTransaction,
    makeDeposit,
    withdraw,
    disputeTransaction,
    cancelTransaction,
    docPreview,
    getSynapseInfo,
    getRoundupAmount,
  },
  initialState,
);
