import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Container, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import GlobalStyle from './../../styles/GlobalStyle';
import TitleText from '../../components/common/TitleText';
import { Context as UserContext } from '../../context/UserContext';
import { currencyFormatter } from 'capio-common/src/patterns';
import { formatToUSPhoneNumber } from 'capio-common/src/main';
import DetailsBox from './DetailsBox';
import { sendAmplitudeData } from '../../utilities/amplitude';
import CLoading from '../../components/common/CLoading';
import CButton from '../../components/common/CButton';
import { ReactComponent as PhoneSVG } from '../../assets/images/phone.svg';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 35,
    marginBottom: 15,
    position: 'relative',
  },
  description2: {
    marginTop: '20px',
  },
  emailText: {
    marginTop: '20px',
    marginBottom: '50px',
    fontWeight: '600',
  },
  button: {
    padding: '8px 10px',
    border: '2px solid  #B5B6DA',
    borderRadius: 4,
    color: '#494C93',
    display: 'block',
    marginTop: 20,
    fontWeight: '600',
  },
  grayBox: {
    padding: '12px 15px',
    backgroundColor: '#F3F1F5',
    borderRadius: 4,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
  },
  phoneNumber: {
    marginLeft: 10,
  },
}));

export default function DivinityLearnMore({
  onContact,
  accounts,
  accountIndex,
  isSettlement,
  setPage,
  setIsGuideLine,
}) {
  const classes = useStyles();
  const { state: userState } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    sendAmplitudeData('Calculator_NegotiateYourBill_ImportedDebt_View');
  }, []);

  const phoneNumber = formatToUSPhoneNumber(accounts[accountIndex].phoneNumber);

  const details = isSettlement
    ? `A one-time payment of ${currencyFormatter(
        accounts[accountIndex].guidelines?.settlement?.amount,
      )}`
    : `Affordable monthly payments of ${currencyFormatter(
        accounts[accountIndex].guidelines?.payment?.amount,
      )}`;

  const monthlyPayment = isSettlement
    ? currencyFormatter(accounts[accountIndex].guidelines?.settlement?.amount)
    : currencyFormatter(accounts[accountIndex].guidelines?.payment?.amount);

  const forgivenAmount = isSettlement
    ? currencyFormatter(accounts[accountIndex].guidelines?.settlement?.forgiven)
    : currencyFormatter(accounts[accountIndex].guidelines?.payment?.forgiven);

  const lengthOfPlan = isSettlement
    ? accounts[accountIndex].guidelines?.settlement?.period === -1
      ? 'one payment'
      : `${accounts[accountIndex].guidelines?.settlement?.period} Months`
    : accounts[accountIndex].guidelines?.payment?.period === -1
    ? 'one payment'
    : `${accounts[accountIndex].guidelines?.payment?.period} Months`;

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText title={'Negotiate your bill'} description={'You selected:'} />
      {userState.loading && <CLoading />}
      <Grid item lg={12} md={12} sm={12}>
        <DetailsBox
          optionTitle={isSettlement ? 'Option1' : 'Option2'}
          title={isSettlement ? 'Account Settlement' : 'Payment Plan'}
          monthlyPayment={monthlyPayment}
          lengthOfPlan={lengthOfPlan}
          forgivenAmount={forgivenAmount}
          hideSelect={true}
          customStyles={{ margin: 0 }}
        />
      </Grid>
      <Typography variant="p" className={classes.description2}>
        Use this payment guideline as a tool to negotiate with your account
        servicer by calling them using the contact info on your statement.
      </Typography>
      <Box className={classes.row}>
        <PhoneSVG />
        <Typography variant="p" className={classes.phoneNumber}>
          {accounts[accountIndex].debt?.debtName}: {phoneNumber}
        </Typography>
      </Box>
      {accounts[accountIndex].capioAccountID && (
        <CButton
          variant="contained"
          fullWidth
          sx={{ marginTop: 10 }}
          onClick={() => {
            sendAmplitudeData('Calculator_NegotiateYourBill_ImportedDebt', {
              click_name: 'Account Servicer Portal',
            });
            setPage('Agency');
          }}>
          <Typography variant="contained">
            Go to account servicer portal
          </Typography>
        </CButton>
      )}
    </Container>
  );
}
