import React, { useEffect } from 'react';
import { Checkbox, Container, FormControlLabel, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../../../styles/GlobalStyle';
import {
  PROVIDE_SPOUSE_INCOME_TITLE,
  PROVIDE_SPOUSE_INCOME_MAIN_DESCRIPTION,
  REQUIRED_AMOUNT,
  INVALID_AMOUNT,
  PROVIDE_OTHER_INCOME_TITLE,
  PROVIDE_OTHER_INCOME_MAIN_DESCRIPTION,
  PROVIDE_OTHER_INCOME_AGE_DESCRIPTION,
} from 'capio-common/src/constants';
import CButton from '../../../../components/common/CButton';
import TitleText from '../../../../components/common/TitleText';
import CPrefixInput from '../../../../components/common/CPrefixInput';
import { useForm } from 'react-hook-form';
import { AMOUNT_PATTERN } from 'capio-common/src/patterns';
import { sendAmplitudeData } from '../../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  closeButton: {
    position: 'absolute',
    top: '25px',
    right: '25px',
  },
  backButton: {
    position: 'absolute',
    top: '25px',
    left: '25px',
  },
  inputWrapper: {
    marginTop: '20px',
  },
}));

export default function EditIncomeForm({
  type,
  userInfo,
  setUserInfo,
  setStep,
  step,
}) {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: userInfo['compassInfo'][type],
      checked: userInfo['compassInfo'][type] === 0 ? true : false,
    },
  });

  const onSubmit = (data) => {
    if (type === 'spouseIncome') {
      sendAmplitudeData('Provide_Spouse_Income_Manual');
    } else {
      sendAmplitudeData('Provide_Other_Income_Manual');
    }
    setUserInfo({
      ...userInfo,
      compassInfo: {
        ...userInfo['compassInfo'],
        [type]: parseFloat(data['amount']),
      },
    });
    reset({ amount: '' });
    setStep('EditIncomeInfo');
  };

  useEffect(() => {
    reset({ amount: userInfo['compassInfo'][type] });
  }, [step]);

  const handleChange = (event) => {
    let amount;
    if (event.target.checked) {
      amount = 0;
    } else {
      amount = '';
    }
    setValue('amount', amount), { shouldValidate: true };
    setUserInfo({
      ...userInfo,
      compassInfo: {
        ...userInfo['compassInfo'],
        [type]: parseFloat(amount),
      },
    });
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title={
          type === 'spousalIncome'
            ? PROVIDE_SPOUSE_INCOME_TITLE
            : PROVIDE_OTHER_INCOME_TITLE
        }
        description={
          type === 'spousalIncome'
            ? PROVIDE_SPOUSE_INCOME_MAIN_DESCRIPTION
            : PROVIDE_OTHER_INCOME_MAIN_DESCRIPTION
        }
        caption={
          type === 'spouseIncome' ? null : PROVIDE_OTHER_INCOME_AGE_DESCRIPTION
        }
      />
      <Grid className={classes.inputWrapper} item>
        <CPrefixInput
          disabled={userInfo['compassInfo'][type] === 0}
          placeholder="Amount"
          inputId="amount"
          name="amount"
          label={
            type === 'spousalIncome'
              ? 'Spousal Monthly Income'
              : 'Total Other Income'
          }
          prefix="$"
          control={control}
          rules={{
            required: {
              value: userInfo['compassInfo'][type] !== 0 ? true : false,
              message: REQUIRED_AMOUNT,
            },
            pattern: { value: AMOUNT_PATTERN, message: INVALID_AMOUNT },
          }}
          error={!!errors.amount}
          helperText={errors.amount && errors.amount.message}
        />
      </Grid>
      <FormControlLabel
        label={
          type === 'spousalIncome'
            ? 'My spouse is unemployed.'
            : 'I do not have other income.'
        }
        control={
          <Checkbox
            checked={userInfo['compassInfo'][type] === 0}
            onChange={handleChange}
          />
        }
      />
      <CButton
        sx={{ marginTop: 15 }}
        disabled={!isValid && userInfo['compassInfo'][type] !== 0}
        variant="contained"
        fullWidth
        onClick={handleSubmit(onSubmit)}>
        Continue
      </CButton>
    </Container>
  );
}
