import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CButton from '../../../components/common/CButton';
import GlobalStyle from '../../../styles/GlobalStyle';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 40px 20px 20px',
    borderBottom: '1px solid #C0B9C7',
  },
  subItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

function RowItem(props) {
  const classes = useStyles();
  const { title, subTitle, extraTxt, onClick } = props;

  return (
    <Box className={classes.item}>
      <Grid item className={classes.subItem}>
        <Typography variant="body2">{title}</Typography>
        {subTitle && (
          <Typography variant="body1" sx={{ marginTop: 1 }}>
            {subTitle}
          </Typography>
        )}
        {extraTxt}
      </Grid>
      <Grid item>
        <CButton
          variant="text"
          className={classes.buttonStyle3}
          onClick={onClick}>
          Edit
        </CButton>
      </Grid>
    </Box>
  );
}

export default RowItem;
