import React, { useState, useContext } from 'react';
import {
  Checkbox,
  Container,
  FormControlLabel,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../../styles/GlobalStyle';
import {
  SETUP_COMPASS_AGREEMENTP1,
  SETUP_COMPASS_AGREEMENTP2,
  SETUP_COMPASS_AUTH,
} from 'capio-common/src/constants';
import CButton from '../../../components/common/CButton';
import CalculatorsImg from '../../../assets/images/calculators.png';
import { Context as UserContext } from '../../../context/UserContext';
import { ReactComponent as Vector } from '../../../assets/images/vector.svg';
import { ReactComponent as CheckMedium } from '../../../assets/images/check-medium.svg';
import { sendAmplitudeData } from '../../../utilities/amplitude';

import CLoading from '../../../components/common/CLoading';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
    marginBottom: 20,
  },
  closeButton: {
    position: 'absolute',
    top: '25px',
    right: '25px',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  bullet: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#DCF1DA',
    color: '#250E39',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    fontSize: '10px',
    fontWeight: 800,
    fontFamily: 'OpenSans',
  },
  listItemText: {
    fontFamily: 'OpenSans',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  title: {
    marginBottom: 24,
    marginLeft: 15,
    fontFamily: 'OpenSans',
    fontWeight: 800,
  },
  description: {
    fontFamily: 'OpenSans',
    marginBottom: '10px',
  },
  checkText: {
    fontFamily: 'OpenSans',
    fontWeight: 800,
    marginLeft: 5,
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  calImage: {
    position: 'absolute',
    bottom: 140,
    right: -100,
  },
}));

const buttonSx = { 
  fontSize: '16px',
  width: '100%',
  backgroundColor: '#4A26D6',
  color: '#FFFFFF',
  padding: '10px 20px',
  textTransform: 'none',
  borderRadius: '54px',
  margin: '20px 0px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: 2
 };

export default function Agree({ updateStep, setStep }) {
  const classes = useStyles();

  const [checked, setChecked] = useState(false);
  const {
    dispatch,
    state: userState,
    getCapioAccount,
  } = useContext(UserContext);

  const items = [
    'Review your bill information',
    'Confirm income with Plaid',
    'Review additional income',
  ];

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleCapioAccounts = async () => {
    sendAmplitudeData('CalcSetup_1_Agree');
    const { status, data } = await getCapioAccount(userState.jwt);
    if (status && data && data.length > 0) {
      setStep('DebtInfo');
    } else {
      setStep('AddMedicalDebt');
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      {userState.loading && <CLoading />}
      <Box className={classes.titleContainer}>
        <Vector />
        <Typography variant="h5" className={classes.title}>
          Find affordable payment solutions with BuoyFi Calculator
        </Typography>
      </Box>
      <Box className={classes.checkContainer}>
        <CheckMedium />
        <Typography className={classes.checkText}>
          How does BuoyFi Calculator help you?
        </Typography>
      </Box>
      <Typography className={classes.description}>
        {SETUP_COMPASS_AGREEMENTP1}
      </Typography>
      <Box className={classes.checkContainer} sx={{ marginTop: '20px' }}>
        <CheckMedium />
        <Typography className={classes.checkText}>What to expect</Typography>
      </Box>
      <Typography className={classes.description}>
        {SETUP_COMPASS_AGREEMENTP2}
      </Typography>
      <List>
        {items.map((item, index) => (
          <ListItem className={classes.listItem} key={index}>
            <div className={classes.bullet}>{index + 1}</div>
            <ListItemText className={classes.listItemText} primary={item} />
          </ListItem>
        ))}
      </List>

      <FormControlLabel
        sx={{ marginTop: 5 }}
        label={SETUP_COMPASS_AUTH}
        control={<Checkbox checked={checked} onChange={handleChange} />}
      />
      <CButton
        sx={{ ...buttonSx }}
        variant="contained"
        disabled={!checked}
        fullWidth
        onClick={handleCapioAccounts}>
        Continue
      </CButton>
    </Container>
  );
}
