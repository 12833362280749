import React, {
  useCallback,
  useRef,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { Container, Button, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../styles/GlobalStyle';
import TitleText from '../../components/common/TitleText';
import CButton from '../../components/common/CButton';
import { ReactComponent as GoLinkSVG } from '../../assets/images/go-link.svg';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 35,
    marginBottom: 35,
    position: 'relative',
  },
  button: {
    padding: '8px 10px',
    border: '2px solid  #B5B6DA',
    borderRadius: 4,
    color: '#494C93',
    display: 'block',
    marginTop: 20,
    fontWeight: '600',
  },
}));

export default function LinkNotice({ accounts, accountIndex, setPage }) {
  const classes = useStyles();

  const timer = useRef();
  const [countDown, setCountDown] = useState(10);

  useEffect(() => {
    timer.current = setInterval(() => setCountDown((pre) => pre - 1), 1000);
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  const account = useMemo(() => {
    return accounts[accountIndex];
  }, [accounts, accountIndex]);

  const handleOpen = useCallback(() => {
    if (!account) return null;
    if (account.debt.debtName === 'AssetCare') {
      window.open('https://ac.mypatientconnect.com', '_blank');
    }
    if (account.debt.debtName === 'MBA Law') {
      window.open('https://mba.mypatientconnect.com', '_blank');
    }
    if (account.debt.debtName === 'Capio Partners') {
      window.open('https://go.capiopay.com/', '_blank');
    }
    if (account.debt.debtName === 'Recoveri') {
      window.open('https://recoveri.mypatientconnect.com/', '_blank');
    }
  }, [account]);

  useEffect(() => {
    if (countDown < 1) {
      handleOpen();
      setCountDown(10);
      clearInterval(timer.current);
    }
  }, [countDown, handleOpen]);

  if (!account) return null;

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Box mt={4} textAlign="center">
        <GoLinkSVG />
      </Box>
      <TitleText
        title={`You will be redirected to ${account.debt.debtName} in ${countDown} seconds`}
        description={
          'You are now leaving the BuoyFi application and being directed towards a website that is operated by your medical debt holder and not governed by BuoyFi’s Terms and Conditions or Privacy Policy. BuoyFi, LLC assumes no liability or responsibility for your medical debt holder’s website.'
        }
      />
      <Typography>
        In addition, BuoyFi, LLC does not guarantee or promise that by
        navigating to your medical debt holder’s website that your medical debt
        holder will honor BuoyFi’s payment guidelines.
      </Typography>
      <CButton
        variant="contained"
        fullWidth
        onClick={handleOpen}
        sx={{ marginTop: '94px' }}>
        Go to {account.debt.debtName}'s now
      </CButton>
      <Button className={classes.button} onClick={() => setPage('DebtInfo')}>
        Cancel
      </Button>
    </Container>
  );
}
