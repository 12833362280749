import React, { useContext, useMemo, useEffect, useRef } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../styles/GlobalStyle';
import TitleText from '../../components/common/TitleText';
import CButton from '../../components/common/CButton';
import { ReactComponent as RedirectIcon } from '../../assets/images/redirect.svg';
import { ReactComponent as Arrow } from '../../assets/images/arrow-right.svg';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 35,
    position: 'relative',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
  },
  arrowContainer: {
    display: 'flex',
    marginRight: 15,
    marginTop: 5,
  },
  contentRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 24,
    marginTop: -5,
  },
}));

export default function Agency({ accounts, accountIndex }) {
  const classes = useStyles();
  const timerId = useRef(null);
  useEffect(() => {
    timerId.current = setTimeout(handleOpen, 10000);
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, []);

  const account = useMemo(() => {
    return accounts[accountIndex];
  }, [accounts, accountIndex]);

  const handleOpen = () => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    if (account.debt.debtName === 'AssetCare') {
      window.open('https://ac.mypatientconnect.com', '_blank');
    }
    if (account.debt.debtName === 'MBA Law') {
      window.open('https://mba.mypatientconnect.com', '_blank');
    }
    if (account.debt.debtName === 'Capio Partners') {
      window.open('https://go.capiopay.com/', '_blank');
    }
    if (account.debt.debtName === 'Recoveri') {
      window.open('https://recoveri.mypatientconnect.com/', '_blank');
    }
  };

  const RenderIcon = () => {
    return (
      <Box className={classes.iconContainer}>
        <RedirectIcon />
      </Box>
    );
  };

  const RenderArrow = () => {
    return (
      <Box className={classes.arrowContainer}>
        <Arrow />
      </Box>
    );
  };

  if (!account) return null;
  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title={`You will be redirected to ${account.debt.debtName} in 10 seconds`}
        icon={<RenderIcon />}
      />
      <Box className={classes.contentRow}>
        <RenderArrow />
        <Typography>
          You are now leaving the BuoyFi application and being directed towards
          a website that is operated by your medical debt holder and not
          governed by BuoyFi’s Terms and Conditions or Privacy Policy. BuoyFi,
          LLC assumes no liability or responsibility for your medical debt
          holder’s website.
          <br />
          <br />
          In addition, BuoyFi, LLC does not guarantee or promise that by
          navigating to your medical debt holder’s website that your medical
          debt holder will honor BuoyFi’s payment guidelines.
        </Typography>
      </Box>
      <Box className={classes.contentRow}>
        <RenderArrow />
        <Typography>
          BuoyFi is not attempting to collect your debt, but has provided this
          contact information for your convenience. BuoyFi assumes no liability
          or responsibility for the communication between you and your debt
          holder and does not guarantee that your medical debt holder will honor
          BuoyFi’s payment guidelines.
        </Typography>
      </Box>
      <CButton variant="contained" fullWidth onClick={handleOpen}>
        Go to servicer portal now
      </CButton>
    </Container>
  );
}
