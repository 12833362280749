import * as React from 'react';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

export default function SettingsButton(props) {
  return (
    <IconButton {...props}>
      <SettingsIcon />
    </IconButton>
  );
}
