import React, { useState } from 'react';
import { Container, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

import GlobalStyle from '../../../../styles/GlobalStyle';
import EditPhone from './EditPhone';
import OTP from '../../../kyc/OTP';
import BackButton from '../../../../components/BackButton';

const steps = ['EditPhone', 'OTP'];

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function EditPhoneIndex() {
  const [step, setStep] = useState(steps[0]);
  const classes = useStyles();
  const navigate = useNavigate();

  const updateStep = (move) => {
    const newStep = steps[steps.indexOf(step) + move];
    setStep(newStep);
  };

  const onClickBack = () => {
    if (step === 'OTP') {
      updateStep(-1);
    } else {
      navigate(-1);
    }
  };

  let activeComponent = '';
  switch (step) {
    case 'EditPhone':
      activeComponent = <EditPhone updateStep={updateStep} />;
      break;
    case 'OTP':
      activeComponent = <OTP updateStep={updateStep} />;
      break;
  }

  return (
    <div className={classes.mainContent}>
      <Container maxWidth="sm">
        <BackButton onClick={onClickBack} />
        <Card sx={{ width: '100%' }}>
          <CardContent>{activeComponent}</CardContent>
        </Card>
      </Container>
    </div>
  );
}

export default EditPhoneIndex;
