import React from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import CButton from '../../components/common/CButton';
import ResultItem from '../../components/ResultItem';
import GlobalStyle from '../../styles/GlobalStyle';
import { ACCOUNT_LOCKED, UNABLE_TO_SIGNIN } from 'capio-common/src/constants';
import CloseButton from '../../components/CloseButton';
import { openEmail } from 'capio-common/src/main';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function LoginFailure(props) {
  const { updateStep, backStepCount } = props;
  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.container}>
      <CloseButton onClick={() => updateStep(backStepCount)} />
      <ResultItem
        title={UNABLE_TO_SIGNIN}
        description={ACCOUNT_LOCKED}
        variant="error"
        icon={<LockOutlinedIcon style={{ fontSize: 40, color: '#fff' }} />}
      />
      <Grid item className={classes.buttonStyle1}>
        <CButton variant="contained" fullWidth onClick={openEmail}>
          Contact Support
        </CButton>
      </Grid>
    </Container>
  );
}

export default LoginFailure;
