import React, { useEffect } from 'react';
import { Checkbox, Container, FormControlLabel, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../../styles/GlobalStyle';
import {
  REQUIRED_AMOUNT,
  INVALID_AMOUNT,
  PROVIDE_OTHER_INCOME_TITLE,
  PROVIDE_OTHER_INCOME_MAIN_DESCRIPTION,
  PROVIDE_OTHER_INCOME_AGE_DESCRIPTION,
} from 'capio-common/src/constants';
import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import CPrefixInput from '../../../components/common/CPrefixInput';
import { useForm } from 'react-hook-form';
import { AMOUNT_PATTERN } from 'capio-common/src/patterns';
import { sendAmplitudeData } from '../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  closeButton: {
    position: 'absolute',
    top: '25px',
    right: '25px',
  },
  backButton: {
    position: 'absolute',
    top: '25px',
    left: '25px',
  },
  inputWrapper: {
    marginTop: '20px',
  },
}));

const buttonSx = { 
  fontSize: '16px',
  width: '100%',
  backgroundColor: '#4A26D6',
  color: '#FFFFFF',
  padding: '10px 20px',
  textTransform: 'none',
  borderRadius: '54px',
  margin: '20px 0px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: 15
 };

export default function ProvideIncome({
  type,
  setIncome,
  income,
  updateStep,
  step,
}) {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: income[type],
  });

  const onSubmit = (data) => {
    sendAmplitudeData('Provide_Other_Income_Manual');

    setIncome({
      ...income,
      [type]: { ...income[type], ...data, edit: false },
    });
    reset({ amount: '' });
    updateStep(1);
  };

  useEffect(() => {
    reset({ amount: income[type]['amount'] });
  }, [step]);

  const handleChange = (event) => {
    if (event.target.checked) {
      setValue('amount', 0), { shouldValidate: true };
    } else {
      setValue('amount', ''), { shouldValidate: true };
    }
    setIncome({
      ...income,
      [type]: { ...income[type], checked: event.target.checked },
    });
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title={PROVIDE_OTHER_INCOME_TITLE}
        description={PROVIDE_OTHER_INCOME_MAIN_DESCRIPTION}
        caption={PROVIDE_OTHER_INCOME_AGE_DESCRIPTION}
      />
      <Grid className={classes.inputWrapper} item>
        <CPrefixInput
          disabled={income[type]['checked']}
          placeholder="Amount"
          inputId="amount"
          name="amount"
          label={'Total Other Income'}
          prefix="$"
          control={control}
          rules={{
            required: {
              value: !income[type]['checked'] ? true : false,
              message: REQUIRED_AMOUNT,
            },
            pattern: { value: AMOUNT_PATTERN, message: INVALID_AMOUNT },
          }}
          error={!!errors.amount}
          helperText={errors.amount && errors.amount.message}
        />
      </Grid>
      <FormControlLabel
        label={'I do not have other income.'}
        control={
          <Checkbox checked={income[type]['checked']} onChange={handleChange} />
        }
      />
      <CButton
        sx={{...buttonSx}}
        disabled={!isValid && !income[type]['checked']}
        variant="contained"
        fullWidth
        onClick={handleSubmit(onSubmit)}>
        Continue
      </CButton>
    </Container>
  );
}
