import React, { useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  FormLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import CloseButton from '../../../components/CloseButton';
import CButton from '../../../components/common/CButton';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 375,
    height: '100%',
    padding: 25,
  },
  filterBtn: {
    marginTop: 50,
    height: 50,
  },
  closeBtn: {
    position: 'absolute',
    right: 28,
    top: 28,
  },
}));

function FilterMenu(props) {
  const { toggleDrawer, transactionType, handleTransactionType, dateRange } =
    props;
  const classes = useStyles();

  return (
    <Box
      className={classes.wrapper}
      role="presentation"
      onKeyDown={toggleDrawer(false)}>
      <CloseButton className={classes.closeBtn} onClick={toggleDrawer(false)} />
      <FormControl>
        <FormLabel>Filters</FormLabel>
        <RadioGroup
          defaultValue={transactionType}
          onChange={handleTransactionType}
          name="transactionType">
          <FormControlLabel
            value=""
            control={<Radio />}
            label="All transactions"
          />
          <FormControlLabel
            value="DEPOSIT"
            control={<Radio />}
            label="Deposit"
          />
          <FormControlLabel
            value="ROUNDUP"
            control={<Radio />}
            label="Round up"
          />
          <FormControlLabel
            value="WITHDRAW"
            control={<Radio />}
            label="Withdraw"
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <Divider sx={{ marginBottom: 2 }} />
        <FormLabel>Filter by date</FormLabel>
        <RadioGroup
          defaultValue={dateRange}
          name="dateRange"
          onChange={handleTransactionType}>
          <FormControlLabel value="" control={<Radio />} label="All time" />
          <FormControlLabel
            value="LAST_90D"
            control={<Radio />}
            label="Past 90 days"
          />
          <FormControlLabel
            value="LAST_MONTH"
            control={<Radio />}
            label="Last month"
          />
          <FormControlLabel
            value="LAST_YEAR"
            control={<Radio />}
            label="Last year"
          />
        </RadioGroup>
      </FormControl>
      <CButton
        variant="contained"
        className={classes.filterBtn}
        onClick={toggleDrawer(false)}>
        Apply Filters
      </CButton>
    </Box>
  );
}

FilterMenu.propTypes = {
  toggleDrawer: PropTypes.func,
  filterGroup: PropTypes.element,
};

export default FilterMenu;
