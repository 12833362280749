import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import CloseButton from '../../../../components/CloseButton';
import accountAvtar from '../../../../assets/images/accountAvtar.svg';
import TitleText from '../../../../components/common/TitleText';
import CButton from '../../../../components/common/CButton';
import PlaidButton from '../../../../components/PlaidButton';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 375,
    height: '100%',
    padding: 25,
  },
  closeBtn: {
    position: 'absolute',
    right: 28,
    top: 28,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px 0px 10px 0px',
  },
  avtarImage: {
    marginBottom: '20px',
  },
}));

function EditAccountMenu(props) {
  const {
    toggleDrawer,
    editAccount,
    linkToken,
    handleUpdateLinkedBank,
    handleRemoveLinkedBank,
  } = props;
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  return (
    <Box className={classes.wrapper} role="presentation">
      <CloseButton
        className={classes.closeBtn}
        onClick={() => toggleDrawer(false)}
      />
      <Box className={classes.item}>
        <TitleText
          title={`Change ${
            editAccount['fromFundingAccount']
              ? 'Funding Account'
              : 'Spending Account'
          }`}
          description=""
        />
        <Typography variant="body4" color="corePurple50.main">
          {editAccount['account']['name']} *{editAccount['account']['mask']}
        </Typography>
        <PlaidButton
          label="Change to another account"
          linkToken={linkToken}
          onSuccess={(val) => {
            setChecked(false);
            handleUpdateLinkedBank(val);
          }}
          onPress={() => setChecked(true)}
          checked={checked}
          setChecked={setChecked}
        />
        {/* <Button
          className={classes.cancelButton}
          onClick={handleRemoveLinkedBank}>
          Remove account
        </Button> */}
      </Box>
    </Box>
  );
}

EditAccountMenu.propTypes = {
  toggleDrawer: PropTypes.func,
};

export default EditAccountMenu;
