import React, { useEffect, useContext, useState, useRef } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import VerificationInput from 'react-verification-input';
import { useNavigate } from 'react-router-dom';

import CButton from '../../components/common/CButton';
import TitleText from '../../components/common/TitleText';
import CAlert from '../../components/common/CAlert';
import { Context as UserContext } from '../../context/UserContext';
import { USER_SETUP } from '../../context/types';
import { getSecureEmail, getSecurePhone } from 'capio-common/src/main';
import {
  CODE_RESENT_EMAIL,
  CODE_RESENT_MESSAGE,
} from 'capio-common/src/constants';
import GlobalStyle from '../../styles/GlobalStyle';
import CLoading from '../../components/common/CLoading';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  inputContainer: {
    width: 300,
    height: 60,
  },
  character: {
    lineHeight: '28px',
    fontSize: 24,
    color: '#7A638E',
    border: '1px solid #998BA5',
    borderRadius: 8,
    marginLeft: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  characterSelected: {
    border: '1px solid #51B748',
  },
  characterInactive: {
    border: '1px solid #998BA5',
  },
}));

function OTP(props) {
  const { updateStep } = props;
  const {
    state,
    dispatch,
    confirmSignUp,
    confirmForgotEmail,
    resendSignUp,
    forgotEmail,
    forgotPassword,
    verifyCurrentUserAttributeSubmit,
    updateUserInfo,
    updateBillGoUser,
  } = useContext(UserContext);
  const [verifyType, setVerifyType] = useState({
    item: '',
    value: '',
  });
  const [alert, setAlert] = useState({ severity: '', message: '' });
  const [canResendCode, setCanResendCode] = useState(true);
  const [count, setCount] = useState(20);
  const classes = useStyles();
  const timer = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const { phone, email, verificationType } = state;
    const securePhone = getSecurePhone(phone);
    const secureEmail = getSecureEmail(email);
    setVerifyType(
      verificationType === 'phone'
        ? { item: 'Phone Number', value: securePhone }
        : { item: 'Email Address', value: secureEmail },
    );
  }, []);

  const handleInputChange = async (input) => {
    const { email, phone, userSub, verificationType, updateProfileFlow } =
      state;
    if (input?.length === 6) {
      if (userSub) {
        if (updateProfileFlow) {
          await verifyCurrentUserAttributeSubmit(
            verificationType === 'phone' ? 'phone_number' : 'email',
            input,
          );
          await updateUserInfo({
            id: state.userSub,
            personalInfo: {
              ...state.personalInfo,
              mobileNumber: phone,
            },
            email,
            emailSearch: email.toLowerCase(),
            searchContent: {
              ...state.searchContent,
              mobileNumber: phone,
              email: email.toLowerCase(),
            },
          });
          await updateBillGoUser(state.jwt, {
            phoneNumber: phone,
            email,
          });
          navigate(-1);
        } else {
          // sign up flow
          await confirmSignUp(email, input);
          updateStep(1);
        }
      } else {
        if (verificationType === 'phone') {
          // forgot email flow
          await confirmForgotEmail(phone, input);
          updateStep(1);
        } else if (verificationType === 'email') {
          // forgot password flow
          dispatch({
            type: USER_SETUP,
            payload: { verificationInput: input },
          });
          updateStep(2);
        }
      }
    }
  };

  useEffect(() => {
    if (!canResendCode) {
      setTimeout(() => {
        setCanResendCode(true);
        clearInterval(timer.current);
        setCount(20);
      }, 20000);

      timer.current = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer.current);
    };
  }, [canResendCode]);

  const resendCode = async () => {
    const { email, phone, userSub, verificationType } = state;
    let res;
    if (userSub) {
      // sign up flow
      res = await resendSignUp(email);
    } else {
      if (verificationType === 'phone') {
        // forgot email flow
        res = await forgotEmail(phone);
      } else if (verificationType === 'email') {
        // forgot password flow
        res = await forgotPassword(email, false);
      }
    }

    if (res.status) {
      setCanResendCode(false);
      setAlert({
        severity: 'success',
        message: 'We’ve sent you a new code',
      });
    } else {
      setAlert({ severity: 'error', message: res.message });
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      {state.loading && <CLoading />}
      <TitleText
        title={'Verify contact information'}
        description={'We sent a code to your phone number or email address.'}
      />
      <CAlert severity={alert.severity} message={alert.message} />
      <Grid container justifyContent="center">
        <VerificationInput
          placeholder=""
          autoFocus
          onChange={handleInputChange}
          removeDefaultStyles
          classNames={{
            container: classes.inputContainer,
            character: classes.character,
            characterInactive: classes.characterInactive,
            characterSelected: classes.characterSelected,
          }}
        />
      </Grid>
      <Typography justifyContent="center" display="flex" mt={5}>
        Didn’t get the verification code?
      </Typography>
      <CButton disabled={!canResendCode} onClick={resendCode}>
        {canResendCode ? 'Resend Code' : `Resend Code (${count})s`}
      </CButton>
    </Container>
  );
}

export default OTP;
