export const ACTION_START = 'ACTION_START';
export const ACTION_COMPLETE = 'ACTION_COMPLETE';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_ROUND_UP = 'SET_ROUND_UP';
export const INIT_ROUND_UP = 'INIT_ROUND_UP';
export const USER_SIGNUP = 'USER_SIGNUP';
export const USER_SETUP = 'USER_SETUP';
export const USER_CLEAR = 'USER_CLEAR';
export const VERIFY_PHONE = 'VERIFY_PHONE';
export const SET_VERIFICATION_TYPE = 'SET_VERIFICATION_TYPE';
export const SET_ROUND_AMOUNTS = 'SET_ROUND_AMOUNTS';
export const SET_PLAID_LINK_TOKEN = 'SET_PLAID_LINK_TOKEN';
export const SET_PLAID_PUBLIC_TOKEN = 'SET_PLAID_PUBLIC_TOKEN';
export const SET_PLAID_PAYROLL_LINK_TOKEN = 'SET_PLAID_PAYROLL_LINK_TOKEN';
export const SET_PLAID_PAYROLL_PUBLIC_TOKEN = 'SET_PLAID_PAYROLL_PUBLIC_TOKEN';
export const SET_PLAID_PAYROLL_SUCCESS_DATA = 'SET_PLAID_PAYROLL_SUCCESS_DATA';
export const SET_TRANSACTION_FILTER = 'SET_TRANSACTION_FILTER';
export const SET_LINKED_BANK = 'SET_LINKED_BANK';
export const SET_LINKED_PAYROLL = 'SET_LINKED_PAYROLL';
export const GET_POLICY = 'GET_POLICY';
export const GET_TOS = 'GET_TOS';
export const VERIFY_TOS = 'VERIFY_TOS';
export const AGREE_TOS = 'AGREE_TOS';
export const GET_CAPIO_ACCOUNT = 'GET_CAPIO_ACCOUNT';
export const USER_PAYMENT_SETUP = 'USER_PAYMENT_SETUP';
export const SET_BILL_GO_DATA = 'SET_BILL_GO_DATA';
export const GET_PDF = 'GET_PDF';
export const GET_STATEMENTS = 'GET_STATEMENTS';
export const GET_TRANSACTION_HISTORY = 'GET_TRANSACTION_HISTORY';
export const SET_TRANSACTION_DATA = 'SET_TRANSACTION_DATA';
export const UPDATE_TRANSACTION_DATA = 'UPDATE_TRANSACTION_DATA';
export const DOC_PREVIEW = 'DOC_PREVIEW';
export const SYNAPSE_INFO = 'SYNAPSE_INFO';
export const SET_PLAID_BANK_PUBLIC_TOKEN = 'SET_PLAID_BANK_PUBLIC_TOKEN';
export const SET_PLAID_BANK_SUCCESS_DATA = 'SET_PLAID_BANK_SUCCESS_DATA';
export const SET_PLAID_BANK_LINK_TOKEN = 'SET_PLAID_BANK_LINK_TOKEN';
export const SET_ALL_LINKED_BANKS = 'SET_ALL_LINKED_BANKS';
export const UPDATE_LINKED_BANK = 'UPDATE_LINKED_BANK';
export const REMOVE_LINKED_BANK = 'REMOVE_LINKED_BANK';
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
export const ROUNDUP_AMOUNT = 'ROUNDUP_AMOUNT';
export const GET_UNREAD_NOTIFICATIONS = 'GET_UNREAD_NOTIFICATIONS';
