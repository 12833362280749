import { Container } from '@mui/material';
import React from 'react';
import TitleText from '../../../components/common/TitleText';

function LinkedPayroll() {

  return (
    <Container maxWidth="sm" >
      <TitleText
        title="Linked Payroll"
      />
    </Container>
  );
}

export default LinkedPayroll;
