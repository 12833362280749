import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../../../styles/GlobalStyle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { currencyFormatter } from 'capio-common/src/patterns';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  accountDetailTitle: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'flex-end',
    margin: '0px 0px 8px 0px',
    fontWeight: 600,
  },
  accountDetail: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    fontWeight: 600,
  },
  accountDetailFooter: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
    marginBottom: '10px',
    fontWeight: 600,
    borderTop: '1px solid',
    borderColor: '#C0B9C7',
    paddingTop: '15px',
  },
  otherAccount: {
    display: 'flex',
  },
  accountUpdateIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  accountIcons: {
    height: '20px',
    marginLeft: '5px',
    cursor: 'pointer',
  },
}));

export default function IncomeInfo({
  userInfo,
  setUserInfo,
  fromEdit,
  setStep,
}) {
  const classes = useStyles();

  const getTotalIncome = () => {
    let total = 0;
    if (userInfo['compassInfo']) {
      if (userInfo['compassInfo']['userIncome'])
        total += parseFloat(userInfo['compassInfo']['userIncome']);
      if (userInfo['compassInfo']['spousalIncome'])
        total += parseFloat(userInfo['compassInfo']['spousalIncome']);
      if (userInfo['compassInfo']['otherIncome'])
        total += parseFloat(userInfo['compassInfo']['otherIncome']);
    }
    return total;
  };

  return (
    <Box>
      <div className={classes.accountDetailTitle}>
        <Typography variant="body3" color="purple40.main">
          Amount
        </Typography>
      </div>
      {userInfo['compassInfo'] && userInfo['compassInfo']['userIncome'] ? (
        <div className={classes.accountDetail}>
          <Typography variant="body3" className={classes.otherAccount}>
            Your Monthly Income
          </Typography>
          <Typography variant="body3">
            {currencyFormatter(userInfo['compassInfo']['userIncome'])}
          </Typography>
        </div>
      ) : null}
      {userInfo['compassInfo'] && userInfo['compassInfo']['spousalIncome'] ? (
        <div className={classes.accountDetail}>
          <Typography variant="body3" className={classes.otherAccount}>
            Spousal Monthly Income
            {fromEdit ? (
              <Typography variant="span" className={classes.accountUpdateIcons}>
                <EditOutlinedIcon
                  onClick={() => setStep('EditSpouseIncome')}
                  color="primary"
                  className={classes.accountIcons}
                />
                <DeleteOutlineOutlinedIcon
                  onClick={() =>
                    setUserInfo({
                      ...userInfo,
                      compassInfo: {
                        ...userInfo['compassInfo'],
                        spousalIncome: 0,
                      },
                    })
                  }
                  color="primary"
                  className={classes.accountIcons}
                />
              </Typography>
            ) : null}
          </Typography>
          <Typography variant="body3">
            {currencyFormatter(userInfo['compassInfo']['spousalIncome'])}
          </Typography>
        </div>
      ) : null}
      {userInfo['compassInfo'] && userInfo['compassInfo']['otherIncome'] ? (
        <div className={classes.accountDetail}>
          <Typography variant="body3" className={classes.otherAccount}>
            Total Other Income
            {fromEdit ? (
              <Typography variant="span" className={classes.accountUpdateIcons}>
                <EditOutlinedIcon
                  onClick={() => setStep('EditOtherIncome')}
                  color="primary"
                  className={classes.accountIcons}
                />
                <DeleteOutlineOutlinedIcon
                  onClick={() =>
                    setUserInfo({
                      ...userInfo,
                      compassInfo: {
                        ...userInfo['compassInfo'],
                        otherIncome: 0,
                      },
                    })
                  }
                  color="primary"
                  className={classes.accountIcons}
                />
              </Typography>
            ) : null}
          </Typography>
          <Typography variant="body3">
            {currencyFormatter(userInfo['compassInfo']['otherIncome'])}
          </Typography>
        </div>
      ) : null}
      <div className={classes.accountDetailFooter}>
        <Typography variant="body3">Total Income</Typography>
        <Typography variant="body3">
          {currencyFormatter(getTotalIncome())}
        </Typography>
      </div>
    </Box>
  );
}
