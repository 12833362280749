import React, { useContext } from 'react';
import { Button, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import GlobalStyle from './../../styles/GlobalStyle';
import TitleText from '../../components/common/TitleText';
import { Context as UserContext } from '../../context/UserContext';
import {
  EMAIL_GUIDELINE_DESCRIPTION,
  EMAIL_GUIDELINE_TITLE,
} from 'capio-common/src/constants';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 35,
    marginBottom: 35,
    position: 'relative',
  },
  button: {
    padding: '8px 10px',
    borderRadius: 4,
    display: 'block',
    marginTop: 120,
    fontWeight: '600',
  },
  emailText: {
    fontWeight: '600',
    marginBottom: 120,
  },
}));

export default function EmailGuideLine({ setPage, setIsGuideLine }) {
  const classes = useStyles();

  const { state: userState } = useContext(UserContext);

  const onSendMeMail = () => {
    setIsGuideLine(true);
    setPage('SendMeMail');
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title={EMAIL_GUIDELINE_TITLE}
        description={EMAIL_GUIDELINE_DESCRIPTION}
      />
      <Typography variant="p" className={classes.emailText}>
        {userState ? userState['email'] : ''}
      </Typography>
      <Button
        variant={'contained'}
        className={classes.button}
        onClick={() => onSendMeMail()}>
        Email me guidelines
      </Button>
    </Container>
  );
}
