import React, { useContext } from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import CButton from '../../../../components/common/CButton';
import ResultItem from '../../../../components/ResultItem';
import GlobalStyle from '../../../../styles/GlobalStyle';
import { currencyFormatter } from 'capio-common/src/patterns';
import { sendAmplitudeData } from '../../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function DepositResult({ data, updateStep, depositSuccess }) {
  const navigate = useNavigate();

  const classes = useStyles();

  const formatDate = (strDate) => {
    const d = new Date(strDate);
    return moment(d).format('MMMM DD');
  };

  const onClickContinue = () => {
    sendAmplitudeData('Savings_Success');
    navigate(-1);
  };
  return (
    <Container maxWidth="sm" className={classes.container}>
      {depositSuccess ? (
        <>
          <ResultItem
            title="Success!"
            description={`${currencyFormatter(
              data.amount,
            )} will be withdrawn from your bank account on ${formatDate(
              data.date,
            )}. Deposits can take 1-2 days to show up in your BuoyFi account.`}
            variant="success"
            icon={<CheckIcon style={{ fontSize: 40, color: '#fff' }} />}
          />
          <Grid item className={classes.buttonStyle1}>
            <CButton
              variant="contained"
              fullWidth
              onClick={() => {
                onClickContinue();
              }}>
              Done
            </CButton>
          </Grid>
        </>
      ) : (
        <>
          <ResultItem
            title="Error"
            description={`${currencyFormatter(data.amount)} cannot be added`}
            variant="error"
            icon={<CloseIcon style={{ fontSize: 40, color: '#fff' }} />}
          />
          <Grid item className={classes.buttonStyle1}>
            <CButton
              variant="contained"
              fullWidth
              onClick={() => updateStep(-2)}>
              Retry
            </CButton>
          </Grid>
        </>
      )}
    </Container>
  );
}

export default DepositResult;
