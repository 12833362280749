import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  Container,
  Grid,
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Drawer,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/system';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import moment from 'moment';

import CButton from '../../../components/common/CButton';
import CAlert from '../../../components/common/CAlert';
import BackButton from '../../../components/BackButton';
import SettingsButton from '../../../components/SettingsButton';
import FilterMenu from './FilterMenu';
import GlobalStyle from '../../../styles/GlobalStyle';
import { Context as PaymentContext } from '../../../context/PaymentContext';
import { Context as UserContext } from '../../../context/UserContext';
import { Context as SynapseContext } from '../../../context/SynapseContext';

import { getNextDepositDate } from 'capio-common/src/main';
import { BUOYFI_FOOTER_TEXT } from 'capio-common/src/constants';
import { currencyFormatter } from 'capio-common/src/patterns';

import { ReactComponent as RefreshSVG } from '../../../assets/images/refresh.svg';
import { sendAmplitudeData } from '../../../utilities/amplitude';
import CLoading from '../../../components/common/CLoading';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  mainButton: {
    width: 160,
    height: 50,
    marginRight: 5,
    marginLeft: 5,
  },
  depositNotificationInfo: {
    padding: 16,
    backgroundColor: 'white',
  },
}));

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 1000,
      lg: 1200,
      xl: 1536,
    },
  },
});

const params = {
  pageNo: 0,
  pageSize: 10,
  sortDirection: 'desc',
  sortField: 'msCreatedAt',
};

const TRANSACTION_TYPE = {
  BILL_PAYMENT_CC: 'Bill Payment (Custody to Clearing)',
  BILL_PAYMENT_CW: 'Bill Payment (Clearing to ACH Wire)',
  BILL_PAYMENT_RETURN_CC: 'Bill Payment Return (Clearing to Custody)',
  DEPOSIT: 'Deposit',
  ROUNDUP: 'Roundup',
  WITHDRAW: 'Withdraw',
};

export default function RoundAccount() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [filter, setFilter] = useState({
    transactionType: '',
    dateRange: '',
  });
  const [pageNo, setPageNo] = useState(0);
  const [skipAutoDeposit, setSkipAutoDeposit] = useState(false);

  const { state: userState } = useContext(UserContext);
  const { state, dispatch, getTransactionHistory } = useContext(PaymentContext);
  const { state: synapseState, getSynapseInfo } = useContext(SynapseContext);

  const { depositDate, frequency } = state.roundUp;

  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const smMatches = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    getTransactionHistory(userState.jwt, params, userState.userSub);
    getSynapseInfo(userState.jwt);
  }, [userState.jwt]);

  useEffect(() => {
    if (menuOpen) return;
    getTransactionHistory(
      userState.jwt,
      {
        ...params,
        ...filter,
      },
      userState.userSub,
    );
  }, [menuOpen, filter]);

  const linkedBanks = useMemo(() => {
    return state.allLinkedBanks.filter((v) => v.plaidType === 'billpay');
  }, [state.allLinkedBanks]);

  const handleTransactionType = ({ target }) => {
    setFilter({ ...filter, [target.name]: target.value });
  };

  const PaymentListItem = ({ item }) => {
    const {
      transactionType,
      transactionStatus,
      fromSource,
      amount,
      createdAt,
      description,
    } = item;
    return (
      <ListItem
        sx={{
          borderBottom: 1,
          borderColor: '#C0B9C7',
          cursor: 'pointer',
        }}
        onClick={() =>
          navigate('../home/round/transaction-detail', {
            state: {
              transaction: item,
            },
          })
        }>
        <Grid container spacing={2} py={1}>
          <Grid container item xs={6} md={8}>
            <Grid item xs={12} md={4}>
              <ListItemText
                primary={createdAt}
                primaryTypographyProps={{ color: 'corePurple60.main' }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <ListItemText
                primary={description}
                primaryTypographyProps={{ color: 'corePurple30.main' }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={6}
            md={4}
            sx={{ textAlign: 'right' }}
            direction={matches && smMatches ? 'column-reverse' : 'row'}
            flexWrap="nowrap">
            <Grid item xs={12} md={6}>
              <ListItemText
                primary={transactionStatus}
                primaryTypographyProps={{ color: 'corePurple30.main' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ListItemText
                primary={currencyFormatter(amount)}
                primaryTypographyProps={{
                  color: [
                    TRANSACTION_TYPE.BILL_PAYMENT_CC,
                    TRANSACTION_TYPE.WITHDRAW,
                  ].includes(transactionType)
                    ? 'coreRed60.main'
                    : 'coreGreen60.main',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setMenuOpen(open);
  };

  const onLoadMore = () => {
    getTransactionHistory(
      userState.jwt,
      {
        ...params,
        pageNo: pageNo + 1,
        ...filter,
      },
      userState.userSub,
      true,
    );
    setPageNo(pageNo + 1);
  };

  return (
    <Container maxWidth={false} disableGutters className={classes.container}>
      <Box
        sx={{
          width: '100%',
          paddingTop: 1,
          paddingBottom: 3,
          backgroundColor: 'purple50.main',
        }}>
        <Grid container display="flex" alignItems="center" direction="column">
          <Grid className={classes.headerContent}>
            <Grid item>
              <BackButton color="white" onClick={() => navigate(-1)} />
            </Grid>
            <Grid item>
              <SettingsButton
                color="white"
                onClick={() => navigate('../home/round/settings')}
              />
            </Grid>
          </Grid>
          <Typography variant="h5" color="white.main" mt={7}>
            BuoyFi Savings
          </Typography>
          <Typography variant="h1" color="white.main">
            {currencyFormatter(synapseState.synapseInfo?.info?.balances?.USD)}
          </Typography>
          <Typography variant="body2" color="purple20.main" mt={1}>
            Current Balance
          </Typography>
          <Grid item mt={5}>
            <CButton
              variant="contained"
              className={clsx(classes.buttonStyle2, classes.mainButton)}
              onClick={() => {
                sendAmplitudeData('SaveUpDetails_MakeDeposit');
                navigate('../home/round/deposit');
              }}>
              Make a Deposit
            </CButton>
            <CButton
              variant="contained"
              className={clsx(classes.buttonStyle2, classes.mainButton)}
              onClick={() => {
                sendAmplitudeData('SaveUpDetails_Withdraw');
                navigate('../home/round/withdraw');
              }}>
              Withdraw
            </CButton>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: 'corePurple10.main',
          padding: 2,
          [theme.breakpoints.up('lg')]: {
            paddingLeft: 28,
            paddingRight: 28,
          },
          height: '100%',
        }}>
        {state.roundUp.autoDeposit && !skipAutoDeposit && (
          <Grid className={classes.depositNotificationInfo}>
            <Grid container justifyContent="space-between">
              <Grid item flexDirection="row" display="flex">
                <RefreshSVG />
                <Grid item ml={2}>
                  <Typography variant="body3">Auto Deposit</Typography>
                  <Typography variant="body2">
                    {`Scheduled for ${getNextDepositDate(
                      depositDate,
                      frequency,
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item mt={1}>
                <CButton
                  variant="contained"
                  className={classes.buttonStyle2}
                  sx={{ marginRight: 1 }}
                  onClick={() => navigate('../home/round/settings')}>
                  Reschedule
                </CButton>
                <CButton
                  variant="text"
                  className={classes.buttonStyle3}
                  onClick={() => setSkipAutoDeposit(true)}>
                  Skip
                </CButton>
              </Grid>
            </Grid>
            {/* <CAlert
            severity="error"
            icon={<ErrorOutlineIcon />}
            title="Unable to make transfer"
            message="Insufficient fund in your checking account"
          /> */}
          </Grid>
        )}
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          my={2}>
          <Typography color="corePurple60.main">Transaction History</Typography>
          <IconButton onClick={toggleDrawer(true)}>
            <FilterListIcon color="primary" />
          </IconButton>
        </Grid>
        <Box mb={4}>
          <CButton
            variant="text"
            className={classes.whiteButtonStyle}
            onClick={() => navigate('../home/round/view-statements')}>
            View statements
          </CButton>
        </Box>
        {state.loading && <CLoading />}
        {state.transactionData.transactions.length > 0 && (
          <>
            <List
              sx={{
                width: '100%',
                backgroundColor: 'white.main',
              }}>
              {state.transactionData.transactions.map((item, index) => (
                <PaymentListItem item={item} key={index} />
              ))}
            </List>
            {state.transactionData.total > 10 &&
              state.transactionData.total / 10 > pageNo && (
                <Grid item display="flex" justifyContent="center" mt={2}>
                  <CButton variant="text" onClick={onLoadMore}>
                    Load more
                  </CButton>
                </Grid>
              )}
          </>
        )}

        <Grid mt={2} mb={1}>
          <Typography>{BUOYFI_FOOTER_TEXT}</Typography>
        </Grid>
        <Box mt={1} mb={1}>
          <CButton
            onClick={() => navigate('/profile/help-and-support')}
            sx={{ padding: 0 }}>
            <HelpOutlineIcon color="purple70.main" />
            <Typography color="purple70.main" variant="body2" ml={1}>
              Having trouble? We can help
            </Typography>
          </CButton>
        </Box>
      </Box>
      <Drawer anchor="right" open={menuOpen} onClose={toggleDrawer(false)}>
        <FilterMenu
          toggleDrawer={toggleDrawer}
          transactionType={filter.transactionType}
          handleTransactionType={handleTransactionType}
          dateRange={filter.dateRange}
        />
      </Drawer>
    </Container>
  );
}
