import React, { useContext, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Context as UserContext } from '../../context/UserContext';
import { USER_SETUP } from '../../context/types';
import TitleText from '../../components/common/TitleText';
import CButton from '../../components/common/CButton';
import FileUpload from '../../components/FileUpload';
import CAlert from '../../components/common/CAlert';
import GlobalStyle from '../../styles/GlobalStyle';
import { FILE_SIZE_ERROR } from 'capio-common/src/constants';
import { sendAmplitudeData } from '../../utilities/amplitude';
import CLoading from '../../components/common/CLoading';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  checklistItem: {
    justifyContent: 'space-between',
    padding: '12px 0px',
  },
  normalContainer: {
    backgroundColor: '#F3F1F5',
  },
  successContainer: {
    backgroundColor: '#DCF1DA',
  },
}));

function DocChecklist(props) {
  const { updateStep } = props;
  const { state, dispatch, verifyDoc, updateUserInfo } =
    useContext(UserContext);
  const classes = useStyles();
  const [error, setError] = useState({
    photoFront: null,
    photoBack: null,
  });
  const [verifyErr, setVerifyErr] = useState(null);
  const handleSelection = (img, type, sizeError) => {
    setVerifyErr(null);
    if (sizeError) {
      setError({ ...error, [type]: FILE_SIZE_ERROR });
    } else {
      setError({ ...error, [type]: null });
      dispatch({
        type: USER_SETUP,
        payload: { [type]: img },
      });
    }
  };

  const handleUploadDocs = async () => {
    setVerifyErr(null);
    const res = await verifyDoc(state);
    if (res === true) {
      updateUserInfo({
        id: state['userSub'],
        appStatus: {
          parent: 'Home',
          sub: 'DocChecklist',
        },
      });
      updateStep(1);
      sendAmplitudeData('SavingsSetup_4B_UploadID');
    } else {
      setVerifyErr(res);
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      {state.loading && <CLoading />}
      <TitleText
        title="Upload photos of your ID"
        description="Make sure your image is clear and all four corners are in frame"
      />
      <FileUpload
        title="Front of ID"
        error={error.photoFront}
        setImage={(img, sizeError) =>
          handleSelection(img, 'photoFront', sizeError)
        }
        removeImage={() => handleSelection('', 'photoFront')}
      />
      <FileUpload
        title="Back of ID"
        error={error.photoBack}
        setImage={(img, sizeError) =>
          handleSelection(img, 'photoBack', sizeError)
        }
        removeImage={() => handleSelection('', 'photoBack')}
      />
      {verifyErr && (
        <Box my={2}>
          <CAlert message={verifyErr} severity="error" />
        </Box>
      )}

      <Grid item className={classes.buttonStyle1}>
        <CButton
          variant="contained"
          fullWidth
          loading={state.loading}
          disabled={!state.photoFront || !state.photoBack}
          onClick={handleUploadDocs}>
          Upload
        </CButton>
      </Grid>
    </Container>
  );
}

export default DocChecklist;
