import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { Context as UserContext } from '../../../context/UserContext';
import CButton from '../../../components/common/CButton';
import ResultItem from '../../../components/ResultItem';
import GlobalStyle from '../../../styles/GlobalStyle';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function TransasctionVerify() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();

  const title = useMemo(() => {
    return state.transaction.transactionStatus === 'Cancelled'
      ? 'Success!'
      : 'We received your request';
  }, [state.transaction]);

  const description = useMemo(() => {
    return state.transaction.transactionStatus === 'Cancelled'
      ? 'Your transaction has been cancelled.'
      : 'Your dispute is being processed. We will get back to you shortly.';
  }, [state.transaction]);

  return (
    <Container maxWidth="sm" className={classes.container}>
      <ResultItem
        title={title}
        description={description}
        variant="success"
        icon={<CheckIcon style={{ fontSize: 40, color: '#fff' }} />}
      />
      <Grid item className={classes.buttonStyle1}>
        <CButton
          variant="contained"
          fullWidth
          loading={state.loading}
          onClick={() =>
            navigate('../home/round/transaction-detail', {
              state: {
                transaction: state.transaction,
              },
            })
          }>
          Done
        </CButton>
      </Grid>
    </Container>
  );
}

export default TransasctionVerify;
