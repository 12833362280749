import React, { useState, useContext } from 'react';
import { Container, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CAlert from '../../../components/common/CAlert';
import { useNavigate } from 'react-router-dom';
import { Context as UserContext } from '../../../context/UserContext';
import { Context as PaymentContext } from '../../../context/PaymentContext';
import { Context as SynapseContext } from '../../../context/SynapseContext';
import TitleText from '../../../components/common/TitleText';
import GlobalStyle from '../../../styles/GlobalStyle';
import CButton from '../../../components/common/CButton';
import ConfirmModal from '../../../components/common/ConfirmModal';
import RowItem from './RowItem';
import ProfileHeader from '../profileHeader';
import CLoading from '../../../components/common/CLoading';
import { openEmail } from 'capio-common/src/main';
import {
  BANK_SERVICE_EMAIL,
  HELP_LINE_1,
  HELP_LINE_2,
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
} from 'capio-common/src/constants';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

function ProfileDetails() {
  const [isVisible, setVisible] = useState(false);
  const [error, setError] = useState(false);
  const {
    state: userState,
    dispatch,
    signOut,
    updateUserInfo,
  } = useContext(UserContext);
  const { state: paymentState, cancelAllPayments } = useContext(PaymentContext);
  const {
    state: synapseState,
    getSynapseInfo,
    withdraw,
  } = useContext(SynapseContext);

  const classes = useStyles();
  const navigate = useNavigate();

  const { firstName, lastName, phone, email, address, loading } = userState;
  const { streetAddress, city, postalCode, state } = address;

  const VerifyTxt = ({ isVerify }) => {
    return (
      <Typography
        color={isVerify ? 'success.main' : 'error.main'}
        variant="body2"
        sx={{ marginTop: 1 }}>
        {isVerify ? 'Verified' : 'Not Verified'}
      </Typography>
    );
  };

  const handleDeactive = async () => {
    setError(false);
    try {
      await cancelAllPayments(userState.jwt);
      await getSynapseInfo(userState.jwt);
      if (+synapseState.synapseInfo?.info?.balances?.USD > 0) {
        await withdraw({
          jwt: userState.jwt,
          body: {
            fromAccountType: 'CMA',
            toAccountType: 'ACH-US',
            speed: 'SAMEDAY',
            amount: +synapseState.synapseInfo?.info?.balances?.USD || 0,
            currency: 'USD',
            note: 'From CMA to ACH-US',
            group_id: 'WITHDRAW',
          },
        });
      }
      updateUserInfo({
        id: userState['userSub'],
        isDisabled: true,
      });
      sessionStorage.removeItem('buoyfiToken');
      sessionStorage.removeItem('buoyfiUserId');
      signOut();
    } catch (e) {
      console.log('Error in deactivating the user: ', e);
      setError(true);
    } finally {
      setVisible(false);
    }
  };

  if (loading || !firstName) {
    return <CLoading />;
  }

  return (
    <>
      <ProfileHeader />
      <Container maxWidth="sm" className={classes.contentContainer}>
        <TitleText title="Personal Contact Information" description="" />
        <RowItem
          title="Name"
          subTitle={`${firstName} ${lastName}`}
          onClick={() => navigate('../profile/details/edit-name')}
        />
        <RowItem
          title="Mobile Number"
          subTitle={phone}
          onClick={() => navigate('../profile/details/edit-phone')}
        />
        <RowItem
          title="Email Address"
          subTitle={email}
          onClick={() => navigate('../profile/details/edit-emailaddress')}
        />
        <RowItem
          title="Address"
          subTitle={streetAddress}
          extraTxt={
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              {`${city}, ${
                state !== null && !!state ? state + ',' : ''
              } ${postalCode}`}
            </Typography>
          }
          onClick={() => navigate('../profile/details/edit-address')}
        />
        <CButton
          sx={{ marginTop: 2, color: 'error.main' }}
          onClick={() => setVisible(true)}>
          Deactivate Account
        </CButton>
        {error && <CAlert message="Deactivating this user is failed." />}
      </Container>
      <ConfirmModal
        open={isVisible}
        loading={loading || paymentState.loading || synapseState.loading}
        title="Deactivate Account"
        content={
          <>
            <Typography align="left">
              Are you sure you want to deactivate the account?
            </Typography>
            <Typography sx={{ mt: 2 }}>
              By deactivating your account, you will no longer have access to
              BuoyFi. If you require access to your transaction history, file
              disputes, statements & tax documents, or account agreements please
              contact BuoyFi Customer Support.
            </Typography>
            <Typography sx={{ my: 2, fontWeight: 'bold' }}>
              All of your pending payments will be canceled and funds will be
              returned to your bank account within 3-5 days.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              {HELP_LINE_1} at{' '}
              <a
                target={'_blank'}
                className={classes.link}
                onClick={() => openEmail()}>
                {SUPPORT_EMAIL}
              </a>{' '}
              or at {SUPPORT_PHONE}. {HELP_LINE_2}
            </Typography>
          </>
        }
        onConfirm={() => {
          handleDeactive();
        }}
        onClose={() => setVisible(false)}
        cancelText="Cancel"
        okText="Yes"
      />
    </>
  );
}

export default ProfileDetails;
