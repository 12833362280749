import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TitleText from '../../../components/common/TitleText';
import BackButton from '../../../components/BackButton';
import { openEmail } from 'capio-common/src/main';
import {
  BANK_SERVICE_EMAIL,
  HELP_LINE_1,
  HELP_LINE_2,
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
} from 'capio-common/src/constants';

const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer',
    color: '#5255A4',
  },
}));

function HelpAndSupport() {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <BackButton onClick={() => navigate(-1)} color="purple70.main" />
      <TitleText title="Help & Support" />
      <Typography>
        {HELP_LINE_1} at{' '}
        <a
          target={'_blank'}
          className={classes.link}
          onClick={() => openEmail()}>
          {SUPPORT_EMAIL}
        </a>{' '}
        or at {SUPPORT_PHONE}. {HELP_LINE_2}
      </Typography>
    </Container>
  );
}

export default HelpAndSupport;
