import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Container,
  Grid,
  FormControlLabel,
  Typography,
  Link,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReactComponent as Shield } from '../../../assets/images/shield.svg';
import { ReactComponent as Lock } from '../../../assets/images/lock.svg';
import { ReactComponent as CheckMedium } from '../../../assets/images/check-medium.svg';
import { ReactComponent as BankIcon } from '../../../assets/images/bank-secure.svg';
import { ReactComponent as Tip } from '../../../assets/images/hint-circle.svg';
import GlobalStyle from '../../../styles/GlobalStyle';
import TitleText from '../../../components/common/TitleText';
import {
  CONNECT_PAYROLL_PLAID_CONFIRM_TEXT,
  CONNECT_PAYROLL_PLAID_MAIN_DESCRIPTION,
  CONNECT_PAYROLL_PLAID_TITLE,
  CONNECT_PAYROLL_PLAID_TOOLTIP,
  CONNECT_PAYROLL_PLAID_CONFIRM_TEXT_CRA
} from 'capio-common/src/constants';
import PlaidButton from '../../../components/PlaidButton';
import { Context as PaymentContext } from '../../../context/PaymentContext';
import { Context as UserContext } from '../../../context/UserContext';
import BootstrapTooltip from '../../../pages/home/BootstrapTooltip';
import CLoading from '../../../components/common/CLoading';
import { sendAmplitudeData } from '../../../utilities/amplitude';
import CButton from '../../../components/common/CButton';
import PopupModal from '../../../components/common/PopupModal';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 20,
  },
  checkText: {
    fontFamily: 'OpenSans',
    fontWeight: 800,
    marginLeft: 5,
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 48,
  },
  smallIconContainer: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginBottom: 24,
    marginLeft: 15,
    fontFamily: 'OpenSans',
    fontWeight: 800,
  },
  description: {
    fontFamily: 'OpenSans',
    marginBottom: '10px',
  },
  tooltip: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  addLink: {
    marginTop: '10px',
    marginBottom: '20px',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  subSection: {
    backgroundColor: '#F8F8F8',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '16px',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 150,
  },
  subText: {
    fontSize: '13px',
    fontFamily: 'OpenSans',
    fontWeight: 400,
  },
  modal: {
    height: '278px',
    padding: '40px',
  },
  modalText: {
    fontFamily: 'OpenSans',
    fontWeight: 400,
    fontSize: '24px',
  },
  contentContainer: {
    display: 'flex',
    marginTop: 30,
    padding: 15,
    border: '1px solid #DEDEDE',
    borderRadius: 16,
  },
  contentRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentTextRow: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  contentText: {
    fontSize: '16px',
    fontFamily: 'OpenSans',
    color: '#250E39',
  },
  contentLink: {
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'OpenSans',
    color: '#414382',
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
}));

export default function ConnectPayrollPlaid({
  income,
  setIncome,
  updateStep,
  setManualIncomeParentName,
  edit
}) {
  const classes = useStyles();
  const {
    dispatch,
    state: paymentState,
    getPlaidPayrollLinkToken,
    exchangePlaidPayrollPublicToken,
    getPlaidBankLinkToken,
    exchangePlaidBankPublicToken,
  } = useContext(PaymentContext);
  const { state: userState, checkAuthentication } = useContext(UserContext);
  const [checked, setChecked] = useState(false);
  const [bankChecked, setBankChecked] = useState(false);
  const [payrollChecked, setPayrollChecked] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const { plaid, loading } = paymentState;

  // useEffect(() => {
  //   if (!userState.jwt) {
  //     return;
  //   }
  //   dispatch(getPlaidPayrollLinkToken(userState.jwt, checkAuthentication));
  //   dispatch(getPlaidBankLinkToken(userState.jwt, checkAuthentication));
  // }, [userState]);


  useEffect(() => {
    if (!userState.jwt) {
      return;
    }
    !plaid.bankLinkToken &&
      dispatch(
        getPlaidBankLinkToken(userState.jwt, checkAuthentication),
      );
  }, [userState])

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };


  const RenderIcon = () => {
    return (
      <Box className={classes.iconContainer}>
        <Shield />
        <Box className={classes.smallIconContainer}>
          <Lock />
        </Box>
      </Box>
    );
  };

  const onSuccessBank = async (success) => {
    sessionStorage.setItem('payrollSuccess', JSON.stringify(success));
    const _type = success.metadata?.institution?.institution_id
      ? 'select_verify'
      : 'upload_doc';
    setManualIncomeParentName('');
    const payload = {
      token: userState.jwt,
      success,
      type: _type,
    };
    setBankChecked(false);
    if (_type === 'select_verify') {
      const { status, data } = await exchangePlaidBankPublicToken(payload);
      if (status) {
        setIncome({
          ...income,
          incomeType: 'plaid',
          monthlyIncome: {
            ...income['monthlyIncome'],
            amount: data['userIncome'],
          },
        });
        updateStep('AddHouseholdSize');
      }
    } else {
      updateStep(11);
    }
  };

  const handleModalClose = () => {
    setIsVisible(false);
    setShowOption(true);
  };


  const renderManualIncomeBox = () => {
   return (
    <Box className={classes.contentContainer}>
            <Box className={classes.contentRow}>
              <Tip />
              <Box className={classes.contentTextRow}>
                <Typography className={classes.contentText}>
                  What if my financial info is not on Plaid?
                </Typography>
                <Link
                  className={classes.contentLink}
                  onClick={() => {
                    setIsVisible(false);
                    sendAmplitudeData('Employment_Info_Unavailable');
                    // if edit, go directly to manual income
                    if(edit) {
                      updateStep('ProvideIncomeManually');
                    } else {
                      updateStep('InfoNotINPlaid');
                    }
                    
                  }}>
                  Enter income manually
                </Link>
              </Box>
            </Box>
          </Box>
   );
  }

  const renderAESTooltip = () => {
    return (
      <Box className={classes.subSection}> 
        <BankIcon />
        <Typography className={classes.subText}>
          Advanced Encryption Standard (AES-256) and Transport Layer Security
        </Typography>
      </Box>
    )
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText icon={<RenderIcon />} title={CONNECT_PAYROLL_PLAID_TITLE} />
      <Box className={classes.checkContainer}>
        <CheckMedium />
        <Typography className={classes.checkText}>
          Why do you need to verify income?
        </Typography>
      </Box>
      <Typography className={classes.description}>
        {CONNECT_PAYROLL_PLAID_MAIN_DESCRIPTION}
      </Typography>
      {loading && <CLoading />}
      <Grid display="flex" className={classes.tooltip}>
        <BootstrapTooltip
          title={CONNECT_PAYROLL_PLAID_TOOLTIP}
          placement="bottom">
          <Box display="flex">
            <InfoOutlinedIcon color="purple90" />
            <Typography color="purple90.main" ml={1}>
              What is Plaid?
            </Typography>
          </Box>
        </BootstrapTooltip>
      </Grid>
      <Box> 
        {showOption ? 
       (renderManualIncomeBox())
       : 
        (renderAESTooltip())
      }
      </Box>
      <CButton
        sx={{ 
          fontSize: '16px',
          width: '100%',
          backgroundColor: '#4A26D6',
          color: '#FFFFFF',
          padding: '10px 20px',
          textTransform: 'none',
          borderRadius: '54px',
          margin: '20px 0px',
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: 2
         }}
        variant="contained"
        fullWidth
        onClick={() => {
          setIsVisible(true);
        }}>
        Connect my bank account
      </CButton>
      <PopupModal
        open={isVisible}
        close={() => {
          setIsVisible(false);
        }}
        styles={{
          height: '500px',
          padding: '40px',
          width: '407px',
        }}>
        <FormControlLabel
          sx={{ marginTop: 2, marginBottom: 2, alignItems: 'flex-start' }}
          label={CONNECT_PAYROLL_PLAID_CONFIRM_TEXT_CRA}
          control={<Checkbox checked={checked} onChange={handleChange} />}
        />
        <PlaidButton
            label="Continue"
            linkToken={plaid.bankLinkToken}
            onSuccess={onSuccessBank}
            loading={loading}
            onModalClose={handleModalClose}
            checked={checked && bankChecked}
            onPress={() => {
              sendAmplitudeData('VerifyIncome_BankAcct');
              setBankChecked(true);
              !plaid.bankLinkToken &&
                dispatch(
                  getPlaidBankLinkToken(userState.jwt, checkAuthentication),
                );
            }}
            setChecked={setBankChecked}
            btnDisabled={!checked}
          />
      </PopupModal>
    </Container>
  );
}
