import React, { useContext, useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { usePlacesWidget } from 'react-google-autocomplete';
import CAlert from '../../../../components/common/CAlert';
import TitleText from '../../../../components/common/TitleText';
import CTextField from '../../../../components/common/CTextField';
import CButton from '../../../../components/common/CButton';
import GlobalStyle from '../../../../styles/GlobalStyle';
import { Context as UserContext } from '../../../../context/UserContext';
import { USER_SETUP } from '../../../../context/types';
import { ZIPCODE_PATTERN } from 'capio-common/src/patterns';
import { polishValue } from 'capio-common/src/main';
import {
  INVALID_ZIP_CODE,
  REQUIRED_ADDRESS,
  REQUIRED_CITY,
  REQUIRED_ZIP_CODE,
} from 'capio-common/src/constants';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function EditAddress(props) {
  const classes = useStyles();
  const [formValid, setFormValid] = useState(false);
  const {
    state: userState,
    dispatch,
    updateUserAttributes,
    updateUserInfo,
    updateBillGoUser,
  } = useContext(UserContext);

  const navigate = useNavigate();

  const { ref: addressFieldRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
    onPlaceSelected: (place) => {
      const { address_components, formatted_address } = place;

      const result = formatted_address?.split(', ');
      const postalCode = address_components.filter(
        (item) => item.types?.[0] === 'postal_code',
      );

      const countryState =
        address_components.find((v) =>
          v.types.includes('administrative_area_level_1'),
        )?.short_name ?? '';

      setValue('address', result?.[0]);
      setValue('city', result?.[1]);
      setValue('zipCode', postalCode?.[0]?.['long_name']);
      setValue('state', countryState);
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    },
  });

  const {
    control,
    watch,
    trigger,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      address: userState.address?.streetAddress,
      city: userState.address?.city,
      zipCode: userState.address?.postalCode,
      state: userState.address?.state ?? '',
    },
  });

  useEffect(() => {
    const { address } = userState;
    setValue('address', address.streetAddress, {
      shouldValidate: !!address.streetAddress,
    });
    setValue('city', address.city, { shouldValidate: !!address.city });
    setValue('zipCode', address.postalCode, {
      shouldValidate: !!address.postalCode,
    });
    setValue('state', address.state);
  }, []);

  const watchZipCode = watch('zipCode');

  useEffect(() => {
    const checkZipCode = async () => {
      setFormValid(false);
      if (polishValue(watchZipCode)?.length > 4) {
        await trigger();
        if (_.isEmpty(errors)) {
          setFormValid(true);
        }
      }
    };
    checkZipCode();
  }, [watchZipCode]);

  const onSubmit = async (data) => {
    const { address, city, zipCode, state } = data;
    const { billGoUserId } = userState;
    try {
      if(billGoUserId) {
        const responseBillGoUser = await updateBillGoUser(userState.jwt, {
          address: {
            streetAddress: address,
            city,
            postalCode: zipCode,
            stateAbbreviation: state,
          },
        });
        if (!responseBillGoUser.status) {
          setFormValid(false);
          return;
        }
      }
      await updateUserAttributes({
        address: `${address}, ${city}, ${state}, ${zipCode} USA`,
      });

      await updateUserInfo({
        id: userState['userSub'],
        address: {
          streetAddress: address,
          city,
          postalCode: zipCode,
          state,
        },
        searchContent: {
          ...userState.searchContent,
          stateName: state.toLowerCase(),
        },
      });
      dispatch({
        type: USER_SETUP,
        payload: {
          address: {
            streetAddress: address,
            city,
            postalCode: zipCode,
            state,
          },
        },
      });
      navigate(-1);
    } catch (err) {
      console.log('update address error', err);
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText title="Edit Address" description="" />
      <Grid item>
        <CTextField
          inputRef={addressFieldRef}
          fullWidth
          name="address"
          label="Street Address"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_ADDRESS },
          }}
          error={!!errors.address}
          helperText={errors.address && errors.address.message}
        />
      </Grid>
      <Grid item>
        <CTextField
          fullWidth
          name="city"
          label="City"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_CITY },
          }}
          error={!!errors.city}
          helperText={errors.city && errors.city.message}
          disabled
        />
      </Grid>
      <Grid item>
        <CTextField
          fullWidth
          name="zipCode"
          label="ZIP Code"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_ZIP_CODE },
            pattern: { value: ZIPCODE_PATTERN, message: INVALID_ZIP_CODE },
          }}
          error={!!errors.zipCode}
          helperText={errors.zipCode && errors.zipCode.message}
          disabled
        />
      </Grid>
      {!formValid && (
        <Grid item>
          <CAlert severity="error" message={userState.error} />
        </Grid>
      )}
      <Grid item className={classes.buttonStyle1}>
        <CButton
          variant="contained"
          fullWidth
          disabled={!formValid}
          loading={userState.loading}
          onClick={handleSubmit(onSubmit)}>
          Update Address
        </CButton>
      </Grid>
    </Container>
  );
}

export default EditAddress;
