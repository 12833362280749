import React, { useEffect, useState } from 'react';
import { Container, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import RoundSettings from './RoundSettings';
import RoundWithdraw from '../../../kyc/RoundWithdraw';
import RoundWeekly from '../../../kyc/RoundWeekly';
import RoundMonthly from '../../../kyc/RoundMonthly';
import RoundDateSelection from '../../../kyc/RoundDateSelection';
import BackButton from '../../../../components/BackButton';
import GlobalStyle from '../../../../styles/GlobalStyle';
import CloseButton from '../../../../components/CloseButton';

const steps = [
  'RoundSettings',
  'RoundWithdraw',
  'RoundWeekly',
  'RoundMonthly',
  'RoundDateSelection',
];

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function RoundSetUp() {
  const [step, setStep] = useState(steps[0]);
  const navigate = useNavigate();
  const classes = useStyles();
  const [showClose, setShowClose] = useState(false);

  useEffect(() => {
    if (step === 'RoundSettings') {
      setShowClose(false);
    } else {
      setShowClose(true);
    }
  }, [step]);

  const updateStep = (move) => {
    const newStep = steps[steps.indexOf(step) + move];
    setStep(newStep);
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const onCloseClick = () => {
    if (step === 'RoundWithdraw') {
      updateStep(-1);
    } else if (step === 'RoundWeekly') {
      updateStep(-2);
    } else if (step === 'RoundMonthly') {
      updateStep(-3);
    } else {
      updateStep(-4);
    }
  };

  let activeComponent = '';
  switch (step) {
    case 'RoundSettings':
      activeComponent = <RoundSettings updateStep={updateStep} />;
      break;
    case 'RoundWithdraw':
      activeComponent = <RoundWithdraw updateStep={updateStep} />;
      break;
    case 'RoundWeekly':
      activeComponent = <RoundWeekly updateStep={updateStep} />;
      break;
    case 'RoundMonthly':
      activeComponent = <RoundMonthly updateStep={updateStep} />;
      break;
    case 'RoundDateSelection':
      activeComponent = <RoundDateSelection updateStep={updateStep} />;
      break;
  }

  return (
    <Container maxWidth="sm" className={classes.mainContent}>
      <div>
        {showClose ? (
          <CloseButton onClick={onCloseClick} />
        ) : (
          <BackButton onClick={onClickBack} />
        )}
        <Card sx={{ width: '100%' }}>
          <CardContent>{activeComponent}</CardContent>
        </Card>
      </div>
    </Container>
  );
}

export default RoundSetUp;
