import * as React from 'react';
import { makeStyles } from '@mui/styles';
import Logo from '../assets/images/logo.svg';
import { Container } from '@mui/material';

const useStyles = makeStyles(() => ({
    logoContainer: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        width:'100%'
    },
    logoImage:{
        height:'50px',
        marginTop:'10px',
        marginBottom:'5px'
    }
}));

export default function LogoImage() {
  const classes = useStyles();
  return (
    <div className={classes.logoContainer} >
        <img className={classes.logoImage} src={Logo} alt="logo" />
    </div>
  );
}
