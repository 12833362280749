import React, { useContext } from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Context as PaymentContext } from '../../context/PaymentContext';
import { SET_ROUND_UP } from '../../context/types';
import TitleText from '../../components/common/TitleText';
import CButton from '../../components/common/CButton';
import GlobalStyle from '../../styles/GlobalStyle';
import { sendAmplitudeData } from '../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function RoundWithdraw(props) {
  const { updateStep } = props;
  const { state, dispatch } = useContext(PaymentContext);
  const classes = useStyles();

  const handleSelection = (frequency, depositMode) => {
    const effectiveFrequency = frequency === '' ? 'Manually' : frequency;
    const capitalizedFrequency =
      effectiveFrequency.charAt(0).toUpperCase() + effectiveFrequency.slice(1);

    sendAmplitudeData('SavingsSetup_8_SetupRoundup', {
      click_name: capitalizedFrequency,
    });

    dispatch({
      type: SET_ROUND_UP,
      payload: { frequency, depositMode },
    });

    if (frequency === 'weekly') {
      updateStep(1);
    } else if (frequency === 'monthly') {
      updateStep(2);
    } else {
      updateStep(3);
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title="How often would you like to add funds?"
        description="Set up recurring deposits and add funds to BuoyFi Savings from your bank account."
      />

      <Grid item className={classes.buttonStyle1}>
        <CButton
          variant="outlined"
          fullWidth
          onClick={() => handleSelection('weekly', 'auto')}>
          Weekly
        </CButton>
        <CButton
          variant="outlined"
          fullWidth
          sx={{ marginTop: 2 }}
          onClick={() => handleSelection('monthly', 'auto')}>
          Monthly
        </CButton>
        {!state.roundUp.roundUpEditing && (
          <CButton
            variant="outlined"
            fullWidth
            sx={{ marginTop: 2 }}
            onClick={() => handleSelection('', 'manual')}>
            I’ll do it manually
          </CButton>
        )}
      </Grid>
    </Container>
  );
}

export default RoundWithdraw;
