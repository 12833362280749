import { Container } from '@mui/material';
import React from 'react';
import TitleText from '../../../components/common/TitleText';

function LinkedBillers() {

  return (
    <Container maxWidth="sm" >
      <TitleText
        title="Linked Billers"
      />
    </Container>
  );
}

export default LinkedBillers;
