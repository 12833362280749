import React from 'react';
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../../styles/GlobalStyle';
import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import {
  INFO_PLAID_MAIN_DESCRIPTION,
  INFO_PLAID_MAIN_DESCRIPTION_P1,
  INFO_PLAID_MAIN_DESCRIPTION_P2,
  INFO_PLAID_MAIN_DESCRIPTION_P3,
  INFO_PLAID_TITLE,
} from 'capio-common/src/constants';
import { sendAmplitudeData } from '../../../utilities/amplitude';
import { ReactComponent as Cash } from '../../../assets/images/currency-plus.svg';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  description: {
    fontFamily: 'OpenSans',
    marginBottom: '15px',
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: 0,
  },
  bullet: {
    width: 20,
    height: 20,
    minWidth: 20,
    borderRadius: '50%',
    backgroundColor: '#DCF1DA',
    color: '#250E39',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    marginTop: 5,
    fontSize: '10px',
    fontWeight: 800,
    fontFamily: 'OpenSans',
  },
  listItemText: {
    fontFamily: 'OpenSans',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 48,
  },
}));

const buttonSx = { 
  fontSize: '16px',
  width: '100%',
  backgroundColor: '#4A26D6',
  color: '#FFFFFF',
  padding: '10px 20px',
  textTransform: 'none',
  borderRadius: '54px',
  margin: '20px 0px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: 6
 };

export default function InfoNotINPlaid({
  updateStep,
  setManualIncomeParentName,
}) {
  const classes = useStyles();

  const items = [
    INFO_PLAID_MAIN_DESCRIPTION_P1,
    INFO_PLAID_MAIN_DESCRIPTION_P2,
    INFO_PLAID_MAIN_DESCRIPTION_P3,
  ];

  const RenderIcon = () => {
    return (
      <Box className={classes.iconContainer}>
        <Cash />
      </Box>
    );
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title={INFO_PLAID_TITLE}
        icon={<RenderIcon />}
        description={INFO_PLAID_MAIN_DESCRIPTION}
      />
      
      <CButton
        sx={{...buttonSx}}
        fullWidth
        variant="contained"
        onClick={() => {
          setManualIncomeParentName('InfoNotINPlaid');
          sendAmplitudeData('CalcSetup_EnterIncomeManually');
          updateStep('AddHouseholdSize');
        }}>
        Enter income manually
      </CButton>
    </Container>
  );
}
