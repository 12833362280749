import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Button,
  Typography,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { onUpdateUserInformationById } from 'capio-common/src/graphql/subscriptions';
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

import GlobalStyle from '../../../styles/GlobalStyle';
import KYCBGP from '../../../assets/images/kyc-bg-purple.png';
import KYCBG from '../../../assets/images/kyc-bg.png';
import CloseButton from '../../../components/CloseButton';
import Agree from './Agree';
import DebtInfo from './DebtInfo';
import AddMedicalDebt from './AddMedicalDebt';
import AddHouseholdSize from './AddHouseholdSize';
import BackButton from '../../../components/BackButton';
import ConfirmDebtInfo from './ConfirmDebtInfo';
import { Context as UserContext } from '../../../context/UserContext';
import { Context as PaymentContext } from '../../../context/PaymentContext';
import ConnectPayroll from './ConnectPayroll';
import ProvideIncome from './ProvideIncome';
import ConfirmIncomeInfo from './ConfirmIncometInfo';
import ConnectPayrollPlaid from './ConnectPayrollPlaid';
import InfoNotINPlaid from './InfoNotINPlaid';
import ProvideIncomeManually from './ProvideIncomeManually';
import LowIncome from './LowIncome';
import PayrollDocVerify from './PayrollDocVerify';
import PayrollDocSuccess from './PayrollDocSuccess';
import SendMeMail from '../../compass/SendMeMail';
import ManualEmail from '../../compass/ManualEmail';
import ProgressIndicator from '../../../components/ProgressIndicator';
import PopupModal from '../../../components/common/PopupModal';
import SPModalContent from '../../../components/common/SPModalContent';
import CopyRight from '../../../components/common/CopyRight';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  cardContent: {
    position: 'relative',
    paddingTop: '30px',
  },
  backgroundImage: {
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100%',
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    top: '25px',
    right: '25px',
  },
  backButton: {
    position: 'absolute',
    top: '25px',
    left: '25px',
  },
  portalButton: {
    width: '100%',
    backgroundColor: '#4A26D6',
    color: '#FFFFFF',
    padding: '10px 20px',
    textTransform: 'none',
    borderRadius: '54px',
    margin: '20px 0px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  portalText: {
    fontSize: '16px',
    fontWeight: 600,
  },
}));

const capioSteps = [
  'Agree',
  'DebtInfo',
  'AddMedicalDebt',
  'ConfirmDebtInfo',
  'ConnectPayrollPlaid',
  'InfoNotINPlaid',
  'ConnectPayroll',
  'AddHouseholdSize',
  'ProvideSpouseIncome',
  'ProvideOtherIncome',
  'ConfirmIncomeInfo',
  'ProvideIncomeManually',
  'LowIncome',
  'SendMeMail',
  'ManualEmail',
  'PayrollDocVerify',
  'PayrollDocSuccess',
];
const nonCapioSteps = [
  'Agree',
  'AddMedicalDebt',
  'ConfirmDebtInfo',
  'ConnectPayrollPlaid',
  'InfoNotINPlaid',
  'ConnectPayroll',
  'AddHouseholdSize',
  'ProvideSpouseIncome',
  'ProvideOtherIncome',
  'ConfirmIncomeInfo',
  'ProvideIncomeManually',
  'LowIncome',
  'SendMeMail',
  'ManualEmail',
  'PayrollDocVerify',
  'PayrollDocSuccess',
];

export default function Compass() {
  const classes = useStyles();
  const theme = useTheme();
  const width = window.innerWidth;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [option, setOption] = useState();
  const [householdSizeEdit, setHouseholdSizeEdit] = useState(false);
  const [manualIncomeParentName, setManualIncomeParentName] = useState('');
  const [showPolicy, setShowPolicy] = useState(false);
  const { state: userState } = useContext(UserContext);
  const subscription = useRef();
  const { state } = useLocation();

  const isLocalStorageAvailable = () => {
    try {
      const testKey = '__test__';
      localStorage.setItem(testKey, '__test__');
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  };

  const [medicalDebt, setMedicalDebt] = useState(() => {
    if (isLocalStorageAvailable()) {
      const savedMedicalDebt = localStorage.getItem('calculatorMedicalDebt');
      if (savedMedicalDebt) {
        return JSON.parse(savedMedicalDebt);
      }
    }
    return {
      amount: '',
      debtHolders: [{ name: '', amount: '' }],
    };
  });

  const [income, setIncome] = useState(() => {
    if (isLocalStorageAvailable()) {
      const savedIncome = localStorage.getItem('calculatorIncome');
      if (savedIncome) {
        return JSON.parse(savedIncome);
      }
    }
    return {
      spouseIncome: { amount: '', checked: false },
      otherIncome: { amount: '', checked: false },
      monthlyIncome: {
        employerName: '',
        businessName: '',
        amount: '',
        isMarried: true,
      },
      incomeType: 'manual',
    };
  });

  const [householdSize, setHouseholdSize] = useState(() => {
    if (isLocalStorageAvailable()) {
      const savedHouseholdSize = localStorage.getItem(
        'calculatorHouseholdSize',
      );
      if (savedHouseholdSize) {
        return JSON.parse(savedHouseholdSize);
      }
    }
    return null;
  });

  const [step, setStep] = useState(() => {
    const steps = userState['capioAccount'] ? capioSteps : nonCapioSteps;
    const initialStep = capioSteps[0];
    if (isLocalStorageAvailable()) {
      const storedStep = localStorage.getItem('calculatorCurrentStep');
      if (storedStep && steps.includes(storedStep)) {
        return storedStep;
      }
    }
    return initialStep;
  });

  const clearSavedData = () => {
    if (isLocalStorageAvailable()) {
      localStorage.removeItem('calculatorMedicalDebt');
      localStorage.removeItem('calculatorIncome');
      localStorage.removeItem('calculatorHouseholdSize');
      localStorage.removeItem('calculatorCurrentStep');
      localStorage.removeItem('calculatorProgress');
    }
    setMedicalDebt({ amount: '', debtHolders: [{ name: '', amount: '' }] });
    setIncome({
      spouseIncome: { amount: '', checked: false },
      otherIncome: { amount: '', checked: false },
      monthlyIncome: {
        employerName: '',
        businessName: '',
        amount: '',
        isMarried: true,
      },
      incomeType: 'manual',
    });
    setHouseholdSize(null);
    setStep(userState['capioAccount'] ? capioSteps[0] : nonCapioSteps[0]);
  };

  useEffect(() => {
    console.log('subscription start');
    if (!userState.userSub) return;
    subscription.current = API.graphql({
      query: onUpdateUserInformationById,
      variables: {
        id: userState.userSub,
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    }).subscribe({
      next: async ({ provider, value }) => {
        console.log('subscription===>', value);
        if (
          value?.data?.onUpdateUserInformationById?.incomeInformation?.verified
        ) {
          setStep('PayrollDocSuccess');
        }
      },
      error: (error) => {
        console.error('subscription error:', error);
      },
    });
    return () => {
      console.log('unsubscription');
      subscription.current && subscription.current.unsubscribe();
    };
  }, [userState.userSub]);

  useEffect(() => {
    if (isLocalStorageAvailable()) {
      localStorage.setItem(
        'calculatorMedicalDebt',
        JSON.stringify(medicalDebt),
      );
    }
  }, [medicalDebt]);

  useEffect(() => {
    if (isLocalStorageAvailable()) {
      localStorage.setItem('calculatorIncome', JSON.stringify(income));
    }
  }, [income]);

  useEffect(() => {
    if (isLocalStorageAvailable()) {
      localStorage.setItem(
        'calculatorHouseholdSize',
        JSON.stringify(householdSize),
      );
    }
  }, [householdSize]);

  useEffect(() => {
    if (state?.step) {
      setStep(state?.step);
    }
  }, [state]);

  const updateStep = useCallback(
    (move) => {
      if (move === 'completion') {
        clearSavedData();
        navigate('/home ');
        return;
      }
      const steps = userState['capioAccount'] ? capioSteps : nonCapioSteps;

      let newStep;
      // backwards compatibility, it will either be a String or number
      if (isNaN(move)) {
        newStep = move;
      } else {
        const currentStepIndex = steps.indexOf(step);
        const newStepIndex = currentStepIndex + move;
        newStep = steps[newStepIndex];
      }

      setStep(newStep);

      if (isLocalStorageAvailable()) {
        localStorage.setItem('calculatorCurrentStep', newStep);
      }

      const currentStepIndex = steps.indexOf(newStep);
      const progressPercentage =
        ((currentStepIndex + 1) / (steps.length - 5)) * 100;

      if (isLocalStorageAvailable()) {
        localStorage.setItem(
          'calculatorProgress',
          progressPercentage.toString(),
        );
      }
    },
    [userState['capioAccount'], step, capioSteps, nonCapioSteps],
  );

  let activeComponent = '';
  switch (step) {
    case 'Agree':
      activeComponent = <Agree updateStep={updateStep} setStep={setStep} />;
      break;
    case 'DebtInfo':
      activeComponent = (
        <DebtInfo
          capioAccount={userState['capioAccount']}
          updateStep={updateStep}
          showPolicy={showPolicy}
          setShowPolicy={setShowPolicy}
        />
      );
      break;
    case 'AddMedicalDebt':
      activeComponent = (
        <AddMedicalDebt
          capioAccount={userState['capioAccount']}
          medicalDebt={medicalDebt}
          setMedicalDebt={setMedicalDebt}
          updateStep={updateStep}
          option={option}
          setOption={setOption}
        />
      );
      break;
    case 'ConfirmDebtInfo':
      activeComponent = (
        <ConfirmDebtInfo
          capioAccount={userState['capioAccount']}
          medicalDebt={medicalDebt}
          setMedicalDebt={setMedicalDebt}
          updateStep={updateStep}
          option={option}
        />
      );
      break;
    case 'ConnectPayrollPlaid':
      activeComponent = (
        <ConnectPayrollPlaid
          income={income}
          setIncome={setIncome}
          updateStep={updateStep}
          setManualIncomeParentName={setManualIncomeParentName}
          edit={state?.edit}
        />
      );
      break;
    case 'InfoNotINPlaid':
      activeComponent = (
        <InfoNotINPlaid
          setManualIncomeParentName={setManualIncomeParentName}
          updateStep={updateStep}
        />
      );
      break;
    case 'ConnectPayroll':
      activeComponent = (
        <ConnectPayroll
          income={income}
          setIncome={setIncome}
          setManualIncomeParentName={setManualIncomeParentName}
          updateStep={updateStep}
        />
      );
      break;
    case 'AddHouseholdSize':
      activeComponent = (
        <AddHouseholdSize
          updateStep={updateStep}
          householdSize={householdSize}
          setHouseholdSize={setHouseholdSize}
          setHouseholdSizeEdit={setHouseholdSizeEdit}
          householdSizeEdit={householdSizeEdit}
          income={income}
        />
      );
      break;
    case 'ProvideSpouseIncome':
      activeComponent = (
        <ProvideIncome
          step={step}
          type="spouseIncome"
          updateStep={updateStep}
          income={income}
          setIncome={setIncome}
        />
      );
      break;
    case 'ProvideOtherIncome':
      activeComponent = (
        <ProvideIncome
          type="otherIncome"
          updateStep={updateStep}
          income={income}
          setIncome={setIncome}
        />
      );
      break;
    case 'ConfirmIncomeInfo':
      activeComponent = (
        <ConfirmIncomeInfo
          capioAccount={userState['capioAccount']}
          medicalDebt={medicalDebt}
          isLoading={isLoading}
          householdSize={householdSize}
          setHouseholdSize={setHouseholdSize}
          setHouseholdSizeEdit={setHouseholdSizeEdit}
          setIsLoading={setIsLoading}
          updateStep={updateStep}
          income={income}
          setIncome={setIncome}
          edit={state?.edit}
        />
      );
      break;
    case 'ProvideIncomeManually':
      activeComponent = (
        <ProvideIncomeManually
          manualIncomeParentName={manualIncomeParentName}
          income={income}
          setIncome={setIncome}
          updateStep={updateStep}
        />
      );
      break;
    case 'LowIncome':
      activeComponent = <LowIncome updateStep={updateStep} />;
      break;
    case 'SendMeMail':
      activeComponent = <SendMeMail fromSetUp={true} />;
      break;
    case 'ManualEmail':
      activeComponent = <ManualEmail fromSetUp={true} />;
    case 'PayrollDocVerify':
      activeComponent = (
        <PayrollDocVerify
          income={income}
          setIncome={setIncome}
          updateStep={updateStep}
          setStep={(val) => setStep(val)}
        />
      );
      break;
    case 'PayrollDocSuccess':
      activeComponent = (
        <PayrollDocSuccess
          income={income}
          setIncome={setIncome}
          updateStep={(val) => {
            subscription.current && subscription.current.unsubscribe();
            updateStep(val);
          }}
          setStep={(val) => {
            subscription.current && subscription.current.unsubscribe();
            setStep(val);
          }}
        />
      );
      break;
  }

  const goBack = () => {
    if (state?.edit) {
      navigate(-1);
      return;
    }
    if (step === 'ProvideIncomeManually') {
      if (manualIncomeParentName === 'ConnectPayroll') {
        updateStep(-5);
      } else {
        updateStep(-4);
      }
    } else if (step === 'AddHouseholdSize') {
      if (manualIncomeParentName === 'InfoNotINPlaid') {
        updateStep(-2);
      } else {
        updateStep(-1);
      }
    } else if (step === 'ProvideOtherIncome') {
      if (manualIncomeParentName) {
        updateStep(2);
      } else {
        updateStep(-3);
      }
    } else if (step !== 'ConnectPayroll') updateStep(-1);
    else if (
      step === 'ProvideOtherIncome' &&
      income['monthlyIncome']['isMarried'] === false
    )
      updateStep(-2);
    else updateStep(-2);
  };

  const onClose = () => {
    if (showPolicy) {
      setShowPolicy(false);
      return;
    }

    if (state?.edit) {
      navigate(-1);
      return;
    }
    if (step === 'InfoNotINPlaid') {
      updateStep(-1);
    } else {
      navigate('/home');
    }
  };

  return (
    <Grid
      container
      sx={{
        flexDirection: 'row',
        height: '100%',
      }}>
      <Grid item width={'100%'} display="flex" alignItems="center">
        <Box
          className={classes.backgroundImage}
          sx={{
            position: 'absolute',
            backgroundColor: theme.palette.bw10.main,
          }}>
          <Container
            className={classes.authContainer}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Container maxWidth="sm" sx={{ marginTop: 5 }}>
              <Card>
                <CardContent className={classes.cardContent}>
                  {!isLoading ? (
                    <>
                      {step !== 'Agree' && !state?.edit && (
                        <ProgressIndicator step={step} />
                      )}
                      {step !== 'Agree' &&
                      step !== 'InfoNotINPlaid' &&
                      !showPolicy &&
                      step !== 'SendMeMail' &&
                      step !== 'ManualEmail' ? (
                        <BackButton
                          className={classes.backButton}
                          onClick={() => goBack()}
                        />
                      ) : null}
                      {step !== 'SendMeMail' && step !== 'ManualEmail' ? (
                        <CloseButton
                          className={classes.closeButton}
                          onClick={() => onClose()}
                        />
                      ) : null}
                    </>
                  ) : null}
                  {activeComponent}
                </CardContent>
              </Card>
            </Container>
          </Container>
          <Box
            mt={4}
            sx={{
              paddingBottom: '20px',
            }}>
            <CopyRight />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
