import * as React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export default function CSelect(props) {
  const { inputLabel, labelId, label, value, menuItems, onChange } = props;
  return (
    <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 2 }}>
      <InputLabel id={labelId}>{inputLabel}</InputLabel>
      <Select
        id="simple-select"
        labelId={labelId}
        label={label}
        value={value}
        onChange={onChange}>
        {menuItems.map((item, key) => {
          return (
            <MenuItem key={key} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
