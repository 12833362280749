import React, { useContext, useState } from 'react';
import { Container, Card, CardContent, Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import LogInAccount from './LogInAccount';
import SignHelp from './SignHelp';
import ForgotEmail from './ForgotEmail';
import ForgotPassword from './ForgotPassword';
import OTP from '../kyc/OTP';
import VerifyResult from './VerifyResult';
import ChangePassword from './ChangePassword';
import CommonResult from '../../components/CommonResult';
import TOS from '../kyc/TOS';
import LoginFailure from './LoginFailure';
import BackButton from '../../components/BackButton';
import CButton from '../../components/common/CButton';
import { Context as UserContext } from '../../context/UserContext';
import { USER_SETUP } from '../../context/types';
import LogoImage from '../../components/LogoImage';
import { makeStyles } from '@mui/styles';
import CopyRight from '../../components/common/CopyRight';

const useStyles = makeStyles(() => ({
  cardContent: {
    position: 'relative',
  },
}));

const steps = [
  'LogInAccount',
  'SignHelp',
  'ForgotEmail',
  'ForgotPassword',
  'OTP',
  'VerifyResult',
  'ChangePassword',
  'CommonResult',
  'TOS',
  'LoginFailure',
];

function SignIn(props) {
  const { updateAuthStep } = props;
  const [step, setStep] = useState(steps[0]);
  const [terms, setTerms] = useState(1);
  const [failureMsg, setFailureMsg] = useState('');
  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const classes = useStyles();

  const updateStep = (move, terms) => {
    if (terms) setTerms(terms);
    const newStep = steps[steps.indexOf(step) + move];
    setStep(newStep);

    if (newStep === 'LogInAccount') {
      dispatch({
        type: USER_SETUP,
        payload: { showOnboarding: true },
      });
    } else {
      dispatch({
        type: USER_SETUP,
        payload: { showOnboarding: false },
      });
    }
  };

  const onClickBack = () => {
    if (step === 'ForgotPassword') {
      updateStep(-2);
    } else {
      updateStep(-1);
    }
  };

  const hideBackButton =
    step === 'LogInAccount' ||
    step === 'TOS' ||
    step === 'LoginFailure' ||
    step === 'VerifyResult' ||
    step === 'ChangePassword' ||
    step === 'CommonResult';

  const showSignUpButton = step === 'LogInAccount';

  let activeComponent = '';
  switch (step) {
    case 'LogInAccount':
      activeComponent = <LogInAccount updateStep={updateStep} />;
      break;
    case 'SignHelp':
      activeComponent = <SignHelp updateStep={updateStep} />;
      break;
    case 'LoginFailure':
      activeComponent = (
        <LoginFailure updateStep={updateStep} backStepCount={-9} />
      );
      break;
    case 'ForgotEmail':
      activeComponent = <ForgotEmail updateStep={updateStep} />;
      break;
    case 'ForgotPassword':
      activeComponent = <ForgotPassword updateStep={updateStep} />;
      break;
    case 'OTP':
      activeComponent = <OTP updateStep={updateStep} />;
      break;
    case 'VerifyResult':
      activeComponent = <VerifyResult updateStep={updateStep} />;
      break;
    case 'ChangePassword':
      activeComponent = (
        <ChangePassword updateStep={updateStep} setFailureMsg={setFailureMsg} />
      );
      break;
    case 'CommonResult':
      activeComponent = (
        <CommonResult
          success={state.forgotPasswordResult}
          successTitle="Password Changed!"
          successMsg="Your password has been changed successfully."
          successBtn="Continue to Sign in"
          failureTitle="Password Reset Failed"
          failureMsg={failureMsg}
          failureBtn="Retry Again"
          onClickDone={() => updateStep(-7)}
          onClickRetry={() => updateStep(-3)}
        />
      );
      break;
    case 'TOS':
      activeComponent = (
        <TOS updateStep={updateStep} backStepCount={-8} terms={terms} />
      );
      break;
  }

  return (
    <Container maxWidth="sm" sx={{ marginTop: -1 }}>
      <Grid display="flex" justifyContent="space-between">
        <BackButton
          sx={{
            visibility: hideBackButton ? 'hidden' : 'visible',
            color: '#fff',
          }}
          onClick={onClickBack}
        />
        <CButton
          sx={{ visibility: showSignUpButton ? 'visible' : 'hidden' }}
          variant="text"
          onClick={() => {
            sessionStorage.removeItem('buoyfiToken');
            sessionStorage.removeItem('buoyfiUserId');
            updateAuthStep(-1);
          }}>
          Sign up
        </CButton>
      </Grid>
      <Card>
        <CardContent className={classes.cardContent}>
          {!state.showOnboarding ? <LogoImage /> : null}
          {activeComponent}
        </CardContent>
      </Card>
      <Box bottom={4} mt={4}>
        <CopyRight />
      </Box>
    </Container>
  );
}

export default SignIn;
