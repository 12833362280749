import React, { useContext } from 'react';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Context as PaymentContext } from '../../context/PaymentContext';
import CustomDatePicker from '../../components/CustomDatePicker';
import GlobalStyle from '../../styles/GlobalStyle';
import { SET_ROUND_UP } from '../../context/types';
import { convertDateLabel } from 'capio-common/src/main';
import { sendAmplitudeData } from '../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function RoundDateSelection(props) {
  const { updateStep } = props;
  const { state, dispatch } = useContext(PaymentContext);
  const classes = useStyles();

  const onClickDone = (day) => {
    sendAmplitudeData('Roundup Monthly', {
      'Day of month': 'Custom Date',
    });
    sendAmplitudeData('Roundup Monthly', {
      'Day of month': `${convertDateLabel(day)}`,
    });
    dispatch({
      type: SET_ROUND_UP,
      payload: { depositDate: day, depositDateLabel: convertDateLabel(day) },
    });

    if (state.roundUp.roundUpEditing) {
      updateStep(-1);
    } else {
      updateStep(-2);
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <CustomDatePicker onClickDone={onClickDone} />
    </Container>
  );
}

export default RoundDateSelection;
