import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

function ReviewItem({ label, description }) {
  return (
    <Grid
      item
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid #C0B9C7',
        paddingBottom: 1,
        mt: 1,
      }}>
      <Typography
        variant="span"
        gutterBottom
        fontWeight={'bold'}
        color="corePurple50.main">
        {label}
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </Grid>
  );
}

export default ReviewItem;
