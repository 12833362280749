import React, { useMemo } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';

import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import GlobalStyle from '../../../styles/GlobalStyle';

import { currencyFormatter } from 'capio-common/src/patterns';
import {
  BANK_SERVICE_EMAIL,
  HELP_LINE_1,
  HELP_LINE_2,
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
} from 'capio-common/src/constants';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

export default function TransactionDetail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const transaction = useMemo(() => {
    return [
      {
        label: 'Date',
        value: moment(state.transaction.msCreatedAt).format(
          'MM/DD/YYYY hh:mm:ss a',
        ),
      },
      {
        label: 'Transaction ID',
        value: state.transaction.transactionID,
      },
      { label: 'Transaction Type', value: state.transaction.transactionType },
      {
        label: 'Accounts of Origin',
        value: state.transaction.fromSource,
      },
      {
        label: 'Destination',
        value:
          state.transaction.toAccount === 'CUSTODY'
            ? 'Save-Up Account'
            : state.transaction.toAccount,
      },
      {
        label: 'Amount',
        value: currencyFormatter(state.transaction.amount),
      },
      {
        label: state.transaction.transactionStatus
          .toLowerCase()
          .includes('pending')
          ? 'Date Scheduled'
          : 'Date Completed',
        value: moment(state.transaction.msCreatedAt).format('YYYY-MM-DD'),
      },
      {
        label: 'Status',
        value: state.transaction.transactionStatus,
      },
      {
        label: 'Description',
        value: state.transaction.description,
      },
    ];
  }, [state]);
  const PaymentListItem = ({ item }) => {
    const { label, value } = item;
    return (
      <Grid
        container
        direction="column"
        borderBottom={1}
        borderColor="#F3F4F6"
        py={1}>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            fontWeight={'bold'}
            color="corePurple50.main"
            gutterBottom>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="corePurple50.main">
            {value}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Card sx={{ width: '100%', marginY: '24px', padding: '12px' }}>
        <Grid item display="flex" justifyContent="flex-end">
          <IconButton onClick={() => navigate('../home/round')}>
            <CloseIcon color="primary" />
          </IconButton>
        </Grid>
        <CardHeader
          component={() => (
            <TitleText
              title="Transaction Details"
              px={2}
              sx={{
                marginBottom: '0 !important',
              }}
            />
          )}
        />
        <CardContent>
          {transaction.map((item, index) => (
            <PaymentListItem item={item} key={index} />
          ))}
          <Box mt={2}>
            <Typography>
              The daily cutoff for canceling a pending ACH transaction is 11am
              PST for same-day ACH transactions, and 3pm PST for regular ACH
              transactions.
            </Typography>
            <Typography mt={2}>
              ACH transfers scheduled on a non-business day will be processed on
              the next business day.
            </Typography>
            <CButton
              variant="contained"
              className={classes.buttonStyle2}
              sx={{ my: 2 }}
              onClick={() =>
                navigate('../home/round/transaction-confirm', {
                  state: {
                    transaction: state.transaction,
                  },
                })
              }>
              {state.transaction.transactionStatus === 'PENDING'
                ? 'Cancel transaction'
                : 'Dispute transaction'}
            </CButton>
            <Typography>
              {HELP_LINE_1} at{' '}
              <a
                target={'_blank'}
                className={classes.link}
                onClick={() => openEmail()}>
                {SUPPORT_EMAIL}
              </a>{' '}
              or at {SUPPORT_PHONE}. {HELP_LINE_2}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}
