import React, { useContext } from 'react';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import TitleText from '../../components/common/TitleText';
import RowItem from '../../components/RowItem';
import CButton from '../../components/common/CButton';
import { Context as UserContext } from '../../context/UserContext';
import { SET_VERIFICATION_TYPE, USER_SETUP } from '../../context/types';
import GlobalStyle from '../../styles/GlobalStyle';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function SignHelp(props) {
  const { updateStep } = props;
  const { dispatch } = useContext(UserContext);
  const classes = useStyles();

  const handleSelection = (type) => {
    dispatch({
      type: SET_VERIFICATION_TYPE,
      payload: { verificationType: type },
    });

    if (type === 'email') {
      updateStep(2);
    } else {
      updateStep(1);
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText title="Need help signing in?" />
      <RowItem
        title="I forgot my password"
        icon={<ArrowForwardIcon />}
        onClick={() => handleSelection('email')}
      />
      <RowItem
        title="I forgot my email address"
        icon={<ArrowForwardIcon />}
        onClick={() => handleSelection('phone')}
      />
      <CButton
        variant="text"
        sx={{ marginTop: 2 }}
        onClick={() => updateStep(-1)}>
        Cancel
      </CButton>
    </Container>
  );
}

export default SignHelp;
