import * as React from 'react';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  button: {
    height: 50,
  },
}));

export default function CButton(props) {
  const classes = useStyles();
  return <LoadingButton className={classes.button} {...props} />;
}
