import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

import TitleText from '../../../components/common/TitleText';
import GlobalStyle from '../../../styles/GlobalStyle';
import CSelect from '../../../components/common/CSelect';
import { Context as SynapseContext } from '../../../context/SynapseContext';
import { Context as UserContext } from '../../../context/UserContext';
import CLoading from '../../../components/common/CLoading';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  animatedLogo: {
    height: '80px',
  },
}));

function ViewStatements(props) {
  const { state: userState } = useContext(UserContext);
  const { state: synapseState, getStatements } = useContext(SynapseContext);
  const classes = useStyles();
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const navigate = useNavigate();

  const years = useMemo(() => {
    const currentYear = moment().year();
    const createdYear = moment(userState.createdAt).year();
    const temp = [];
    for (let i = createdYear; i <= currentYear; i++) {
      temp.push({ label: i.toString(), value: i });
    }
    return temp ?? [];
  }, [userState]);

  useEffect(() => {
    getStatements({
      jwt: userState.jwt,
      params: {
        year: selectedYear,
      },
    });
  }, [userState.jwt, selectedYear]);

  const downloadPDF = (item) => {
    window.open(item.urls.pdf, '_blank');
  };

  if (synapseState.loading) {
    return <CLoading />;
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Card sx={{ width: '100%', marginY: '24px', padding: '12px' }}>
        <Grid item display="flex" justifyContent="flex-end">
          <IconButton onClick={() => navigate(-1)}>
            <CloseIcon color="primary" />
          </IconButton>
        </Grid>
        <CardHeader
          component={() => (
            <TitleText
              title="View Statements"
              description="Select a year to view and download PDF copies of your monthly statements."
              px={2}
              sx={{
                marginBottom: '0 !important',
              }}
            />
          )}></CardHeader>
        <CardContent>
          {years.length && (
            <Box mt={3}>
              <CSelect
                inputLabel="Year"
                label="Year"
                labelId="year-select-label"
                value={selectedYear}
                menuItems={years}
                onChange={(e) => setSelectedYear(e.target.value)}
              />
            </Box>
          )}

          {synapseState.statements.map((item) => (
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              borderBottom={1}
              borderColor="#F3F4F6"
              py={2}
              key={item._id}>
              <Grid item>
                <Typography color="corePurple60.main">
                  {item.type.split('_').slice(0, 2).join('')}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  component="label"
                  color="purple50"
                  onClick={() => downloadPDF(item)}>
                  <FileDownloadIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </CardContent>
      </Card>
    </Container>
  );
}

export default ViewStatements;
