import moment from 'moment';
import {
  ENTER_VALID_DATE,
  INVALID_DOB,
  MUST_18YEARS_OLD,
  SOMETHING_WRONG,
  SUPPORT_EMAIL,
  TRANSACTION_NOT_PAST,
} from './constants';

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const polishValue = (value) => {
  return value.replace(/-/g, '').replace(/_/g, '');
};

export const formatPhoneNumber = (number) => {
  const newNumber = number.replace(/-/g, '');
  return `+1${newNumber}`;
};

export const revertPhoneFormat = (number) => {
  return `${number.slice(2, 5)}-${number.slice(5, 8)}-${number.slice(8, 12)}`;
};

export const formatToUSPhoneNumber = (number) => {
  return `1 (${number.slice(0, 3)}) ${number.slice(4, 7)}-${number.slice(8)}`;
};

export const getSecurePhone = (phone) => {
  const securePhone = `(•••) ••• ${phone.slice(-4)}`;
  return securePhone;
};

export const getSecureEmail = (email) => {
  const pIndex = email.indexOf('@');
  const secureEmail = `${email.slice(0, 2)}••••••••${email.slice(pIndex)}`;
  return secureEmail;
};

export const validateDate = (date) => {
  const todayDate = moment(new Date());
  const formatDate = moment(date, 'MM-DD-YYYY');
  if (!formatDate.isValid() || formatDate.year() < 1900) {
    return INVALID_DOB;
  }

  const durationYear = moment.duration(todayDate.diff(formatDate)).asYears();
  if (durationYear < 18) {
    return MUST_18YEARS_OLD;
  }

  return '';
};

export const validatePaymentDate = (date) => {
  const todayDate = moment(new Date(), 'MM-DD-YYYY');
  const formatDate = moment(date, 'MM-DD-YYYY');
  if (!formatDate.isValid()) {
    return ENTER_VALID_DATE;
  }

  const dayDiff = moment.duration(todayDate.diff(formatDate)).asDays();
  if (dayDiff >= 1) {
    return TRANSACTION_NOT_PAST;
  }

  return '';
};

export const getLastDayOfMonth = () => {
  const today = new Date();
  const lastDayOfMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0,
  ).getDate();
  return lastDayOfMonth;
};

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

export const convertDateLabel = (date, frequency) => {
  if (frequency === 'weekly') {
    return WEEKDAYS[date - 1];
  }

  if (date == 1 || date == 21 || date == 31) {
    return `${date}st`;
  }

  if (date == 2 || date == 22) {
    return `${date}nd`;
  }

  if (date == 3 || date == 23) {
    return `${date}rd`;
  }

  return `${date}th`;
};

export const getNextDepositDate = (day, frequency) => {
  let date = '',
    nextDate = '';
  if (frequency === 'weekly') {
    const today = moment().format('MMM DD');
    const settingDate = moment().isoWeekday(day).format('MMM DD');
    const isSameMonth = moment(today).isSame(settingDate, 'month');
    if (today <= settingDate) {
      if (!isSameMonth) {
        nextDate = moment().add(1, 'weeks').isoWeekday(day);
      } else {
        nextDate = moment().isoWeekday(day);
      }
    } else {
      if (!isSameMonth) {
        nextDate = moment().isoWeekday(day);
      } else {
        nextDate = moment().add(1, 'weeks').isoWeekday(day);
      }
    }
    date = moment(nextDate).format('MMM DD');
  } else {
    date = `${moment().add(1, 'months').format('MMM')} ${convertDateLabel(
      day,
    )}`;
  }

  return date;
};

export const openEmail = (value) => {
  window.open(`mailto:${value || SUPPORT_EMAIL}?subject=Help&body=`);
};

export const formatError = (error) => {
  return error.response.data.message || SOMETHING_WRONG;
};
