import React, { useContext, useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

import { Context as UserContext } from '../../context/UserContext';
import CButton from '../../components/common/CButton';
import ResultItem from '../../components/ResultItem';
import GlobalStyle from '../../styles/GlobalStyle';
import { sendAmplitudeData } from '../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function VerifyResult(props) {
  const { updateStep } = props;
  const { state, createUser, handleRefreshToken } = useContext(UserContext);
  const [verifyType, setVerifyType] = useState('');
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    const { verificationType } = state;
    setVerifyType(
      verificationType === 'phone' ? 'phone number' : 'email address',
    );
  }, []);

  const handleCreateUser = async () => {
    await createUser(state);
    sendAmplitudeData('InitialKYC_5_KYCCompleted');
    setInterval(() => {
      handleRefreshToken();
    }, [3602 * 1000]);
    navigate('../home');
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      {state.userConfirmed ? (
        <>
          <ResultItem
            title="You’re all set!"
            description="Congratulations! Are you ready to rise above medical bill?"
            variant="success"
            icon={<CheckIcon style={{ fontSize: 40, color: '#fff' }} />}
          />
          <Grid item className={classes.buttonStyle1}>
            <CButton
              variant="contained"
              fullWidth
              loading={state.loading}
              onClick={handleCreateUser}>
              Let’s get started
            </CButton>
          </Grid>
        </>
      ) : (
        <>
          <ResultItem
            title="Error"
            description={`Your ${verifyType} is not verified.`}
            variant="error"
            icon={<CloseIcon style={{ fontSize: 40, color: '#fff' }} />}
          />
          <Grid item className={classes.buttonStyle1}>
            <CButton
              variant="contained"
              fullWidth
              onClick={() => updateStep(-2)}>
              Retry verification
            </CButton>
          </Grid>
        </>
      )}
    </Container>
  );
}

export default VerifyResult;
