import * as React from 'react';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const useStyles = makeStyles(() => ({
  button: {
    height: 50,
  },
}));

export default function BackButton(props) {
  // const classes = useStyles();
  return (
    <IconButton {...props}>
      <ArrowBackIcon />
    </IconButton>
  );
}
