import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import GlobalStyle from '../../../styles/GlobalStyle';
import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import {
  CONFIRM_INCOME_INFO_DESCRIPTIONP1,
  CONFIRM_INCOME_INFO_DESCRIPTIONP2,
  CONFIRM_INCOME_INFO_MAIN_DESCRIPTION,
  CONFIRM_INCOME_INFO_TITLE,
  CONFIRM_INFO_LOADING_DESCRIPTION,
  CONFIRM_INFO_LOADING_TITLE,
} from 'capio-common/src/constants';
import { Context as UserContext } from '../../../context/UserContext';
import { ReactComponent as BulbIcon } from '../../../assets/images/light-bulb.svg';
import { currencyFormatter } from 'capio-common/src/patterns';
import { sendAmplitudeData } from '../../../utilities/amplitude';
import CLoading from '../../../components/common/CLoading';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  description: {
    fontFamily: 'OpenSans',
    marginBottom: '10px',
    marginTop: '10px',
  },
  accountDetailTitle: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'flex-end',
    margin: '0px 0px 8px 0px',
    fontWeight: 600,
  },
  accountDetail: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    fontWeight: 600,
  },
  accountDetailFooter: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
    marginBottom: '10px',
    fontWeight: 600,
    borderTop: '1px solid',
    borderColor: '#C0B9C7',
    paddingTop: '15px',
  },
  accountDetailHeader: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
    fontWeight: 600,
    borderBottom: '1px solid',
    borderColor: '#C0B9C7',
    paddingBottom: '10px',
  },
  otherAccount: {
    display: 'flex',
  },
  accountUpdateIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  accountIcons: {
    height: '20px',
    marginLeft: '5px',
    cursor: 'pointer',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  animatedLogo: {
    height: '80px',
  },
  infoBlock: {
    marginTop: 50,
    position: 'relative',
    background: '#F3F1F5',
    padding: 20,
    borderRadius: 16,
    width: '97%',
  },
  infoIcon: {
    height: 50,
    position: 'absolute',
    top: -20,
    left: -20,
  },
  IconRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  infoText: {
    marginLeft: 10,
    fontWeight: 600,
  },
  loadingText: {
    marginTop: '10px',
    fontSize: '16px',
    fontFamily: 'OpenSans',
  },
}));

const buttonSx = { 
  fontSize: '16px',
  width: '100%',
  backgroundColor: '#4A26D6',
  color: '#FFFFFF',
  padding: '10px 20px',
  textTransform: 'none',
  borderRadius: '54px',
  margin: '20px 0px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: 2
 };

export default function ConfirmIncomeInfo({
  capioAccount,
  medicalDebt,
  householdSize,
  setHouseholdSize,
  setHouseholdSizeEdit,
  updateStep,
  income,
  setIncome,
  isLoading,
  setIsLoading,
  edit,
}) {
  const classes = useStyles();

  const [checked, setChecked] = useState(false);
  const [userIncome, setUserIncome] = useState(0);
  const [isBeforeFinish, setBeforeFinish] = useState(true);
  const [isDataReady, setIsDataReady] = useState(false);
  const [delayIntervalId, setDelayIntervalId] = useState();

  const {
    state: userState,
    updateCapioAccount,
    getUserInfo,
    updateUserInfo,
  } = useContext(UserContext);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onDataReady = (income, intervalClearId) => {
    setUserIncome(income);
    setIsDataReady(true)
    setIsLoading(false);
    clearInterval(intervalClearId);
  }
  
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      await getUserInfo(userState.userSub); // Fetch user info from API
      if (userState?.incomeInformation?.userMonthlyIncome) {
        onDataReady(userState?.incomeInformation?.userMonthlyIncome, intervalId)
      } else if(income['incomeType'] == 'manual' ) {
        onDataReady(income['monthlyIncome']['amount'], intervalId)
      }
    };
    fetchData(); // Initial fetch
    const intervalId = setInterval(fetchData, 2000); // Fetch every 2 seconds
    setDelayIntervalId(intervalId);
    // Cleanup function to clear the interval
   return () => clearInterval(intervalId);
  }, [userState.userSub]); // Dependency on userState.userSub

  useEffect(() => {
    if(userState?.incomeInformation?.userMonthlyIncome) {
        onDataReady(userState.incomeInformation.userMonthlyIncome, delayIntervalId);
    }
  }, [userState.incomeInformation])


  const navigate = useNavigate();

  const getTotalIncome = () => {
    let total =
      parseFloat(userIncome) +
      parseFloat(income?.spousalIncome?.amount ?? 0) +
      parseFloat(income?.otherIncome?.amount ?? 0);
    return total;
  };

  const getTotalMonthlyIncome = () => {
    let totalMonthlyIncome = 0;
    if (income['monthlyIncome']['amount']) {
      totalMonthlyIncome += parseFloat(income['monthlyIncome']['amount']);
    }
    if (income['spouseIncome']['amount']) {
      totalMonthlyIncome += parseFloat(income['spouseIncome']['amount']);
    }
    if (income['otherIncome']['amount']) {
      totalMonthlyIncome += parseFloat(income['otherIncome']['amount']);
    }
    return totalMonthlyIncome;
  };

  const getTotalDebt = () => {
    let balanceSum = 0;
    if (capioAccount) {
      balanceSum = capioAccount.map((item) => item['amount'] || 0);
    }

    if (medicalDebt['amount']) {
      return parseFloat(medicalDebt['amount']) + parseFloat(balanceSum);
    } else {
      return (
        medicalDebt['debtHolders'].reduce(
          (total, debtHolder) => total + parseFloat(debtHolder['amount']),
          0,
        ) + parseFloat(balanceSum)
      );
    }
  };

  const getSavedMedicalDebt = () => {
    let savedMedicalDebt = userState?.compassInfo?.debtInfo;
    let data = {};
    // Same logic as in if not in edit mode
    if (
      (savedMedicalDebt &&
        savedMedicalDebt[0] &&
        !savedMedicalDebt[0].debt.debtName !== 'Medical Bill') ||
      !savedMedicalDebt[0].debt.debtName !== 'Other'
    ) {
      if (!savedMedicalDebt[0].debt.debtName) {
        data['manualDebt'] = [];
        return data['manualDebt'];
      } else {
        data['manualDebt'] = savedMedicalDebt.map((debtHolder) => {
          return {
            debtName: debtHolder.debt.debtName,
            debtAmount: parseFloat(debtHolder.debt.amount),
          };
        });
        return data['manualDebt'];
      }
    } else {
      data['manualDebt'] = [
        {
          debtName: capioAccount ? 'Other' : 'Medical Bill',
          debtAmount: parseFloat(savedMedicalDebt[0].debt.amount),
        },
      ];
      return data['manualDebt'];
    }
  };

  const onFinish = async () => {
    setBeforeFinish(false);
    setIsLoading(true);
    let data = {
      // totalMonthlyIncome: getTotalMonthlyIncome(),
      userIncome: income['monthlyIncome']['amount']
        ? parseFloat(income['monthlyIncome']['amount'])
        : 0,
      spousalIncome: income['spouseIncome']['amount']
        ? parseFloat(income['spouseIncome']['amount'])
        : 0,
      otherIncome: income['otherIncome']['amount']
        ? parseFloat(income['otherIncome']['amount'])
        : 0,
      unemployed: false,
      householdSize: householdSize,
      consenttoshare: moment().format('YYYY-MM-DD'),
      contact: true,
      married: !!income['monthlyIncome']['isMarried'],
      // totalDebt: getTotalDebt(),
      partnerDebt: capioAccount,
      incomeType: income.incomeType,
    };
    if (edit) {
      data['manualDebt'] = getSavedMedicalDebt();
    } else {
      if (!medicalDebt['amount']) {
        if (!medicalDebt.debtHolders?.[0].name) {
          // didn't add additional debt manually
          data['manualDebt'] = [];
        } else {
          data['manualDebt'] = medicalDebt['debtHolders'].map((debtHolder) => {
            return {
              debtName: debtHolder['name'],
              debtAmount: parseFloat(debtHolder['amount']),
            };
          });
        }
      } else {
        data['manualDebt'] = [
          {
            debtName: capioAccount ? 'Other' : 'Medical Bill',
            debtAmount: parseFloat(medicalDebt['amount']),
          },
        ];
      }
    }

    const { status } = await updateCapioAccount(userState.jwt, data);
    if (status) {
      sendAmplitudeData('CalcSetup_9_ReviewIncomeInfo');
      await updateUserInfo({
        id: userState['userSub'],
        paymentSetupFlags: {
          ...userState.paymentSetupFlags,
          compass: true,
        },
        householdSize: householdSize,
        appStatus: {
          parent: '',
          sub: '',
        },
      });
      await getUserInfo(userState.userSub);
      setIsLoading(false);
      if (edit) {
        navigate('/profile/compass-settings');
        return;
      }
      navigate('/compass');
      return;
    }

    setIsLoading(false);
  };



  const RenderGuidelinesLoading = () => {
    return (
      <Box className={classes.contentBox}>
        <Typography variant="h4" className={classes.loadingText}>
          {CONFIRM_INFO_LOADING_TITLE}
        </Typography>
        <Box className={classes.infoBlock} sx={{ marginTop: 2 }}>
          <Box className={classes.IconRow}>
            <BulbIcon />
            <Typography className={classes.infoText}>
              Did you know...
            </Typography>
          </Box>
          <Typography className={classes.loadingText}>
            {CONFIRM_INFO_LOADING_DESCRIPTION}
          </Typography>
        </Box>
      </Box>
    );
  };

  const RenderDataLoading = () => {
    return (
      <Box className={classes.contentBox}>
        <Typography variant="h4" className={classes.loadingText}>
          {'Calculating income & \n household information...'}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {isLoading && <CLoading>{isDataReady ? RenderGuidelinesLoading() :  RenderDataLoading()}</CLoading>}
      <Container maxWidth="sm" className={classes.container}>
        <TitleText
          title={CONFIRM_INCOME_INFO_TITLE}
          description={CONFIRM_INCOME_INFO_MAIN_DESCRIPTION}
        />

        <div className={classes.accountDetailHeader}>
          <Typography variant="body3" className={classes.otherAccount}>
            Household Size
            <Typography variant="span" className={classes.accountUpdateIcons}>
              <EditOutlinedIcon
                onClick={() => {
                  setHouseholdSize({ ...householdSize });
                  setHouseholdSizeEdit(true);
                  updateStep('AddHouseholdSize');
                  sendAmplitudeData('Household_size_Edit');
                }}
                color="primary"
                className={classes.accountIcons}
              />
            </Typography>
          </Typography>
          <Typography variant="body3">{householdSize}</Typography>
        </div>

        <div className={classes.accountDetailTitle}>
          <Typography variant="body3" color="purple40.main">
            Amount
          </Typography>
        </div>
        <div className={classes.accountDetail}>
          <Typography variant="body3" className={classes.otherAccount}>
            Your Monthly Income
            <Typography variant="span" className={classes.accountUpdateIcons}>
              <EditOutlinedIcon
                onClick={() => {
                  income['monthlyIncome']['edit'] = true;
                  setIncome({ ...income });
                  updateStep(-6);
                  sendAmplitudeData('CalcSetup_9_spousalEdit');
                }}
                color="primary"
                className={classes.accountIcons}
              />
            </Typography>
          </Typography>
          <Typography variant="body3">
            {currencyFormatter(userIncome)}
          </Typography>
        </div>
        {income['spouseIncome'] && income['spouseIncome']['amount'] ? (
          <div className={classes.accountDetail}>
            <Typography variant="body3" className={classes.otherAccount}>
              Spousal Monthly Income
              <Typography variant="span" className={classes.accountUpdateIcons}>
                <EditOutlinedIcon
                  onClick={() => {
                    income['spouseIncome']['edit'] = true;
                    setIncome({ ...income });
                    updateStep(-2);
                    sendAmplitudeData('CalcSetup_9_spousalEdit');
                  }}
                  color="primary"
                  className={classes.accountIcons}
                />
                <DeleteOutlineOutlinedIcon
                  onClick={() => {
                    income['spouseIncome']['amount'] = 0;
                    setIncome({ ...income });
                    sendAmplitudeData('CalcSetup_9_spousalDelete');
                  }}
                  color="primary"
                  className={classes.accountIcons}
                />
              </Typography>
            </Typography>
            <Typography variant="body3">
              {currencyFormatter(income['spouseIncome']['amount'])}
            </Typography>
          </div>
        ) : null}
        {income['otherIncome'] && income['otherIncome']['amount'] ? (
          <div className={classes.accountDetail}>
            <Typography variant="body3" className={classes.otherAccount}>
              Other Monthly Income
              <Typography variant="span" className={classes.accountUpdateIcons}>
                <EditOutlinedIcon
                  onClick={() => {
                    income['otherIncome']['edit'] = true;
                    setIncome({ ...income });
                    updateStep(-1);
                    sendAmplitudeData('CalcSetup_9_otherEdit');
                  }}
                  color="primary"
                  className={classes.accountIcons}
                />
                <DeleteOutlineOutlinedIcon
                  onClick={() => {
                    income['otherIncome']['amount'] = 0;
                    setIncome({ ...income });
                    sendAmplitudeData('CalcSetup_9_otherDelete');
                  }}
                  color="primary"
                  className={classes.accountIcons}
                />
              </Typography>
            </Typography>
            <Typography variant="body3">
              {currencyFormatter(income['otherIncome']['amount'])}
            </Typography>
          </div>
        ) : null}
        <div className={classes.accountDetailFooter}>
          <Typography variant="body3">Total Income</Typography>
          <Typography variant="body3">
            {currencyFormatter(getTotalIncome())}
          </Typography>
        </div>
        <Typography className={classes.description}>
          {CONFIRM_INCOME_INFO_DESCRIPTIONP1}
        </Typography>
        <FormControlLabel
          sx={{ marginTop: 3 }}
          className={classes.checkbox}
          label={CONFIRM_INCOME_INFO_DESCRIPTIONP2}
          control={<Checkbox checked={checked} onChange={handleChange} />}
        />
        <CButton
          sx={{ ...buttonSx }}
          variant="contained"
          fullWidth
          loading={userState.loading}
          disabled={!checked}
          onClick={() => onFinish()}>
          Finish
        </CButton>
      </Container>
    </>
  );
}
