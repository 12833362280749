import * as React from 'react';
import { Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function CloseButton(props) {
  return (
    <Grid item display="flex" justifyContent="flex-end">
      <IconButton {...props}>
        <CloseIcon />
      </IconButton>
    </Grid>
  );
}
