import React, { useEffect, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TitleText from '../../../../components/common/TitleText';
import GlobalStyle from '../../../../styles/GlobalStyle';
import DebtInfo from './DebtInfo';
import IncomeInfo from './IncomeInfo';
import accountAvtar from '../../../../assets/images/accountAvtar.svg';
import { Context as PaymentContext } from '../../../../context/PaymentContext';
import { Context as UserContext } from '../../../../context/UserContext';
import CLoading from '../../../../components/common/CLoading';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  description: {
    fontFamily: 'OpenSans',
    marginBottom: '15px',
    marginTop: '10px',
    fontWeight: 'bold',
  },
  description2: {
    marginTop: '20px',
    marginBottom: '30px',
  },
  accountDetailTitle: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'flex-end',
    margin: '0px 0px 8px 0px',
    fontWeight: 600,
  },
  accountDetail: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    fontWeight: 600,
  },
  accountDetailFooter: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
    marginBottom: '10px',
    fontWeight: 600,
    borderTop: '1px solid',
    borderColor: '#C0B9C7',
    paddingTop: '15px',
  },
  otherAccount: {
    display: 'flex',
  },
  accountUpdateIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  accountIcons: {
    height: '20px',
    marginLeft: '5px',
    cursor: 'pointer',
  },
  editButton: {
    marginTop: 20,
    backgroundColor: '#F3F1F5',
    padding: '6px 10px',
  },
  infoBox: {
    marginBottom: 20,
    marginTop: 40,
  },
  accountItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  avtarItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '20px',
  },
  avtarImage: {
    marginRight: '5px',
  },
  subItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

function CompassSettingList({ setStep, isLoading }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState();

  const { state: userState, checkAuthentication } = useContext(UserContext);
  const {
    state: paymentState,
    dispatch,
    getAllLinkedBanks,
  } = useContext(PaymentContext);

  useEffect(() => {
    if (userState.id) {
      dispatch(
        getAllLinkedBanks(userState.jwt, userState.id, checkAuthentication),
      );
    }
  }, [userState.id]);

  useEffect(() => {
    setUserInfo({ ...userState });
  }, [userState]);

  const accounts = useMemo(() => {
    let accountData = [];
    if (userState['compassInfo'] && userState['compassInfo']['debtInfo']) {
      accountData = accountData.concat(userState['compassInfo']['debtInfo']);
    }
    return accountData;
  }, [userState]);

  const linkedPayroll = useMemo(() => {
    if (!paymentState?.allLinkedBanks?.length) return null;
    return paymentState?.allLinkedBanks.find((v) => v.plaidType === 'payroll');
  }, [paymentState.allLinkedBanks]);

  return (
    <Container maxWidth="sm">
      <TitleText title="BuoyFi Calculator Settings" />
      {isLoading && <CLoading />}
      <Box>
        {userInfo && accounts ? (
          <>
            <Box className={classes.infoBox}>
              <Typography className={classes.description}>
                Bill Information
              </Typography>
              <Typography className={classes.description2}>
                Here is a detailed breakdown and total of your medical bill:
              </Typography>
              <DebtInfo accounts={accounts} setStep={setStep} />
              <Button
                className={classes.editButton}
                onClick={() => setStep('EditDebtInfo')}>
                Edit
              </Button>
            </Box>
            <Box className={classes.infoBox}>
              <Typography className={classes.description}>
                Income Information
              </Typography>
              <Typography className={classes.description2}>
                Here is a detailed breakdown and total of all your income:
              </Typography>
              <IncomeInfo
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                setStep={setStep}
              />
              <Button
                className={classes.editButton}
                onClick={() => {
                  navigate('/choose-tool/compass', {
                    state: {
                      step: 'ConnectPayrollPlaid',
                      edit: true,
                    },
                  });
                  // setStep()
                }}>
                Edit
              </Button>
            </Box>
          </>
        ) : null}
      </Box>
    </Container>
  );
}

export default CompassSettingList;
