import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

import GlobalStyle from '../../../styles/GlobalStyle';
import {
  LINK_BILL_PAY_DESCRIPTION,
  LINK_BILL_PAY_TITLE,
} from 'capio-common/src/constants';
import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import CloseButton from '../../../components/CloseButton';

import { Context as PaymentContext } from '../../../context/PaymentContext';
import { Context as UserContext } from '../../../context/UserContext';

import AccountBackSvg from '../../../assets/images/accountBackImage.svg';
import { sendAmplitudeData } from '../../../utilities/amplitude';
import CLoading from '../../../components/common/CLoading';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
    position: 'relative',
  },
  accountImage: {},
  linkBill: {
    position: 'fixed',
    height: '100vh',
    width: '100%',
    top: 0,
    left: 0,
    overflowY: 'auto',
    zIndex: 9999,
    background: 'white',
  },
}));

const REFRESH_TOKEN_INTERVAL = 4 * 60 * 1000; // 4 mins

export default function LinkBillPay({ updateStep }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const billPayRef = useRef(null);
  const widgetRef = useRef(null);
  const widgetSubscriptionRef = useRef(null);

  const { state: userState, updateUserInfo } = useContext(UserContext);
  const {
    state: paymentState,
    registerBillGoUser,
    getBillGoUser,
    getBillGoToken,
  } = useContext(PaymentContext);
  const [billGoInfo, setBillGoInfo] = useState({
    userId: '',
    token: '',
  });
  const [showBillPay, setShowBillPay] = useState(false);
  const [billgoInfo, setBillgoInfo] = useState(null);
  const [subWidgetInfo, setSubWidgetInfo] = useState(null);

  const onContinue = async () => {
    sendAmplitudeData('PaymentSetup_2_SetUpBillPay', {
      click_name: 'Continue',
    });
    let billGoResponse = null;
    try {
      billGoResponse = await getBillGoUser(userState.jwt);
      if (!billGoResponse.status) {
        billGoResponse = await registerBillGoUser(userState.jwt);
      }
      if (!billGoResponse.status) return;

      console.log('BillGo User Information:', billGoResponse);

      const { status: tokenStatus, token } = await getBillGoToken(
        userState.jwt,
      );
      if (!tokenStatus) return;
      console.log(billGoResponse, 'billResponse---');
      setBillgoInfo({ token, userId: billGoResponse.data.id });
      setShowBillPay(true);
      window.resetUser(token);
      window.addWidgetToPage(
        'add-payee',
        { token, userId: billGoResponse.data.id },
        { widgetName: 'AddPayee', eventName: 'ADD_PAYEE' },
        {
          dontUseShadowDom: true,
        },
        (evt) => {
          // Log events here if you want. You will care about some, but probably ignore most.
          console.log('[BillGO Bill Pay Widget]', evt);
          if (
            evt.role === 'BILL_PAY_HOME_BUTTON_CLICKED' ||
            evt.role === 'DONE_BUTTON_CLICKED'
          ) {
            setShowBillPay(false);
            sendAmplitudeData('SavingsSetup_4_BillPayConnect');
            updateStep(1);
          }
          if (evt.role === 'LINK_BILLS_BUTTON_CLICKED') {
            setSubWidgetInfo({ merchantId: evt.payee.merchantDirectoryId });
          }
        },
      );
    } catch (e) {
      console.error('Error in billgo user:', e);
    }
  };

  const onLater = async () => {
    sendAmplitudeData('PaymentSetup_2_SetUpBillPay', {
      click_name: 'I’ll do this later',
    });
    let billGoResponse = null;
    try {
      billGoResponse = await getBillGoUser(userState.jwt);
      if (!billGoResponse.status) {
        billGoResponse = await registerBillGoUser(userState.jwt);
      }
      if (!billGoResponse.status) return;

      console.log('BillGo User Information:', billGoResponse);

      const { status: tokenStatus, token } = await getBillGoToken(
        userState.jwt,
      );
      if (!tokenStatus) return;
      console.log(billGoResponse, 'billResponse---');
      updateStep(1);
    } catch (e) {
      console.error('Error in billgo user:', e);
    }
  };

  useEffect(() => {
    updateUserInfo({
      id: userState['userSub'],
      appStatus: {
        parent: 'Home',
        sub: 'LinkBillAccount',
      },
    });
  }, []);

  useEffect(() => {
    if (!showBillPay) return;
    let timer = setInterval(async () => {
      const { status: tokenStatus, token } = await getBillGoToken(
        userState.jwt,
      );
      if (!tokenStatus) return;
      console.log(token, ': refreshed token');
      window.updateToken(token);
    }, REFRESH_TOKEN_INTERVAL);
    return () => {
      clearInterval(timer);
    };
  }, [showBillPay]);

  useEffect(async () => {
    if (!billgoInfo || !subWidgetInfo) return;

    window.addWidgetToPage(
      'add-payee',
      billgoInfo,
      { widgetName: 'LinkPayee', eventName: 'LINK_PAYEE' },
      subWidgetInfo,
      {
        dontUseShadowDom: true,
      },
      (evt) => {
        if (
          evt.role === 'BILL_PAY_HOME_BUTTON_CLICKED' ||
          evt.role === 'DONE_BUTTON_CLICKED'
        ) {
          setShowBillPay(false);
          sendAmplitudeData('SavingsSetup_4_BillPayConnect');
          updateStep(1);
        }
      },
    );
  }, [billgoInfo, subWidgetInfo]);

  return (
    <>
      {(paymentState.loading || userState.loading) && <CLoading />}
      <Container maxWidth="sm" className={classes.container}>
        {showBillPay ? (
          <Box
            id="add-payee"
            sx={{
              maxHeight: 500,
              overflow: 'auto',
              height: '100vh',
            }}
          />
        ) : (
          <>
            <TitleText
              title={LINK_BILL_PAY_TITLE}
              description={LINK_BILL_PAY_DESCRIPTION}
            />
            <img
              className={classes.accountImage}
              width={'auto'}
              height={240}
              src={AccountBackSvg}
              alt="account"
            />
            <CButton
              sx={{ marginTop: 4 }}
              variant="contained"
              fullWidth
              loading={paymentState.loading}
              onClick={onContinue}>
              Continue
            </CButton>
            <CButton
              variant="outlined"
              sx={{ mt: 2 }}
              fullWidth
              onClick={onLater}>
              I’ll do this later
            </CButton>
          </>
        )}
      </Container>
    </>
  );
}
