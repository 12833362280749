import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import { makeStyles } from '@mui/styles';
import { Button, Container } from '@mui/material';
import Divider from '@mui/material/Divider';
import { isToday, parseISO } from 'date-fns';
import { Context as UserContext } from '../../context/UserContext';
import { Context as PaymentContext } from '../../context/PaymentContext';

import Notification from './Notification';
import TitleText from '../../components/common/TitleText';
import BackButton from '../../components/BackButton';
import CLoading from '../../components/common/CLoading';

const useStyles = makeStyles(() => ({
  button: {
    padding: '8px 10px',
    color: '#494C93',
    display: 'block',
    margin: 'auto',
    marginTop: 20,
    marginBottom: 20,
    fontWeight: '400',
  },
  subHeader: {
    fontSize: 13,
    fontWeight: '600',
    color: '#3C1D57',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
    position: 'relative',
  },
  title: {
    '& h5': {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  },
  backButton: {
    position: 'absolute',
    left: 0,
  },
}));

function Notifications() {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    dispatch: paymentDispatch,
    getPlaidLinkTokenUpdate,
    state: paymentState,
  } = useContext(PaymentContext);
  const {
    state,
    getUserNotifications,
    updateNotificationStatus,
    checkAuthentication,
  } = useContext(UserContext);
  const [newUserNotifications, setNewUserNotifications] = useState([]);
  const [earlierUserNotifications, setEarlierUserNotifications] = useState([]);
  const [nextToken, setNextToken] = useState(null);

  useEffect(async () => {
    await handleLoadNotifications();

    return () => {
      setNewUserNotifications([]);
      setEarlierUserNotifications([]);
    };
  }, [state.id]);

  const handleLoadNotifications = async (
    nextToken = null,
    loadMore = false,
    limit = 10,
  ) => {
    if (loadMore && !nextToken) return;

    const notificationResponse = await getUserNotifications({
      userId: state.id,
      sortDirection: 'DESC',
      limit,
      nextToken,
    });

    if (loadMore) {
      setEarlierUserNotifications([
        ...earlierUserNotifications,
        ...notificationResponse.items,
      ]);
    } else {
      setNewUserNotifications(
        notificationResponse.items.filter(({ createdAt }) =>
          isToday(parseISO(createdAt)),
        ),
      );
      setEarlierUserNotifications(
        notificationResponse.items.filter(
          ({ createdAt }) => !isToday(parseISO(createdAt)),
        ),
      );
    }

    await Promise.all(
      notificationResponse?.items.map((notification) => {
        return updateNotificationStatus({
          id: notification.id,
          isRead: true,
        });
      }),
    );

    setNextToken(notificationResponse.nextToken);
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const onClickItem = useCallback(
    (item) => {
      if (!item.itemId) {
        console.warn("There's no valid itemId.");
        return;
      }
      paymentDispatch(
        getPlaidLinkTokenUpdate(
          state.jwt,
          item.itemId,
          item.notificationId,
          checkAuthentication,
          () => {
            navigate('/connect-bank/update');
          },
        ),
      );
    },
    [state?.jwt, checkAuthentication, navigate, paymentDispatch],
  );

  return (
    <Container maxWidth="sm">
      <Container maxWidth="sm" className={classes.titleContainer}>
        <BackButton className={classes.backButton} onClick={handleBackButton} />
        <TitleText title="Notifications" className={classes.title} />
      </Container>
      <Divider />
      {paymentState.loading && <CLoading />}
      {newUserNotifications.length > 0 && (
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className={classes.subHeader}>
              New
            </ListSubheader>
          }>
          {newUserNotifications.map((item, index) => (
            <Notification
              item={item}
              key={`notification_${index}`}
              onClickItem={onClickItem}
            />
          ))}
        </List>
      )}
      {earlierUserNotifications.length > 0 && (
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className={classes.subHeader}>
              Earlier
            </ListSubheader>
          }>
          {earlierUserNotifications.map((item, index) => (
            <Notification
              item={item}
              key={`notification_${index}`}
              onClickItem={onClickItem}
            />
          ))}
        </List>
      )}
      <Button
        className={classes.button}
        onClick={() => handleLoadNotifications(nextToken, true)}
        disabled={!nextToken}>
        Load more
      </Button>
    </Container>
  );
}

export default Notifications;
