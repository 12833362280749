import React, { useState } from 'react';
import { Container, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

import WithdrawFund from './WithdrawFund';
import WithdrawResult from './WithdrawResult';
import ReviewTransaction from './ReviewTransaction';
import GlobalStyle from '../../../../styles/GlobalStyle';

const steps = ['WithdrawFund', 'ReviewTransaction', 'WithdrawResult'];

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function WithdrawFunds() {
  const [step, setStep] = useState(steps[0]);
  const [data, setData] = useState({ amount: '' });
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);

  const classes = useStyles();

  const updateStep = (move) => {
    const newStep = steps[steps.indexOf(step) + move];
    setStep(newStep);
  };

  const updateData = (amount) => {
    setData({
      amount,
      date: moment().format('MM/DD/YYYY'),
    });
  };

  let activeComponent = '';
  switch (step) {
    case 'WithdrawFund':
      activeComponent = (
        <WithdrawFund updateStep={updateStep} updateData={updateData} />
      );
      break;
    case 'ReviewTransaction':
      activeComponent = (
        <ReviewTransaction
          data={data}
          updateStep={updateStep}
          setWithdrawSuccess={setWithdrawSuccess}
        />
      );
      break;
    case 'WithdrawResult':
      activeComponent = (
        <WithdrawResult
          withdrawSuccess={withdrawSuccess}
          data={data}
          updateStep={updateStep}
        />
      );
      break;
  }

  return (
    <Container maxWidth="sm" className={classes.mainContent}>
      <Card sx={{ width: '100%' }}>
        <CardContent>{activeComponent}</CardContent>
      </Card>
    </Container>
  );
}

export default WithdrawFunds;
