import React, { useState, useContext, useEffect } from 'react';
import { Container, Grid, Switch, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TitleText from '../../../components/common/TitleText';
import { Context as UserContext } from '../../../context/UserContext';
import CLoading from '../../../components/common/CLoading';

const useStyles = makeStyles(() => ({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: 16,
  },
}));

function Notifications() {
  const [notification, setNotification] = useState({
    emailNotification: false,
    pushNotification: false,
  });
  const classes = useStyles();
  const { state: userState, updateUserInfo } = useContext(UserContext);

  useEffect(() => {
    if (userState.notifySetting) {
      setNotification(userState.notifySetting);
    }
  }, [userState.notifySetting]);

  const toggleEnableNotification = async (val) => {
    setNotification(val);
    await updateUserInfo({
      id: userState['userSub'],
      notifySetting: val,
    });
  };

  if (userState.loading) {
    return <CLoading />;
  }

  return (
    <Container maxWidth="sm">
      <TitleText title="Notifications" />
      <Grid item className={classes.item}>
        <Typography>Push Notifications</Typography>
        <Switch
          checked={notification.pushNotification}
          onChange={(e) =>
            toggleEnableNotification({
              pushNotification: e.target.checked,
            })
          }
          color="primary"
        />
      </Grid>
    </Container>
  );
}

export default Notifications;
