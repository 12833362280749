import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { WIDGET_TYPE, BILLGO_WIDGET } from 'capio-common/src/constants';

function ScheduledPayments() {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const handleMessage = (message) => {
      console.log('PostMessage from the app:', message.data);
      if (!message.data) return;
      const parsedData = JSON.parse(message.data);
      window.resetUser(parsedData.token);
      window.addWidgetToPage(
        WIDGET_TYPE.SCHEDULED_PAYMENTS,
        { token: parsedData.token, userId: parsedData.userId },
        BILLGO_WIDGET[WIDGET_TYPE.SCHEDULED_PAYMENTS],
        {
          uiOptions: {
            hideSchedulePayment: true,
          },
        },
        (evt) => {
          console.log('[BillGO Bill Pay Widget]', evt);
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              `BillGo SCHEDULED_PAYMENTS Widget Event: ${evt.role}`,
            );
            window.ReactNativeWebView.postMessage(JSON.stringify(evt));
          }
        },
      );
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const maxWidth = useMemo(() => {
    return searchParams.get('width') - 48 ?? 342;
  }, [searchParams]);

  return (
    <div
      id="scheduled-payments"
      style={{ width: '100%', maxWidth: maxWidth, margin: '0 auto' }}></div>
  );
}

export default ScheduledPayments;
