import * as React from 'react';
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Alert,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(() => ({
  prefix: {
    color: 'primary',
  },
}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

export default function CPrefixInput(props) {
  const classes = useStyles();
  const {
    name,
    control,
    rules,
    inputId,
    label,
    prefix,
    error,
    helperText,
    placeholder,
    disabled,
  } = props;
  const invalidChars = ['-', '+', 'e'];

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <FormControl fullWidth>
            <InputLabel htmlFor={inputId}>{label}</InputLabel>
            <OutlinedInput
              {...field}
              disabled={disabled}
              id={inputId}
              label={label}
              inputProps={{
                min: 0,
                max: 99999999999,
              }}
              error={error}
              inputComponent={NumberFormatCustom}
              startAdornment={
                <InputAdornment position="start">{prefix}</InputAdornment>
              }
              placeholder={placeholder}
              onKeyDown={(e) => {
                if (invalidChars.includes(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            <Typography
              color="error.main"
              mr={2}
              ml={2}
              mt={0.5}
              variant="span">
              {helperText}
            </Typography>
          </FormControl>
        );
      }}
    />
  );
}
