import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 40px 20px 20px',
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 8,
  },
  selectedItem: {
    border: '2px solid #41923A',
  },
  subItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

function RoundSetItem(props) {
  const classes = useStyles();
  const { title, subTitle, price, subPrice, icon, selectedItem, onClick } =
    props;

  return (
    <Box
      className={clsx(classes.item, {
        [classes.selectedItem]: selectedItem === title,
      })}
      boxShadow={3}
      onClick={onClick}>
      {icon}
      <Grid container ml={1.5} direction="column">
        <Grid item className={classes.subItem}>
          <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
          {price && (
            <Typography sx={{ fontWeight: 600 }} color="coreGreen60.main">
              {price}
            </Typography>
          )}
        </Grid>
        <Grid item className={classes.subItem} mt={1}>
          <Typography variant="body2">{subTitle}</Typography>
          {subPrice && (
            <Typography variant="body2" color="coreGreen60.main">
              {subPrice}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

RoundSetItem.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  price: PropTypes.string,
  subPrice: PropTypes.string,
  icon: PropTypes.node,
  selectedItem: PropTypes.string,
  onClick: PropTypes.func,
};

export default RoundSetItem;
