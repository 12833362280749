import React, { createContext } from 'react';
import { Auth, API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
// import OneSignal from 'react-onesignal';

import {
  ACTION_COMPLETE,
  ACTION_START,
  USER_SIGNUP,
  USER_SETUP,
  SET_VERIFICATION_TYPE,
  VERIFY_PHONE,
  SET_LOADING,
  GET_TOS,
  GET_POLICY,
  GET_CAPIO_ACCOUNT,
  USER_PAYMENT_SETUP,
  USER_CLEAR,
  GET_USER_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
  AGREE_TOS,
  VERIFY_TOS
} from './types';
import CreateDataContext from './CreateDataContext';
import {
  createUserInformationEncrypt,
  updateUserInformationEncrypt,
  updateUserNotifications,
} from 'capio-common/src/graphql/mutations';
import {
  getUserInformationEncrypt,
  userInformationByEmailEncrypt,
  userNotificationsByUserId,
} from 'capio-common/src/graphql/queries';
import {
  ACCOUNT_EXISTS,
  API_BILL_GO,
  API_CONTACT,
  API_DIVINITY,
  API_SYNAPSE,
  API_USER,
  API_UTILS,
  API_NOTIFICATION,
  CODE_RESENT_MESSAGE_ERROR,
  ENTER_VALID_EMAIL,
  INCORRECT_INFO,
  MISMATCH_PHONE_NUMBER,
  SOMETHING_WRONG,
  UNABLE_TO_SIGNIN,
} from 'capio-common/src/constants';
import { formatError } from 'capio-common/src/main';
import { setAmplitudeUserId, determineAndSetTestProperty } from '../utilities/amplitude';

export const UserContext = createContext();

const initialState = {
  userSub: null,
  userConfirmed: false,
  jwt: '',
  email: '',
  firstName: '',
  lastName: '',
  dob: '',
  ssn: '',
  phone: '',
  address: {
    city: '',
    postalCode: '',
    streetAddress: '',
    state: '',
  },
  emailVerified: false,
  phoneVerified: false,
  updateProfileFlow: false,
  verificationType: 'phone',
  verificationInput: '',
  docVerificationType: '',
  photoFront: '',
  photoBack: '',
  forgotPasswordResult: false,
  paymentSetupFlags: {
    billpay: false,
    compass: false,
  },
  loading: false,
  error: null,
  errorCount: 0,
  showOnboarding: true,
  showSideMenu: false,
  policyStatus: null,
  capioAccount: null,
  compassInfo: {},
  unreadNotificationCount: 0,
};

const setLoading = (dispatch, loading) => {
  let payload = { loading };
  if (loading) {
    payload.error = null;
  }
  dispatch({
    type: SET_LOADING,
    payload,
  });
};

const checkUserInformation = (dispatch) => {
  return async (email) => {
    try {
      setLoading(dispatch, true);

      const { data } = await API.graphql({
        query: userInformationByEmailEncrypt,
        variables: {
          email,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      if (data.userInformationByEmailEncrypt.items.length > 0) {
        return { status: false, message: ACCOUNT_EXISTS };
      } else {
        return { status: true };
      }
    } catch (error) {
      dispatch({
        type: USER_SIGNUP,
        payload: { error },
      });
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const checkUser = (dispatch) => {
  return async (email) => {
    const path = `/user/checked`;
    const init = {
      body: {
        email,
      },
    };

    try {
      setLoading(dispatch, true);
      const res = await API.post(API_USER, path, init);
      if (res?.id) {
        return { status: false, message: ACCOUNT_EXISTS };
      } else {
        return { status: true };
      }
    } catch (error) {
      console.log('error check user:', error);
      return { status: false, message: SOMETHING_WRONG };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const signUp = (dispatch) => {
  return async (userSubData, email, password, phone, verificationType) => {
    try {
      setLoading(dispatch, true);

      if (userSubData) {
        // need to delete user first as we created user when send verification code
        const path = `/user/delete`;
        const init = {
          body: {
            email,
          },
        };
        await API.del(API_USER, path, init);
      }

      let attributes;
      if (verificationType === 'phone') {
        attributes = { phone_number: phone };
      } else {
        attributes = { email };
      }
      const authResult = await Auth.signUp({
        username: email,
        password,
        attributes,
      });

      setLoading(dispatch, false);
      const { userSub } = authResult;
      dispatch({
        type: USER_SIGNUP,
        payload: { userSub },
      });

      return { status: true };
    } catch (error) {
      console.log('error sign up:', error);
      setLoading(dispatch, false);
      dispatch({
        type: USER_SIGNUP,
        payload: { error },
      });

      if (error.code === 'UsernameExistsException') {
        return { status: false, message: ACCOUNT_EXISTS };
      } else {
        return { status: false, message: SOMETHING_WRONG };
      }
    }
  };
};

const confirmSignUp = (dispatch) => {
  return async (username, code) => {
    try {
      setLoading(dispatch, true);
      const confirmResult = await Auth.confirmSignUp(username, code);

      setLoading(dispatch, false);
      dispatch({
        type: USER_SIGNUP,
        payload: { userConfirmed: confirmResult === 'SUCCESS' },
      });

      return true;
    } catch (error) {
      console.log('error confirm up:', error);
      setLoading(dispatch, false);
      dispatch({
        type: USER_SIGNUP,
        payload: { error, userConfirmed: false },
      });

      return false;
    }
  };
};

const resendSignUp = (dispatch) => {
  return async (username) => {
    try {
      const resendResult = await Auth.resendSignUp(username);
      return { status: true };
    } catch (error) {
      console.log('error resend:', error);
      return { status: false, message: CODE_RESENT_MESSAGE_ERROR };
    } finally {
    }
  };
};

const getUserInfo = (dispatch) => {
  return async (username) => {
    try {
      setLoading(dispatch, true);
      const { data } = await API.graphql({
        query: getUserInformationEncrypt,
        variables: {
          id: username,
          userId: username,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
      if (data && data.getUserInformationEncrypt) {
        console.log('graphql look at updating icome info from here')
        console.log(data.getUserInformationEncrypt);
        console.log(`the user income information is`);
        console.log(data.getUserInformationEncrypt.incomeInformation);
        const {
          address,
          email,
          capioAccounts,
          compassInfo,
          personalInfo,
          policyStatus,
          paymentSetupFlags,
          incomeInformation
        } = data.getUserInformationEncrypt;
        const {
          dateOfBirth,
          lastFourSSN,
          legalFirstName,
          legalLastName,
          mobileNumber,
        } = personalInfo;
        console.log(`palyoad is`);
        let p = {
          ...data.getUserInformationEncrypt,
          email,
          capioAccount: capioAccounts?.length > 0 ? capioAccounts : null,
          compassInfo,
          dob: dateOfBirth,
          firstName: legalFirstName,
          lastName: legalLastName,
          phone: mobileNumber,
          ssn: lastFourSSN,
          policyStatus,
          paymentSetupFlags,
        };
        console.log(p);
        dispatch({
          type: USER_SETUP,
          payload: {
            ...data.getUserInformationEncrypt,
            email,
            capioAccount: capioAccounts?.length > 0 ? capioAccounts : null,
            compassInfo,
            dob: dateOfBirth,
            firstName: legalFirstName,
            lastName: legalLastName,
            phone: mobileNumber,
            ssn: lastFourSSN,
            policyStatus,
            paymentSetupFlags,
          },
        });
      }
    } catch (err) {
      console.log('get user info error', err);
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const signIn = (dispatch) => {
  return async (username, password, errorCount) => {
    try {
      setLoading(dispatch, true);
      const path = '/user/signin';
      const init = {
        body: {
          username,
          password,
        },
      };
      await API.post(API_USER, path, init);
      const signinResult = await Auth.signIn({
        username,
        password,
      });
      const currentSession = await Auth.currentSession();
      const jwt = currentSession.getAccessToken().getJwtToken();
      dispatch({
        type: USER_SETUP,
        payload: { jwt, userSub: signinResult.username },
      });

      const { data } = await API.graphql({
        query: getUserInformationEncrypt,
        variables: {
          id: signinResult.username,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const { email_verified, phone_number_verified } = signinResult.attributes;

      if (data && data.getUserInformationEncrypt) {
        const {
          address,
          email,
          capioAccounts,
          compassInfo,
          personalInfo,
          policyStatus,
          paymentSetupFlags,
        } = data.getUserInformationEncrypt;
        const { city, postalCode, streetAddress } = address;
        const {
          dateOfBirth,
          lastFourSSN,
          legalFirstName,
          legalLastName,
          mobileNumber,
        } = personalInfo;

        dispatch({
          type: USER_SETUP,
          payload: {
            ...data.getUserInformationEncrypt,
            email,
            capioAccount: capioAccounts?.length > 0 ? capioAccounts : null,
            compassInfo,
            dob: dateOfBirth,
            firstName: legalFirstName,
            lastName: legalLastName,
            phone: mobileNumber,
            ssn: lastFourSSN,
            emailVerified: email_verified,
            phoneVerified: phone_number_verified,
            policyStatus,
            paymentSetupFlags,
          },
        });
        sessionStorage.setItem('buoyfiToken', jwt);
        sessionStorage.setItem('buoyfiUserId', signinResult.username);
        setAmplitudeUserId(signinResult.username);
        determineAndSetTestProperty(email);
      }
      return '';
    } catch (error) {
      console.log('error signin:', error);
      dispatch({
        type: USER_SETUP,
        payload: { error: error.response?.data?.message ?? SOMETHING_WRONG },
      });
      return error.response?.data?.message ?? SOMETHING_WRONG;
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const handleRefreshToken = (dispatch) => {
  return async () => {
    const currentSession = await Auth.currentSession();
    const jwt = currentSession.getAccessToken().getJwtToken();
    dispatch({
      type: USER_SETUP,
      payload: {
        jwt,
      },
    });
  };
};

const verifyDoc = (dispatch) => {
  return async (params) => {
    // const id = await OneSignal.getUserId();
    const { jwt, photoFront, photoBack, userSub } = params;
    const path = '/synapse/users';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        photoFront,
        photoBack,
        docType: 'id_card',
        fp: userSub,
      },
    };

    try {
      setLoading(dispatch, true);
      await API.post(API_SYNAPSE, path, init);
      return true;
    } catch (error) {
      return error.response?.data?.message || SOMETHING_WRONG;
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const forgotEmail = (dispatch) => {
  return async (phone) => {
    const path = '/user/forgotEmail';
    const init = {
      body: {
        mobileNumber: phone,
      },
    };

    try {
      setLoading(dispatch, true);
      const res = await API.post(API_USER, path, init);
      return { status: true };
    } catch (error) {
      console.log('error forgot:', error);
      return { status: false, message: MISMATCH_PHONE_NUMBER };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const confirmForgotEmail = (dispatch) => {
  return async (mobileNumber, verifyCode) => {
    const path = '/user/forgotEmail/confirm';
    const init = {
      body: {
        mobileNumber,
        verifyCode,
      },
    };

    try {
      setLoading(dispatch, true);
      const res = await API.post(API_USER, path, init);
      const { email } = res[0];
      dispatch({
        type: USER_SETUP,
        payload: { email, userConfirmed: true },
      });

      return true;
    } catch (error) {
      console.log('error forgot confirm:', error);
      dispatch({
        type: USER_SETUP,
        payload: { userConfirmed: false },
      });
      return false;
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const forgotPassword = (dispatch) => {
  return async (username, showLoading = true) => {
    try {
      if (showLoading) {
        setLoading(dispatch, true);
      }
      await Auth.forgotPassword(username);
      return { status: true };
    } catch (error) {
      if (error.code === 'UserNotFoundException') {
        return { status: false, message: ENTER_VALID_EMAIL };
      } else {
        return { status: false, message: CODE_RESENT_MESSAGE_ERROR };
      }
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const forgotPasswordSubmit = (dispatch) => {
  return async (username, code, password) => {
    try {
      setLoading(dispatch, true);
      const res = await Auth.forgotPasswordSubmit(username, code, password);
      dispatch({
        type: USER_SETUP,
        payload: { forgotPasswordResult: res === 'SUCCESS' },
      });
      return { status: true };
    } catch (error) {
      dispatch({
        type: USER_SETUP,
        payload: { forgotPasswordResult: false },
      });
      return { status: false, message: error.message };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const changePassword = (dispatch) => {
  return async (oldPass, newPass) => {
    try {
      setLoading(dispatch, true);
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldPass, newPass);
      return { status: true };
    } catch (error) {
      return {
        status: false,
        message:
          error?.code === 'NotAuthorizedException'
            ? 'Incorrect password'
            : error?.message,
      };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const createUser = (dispatch) => {
  return async (params) => {
    const { email, password, phone, dob, firstName, lastName, address, ssn } =
      params;
    try {
      setLoading(dispatch, true);

      const loggedUser = await Auth.signIn({
        username: email,
        password,
      });

      await Auth.updateUserAttributes(loggedUser, {
        birthdate: dob,
        given_name: firstName,
        family_name: lastName,
        address: `${address.streetAddress}, ${address.city}, ${address.state} ${address.postalCode} USA`,
        // email,
        // phone_number: phone,
      });

      const currentSession = await Auth.currentSession();
      const jwt = currentSession.getAccessToken().getJwtToken();
      // Jacob: TODO: can we just this jwt to agree to terms?
      sessionStorage.setItem('buoyfiToken', jwt);
      sessionStorage.setItem('buoyfiUserId', loggedUser.username);

      setAmplitudeUserId(loggedUser.username);
      determineAndSetTestProperty(email);
      const data = {
        id: loggedUser.username, // same as userSub
        userId: loggedUser.username,
        email,
        personalInfo: {
          mobileNumber: phone,
          dateOfBirth: dob,
          legalFirstName: firstName,
          legalLastName: lastName,
          lastFourSSN: ssn,
        },
        address,
        emailSearch: email.toLowerCase(),
        lastLogged: new Date().toISOString(),
        searchContent: {
          email: email.toLowerCase(),
          legalFirstName: firstName.toLowerCase(),
          legalLastName: lastName.toLowerCase(),
          mobileNumber: phone,
          stateName: address.state.toLowerCase(),
          lastLogged: new Date().toISOString(),
        },
        notifySetting: {
          pushNotification: true,
        }
      };

      const userInfo = await API.graphql({
        query: createUserInformationEncrypt,
        variables: {
          input: data,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
    const path = `/user/terms/agree`;
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await API.post(API_USER, path, init);
    console.log(result);
    console.log(`hopefully`);

      dispatch({
        type: USER_SIGNUP,
        payload: {
          jwt,
          ...userInfo.data.createUserInformationEncrypt,
          ...data,
        },
      });
    } catch (error) {
      console.log('user create error:', error);
    } finally {
      setLoading(dispatch, false);
    }
  };
};


const updateUserInfo = (dispatch) => {
  return async (params, paymentSetupFlags) => {
    try {
      setLoading(dispatch, true);
      const { data } = await API.graphql({
        query: updateUserInformationEncrypt,
        variables: {
          input: params,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
      dispatch({
        type: USER_SETUP,
        payload: data.updateUserInformationEncrypt,
      });
      if (paymentSetupFlags) {
        dispatch({
          type: USER_PAYMENT_SETUP,
          payload: paymentSetupFlags,
        });
      }
    } catch (error) {
      console.log('user update error:', error);
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const updateCapioAccount = (dispatch) => {
  return async (jwt, data) => {
    const path = `/divinity/update-capio-account`;
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: data,
    };
    try {
      setLoading(dispatch, true);
      const result = await API.post(API_DIVINITY, path, init);
      setLoading(dispatch, false);
      return { status: true, data: result };
    } catch (error) {
      return { status: false };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const signOut = (dispatch) => {
  // window.removeWidgetFromPage('bills-due');
  // window.removeWidgetFromPage('add-payee');
  return async () => {
    dispatch({
      type: USER_CLEAR,
      payload: initialState,
    });
    await Auth.signOut();
  };
};

const updateUserAttributes = (dispatch) => {
  return async (newAttributes) => {
    try {
      setLoading(dispatch, true);
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(currentUser, newAttributes);
      return { status: true };
    } catch (error) {
      console.log('update user error', error);
      return { status: false, message: error.message };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const verifyCurrentUserAttributeSubmit = (dispatch) => {
  return async (verificationType, value) => {
    try {
      setLoading(dispatch, true);
      await Auth.verifyCurrentUserAttributeSubmit(verificationType, value);
      return { status: true };
    } catch (error) {
      return { status: false, message: error.message };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const verifyAddress = (dispatch) => {
  return async (params) => {
    const path = '/billgo/address_validation';
    const init = {
      body: params,
    };

    try {
      setLoading(dispatch, true);
      await API.post(API_BILL_GO, path, init);
      return { status: true };
    } catch (error) {
      return { status: false, message: formatError(error) };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const updateBillGoUser = (dispatch) => {
  return async (jwt, body) => {
    const path = '/billgo/users';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body,
    };

    try {
      setLoading(dispatch, true);
      const data = await API.patch(API_BILL_GO, path, init);
      dispatch({
        type: USER_SETUP,
        payload: { error: null },
      });
      return { status: true, data };
    } catch (error) {
      dispatch({
        type: USER_SETUP,
        payload: { error: error.response?.data?.message ?? SOMETHING_WRONG },
      });
      console.log('register billgo user error ===>', error);
      return {
        status: false,
        message: error.response?.data?.message || SOMETHING_WRONG,
      };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getPolicy = (dispatch) => {
  return async () => {
    const path = '/common/policy';

    try {
      setLoading(dispatch, true);
      const res = await API.get(API_UTILS, path, {});
      dispatch({
        type: GET_POLICY,
        payload: res,
      });
      return { status: true, data: res };
    } catch (error) {
      return { status: false };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getTOS = (dispatch) => {
  return async () => {
    const path = '/user/terms';
    try {
      setLoading(dispatch, true);
      const res = await API.get(API_USER, path, {});
      dispatch({
        type: GET_TOS,
        payload: res,
      });
      return { status: true, data: res };
    } catch (error) {
      return { status: false };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const compareTOSVersion = (dispatch) => {
  return async (jwt) => {
    const path = `/user/terms/verify`;
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    console.log(init);

    try {
      setLoading(dispatch, true);
      const result = await API.post(API_USER, path, init);
      console.log('')
      return { status: true, data: result };
    } catch (error) {
      console.log(error);
      return { status: false };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const agreeTOSVersion = (dispatch) => {
  return async (jwt) => {
    const path = `/user/terms/agree`;
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    console.log(init);

    try {
      setLoading(dispatch, true);
      const result = await API.post(API_USER, path, init);
      return { status: true, data: result };
    } catch (error) {
      return { status: false };
    } finally {
      setLoading(dispatch, false);
    }
  };
};


const comparePolicyVersion = (dispatch) => {
  return async (jwt) => {
    const path = `/common/comparePolicy?policyStatus=1`;
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const result = await API.get(API_UTILS, path, init);
      return { status: true, data: result };
    } catch (error) {
      return { status: false };
    } finally {
      setLoading(dispatch, false);
    }
  };
};



const checkAuthentication = () => {
  return async (error) => {
    if (error?.response?.status === 401) {
      signOut();
    }
  };
};

const getCapioAccount = (dispatch) => {
  return async (jwt) => {
    const path = `/divinity/get-capio-accounts`;
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    try {
      setLoading(dispatch, true);
      const result = await API.get(API_DIVINITY, path, init);
      dispatch({
        type: GET_CAPIO_ACCOUNT,
        payload: result?.length > 0 ? result : null,
      });
      return { status: true, data: result };
    } catch (error) {
      return { status: false };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const addContact = (dispatch) => {
  return async (jwt, data) => {
    const path = '/divinity/contacts';
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: data,
    };

    try {
      setLoading(dispatch, true);
      const result = await API.post(API_DIVINITY, path, init);
      setLoading(dispatch, false);
      return { status: true, data: result };
    } catch (error) {
      return { status: false };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getUserNotifications = (dispatch) => {
  return async (params) => {
    try {
      setLoading(dispatch, true);
      const { data } = await API.graphql({
        query: userNotificationsByUserId,
        variables: {
          ...params,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
      console.log(data.userNotificationsByUserId);
      return data.userNotificationsByUserId;
    } catch (error) {
      console.log('Get user notifications error:', error);
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const getUnreadNotificationCount = (dispatch) => {
  return async (params) => {
    try {
      let unreadNotificationCount = 0;
      let nextToken;

      do {
        const { data } = await API.graphql({
          query: userNotificationsByUserId,
          variables: {
            ...params,
            filter: {
              isRead: {
                eq: false,
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        unreadNotificationCount += data.userNotificationsByUserId.items.length;

        nextToken = data.userNotificationsByUserId.nextToken;
      } while (nextToken);

      dispatch({
        type: GET_UNREAD_NOTIFICATIONS,
        payload: unreadNotificationCount,
      });
    } catch (error) {
      console.log('Get unread user notifications error:', error);
    }
  };
};

const updateUserTags = (dispatch) => {
  return async (jwt, { userId, tags }) => {
    const path = `/notifications/segments/${userId}`;
    const init = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        tags,
      },
    };

    try {
      setLoading(dispatch, true);
      const result = await API.patch(API_NOTIFICATION, path, init);
      return { status: true, data: result };
    } catch (error) {
      return { status: false };
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const updateNotificationStatus = (dispatch) => {
  return async (params) => {
    try {
      setLoading(dispatch, true);
      const { data } = await API.graphql({
        query: updateUserNotifications,
        variables: {
          input: params,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
      return data.updateUserNotifications;
    } catch (error) {
      console.log('User notification status update error:', error);
      return null;
    } finally {
      setLoading(dispatch, false);
    }
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        ...action.payload,
      };
    case USER_SIGNUP:
      return {
        ...state,
        ...action.payload,
      };
    case USER_SETUP:
      return {
        ...state,
        ...action.payload,
      };
    case USER_CLEAR:
      return { ...initialState };
    case USER_PAYMENT_SETUP:
      return {
        ...state,
        paymentSetupFlags: {
          ...state.paymentSetupFlags,
          ...action.payload,
        },
      };
    case SET_VERIFICATION_TYPE:
      const { verificationType } = action.payload;
      return {
        ...state,
        verificationType,
      };
    case VERIFY_PHONE:
      return {
        ...state,
        ...action.payload,
      };
    case ACTION_START:
      return {
        ...state,
        loading: true,
      };
    case ACTION_COMPLETE:
      return {
        ...state,
        loading: false,
      };
    case GET_POLICY:
      return {
        ...state,
        policyData: action.payload,
      };
    case GET_TOS:
      return {
        ...state,
        tosData: action.payload,
      };
    case GET_CAPIO_ACCOUNT:
      return {
        ...state,
        capioAccount: action.payload,
      };
    case GET_USER_NOTIFICATIONS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        unreadNotificationCount: action.payload,
      };
    case VERIFY_TOS:
      return {
        ...state, 
        ...action.payload
      };
    case AGREE_TOS:
        return {
          ...state,
          ...action.payload
        }
    default:
      return state;
  }
};

export const dispatch = (dispatch) => {
  return async (action) => {
    dispatch(action);
  };
};

export const { Provider, Context } = CreateDataContext(
  reducer,
  {
    checkUserInformation,
    checkUser,
    updateUserInfo,
    getUserInfo,
    signUp,
    signIn,
    signOut,
    handleRefreshToken,
    confirmSignUp,
    resendSignUp,
    verifyDoc,
    forgotEmail,
    confirmForgotEmail,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
    createUser,
    updateUserAttributes,
    verifyCurrentUserAttributeSubmit,
    verifyAddress,
    getPolicy,
    getTOS,
    comparePolicyVersion,
    compareTOSVersion,
    agreeTOSVersion,
    checkAuthentication,
    getCapioAccount,
    updateCapioAccount,
    addContact,
    updateBillGoUser,
    getUserNotifications,
    getUnreadNotificationCount,
    updateUserTags,
    updateNotificationStatus,
    dispatch,
  },
  initialState,
);
