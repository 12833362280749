import React, { useContext, useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TitleText from '../../components/common/TitleText';
import CloseButton from '../../components/CloseButton';
import GlobalStyle from '../../styles/GlobalStyle';
import { Context as UserContext } from '../../context/UserContext';
import {
  TERMS_SERVICE,
  TERMS_URL,
  PRIVACY_URL,
  AGREEMENT_URL,
} from 'capio-common/src/constants';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    ...GlobalStyle.container,
    height: '75vh',
  },
  closeButton: {
    position: 'absolute',
    top: '25px',
    right: '25px',
  },
}));

function TOS(props) {
  const { updateStep, backStepCount, terms, termsUrl, closeTerms = () => {} } = props;
  const { getPolicy, getTOS } = useContext(UserContext);
  const [latestTOS, setLatestTOS] = useState();
  const classes = useStyles();
  const [tosContent, setTOSContent] = useState();
  const [policyContent, setPolicyContent] = useState();

  useEffect(() => {
    const getPolicyData = async () => {
      const res = await getPolicy();
      if (res && res['data']) {
        setPolicyContent(res['data']);
      }
    };
    const getTOSData = async () => {
      const res = await getTOS();
      if (res && res['data']) {
        setLatestTOS(res['data']['tosURL']);
      }
    };
    getTOSData();
    // if (terms === 1) {
    //   getTOSData();
    // } else {
    //   getPolicyData();
    // }
  }, []);

  return (
    <Container className={classes.container}>
      <CloseButton
        className={classes.closeButton}
        onClick={() =>
          backStepCount ? updateStep(backStepCount) : closeTerms()
        }
      />
      <TitleText
        title={
          terms === 1
            ? 'Terms of Service'
            : terms === 2
            ? 'Privacy Policy'
            : 'Custodial Agreement'
        }
        // description={
        //   terms === 1
        //     ? tosContent
        //       ? tosContent['content']
        //       : TERMS_SERVICE
        //     : policyContent
        //     ? policyContent['content']
        //     : TERMS_SERVICE
        // }
      />
      <iframe
        src={
        termsUrl ?? (terms === 1 ? latestTOS : terms === 2 ? PRIVACY_URL : AGREEMENT_URL)
        }
        frameBorder="0"
        scrolling="auto"
        height="100%"
        width="100%"></iframe>
    </Container>
  );
}

export default TOS;
