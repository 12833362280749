import React, { useContext, useEffect, useState } from 'react';
import { Container, Grid, Switch, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Context as PaymentContext } from '../../../../context/PaymentContext';
import { Context as UserContext } from '../../../../context/UserContext';
import TitleText from '../../../../components/common/TitleText';
import GlobalStyle from '../../../../styles/GlobalStyle';
import CButton from '../../../../components/common/CButton';
import { SET_ROUND_UP } from '../../../../context/types';
import CLoading from '../../../../components/common/CLoading';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: 16,
  },
}));

function RoundSettings(props) {
  const { updateStep } = props;
  const { state: userState, checkAuthentication } = useContext(UserContext);
  const { state, dispatch, updateRoundUp, setUpRoundUp } =
    useContext(PaymentContext);
  const {
    roundUp: { autoDeposit: autoDepositSettings, depositDateLabel },
  } = state;
  const classes = useStyles();
  const [autoDeposit, setAutoDeposit] = useState(false);

  useEffect(() => {
    if (depositDateLabel === 'undefinedth') {
      setAutoDeposit(false);
      return;
    }
    setAutoDeposit(autoDepositSettings);
  }, [dispatch, autoDepositSettings, depositDateLabel]);

  const handleSwitchChange = (event) => {
    setAutoDeposit(event.target.checked);
    if (event.target.checked) {
      dispatch({
        type: SET_ROUND_UP,
        payload: { roundUpEditing: true },
      });
      updateStep(1);
      return;
    }

    if (state.roundUp.id && state.roundUp.id !== 'null') {
      updateRoundUp(
        userState.jwt,
        {
          ...state.roundUp,
          depositMode: 'manual',
          // frequency: 'manual',
          autoDeposit: false,
          manualDate: new Date(),
          createdAt: new Date(),
          amount: 0,
        },
        checkAuthentication,
      );
    } else {
      setUpRoundUp(
        userState.jwt,
        {
          ...state.roundUp,
          autoDeposit: false,
          depositMode: 'manual',
        },
        checkAuthentication,
      );
    }
  };

  const handleNext = () => {
    dispatch({
      type: SET_ROUND_UP,
      payload: { roundUpEditing: true },
    });
    updateStep(1);
  };

  return (
    <Container
      maxWidth="sm"
      className={classes.container}
      sx={{ position: 'relative' }}>
      <TitleText
        title="BuoyFi Savings Settings"
        description="Set up recurring deposits and automatically add funds to BuoyFi Savings from your bank"
      />
      {state.loading && <CLoading />}

      <Grid item className={classes.item}>
        <Typography variant="body3">Auto Deposit</Typography>
        <Switch
          checked={autoDeposit}
          onChange={handleSwitchChange}
          color="success"
        />
      </Grid>
      <Grid item className={classes.item}>
        <Typography variant="body3" mt={1}>
          Frequency
        </Typography>
        <CButton variant="text" disabled={!autoDeposit} onClick={handleNext}>
          <Typography
            variant="body3"
            mr={1}
            color={autoDeposit ? 'success.main' : ''}>
            {autoDeposit && depositDateLabel
              ? `Every ${depositDateLabel}`
              : 'Off'}
          </Typography>
        </CButton>
      </Grid>
    </Container>
  );
}

export default RoundSettings;
