import React, { useContext, useMemo, useState } from 'react';
import { Container, Card, CardContent, Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PersonalInfo from './PersonalInfo';
import CreateAccount from './CreateAccount';
import ContactInfo from './ContactInfo';
import BackButton from '../../components/BackButton';
import CButton from '../../components/common/CButton';
import MFA from './MFA';
import OTP from './OTP';
import VerifyResult from './VerifyResult';
import TOS from './TOS';
import DocVerification from './DocVerification';
import DocChecklist from './DocChecklist';
import DocSuccess from './DocSuccess';
import RoundSetUp from './RoundSetUp';
import RoundWithdraw from './RoundWithdraw';
import RoundWeekly from './RoundWeekly';
import RoundMonthly from './RoundMonthly';
import RoundManual from './RoundManual';
import RoundResult from './RoundResult';
import RoundDateSelection from './RoundDateSelection';
import { Context as UserContext } from '../../context/UserContext';
import { USER_SETUP } from '../../context/types';
import LogoImage from '../../components/LogoImage';
import CopyRight from '../../components/common/CopyRight';

const useStyles = makeStyles(() => ({
  cardContent: {
    position: 'relative',
  },
}));

const steps = [
  'CreateAccount',
  'PersonalInfo',
  'ContactInfo',
  'MFA',
  'OTP',
  'VerifyResult',
  'TOS',
  'DocVerification',
  'DocChecklist',
  'DocSuccess',
  'RoundSetUp',
  'RoundWithdraw',
  'RoundWeekly',
  'RoundMonthly',
  'RoundManual',
  'RoundResult',
  'RoundDateSelection',
];

function KYC(props) {
  const classes = useStyles();
  const { updateAuthStep } = props;
  const [step, setStep] = useState(steps[0]);
  const [terms, setTerms] = useState(1);
  const { dispatch } = useContext(UserContext);
  const { state } = useContext(UserContext);

  const updateStep = (move, terms) => {
    if (terms) setTerms(terms);
    const newStep = steps[steps.indexOf(step) + move];
    setStep(newStep);

    if (newStep === 'CreateAccount') {
      dispatch({
        type: USER_SETUP,
        payload: { showOnboarding: true },
      });
    } else {
      dispatch({
        type: USER_SETUP,
        payload: { showOnboarding: false },
      });
    }
  };

  const onClickBack = () => {
    if (step === 'RoundDateSelection') {
      updateStep(-3);
    } else if (step === 'RoundManual') {
      updateStep(-3);
    } else if (step === 'RoundMonthly') {
      updateStep(-2);
    } else {
      updateStep(-1);
    }
  };

  const hideBackButton =
    step === 'CreateAccount' ||
    step === 'TOS' ||
    step === 'VerifyResult' ||
    step === 'DocSuccess' ||
    step === 'RoundResult';

  const showSignInButton = step === 'CreateAccount';

  const activeComponent = useMemo(() => {
    let temp = '';
    switch (step) {
      case 'PersonalInfo':
        temp = <PersonalInfo updateStep={updateStep} />;
        break;
      case 'CreateAccount':
        temp = <CreateAccount updateStep={updateStep} />;
        break;
      case 'ContactInfo':
        temp = <ContactInfo updateStep={updateStep} />;
        break;
      case 'MFA':
        temp = <MFA updateStep={updateStep} />;
        break;
      case 'OTP':
        temp = <OTP updateStep={updateStep} />;
        break;
      case 'VerifyResult':
        temp = <VerifyResult updateStep={updateStep} />;
        break;
      case 'TOS':
        temp = <TOS updateStep={updateStep} backStepCount={-6} terms={terms} />;
        break;
      case 'DocVerification':
        temp = <DocVerification updateStep={updateStep} />;
        break;
      case 'DocChecklist':
        temp = <DocChecklist updateStep={updateStep} />;
        break;
      case 'DocSuccess':
        temp = <DocSuccess updateStep={updateStep} />;
        break;
      case 'RoundSetUp':
        temp = <RoundSetUp updateStep={updateStep} />;
        break;
      case 'RoundWithdraw':
        temp = <RoundWithdraw updateStep={updateStep} />;
        break;
      case 'RoundWeekly':
        temp = <RoundWeekly updateStep={updateStep} />;
        break;
      case 'RoundMonthly':
        temp = <RoundMonthly updateStep={updateStep} />;
        break;
      case 'RoundManual':
        temp = <RoundManual updateStep={updateStep} />;
        break;
      case 'RoundResult':
        temp = <RoundResult updateStep={updateStep} />;
        break;
      case 'RoundDateSelection':
        temp = <RoundDateSelection updateStep={updateStep} />;
        break;
      default:
        temp = '';
    }
    return temp;
  }, [step]);

  return (
    <Container maxWidth="sm" sx={{ marginTop: -1 }}>
      <Grid display="flex" justifyContent="flex-end">
        <CButton
          data-testid="loginLink"
          sx={{ visibility: showSignInButton ? 'visible' : 'hidden' }}
          variant="text"
          onClick={() => updateAuthStep(1)}>
          Sign in
        </CButton>
      </Grid>
      <Card>
        <CardContent className={classes.cardContent}>
          <Grid display="flex" justifyContent="space-between">
            <BackButton
              sx={{
                visibility: hideBackButton ? 'hidden' : 'visible',
                color: 'black',
              }}
              onClick={onClickBack}
            />
            {!state.showOnboarding ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <LogoImage />
              </Box>
            ) : null}
            <Box sx={{ width: 30 }} />
          </Grid>

          {activeComponent}
        </CardContent>
      </Card>
      <Box bottom={4} mt={4}>
        <CopyRight />
      </Box>
    </Container>
  );
}

export default KYC;
