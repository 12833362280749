import React, { useState, useContext } from 'react';
import { Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PasswordChecklist from 'react-password-checklist';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import CTextField from '../../../components/common/CTextField';
import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import CAlert from '../../../components/common/CAlert';
import { Context as UserContext } from '../../../context/UserContext';
import {
  PASSWORD_NOT_MATCH,
  PWD_MINIMUM_8_CHARS,
  PWD_ONE_NUMBER,
  PWD_ONE_SPECIAL_CHAR,
  PWD_UPPER_LOWER_LETTERS,
} from 'capio-common/src/constants';

import GlobalStyle from '../../../styles/GlobalStyle';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function ChangePassword(props) {
  const navigate = useNavigate();
  const { state, dispatch, changePassword } = useContext(UserContext);
  const classes = useStyles();
  const [fields, setFields] = useState({
    oldPass: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [validPassword, setValidPassword] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(true);

  const handleInputChange = ({ target }) => {
    setError('');
    setFields({ ...fields, [target.name]: target.value });

    if (target.name === 'confirmPassword') {
      setConfirmPasswordError(fields.password !== target.value);
    }
  };

  const onSubmit = async (data) => {
    const { password } = fields;
    setError('');
    try {
      const res = await changePassword(data.oldPass, password);
      if (res.status) {
        navigate(-1);
      } else {
        setError(res.message);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      confirmPassword: '',
      oldPass: '',
      newPass: '',
    },
  });

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title="Change Your Password"
        description="Enter a new password below to change your password."
      />
      <CAlert severity="error" message={error} />
      <Grid item>
        <CTextField
          fullWidth
          type="password"
          name="oldPass"
          label="Old Password"
          control={control}
          rules={{
            required: { value: true },
          }}
        />
      </Grid>
      <Grid item>
        <CTextField
          fullWidth
          type="password"
          name="password"
          label="New Password"
          value={fields.password}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item>
        <CTextField
          fullWidth
          type="password"
          name="confirmPassword"
          label="Re-enter Password"
          value={fields.confirmPassword}
          onChange={handleInputChange}
          error={confirmPasswordError && fields.confirmPassword}
          helperText={
            confirmPasswordError && fields.confirmPassword && PASSWORD_NOT_MATCH
          }
        />
      </Grid>
      <PasswordChecklist
        rules={['minLength', 'capital', 'number', 'specialChar']}
        minLength={8}
        value={fields.password}
        messages={{
          minLength: PWD_MINIMUM_8_CHARS,
          specialChar: PWD_ONE_SPECIAL_CHAR,
          number: PWD_ONE_NUMBER,
          capital: PWD_UPPER_LOWER_LETTERS,
        }}
        iconComponents={{
          ValidIcon: (
            <CheckCircleIcon
              color="success"
              fontSize="small"
              style={{ marginRight: 8 }}
            />
          ),
          InvalidIcon: (
            <CheckCircleIcon
              color="disabled"
              fontSize="small"
              style={{ marginRight: 8 }}
            />
          ),
        }}
        onChange={setValidPassword}
      />
      <Grid item className={classes.buttonStyle1}>
        <CButton
          variant="contained"
          disabled={confirmPasswordError || !validPassword || !isValid}
          fullWidth
          loading={state.loading}
          onClick={handleSubmit(onSubmit)}>
          Reset Password
        </CButton>
      </Grid>
    </Container>
  );
}

export default ChangePassword;
