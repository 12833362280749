import React, { useState, useContext, useEffect } from 'react';
import { Grid, Container, Typography, Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import CTextField from '../../components/common/CTextField';
import CButton from '../../components/common/CButton';
import CDatePicker from '../../components/common/CDatePicker';
import TitleText from '../../components/common/TitleText';
import BootstrapTooltip from '../home/BootstrapTooltip';
import { NAME_PATTERN, SSN_PATTERN } from 'capio-common/src/patterns';
import { USER_SETUP } from '../../context/types';
import { Context as UserContext } from '../../context/UserContext';
import { validateDate } from 'capio-common/src/main';
import {
  INVALID_FIRST_NAME,
  INVALID_FORMAT,
  INVALID_LAST_NAME,
  MAX_LENGTH_50,
  REQUIRED_BIRTHDAY,
  REQUIRED_FIRST_NAME,
  REQUIRED_LAST_NAME,
  REQUIRED_SSN,
  SSN_MEAN,
} from 'capio-common/src/constants';
import GlobalStyle from '../../styles/GlobalStyle';
import { sendAmplitudeData } from '../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function PersonalInfo(props) {
  const { updateStep } = props;
  const { state, dispatch } = useContext(UserContext);
  const classes = useStyles();
  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    ssn: '',
  });
  const [dobError, setDobError] = useState(REQUIRED_BIRTHDAY);
  const [dob, setDob] = useState('');

  const handleInputChange = ({ target }) => {
    setFields({ ...fields, [target.name]: target.value });
  };

  const handleDateChange = (value) => {
    if (value) {
      setDobError(validateDate(value));
    } else {
      setDobError(REQUIRED_BIRTHDAY);
    }
    const dob = moment(value).format('MM/DD/YYYY');
    setDob(dob);
  };

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      ssn: '',
    },
  });

  const onSubmit = (data) => {
    const { firstName, lastName, ssn } = data;
    const newSSN = ssn.replace('_', '');
    dispatch({
      type: USER_SETUP,
      payload: {
        firstName,
        lastName,
        dob,
        ssn: newSSN.length === 4 ? newSSN : '',
      },
    });
    updateStep(1);
    sendAmplitudeData('InitialKYC_2_PII1');
  };

  useEffect(() => {
    const { firstName, lastName, ssn, dob } = state;
    setValue('firstName', firstName, { shouldValidate: !!firstName });
    setValue('lastName', lastName, { shouldValidate: !!lastName });
    setValue('ssn', ssn, { shouldValidate: !!ssn });
    if (dob) {
      handleDateChange(dob);
    }
  }, []);

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title="Let’s start with some info"
        description="We'll guide you through the process of quickly creating your account."
      />
      <CTextField
        name="firstName"
        control={control}
        rules={{
          required: { value: true, message: REQUIRED_FIRST_NAME },
          pattern: { value: NAME_PATTERN, message: INVALID_FIRST_NAME },
          maxLength: { value: 50, message: MAX_LENGTH_50 },
        }}
        fullWidth
        label="Legal First Name"
        error={!!errors.firstName}
        helperText={errors.firstName && errors.firstName.message}
      />
      <CTextField
        name="lastName"
        control={control}
        rules={{
          required: { value: true, message: REQUIRED_LAST_NAME },
          pattern: { value: NAME_PATTERN, message: INVALID_LAST_NAME },
          maxLength: { value: 50, message: MAX_LENGTH_50 },
        }}
        fullWidth
        label="Legal Last Name"
        error={!!errors.lastName}
        helperText={errors.lastName && errors.lastName.message}
      />
      <CDatePicker
        name="dob"
        label="Date of Birth (mm/dd/yyyy)"
        error={!!dobError && !!dob}
        helperText={dob && dobError}
        value={dob}
        disableOpenPicker
        disableFuture
        onChange={() => {}}
        onBlur={({ target }) => handleDateChange(target.value)}
      />
      <CTextField
        name="ssn"
        control={control}
        // rules={{
        //   required: { value: true, message: REQUIRED_SSN },
        //   pattern: { value: SSN_PATTERN, message: INVALID_FORMAT },
        // }}
        // mask="999-99-9999"
        mask="9999"
        fullWidth
        label="Last 4 digits of SSN (optional)"
        // error={!!errors.ssn}
        // helperText={errors.ssn && errors.ssn.message}
      />
      <Grid display="flex">
        <BootstrapTooltip title={SSN_MEAN} placement="bottom">
          <Box display="flex">
            <InfoOutlinedIcon color="purple70" />
            <Typography color="purple70.main" ml={1}>
              What’s this for?
            </Typography>
          </Box>
        </BootstrapTooltip>
      </Grid>
      <Grid item className={classes.buttonStyle1}>
        <CButton
          variant="contained"
          disabled={!isValid || !!dobError}
          fullWidth
          onClick={handleSubmit(onSubmit)}>
          Continue
        </CButton>
      </Grid>
    </Container>
  );
}

export default PersonalInfo;
