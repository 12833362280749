import React, { useContext, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

import GlobalStyle from '../../../../styles/GlobalStyle';
import CButton from '../../../../components/common/CButton';
import TitleText from '../../../../components/common/TitleText';
import {
  CONNECT_PAYROLL_FAILED_MAIN_DESCRIPTION,
  CONNECT_PAYROLL_FAILED_TITLE,
  CONNECT_PAYROLL_MAIN_DESCRIPTION,
  CONNECT_PAYROLL_SUCCESS,
  CONNECT_PAYROLL_TITLE,
} from 'capio-common/src/constants';
import accountAvtar from '../../../../assets/images/accountAvtar.svg';
import ResultItem from '../../../../components/ResultItem';
import { Context as PaymentContext } from '../../../../context/PaymentContext';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  failedContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 50,
    marginBottom: 24,
    marginLeft: 20,
    marginRight: 20,
  },
  successMessage: {
    backgroundColor: '#DCF1DA',
    padding: '8px 16px',
    borderRadius: '4px',
  },
  avtarItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '20px',
  },
  avtarImage: {
    marginRight: '5px',
  },
  subItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  enterManuallyLink: {
    marginTop: '10px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  closeIcon: {
    backgroundColor: '#EE5071',
    color: 'white',
    marginTop: '50px',
  },
  buttonStyle1: {
    marginTop: 24,
    textAlign: 'center',
  },
  retryButton: {
    height: 50,
    marginBottom: 10,
  },
}));

export default function EditAccountInfo({ setStep }) {
  const { state: paymentState } = useContext(PaymentContext);
  const classes = useStyles();
  const [account, setAccount] = useState({});

  const renderAccountView = () => {
    return (
      <Container maxWidth="sm" className={classes.container}>
        <TitleText
          title={CONNECT_PAYROLL_TITLE}
          description={CONNECT_PAYROLL_MAIN_DESCRIPTION}
        />
        <Grid item className={classes.avtarItem}>
          {/* <img
            className={classes.avtarImage}
            width={60}
            height={60}
            src={accountAvtar}
            alt="account"
          /> */}
          <Grid item className={classes.subItem}>
            <Typography variant="body1" color="corePurple50.main">
              {paymentState &&
              paymentState['plaid'] &&
              paymentState['plaid']['institution']
                ? paymentState['plaid']['institution']['name']
                : ''}
            </Typography>
            <Typography
              variant="span"
              color="secondary"
              sx={{ marginTop: 0.5 }}>
              {'Verified'}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.successMessage}>
          <Typography variant="body2" color="corePurple60.main">
            {CONNECT_PAYROLL_SUCCESS}
          </Typography>
        </div>
        <CButton
          sx={{ marginTop: 15 }}
          variant="contained"
          fullWidth
          onClick={() => setStep('CompassSettingInfo')}>
          Done
        </CButton>
      </Container>
    );
  };

  return (
    <>
      {account ? (
        renderAccountView()
      ) : (
        <Grid className={classes.failedContainer}>
          <ResultItem
            title={CONNECT_PAYROLL_FAILED_TITLE}
            description={CONNECT_PAYROLL_FAILED_MAIN_DESCRIPTION}
            variant="error"
            icon={<CloseIcon style={{ fontSize: 20, color: '#fff' }} />}
          />
          <Grid item className={classes.buttonStyle1}>
            <CButton
              className={classes.retryButton}
              variant="contained"
              fullWidth
              onClick={() => setStep('EditPayrollPlaid')}>
              Back
            </CButton>
          </Grid>
        </Grid>
      )}
    </>
  );
}
