import React, { useState, useContext, useEffect } from 'react';
import {
  Grid,
  Container,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import { Context as UserContext } from '../../../context/UserContext';
import { polishValue, revertPhoneFormat } from 'capio-common/src/main';
import GlobalStyle from '../../../styles/GlobalStyle';
import { sendAmplitudeData } from '../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  checkedLabel: {
    color: '#5255A4',
  },
}));

function W9Certification(props) {
  const { updateStep } = props;
  const classes = useStyles();

  const [checked, setChecked] = useState(false);
  const { state, updateUserInfo } = useContext(UserContext);

  useEffect(() => {
    updateUserInfo({
      id: state['userSub'],
      appStatus: {
        parent: 'Home',
        sub: 'W9Certification',
      },
    });
  }, []);

  const onSubmit = () => {
    sendAmplitudeData('SavingsSetup_9_W-9');
    updateStep(1);
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title="Electronic W-9 Certifications:"
        description="We will verify your identity using the information provided below."
      />
      <Box my={3}>
        <FormControlLabel
          label="I certify, under perjury that: "
          control={
            <Checkbox
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
            />
          }
          className={classes.checkedLabel}
          sx={{
            color: '#5255A4',
          }}
        />
      </Box>

      <Typography className={classes.description}>
        1. The Taxpayer Identification Number or Social Security Number I
        provided is correct;
      </Typography>
      <Typography className={classes.description}>
        2. I am not subject to backup withholding because:
      </Typography>
      <Box ml={2}>
        <Typography className={classes.description}>
          (a) I am exempt from backup withholding, or
        </Typography>
        <Typography className={classes.description}>
          (b) I have not been notified by the Internal Revenue Service (IRS)
          that I am subject to backup withholding as a result of a failure to
          report all interest or dividends, or
        </Typography>
        <Typography className={classes.description}>
          (c) the IRS has notified me that I am no longer subject to backup
          withholding; and
        </Typography>
      </Box>
      <Typography className={classes.description}>
        3. I am a U.S. citizen or other U.S. person
      </Typography>
      <Grid item className={classes.buttonStyle1}>
        <CButton
          variant="contained"
          disabled={!checked}
          loading={state.loading}
          fullWidth
          onClick={onSubmit}>
          Continue
        </CButton>
      </Grid>
    </Container>
  );
}

export default W9Certification;
