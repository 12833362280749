import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import GlobalStyle from '../../../../styles/GlobalStyle';
import TitleText from '../../../../components/common/TitleText';
import BootstrapTooltip from '../../../home/BootstrapTooltip';
import {
  CONNECT_PAYROLL_PLAID_CONFIRM_TEXT,
  CONNECT_PAYROLL_PLAID_MAIN_DESCRIPTION,
  CONNECT_PAYROLL_PLAID_MAIN_DESCRIPTION2,
  CONNECT_PAYROLL_PLAID_TITLE,
  CONNECT_PAYROLL_PLAID_TOOLTIP,
} from 'capio-common/src/constants';
import PlaidButton from '../../../../components/PlaidButton';
import { Context as PaymentContext } from '../../../../context/PaymentContext';
import { Context as UserContext } from '../../../../context/UserContext';
import CLoading from '../../../../components/common/CLoading';
import { sendAmplitudeData } from '../../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
  },
  description: {
    fontFamily: 'OpenSans',
    marginBottom: '10px',
  },
  addLink: {
    marginTop: '10px',
    marginBottom: '20px',
    cursor: 'pointer',
  },
}));

export default function EditPayrollPlaid({ setStep }) {
  const classes = useStyles();
  const {
    dispatch,
    state: paymentState,
    getPlaidPayrollLinkToken,
    exchangePlaidPayrollPublicToken,
    getPlaidBankLinkToken,
    exchangePlaidBankPublicToken,
  } = useContext(PaymentContext);
  const { state: userState, checkAuthentication } = useContext(UserContext);
  const [checked, setChecked] = useState(false);
  const [bankChecked, setBankChecked] = useState(false);
  const [payrollChecked, setPayrollChecked] = useState(false);

  const { plaid, loading } = paymentState;

  // useEffect(() => {
  //   if (!userState.jwt) {
  //     return;
  //   }
  //   dispatch(getPlaidPayrollLinkToken(userState.jwt, checkAuthentication));
  //   dispatch(getPlaidBankLinkToken(userState.jwt, checkAuthentication));
  // }, [userState]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onSuccess = async (success) => {
    sessionStorage.setItem('payrollSuccess', JSON.stringify(success));
    const type = success.metadata?.institution?.institution_id
      ? 'select_verify'
      : 'upload_doc';
    const payload = {
      token: userState.jwt,
      success,
      type,
    };
    setPayrollChecked(false);
    if (type === 'select_verify') {
      const { status } = await exchangePlaidPayrollPublicToken(payload);
      if (status) {
        setStep('EditAccountInfo');
      }
    } else {
      setStep('PayrollDocVerify');
    }
  };

  const onSuccessBank = async (success) => {
    sessionStorage.setItem('payrollSuccess', JSON.stringify(success));
    const _type = success.metadata?.institution?.institution_id
      ? 'select_verify'
      : 'upload_doc';
    setManualIncomeParentName('');
    const payload = {
      token: userState.jwt,
      success,
      type: _type,
    };

    setBankChecked(false);
    if (_type === 'select_verify') {
      const { status, data } = await exchangePlaidBankPublicToken(payload);
      if (status) {
        setIncome({
          ...income,
          monthlyIncome: {
            ...income['monthlyIncome'],
            amount: data['userIncome'],
          },
        });
        updateStep(2);
      }
    } else {
      updateStep(10);
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      {loading && <CLoading />}
      <TitleText
        title={CONNECT_PAYROLL_PLAID_TITLE}
        description={CONNECT_PAYROLL_PLAID_MAIN_DESCRIPTION}
      />
      <Grid display="flex" className={classes.tooltip}>
        <BootstrapTooltip
          title={CONNECT_PAYROLL_PLAID_TOOLTIP}
          placement="bottom">
          <Box display="flex">
            <InfoOutlinedIcon color="purple70" />
            <Typography color="purple70.main" ml={1}>
              What if you can't verify your income with Plaid?
            </Typography>
          </Box>
        </BootstrapTooltip>
      </Grid>
      {/* <Typography
        className={classes.addLink}
        onClick={() => updateStep(1)}
        color="purple70.main">
        What if your employment info is not on Plaid?
      </Typography> */}
      <FormControlLabel
        sx={{ marginTop: 5 }}
        label={CONNECT_PAYROLL_PLAID_CONFIRM_TEXT}
        control={<Checkbox checked={checked} onChange={handleChange} />}
      />
      <PlaidButton
        label="Connect my Bank Account"
        linkToken={plaid.bankLinkToken}
        onSuccess={onSuccessBank}
        loading={loading}
        checked={checked && bankChecked}
        onPress={() => {
          sendAmplitudeData('VerifyIncome_BankAcct');
          setBankChecked(true);
          !plaid.bankLinkToken &&
            dispatch(getPlaidBankLinkToken(userState.jwt, checkAuthentication));
        }}
        setChecked={setBankChecked}
        btnDisabled={!checked}
      />
      <PlaidButton
        label="Connect my Payroll Account"
        linkToken={plaid.payrollLinkToken}
        onSuccess={onSuccess}
        loading={loading}
        checked={checked && payrollChecked}
        onPress={() => {
          sendAmplitudeData('VerifyIncome_Payroll');
          setPayrollChecked(true);
          !plaid.payrollLinkToken &&
            dispatch(
              getPlaidPayrollLinkToken(userState.jwt, checkAuthentication),
            );
        }}
        setChecked={setPayrollChecked}
        btnDisabled={!checked}
      />
    </Container>
  );
}
