import React, { useContext, useEffect, useState } from 'react';

import KYC from '../kyc';
import SignIn from '../signin';
import { Context as UserContext } from '../../context/UserContext';
import { Context as PaymentContext } from '../../context/PaymentContext';

const steps = ['SignUp', 'SignIn'];

function AuthGroup() {
  const [step, setStep] = useState(
    sessionStorage.getItem('loggedInBefore') ? steps[1] : steps[0],
  );
  const { signOut } = useContext(UserContext);
  const { initRoundUp } = useContext(PaymentContext);

  useEffect(() => {
    sessionStorage.removeItem('buoyfiToken');
    sessionStorage.removeItem('buoyfiUserId');
    initRoundUp();
    signOut();
  }, []);

  const updateAuthStep = (move, terms) => {
    if (terms) setTerms(terms);
    const newStep = steps[steps.indexOf(step) + move];
    setStep(newStep);
  };

  let activeComponent = '';
  switch (step) {
    case 'SignIn':
      activeComponent = <SignIn updateAuthStep={updateAuthStep} />;
      break;
    case 'SignUp':
      activeComponent = <KYC updateAuthStep={updateAuthStep} />;
      break;
  }

  return <>{activeComponent}</>;
}

export default AuthGroup;
