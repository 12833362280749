import { PROGRESS_INDICATOR_STEPS } from 'capio-common/src/constants';

const stepOneStrings = ['AddMedicalDebt', 'ConfirmDebtInfo', 'DebtInfo'];

export const stepTwoStrings = [
  'InfoNotINPlaid',
  'ConnectPayrollPlaid',
  'ConnectPayroll',
  'ProvideSpouseIncome',
  'ProvideOtherIncome',
  'ProvideIncomeManually',
  'PayrollDocVerify',
  'PayrollDocSuccess',
  'AddHouseholdSize',
];
const stepThreeStrings = ['ConfirmIncomeInfo'];
export default function useStep(step) {
  if (stepOneStrings.includes(step)) {
    if (step == 'ConfirmDebtInfo') {
      return { currentStep: 1, caption: PROGRESS_INDICATOR_STEPS.Step1Capio };
    }
    return { currentStep: 1, caption: PROGRESS_INDICATOR_STEPS.Step1 };
  }
  if (stepTwoStrings.includes(step)) {
    switch (step) {
      case 'ConnectPayrollPlaid':
        return { currentStep: 2, caption: PROGRESS_INDICATOR_STEPS.Step2Plaid };
      case 'ConnectPayroll':
        return { currentStep: 2, caption: PROGRESS_INDICATOR_STEPS.Step2Plaid };
      case 'AddHouseholdSize':
        return {
          currentStep: 2,
          caption: PROGRESS_INDICATOR_STEPS.Step2AddInfo,
        };
      case 'ProvideSpouseIncome':
      case 'ProvideOtherIncome':
        return {
          currentStep: 2,
          caption: PROGRESS_INDICATOR_STEPS.Step2AddIncome,
        };
      default:
        return {
          currentStep: 2,
          caption: PROGRESS_INDICATOR_STEPS.Step2Manual,
        };
    }
  }
  if (stepThreeStrings.includes(step)) {
    return { currentStep: 3, caption: PROGRESS_INDICATOR_STEPS.Step3 };
  }
}
