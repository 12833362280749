import React, { useContext, useMemo } from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TitleText from '../../../../components/common/TitleText';
import BackButton from '../../../../components/BackButton';
import GlobalStyle from '../../../../styles/GlobalStyle';
import CButton from '../../../../components/common/CButton';
import ReviewItem from '../../../../components/ReviewItem';
import { currencyFormatter } from 'capio-common/src/patterns';
import {
  REVIEW_TRANSACTION_TITLE,
  REVIEW_TRANSACTION_DESCRIPTION,
} from 'capio-common/src/constants';
import { Context as SynapseContext } from '../../../../context/SynapseContext';
import { Context as UserContext } from '../../../../context/UserContext';
import { Context as PaymentContext } from '../../../../context/PaymentContext';
import CLoading from '../../../../components/common/CLoading';
import { sendAmplitudeData } from '../../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
}));

function ReviewTransaction(props) {
  const { data, updateStep, setWithdrawSuccess } = props;
  const classes = useStyles();

  const { withdraw, state } = useContext(SynapseContext);
  const { state: userState } = useContext(UserContext);
  const { state: paymentState } = useContext(PaymentContext);

  const linkedBanks = useMemo(() => {
    return paymentState.allLinkedBanks.filter((v) => v.plaidType === 'billpay');
  }, [paymentState.allLinkedBanks]);

  const handleNext = async () => {
    sendAmplitudeData('Savings_WithdrawFunds_Step2', {click_name: 'Confirm'});
    const res = await withdraw({
      jwt: userState.jwt,
      body: {
        fromAccountType: 'CMA',
        toAccountType: 'ACH-US',
        amount: +data.amount,
        currency: 'USD',
        note: 'From CMA to ACH-US',
        group_id: 'WITHDRAW',
      },
    });
    if (res) {
      updateStep(1);
      setWithdrawSuccess(true);
    } else {
      updateStep(1);
      setWithdrawSuccess(false);
    }
  };

  const handleBack = () => {
    sendAmplitudeData('Savings_WithdrawFunds_Step2', {click_name: 'Back'});
    updateStep(-1);
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Grid item>
        <BackButton
          className={classes.backButton}
          onClick={handleBack}
        />
      </Grid>
      <TitleText
        title={REVIEW_TRANSACTION_TITLE}
        description={REVIEW_TRANSACTION_DESCRIPTION}
      />
      {state.loading && <CLoading />}
      <ReviewItem label="From" description="BuoyFi Savings" />
      <ReviewItem
        label="To"
        description={`${linkedBanks[0]?.account?.name} *${linkedBanks[0]?.account?.mask}`}
      />
      <ReviewItem label="Amount" description={currencyFormatter(data.amount)} />
      <ReviewItem label="Date Scheduled" description={data.date} />
      <Grid item mt={4}>
        <CButton
          variant="contained"
          fullWidth
          onClick={handleNext}
          disabled={state.loading}>
          Confirm
        </CButton>
      </Grid>
    </Container>
  );
}

export default ReviewTransaction;
