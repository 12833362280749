import React, { useContext, useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Context as PaymentContext } from '../../context/PaymentContext';
import { Context as UserContext } from '../../context/UserContext';
import TitleText from '../../components/common/TitleText';
import CButton from '../../components/common/CButton';
import GlobalStyle from '../../styles/GlobalStyle';
import RoundSetItem from '../../components/RoundSetItem';

import { ReactComponent as CoffeeSVG } from '../../assets/images/coffee.svg';
import { ReactComponent as TransferSVG } from '../../assets/images/transfer.svg';
import { ReactComponent as CurrencySVG } from '../../assets/images/currency.svg';
import { sendAmplitudeData } from '../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  plainButton: {
    padding: '8px 10px',
    border: 'none',
    borderRadius: 4,
    color: '#494C93',
    display: 'block',
    margin: '0 auto',
    fontWeight: '600',
  },
}));

function RoundSetUp(props) {
  const { updateStep } = props;
  const { dispatch } = useContext(PaymentContext);
  const { state: userState, updateUserInfo } = useContext(UserContext);

  const [selectedItem, setSelectedItem] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    sendAmplitudeData('SavingsSetup_7B_ViewSetupSavings');
  }, []);

  const onClickContinue = () => {
    sendAmplitudeData('SavingsSetup_7B_ClickSetupSavings', {
      click_name: 'Continue',
    });
    updateStep(1);
    updateUserInfo({
      id: userState['userSub'],
      appStatus: {
        parent: 'Home',
        sub: 'Withdraw',
      },
    });
  };

  const onMaybeLater = () => {
    sendAmplitudeData('SavingsSetup_7B_ClickSetupSavings', {
      click_name: 'Maybe later',
    });
    updateStep(6);
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title="Let’s configure your BuoyFi Savings account"
        description="Round up purchase to the next dollar and set up recurring deposits."
      />
      <RoundSetItem
        title="Cafe M"
        subTitle="Coffee & Tea"
        price="$3.50"
        subPrice="Round Up +$0.50"
        icon={<CoffeeSVG />}
        selectedItem={selectedItem}
      />
      <RoundSetItem
        title="Payment Transfer"
        subTitle="From Round Up"
        price="$0.50"
        icon={<TransferSVG />}
        selectedItem={selectedItem}
      />
      <RoundSetItem
        title="BuoyFi Match"
        subTitle="1% towards your bill"
        price="$0.25"
        icon={<CurrencySVG />}
        selectedItem={selectedItem}
      />
      <Grid item className={classes.buttonStyle1}>
        <CButton variant="contained" fullWidth onClick={onClickContinue}>
          Continue
        </CButton>
        <CButton className={classes.plainButton} onClick={() => onMaybeLater()}>
          Maybe later
        </CButton>
      </Grid>
    </Container>
  );
}

export default RoundSetUp;
