import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, {useContext, useEffect, useState} from 'react';

import TitleText from '../../../components/common/TitleText';
import { TERMS_URL } from 'capio-common/src/constants';
import GlobalStyle from '../../../styles/GlobalStyle';
import { Context as UserContext } from '../../../context/UserContext';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    ...GlobalStyle.container,
    height: '90%',
  },
}));

function TermsService() {
  const classes = useStyles();
  const { getTOS } = useContext(UserContext);
  const [tos, setTOS] = useState();

  useEffect(() => {
    const getTOSData = async () => {
      const res = await getTOS();
      console.log(res);
      if (res && res['data']) {
        setTOS(res['data']['tosURL']);
      }
    };
    getTOSData();
  }, []);

  return (
    <Container className={classes.container}>
      <TitleText title="Terms of Service" />
      <iframe
        src={tos}
        frameBorder="0"
        scrolling="auto"
        height="100%"
        width="100%"></iframe>
    </Container>
  );
}

export default TermsService;
