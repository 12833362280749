import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SUPPORT_EMAIL } from 'capio-common/src/constants';
import { openEmail } from 'capio-common/src/main';

const text = {
  fontSize: 14,
  fontWeight: '400',
};
const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer',
    color: '#494C93',
  },
}));

export default function TermsModalContent({ onClickTerms }) {
  const classes = useStyles();

  return (
    <div>
      <Box>
        <Typography sx={text}>
          We want to inform you about recent updates to our user agreement. The updated sections include information about our "Third Party Vendor" and our "Banking" services.
          These changes have taken effect as of April 4th, 2024.
        </Typography>
        <br />
        <Typography sx={text}>
          The updated terms are available for review here:
          <Link className={classes.link} onClick={() => onClickTerms()}>
            Read User Agreement.
          </Link>
        </Typography>
        <br />
        <Typography sx={text}>
          If you have any questions or concerns about these updates, or if you wish to opt out,
          please feel free to contact us at 888-308-6004 or{' '}
          <Link
            target={'_blank'}
            className={classes.link}
            onClick={() => openEmail()}>
            {SUPPORT_EMAIL}
          </Link>
          . By choosing to continue you agree to have read and accept the terms.
          Thank you for your continued support.
        </Typography>
      </Box>
    </div>
  );
}
