import React, { useState, useContext, useEffect } from 'react';
import {
  Grid,
  Container,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import CAlert from '../../../components/common/CAlert';
import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import { ReactComponent as Clipboard } from '../../../assets/images/clipboard-check.svg';
import { ReactComponent as Warning } from '../../../assets/images/alert-circle.svg';
import { Context as UserContext } from '../../../context/UserContext';
import { Context as SynapseContext } from '../../../context/SynapseContext';
import GlobalStyle from '../../../styles/GlobalStyle';
import CLoading from '../../../components/common/CLoading';
import { sendAmplitudeData } from '../../../utilities/amplitude';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  checkedLabel: {
    color: '#D1D5DB',
  },
  link: {
    cursor: 'pointer',
    color: '#5255A4',
    textDecoration: 'underline',
    fontSize: '16px',
  },
  description: {
    color: '#250E39',
  },
  linkRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
  },
  checkRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -10,
    marginBottom: 2,
  },
  icon: {
    marginRight: 10,
  },
  agree: {
    color: '#250E39',
    fontSize: '13px',
    fontFamily: 'OpenSans',
    fontWeight: 400,
  },
  warningContainer: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#FFF5CC',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    borderRadius: 8,
    marginBottom: '60px',
  },
  warningText: {
    color: '#4D4D4D',
    fontSize: '14px',
    fontFamily: 'OpenSans',
    fontWeight: 400,
    marginLeft: 8,
  },
}));

function Argeement(props) {
  const { updateStep } = props;
  const classes = useStyles();

  const [checked, setChecked] = useState(false);
  const [openDoc, setOpenDoc] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { state: userState, updateUserInfo } = useContext(UserContext);
  const { state: synapseState, docPreview } = useContext(SynapseContext);

  useEffect(() => {
    docPreview({
      jwt: userState.jwt,
      body: {
        type: 'CMA',
        preview_only: true,
      },
    });
    updateUserInfo({
      id: userState['userSub'],
      appStatus: {
        parent: 'Home',
        sub: 'Agreement',
      },
    });
  }, []);

  const onChecked = (event) => {
    if (!openDoc) {
      setShowAlert(true);
      return;
    } else {
      setChecked(event.target.checked);
    }
  };

  const onClick = () => {
    sendAmplitudeData('SavingsSetup_6_ViewAccountAgreement');
    setOpenDoc(true);
    setShowAlert(false);
  };

  const onSubmit = async () => {
    sendAmplitudeData('SavingsSetup_6_ConsentAccountAgreement');
    await docPreview({
      jwt: userState.jwt,
      body: {
        type: 'CMA',
        preview_only: false,
      },
    });
    updateStep(1);
  };

  const RenderWarning = () => {
    return (
      <Box className={classes.warningContainer}>
        <Warning />
        <Typography className={classes.warningText}>
          Please review agreement before continuing.
        </Typography>
      </Box>
    );
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText
        title="Account Terms & Agreement"
        description="Before we create your savings account please agree to the terms and conditions of account usage from our banking partner SynapseFi."
      />
      {synapseState.loading && <CLoading />}
      {synapseState.error && <CAlert message={synapseState.error} />}
      <Grid item>
        {synapseState.docPreview && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Box
              className={classes.linkRow}
              style={{ marginBottom: showAlert ? null : '130px' }}>
              <Clipboard className={classes.icon} />
              <a
                target={'_blank'}
                className={classes.link}
                onClick={() => {
                  onClick();
                }}
                href={synapseState.docPreview?.info?.agreements[0]?.url}>
                Click here to review agreement before continuing
              </a>
            </Box>
            {showAlert ? <RenderWarning /> : null}

            <Box className={classes.checkRow}>
              <Checkbox
                checked={checked}
                onChange={(event) => {
                  onChecked(event);
                }}
                className={classes.checkedLabel}
              />

              <Typography className={classes.agree}>
                I agree to the terms detailed in the link above.
              </Typography>
            </Box>
          </Box>
        )}
        <CButton
          variant="contained"
          disabled={!checked || !openDoc}
          loading={synapseState.loading}
          fullWidth
          onClick={onSubmit}>
          Continue
        </CButton>
      </Grid>
    </Container>
  );
}

export default Argeement;
