import React, { useContext, useMemo } from 'react';
import { Container, Stack, Typography, Box, ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalStyle from '../../styles/GlobalStyle';
import TitleText from '../../components/common/TitleText';
import { ReactComponent as LocationSVG } from '../../assets/images/location-marker.svg';
import { ReactComponent as PhoneSVG } from '../../assets/images/phone.svg';
import { ReactComponent as OfficeSVG } from '../../assets/images/office-building.svg';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 35,
    marginBottom: 35,
    position: 'relative',
  },
}));

export default function DebtInfo({ accounts, accountIndex, setPage }) {
  const classes = useStyles();

  const account = useMemo(() => {
    return accounts[accountIndex];
  }, [accounts, accountIndex]);

  const info = useMemo(() => {
    let temp = {
      company: 'AssetCare LLC',
      address1: 'Dept. 0540',
      address2: 'P.O. Box 120540',
      city: 'Dallas',
      state: 'TX 75312-0540',
      phone: '1 (888) 850-0618',
      link: 'https://ac.mypatientconnect.com/',
    };
    if (account.debt.debtName === 'MBA Law') {
      temp = {
        company: 'Law Offices of Mitchell D. Bluhm & Associates',
        address1: 'Dept. 0267',
        address2: 'P.O. Box 120267',
        city: 'Dallas',
        state: 'TX 75312-0267',
        phone: '1 (888) 850-1612',
        link: 'https://mba.mypatientconnect.com/',
      };
    }
    if (account.debt.debtName === 'Capio Partners') {
      temp = {
        company: 'Capio Partners LLC',
        address1: 'Dept. 0225',
        address2: 'P.O. Box 120225',
        city: 'Dallas',
        state: 'TX 75312-0225',
        phone: '1 (888) 850-1611',
        link: 'https://go.capiopay.com/',
      };
    }
    if (account.debt.debtName === 'Recoveri') {
      temp = {
        company: 'Recoveri LLC',
        address1: 'Dept. 0225',
        address2: 'P.O. Box 120225',
        city: 'Dallas',
        state: 'TX 75312-0225',
        phone: '1 (833)-978-0409',
        link: 'https://recoveri.mypatientconnect.com/',
      };
    }
    return temp;
  }, [account]);

  if (!account) return null;
  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleText title={'Debt Holder Info'} />
      <Stack display={'flex'} flexDirection="row" mt={2}>
        <OfficeSVG />
        <Box sx={{ marginLeft: '15px' }}>
          <Typography color={'#250E39'} fontSize={16}>
            {info.company}
          </Typography>
          <Typography color={'#250E39'} fontSize={16}>
            {info.address1}
          </Typography>
          <Typography color={'#250E39'} fontSize={16}>
            {info.address2}
          </Typography>
          <Typography color={'#250E39'} fontSize={16}>
            {info.city}, {info.state}
          </Typography>
        </Box>
      </Stack>
      <Stack display={'flex'} flexDirection="row" mt={2}>
        <PhoneSVG />
        <Typography sx={{ marginLeft: '15px' }} color={'#250E39'}>
          {info.phone}
        </Typography>
      </Stack>
      <Stack
        display={'flex'}
        flexDirection="row"
        mt={2}
        sx={{ marginBottom: '39px' }}>
        <LocationSVG />
        <ButtonBase onClick={() => setPage('LinkNotice')}>
          <Typography
            sx={{
              marginLeft: '15px',
              textDecoration: 'underline',
              color: '#5255A4',
            }}>
            {info.link}
          </Typography>
        </ButtonBase>
      </Stack>
      <Typography>
        BuoyFi is not attempting to collect your debt, but has provided this
        contact information for your convenience. BuoyFi assumes no liability or
        responsibility for the communication between you and your debt holder
        and does not guarantee that your medical debt holder will honor BuoyFi’s
        payment guidelines.
      </Typography>
    </Container>
  );
}
