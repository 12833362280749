import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { WIDGET_TYPE, BILLGO_WIDGET } from 'capio-common/src/constants';

const AddPayees = () => {
  const [searchParams] = useSearchParams();
  const [subWidgetInfo, setSubWidgetInfo] = useState(null);
  const [billGoUser, setBillGoUser] = useState(null);

  useEffect(() => {
    const handleMessage = (message) => {
      console.log('PostMessage from the app:', message.data);
      if (!message.data) return;
      const parsedData = JSON.parse(message.data);
      setBillGoUser(parsedData);
      window.resetUser(parsedData.token);
      window.addWidgetToPage(
        WIDGET_TYPE.ADD_PAYEE,
        { token: parsedData.token, userId: parsedData.userId },
        BILLGO_WIDGET[WIDGET_TYPE.ADD_PAYEE],
        {
          dontUseShadowDom: true,
        },
        (evt) => {
          if (evt.role === 'LINK_BILLS_BUTTON_CLICKED') {
            setSubWidgetInfo({ merchantId: evt.payee.merchantDirectoryId });
          }
          console.log('[BillGO Bill Pay Widget]', evt);
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              `BillGo ADD_PAYEE Widget Event: ${evt.role}`,
            );
            window.ReactNativeWebView.postMessage(JSON.stringify(evt));
          }
        },
      );
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(async () => {
    if (!billGoUser || !subWidgetInfo) return;

    window.addWidgetToPage(
      WIDGET_TYPE.ADD_PAYEE,
      billGoUser,
      { widgetName: 'LinkPayee', eventName: 'LINK_PAYEE' },
      { ...subWidgetInfo, dontUseShadowDom: true },
      (evt) => {
        console.log('[BillGO Bill Pay Widget]', evt);
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            `BillGo ADD_PAYEE Widget Event: ${evt.role}`,
          );
          window.ReactNativeWebView.postMessage(JSON.stringify(evt));
        }
      },
    );
  }, [billGoUser, subWidgetInfo]);

  const maxWidth = useMemo(() => {
    return searchParams.get('width') - 48 ?? 342;
  }, [searchParams]);

  return (
    <div
      id="add-payee"
      style={{ width: '100%', maxWidth: maxWidth, margin: '0 auto' }}></div>
  );
};

export default AddPayees;
