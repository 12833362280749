import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  SP_NOT_AVAILABLE_BODY,
  SP_NOT_AVAILABLE_TITLE,
} from 'capio-common/src/constants';
import { openEmail } from 'capio-common/src/main';
import LogoImage from '../LogoImage';
import Logo64 from '../../assets/images/logo64.svg';

const text = {
  fontSize: 14,
  fontWeight: '400',
  color: '#000000',
  lineHeight: 'normal',
};
const title = {
  fontSize: 16,
  fontWeight: '600',
  lineHeight: 'normal',
  color: '#000000',
};
const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer',
    color: '#494C93',
  },
}));

export default function SPModalContent({ onClose }) {
  const classes = useStyles();

  return (
    <div>
      <Box>
        <img src={Logo64} />
        <Typography sx={title}>{SP_NOT_AVAILABLE_TITLE}</Typography>
        <br />
        <Typography sx={text}>{SP_NOT_AVAILABLE_BODY}</Typography>
      </Box>
    </div>
  );
}
