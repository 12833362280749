import React, { useMemo, useState, useContext } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';

import { Context as UserContext } from '../../../context/UserContext';
import { Context as SynapseContext } from '../../../context/SynapseContext';
import CButton from '../../../components/common/CButton';
import TitleText from '../../../components/common/TitleText';
import GlobalStyle from '../../../styles/GlobalStyle';
import { currencyFormatter } from 'capio-common/src/patterns';
import CAlert from '../../../components/common/CAlert';
import CLoading from '../../../components/common/CLoading';

const useStyles = makeStyles(() => ({
  ...GlobalStyle,
  button: {
    width: '100%',
  },
}));

export default function TransactionConfirm() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [option, setOption] = useState(null);
  const [error, setError] = useState(null);
  const { state: userState } = useContext(UserContext);
  const {
    state: synapseState,
    disputeTransaction,
    cancelTransaction,
  } = useContext(SynapseContext);

  const transaction = useMemo(() => {
    return [
      {
        label: 'Transaction ID',
        value: state.transaction.transactionID,
      },
      { label: 'Transaction Type', value: state.transaction.transactionType },
      {
        label: 'Accounts of Origin',
        value: state.transaction.fromSource,
      },
      {
        label: 'Destination',
        value:
          state.transaction.toAccount === 'CUSTODY'
            ? 'Save-Up Account'
            : state.transaction.toAccount,
      },
      {
        label: 'Amount',
        value: currencyFormatter(state.transaction.amount),
      },
      {
        label:
          state.transaction.transactionStatus === 'PENDING'
            ? 'Date Scheduled'
            : 'Date Completed',
        value: moment(state.transaction.msCreatedAt).format('YYYY-MM-DD'),
      },
      {
        label: 'Status',
        value: state.transaction.transactionStatus,
      },
      {
        label: 'Description',
        value: state.transaction.description,
      },
    ];
  }, [state]);

  const handleRadioChange = ({ target }) => {
    setOption(target.value);
  };

  const disabled = useMemo(() => {
    if (state.transaction.transactionStatus === 'PENDING') return false;
    if (option) return false;
    return true;
  }, [state.transaction, option]);

  const handleConfirm = async () => {
    let success = false;
    try {
      setError(null);
      if (state.transaction.transactionStatus === 'PENDING') {
        success = await cancelTransaction({
          jwt: userState.jwt,
          transaction: state.transaction,
          status: 'Cancelled',
        });
      } else {
        success = await disputeTransaction({
          jwt: userState.jwt,
          transaction: state.transaction,
          status: 'Completed (Dispute in progress)',
          body: {
            dispute_reason: option,
          },
        });
      }

      if (!success) return;
      navigate('../home/round/transaction-verify', {
        state: {
          transaction: {
            ...state.transaction,
            transactionStatus:
              state.transaction.transactionStatus === 'PENDING'
                ? 'Cancelled'
                : 'Completed (Dispute in progress)',
          },
        },
      });
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  const PaymentListItem = ({ item }) => {
    const { label, value } = item;
    return (
      <Grid
        container
        direction="column"
        borderBottom={1}
        borderColor="#F3F4F6"
        py={2}>
        <Grid item xs={12}>
          <Typography variant="body2" color="corePurple50.main" gutterBottom>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="corePurple50.main" gutterBottom>
            {value}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Card sx={{ width: '100%', marginTop: '24px', padding: '12px' }}>
        {synapseState.loading && <CLoading />}
        <Grid item display="flex" justifyContent="flex-end">
          <IconButton onClick={() => navigate(-1)}>
            <CloseIcon color="primary" />
          </IconButton>
        </Grid>
        <CardHeader
          component={() => (
            <TitleText
              title={
                state.transaction.transactionStatus === 'PENDING'
                  ? 'Cancel a transaction'
                  : 'Dispute a transaction'
              }
              px={2}
              sx={{
                marginBottom: '0 !important',
              }}
              description={
                state.transaction.transactionStatus === 'PENDING'
                  ? 'Are you sure you want to cancel the following transaction?'
                  : 'Are you sure you want to dispute the following transaction?'
              }
            />
          )}
        />
        {error && <CAlert severity="error" message={error} />}
        <CardContent>
          {transaction.map((item, index) => (
            <PaymentListItem item={item} key={index} />
          ))}
          {state.transaction.transactionStatus !== 'PENDING' && (
            <Box mt={4}>
              <Typography variant="body2">
                Which option best describes your situation?
              </Typography>
              <RadioGroup
                value={option}
                onChange={handleRadioChange}
                name="optionGroup">
                <FormControlLabel
                  value="INCORRECT_AMOUNT"
                  control={<Radio />}
                  label="The transaction information is incorrect"
                />
                <FormControlLabel
                  value="UNAUTHORIZED"
                  control={<Radio />}
                  label="I did not authorize this transaction"
                />
                <FormControlLabel
                  value="CHARGED_TWICE"
                  control={<Radio />}
                  label="The transaction was duplicated"
                />
                <FormControlLabel
                  value="CANCEL_RECURRING_PMT"
                  control={<Radio />}
                  label="I cancelled auto deposit but was still charged"
                />
              </RadioGroup>
            </Box>
          )}
          <Box mt={2}>
            <CButton
              variant="contained"
              disabled={disabled}
              sx={{
                width: '100%',
              }}
              onClick={handleConfirm}>
              Confirm
            </CButton>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}
