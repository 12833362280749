import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Grid, Badge, IconButton } from '@mui/material';
import { ReactComponent as CalculatorSVG } from '../../assets/images/calculator-primary.svg';
import { ReactComponent as PaymentSVG } from '../../assets/images/currency-dollar-yellow.svg';
import { ReactComponent as ArrowSVG } from '../../assets/images/tools-button-arrow.svg';
import { ReactComponent as CheckSVG } from '../../assets/images/tools-button-check.svg';
import ToolCard from './../../components/ToolCard';
import { styled } from '@mui/material/styles';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import { Context as PaymentContext } from '../../context/PaymentContext';
import { Context as SynapseContext } from '../../context/SynapseContext';
import { Context as UserContext } from '../../context/UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import CLoading from '../../components/common/CLoading';
import { USER_SETUP } from '../../context/types';
import { sendAmplitudeData } from '../../utilities/amplitude';

const Root = styled(Box)({
  padding: '20px',
});

const Header = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '16px',
});

const SectionTitle = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '16px',
  fontSize: '16px',
  fontWeight: 600,
});

const NewToolsSection = styled(Box)({
  marginTop: '32px',
});

const HomeScreen = () => {
  const {
    state: paymentState,
    getRoundUp,
    getRoundAmounts,
  } = useContext(PaymentContext);
  const {
    state: synapseState,
    getSynapseInfo,
    getRoundupAmount,
  } = useContext(SynapseContext);
  const {
    state: userState,
    checkAuthentication,
    dispatch,
    getUserNotifications,
    getUnreadNotificationCount,
  } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [tools, setTools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [plaidNotification, setPlaidNotification] = useState({});
  const [isVisibleForNotification, setVisibleForNotification] = useState(false);
  const [calculatorProgress, setCalculatorProgress] = useState(null);

  const toolsData = [
    {
      name: 'Calculator',
      description: 'Find affordable payment solutions',
      icon: CalculatorSVG,
    },
    {
      name: 'Payments',
      description: 'Make saving and paying for future medical bills easy',
      icon: PaymentSVG,
    },
  ];

  useEffect(() => {
    sendAmplitudeData('Home_View');
  }, []);

  useEffect(() => {
    const storedProgress = localStorage.getItem('calculatorProgress');
    if (storedProgress !== null) {
      setCalculatorProgress(parseFloat(storedProgress));
    } else {
      setCalculatorProgress(0);
    }
  }, [location]);

  useEffect(() => {
    const storedProgress = localStorage.getItem('calculatorProgress');
    if (storedProgress !== null) {
      setCalculatorProgress(parseFloat(storedProgress));
    } else {
      setCalculatorProgress(0);
    }
  }, [location]);

  useEffect(async () => {
    await getUnreadNotificationCount({
      userId: userState.id,
    });
  }, [userState.id]);

  useEffect(() => {
    dispatch({
      type: USER_SETUP,
      payload: { showSideMenu: true },
    });
    if (userState.jwt && userState.id && calculatorProgress !== null) {
      const fetchData = async () => {
        setLoading(true);
        try {
          await Promise.all([
            /*
           getRoundUp(userState.jwt, checkAuthentication),
           getRoundAmounts(userState.jwt, checkAuthentication),
           getSynapseInfo(userState.jwt),
           getRoundupAmount(userState.jwt),
           */
            handleLoadNotifications(),
          ]);
          const updatedTools = toolsData.map((tool) => {
            if (tool.name === 'Payments') {
              const savingsBalance =
                synapseState.synapseInfo?.balance?.available || 0;
              const roundUpAmount = synapseState.roundupSum || 0;
              const paymentsToolState =
                savingsBalance > 0 ? 'completed' : 'new';
              return {
                ...tool,
                state: paymentsToolState,
                savingsBalance: savingsBalance.toFixed(2),
                roundUpAmount: roundUpAmount.toFixed(2),
              };
            } else if (tool.name === 'Calculator') {
              let calculatorToolState = 'new';
              if (userState.paymentSetupFlags?.compass) {
                calculatorToolState = 'completed';
              } else if (calculatorProgress > 0 && calculatorProgress < 100) {
                calculatorToolState = 'inProgress';
              }
              return {
                ...tool,
                state: calculatorToolState,
                progress: calculatorProgress,
              };
            }
            return tool;
          });
          setTools(updatedTools);
        } catch (err) {
          console.error('Error fetching data:', err);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    } else {
      setLoading(false);
    }
  }, [userState.jwt, userState.id, calculatorProgress]);

  if (loading) {
    return <CLoading />;
  }

  const yourTools = tools.filter((tool) => tool.state !== 'new');
  const newTools = tools.filter((tool) => tool.state === 'new');

  const handleToolEngagement = (tool, isInYourTools) => {
    const { name, state } = tool;

    const toolTileSection = isInYourTools ? 'Engaged' : 'Not Engaged';

    sendAmplitudeData('Home_ToolSelection', {
      [name]: toolTileSection,
    });

    if (name === 'Payments') {
      if (state === 'completed') {
        navigate('/payments');
      } else {
        navigate('/choose-tool/bill-pay');
      }
    } else if (name === 'Calculator') {
      if (state === 'completed') {
        navigate('/compass');
      } else {
        navigate('/choose-tool/compass');
      }
    }
  };

  const handleLoadNotifications = async () => {
    const notificationResponse = await getUserNotifications({
      userId: userState.id,
    });
    const unProcessedNotification = notificationResponse.items.find(
      ({ status }) => status === 'Unprocessed',
    );
    setPlaidNotification(unProcessedNotification);
    if (unProcessedNotification) {
      setVisibleForNotification(true);
    }
  };

  return (
    <Root>
      <Header container>
        <Typography variant="h6" gutterBottom>
          {`Hi, ${userState.firstName}`}
        </Typography>
        <Grid item>
          <Badge
            badgeContent={userState.unreadNotificationCount}
            color="error"
            overlap="circular"
            sx={{
              '& .MuiBadge-badge': {
                color: '#fff',
                backgroundColor: '#FF0000',
              },
            }}>
            <IconButton
              color="black"
              onClick={() => {
                navigate('/notifications');
              }}>
              <NotificationsNoneIcon />
            </IconButton>
          </Badge>
        </Grid>
      </Header>
      {yourTools.length > 0 && (
        <Box>
          <SectionTitle variant="h5">
            <CheckSVG style={{ width: 24, height: 24, marginRight: 8 }} /> Your
            Tools ({yourTools.length})
          </SectionTitle>
          <Grid container spacing={2}>
            {yourTools.map((tool, index) => (
              <Grid item key={index}>
                <ToolCard
                  tool={tool}
                  buttonLabel={tool.state === 'completed' ? 'Open' : 'Continue'}
                  onButtonClick={() => handleToolEngagement(tool, true)}
                  isInYourTools={true}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {newTools.length > 0 && (
        <NewToolsSection>
          <SectionTitle variant="h5">
            <ArrowSVG style={{ width: 24, height: 24, marginRight: 8 }} /> New
            tools for you ({newTools.length})
          </SectionTitle>
          <Grid container spacing={2}>
            {newTools.map((tool, index) => (
              <Grid item key={index}>
                <ToolCard
                  tool={tool}
                  buttonLabel="Start"
                  onButtonClick={() => handleToolEngagement(tool, false)}
                  isInYourTools={false}
                />
              </Grid>
            ))}
          </Grid>
        </NewToolsSection>
      )}
    </Root>
  );
};

export default HomeScreen;
